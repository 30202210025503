import Reduce from './reduce';
import Store from '@mjcloud/redux';
import FormItem from './form-item';
import FormExtendStore from './extendStore';
import FormInstanceBase from '../common/form';
import { ExceptionHelper } from '@mjcloud/exception';
import {
  IDictionary,
  PageModeEnum,
  DataModeEnum,
  DataStateEnum,
  RequiredTypeEnum,
} from '@mjcloud/types';
import {
  IFormState,
  IFormConfig,
  IFormConfigItem,
  IFormConfigStartLoadParams,
  FormActionType,
  FormEventType,
} from './typings';

class Form extends FormInstanceBase<IFormState, FormEventType, FormActionType> {
  // isJudgePageMode = true;
  private formItems: IDictionary<FormItem> = {};
  private _initConfig?: IFormConfig;
  __createStore() {
    return new Store<IFormState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new FormExtendStore(this),
    });
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.one;
  }

  initialState(initConfig: IFormConfig) {
    this._initConfig = initConfig;
    this.store.dispatch<IFormConfigStartLoadParams>('configStartLoad', {
      initConfig,
    });
  }

  createControl(config: IFormConfigItem) {
    const { id: controlId, title, control, modifyMode } = config;
    if (controlId) {
      if (control) {
        if (!control.nodeName) {
          console.error(`容器控件Form-${this.id} 中的控件 ${controlId} 中不存在nodeName节点`);
          return null;
        }
        control['title'] = title;
        if (modifyMode != null) control['modifyMode'] = modifyMode;
        return super.__createControl(controlId, control);
      } else {
        return this.page.createControl(controlId);
      }
    } else {
      console.error(`容器控件Form-${this.id} 中的控件 controlId 不存在`);
      return null;
    }
  }

  __updateValues(values: IDictionary, actionSourceSign?: any, isParentChange: boolean = false) {
    let isUpdate = false;
    for (const key in this.formItems) {
      const item = this.formItems[key];
      const { field } = item.store.state;
      isUpdate = false;
      if (item.controlStore) {
        // TODO: datetime-range 控件的特别处理
        const { startFieldName, endFieldName } = item.controlStore.state;
        if (startFieldName && values[startFieldName] != undefined) {
          isUpdate = true;
          item.updateValue(values[startFieldName], actionSourceSign, isParentChange);
        } else if (endFieldName && values[endFieldName] != undefined) {
          isUpdate = true;
          item.updateValue(values[endFieldName], actionSourceSign, isParentChange);
        }

        // TODO: textbox 控件的特别处理
        const value = values[field] === undefined ? this.dataModel[field] : values[field];
        const { prefixFieldName, suffixFieldName } = item.controlStore.state;
        if (prefixFieldName && values[prefixFieldName] != undefined) {
          isUpdate = true;
          item.updateValue(value, actionSourceSign, isParentChange);
        } else if (suffixFieldName && values[suffixFieldName] != undefined) {
          isUpdate = true;
          item.updateValue(value, actionSourceSign, isParentChange);
        }
      }
      // TODO: 清空操作需要重现规划
      if (!isUpdate && field && values[field] !== undefined) {
        item.updateValue(values[field], actionSourceSign, isParentChange);
      }
    }
  }

  get dataModel() {
    return this.store.state.dataSource;
  }

  get isReset() {
    return this.store.state.isReset;
  }

  public resetConfig() {
    // TODO: 该方法应该应用到全部控件，目前无法评估所有控件的影响点。
    if (this._initConfig && this._initConfig.layoutService) {
      // this.formItems = {};
      this.store.dispatch<IFormConfigStartLoadParams>('configStartLoad', {
        initConfig: this._initConfig,
        isReset: true,
      });
    }
  }

  __createFormItem(id: string, config: IFormConfigItem) {
    if (!this.formItems[id]) {
      this.formItems[id] = new FormItem(id, this, config);
    }
    return this.formItems[id];
  }

  /**
   * 手动触发校验报错
   */
  triggervValid(message: string, itemId: string, rowId: string) {
    for (const key in this.formItems) {
      if (itemId == key) {
        const item = this.formItems[key];
        item.triggervValid(message);
      }
    }
    throw ExceptionHelper.businessException(message);
  }

  /**
   * 设置表单子项是否必填
   * @param key 子项Id
   * @param readonly 是否只读
   */
  setFormItem2RequiredType(key: string, requiredType: RequiredTypeEnum) {
    const item = this.formItems[key];
    if (item) {
      item.store.dispatch('updateRequiredType', { requiredType });
    } else {
      throw ExceptionHelper.argumentNullException(key);
    }
  }

  /**
   * 设置表单子项是否只读
   * @param key 子项Id
   * @param readonly 是否只读
   */
  setFormItem2Readonly(key: string, readonly: boolean) {
    const item = this.formItems[key];
    if (item) item.store.dispatch('updateReadonly', { readonly });
  }

  /**
   * 设置表单子项是否显示
   * @param key 子项Id
   * @param readonly 是否显示
   */
  setFormItem2Display(key: string, display: boolean) {
    const item = this.formItems[key];
    if (item) item.store.dispatch('updateDisplay', { display });
  }

  setFormItem2Tip(key: string, tip: string | undefined, tipIcon?: string) {
    const item = this.formItems[key];
    if (item) item.store.dispatch('updateTip', { tip, tipIcon });
  }

  /**
   * 设置表单子项的标题
   * @param key 子项Id
   * @param readonly 是否显示
   */
  setFormItem2Title(key: string, title: string) {
    const item = this.formItems[key];
    if (item) item.store.dispatch('updateTitle', { title });
  }

  /**
   * 设置表单某Section下的子项是否只读
   * @param key Section Id
   * @param readonly 是否只读
   */
  setFormSection2Readonly(key: string, readonly: boolean) {
    const { sections } = this.store.state;
    for (const section of sections) {
      if (section.state.id === key) {
        section.dispatch('updateReadonly', { readonly });
      }
    }
  }

  /**
   * 设置表单Section是否显示
   * @param key Section Id
   * @param readonly 是否显示
   */
  setFormSection2Display(key: string, display: boolean) {
    const { sections } = this.store.state;
    for (const section of sections) {
      if (section.state.id === key) {
        section.dispatch('updateDisplay', { display });
      }
    }
  }

  /**
   * 设置表单Section的标题
   * @param key Section Id
   * @param readonly 是否显示
   */
  setFormSection2Title(key: string, title: string) {
    const { sections } = this.store.state;
    for (const section of sections) {
      if (section.state.id === key) {
        section.dispatch('updateTitle', { title });
      }
    }
  }

  async valid() {
    for (const key in this.formItems) {
      const item = this.formItems[key];
      if (item) {
        const validResult = await item.valid(true);
        if (validResult && validResult !== true) {
          throw ExceptionHelper.businessException(validResult.message);
        }
      }
    }
    return true;
  }

  /**
   * 获取当前控件数据集
   * @param isValid 是否获取前做校验
   */
  async getData(isValid: boolean): Promise<any> {
    if (isValid) {
      await this.valid();
    }
    return this.dataModel.toJSON();
  }

  /**
   * 初始化数据，但是不触发任何事件
   * @param datas
   * @param dataState DataStateEnum， 默认数据的修改状态更新为新增，若页面状态为修改请改为未修改
   */
  initDatas(datas: IDictionary, dataState?: DataStateEnum) {
    this.store.dispatch('loaded', {
      dataSource: datas,
      dataState,
    });
  }

  /**
   * 控件数据提交完成
   */
  dataSubmitFinish(): void {
    this.page.controlDataSubmitFinish(this.id);
  }
}

export default Form;
