import Store from '@mjcloud/redux';
import TableCellBase from './cell';
import { AUTOCELLWIDTH } from './constant';
import OperationContent from './operationContent';
import CellTitle, { ICellTitleState } from './cellTitle';
import { ContainerDataInstanceBase, InstanceBase } from '@mjcloud/instance';
import { ControlConfig, PageControlMode, IDictionary } from '@mjcloud/types';
import DataSource, { IDataSourceParameters } from '@mjcloud/data-source-helper';
import {
  ITableBaseState,
  TableBaseActionType,
  ITableBaseConfigItem,
  ITableBaseColumnProps,
  ITableBaseConfigItemSet,
  ITableBatchSetCellTitleItem,
  IBatchAddOrRemoveColumnItem,
  ITabsSetColumn2DisplayItem,
  IBatchAddColumnItem,
} from './typings';
import Service from '@mjcloud/service';

export default abstract class TableInstanceBase<
  TDataSource,
  TItem,
  S extends ITableBaseState<TDataSource, TItem>,
  TCell extends TableCellBase<any, any> = TableCellBase<any, any>,
  TEventType extends string = string,
  TDispatchType extends string = string
  > extends ContainerDataInstanceBase<S, TEventType, TDispatchType> {
  private _cells: IDictionary<TCell> = {};
  private _specialCells: IDictionary<InstanceBase> = {};
  private _buttons: IDictionary<InstanceBase> = {};
  private _cellTitles: IDictionary<CellTitle> = {};
  private _cellTitleStores: IDictionary<ICellTitleState> = {};
  private _operation: OperationContent | undefined;
  abstract __getCellRecord(rowId: number): IDictionary | undefined;
  abstract get pageControlMode(): PageControlMode;

  private getColumnsToExportColumns(items: ITableBaseColumnProps<TItem>[]) {
    let columns: Array<IDictionary> = [],
      column: IDictionary = {},
      title: string | undefined;
    for (const { key, control, width = AUTOCELLWIDTH, children = [] } of items) {
      if (typeof key === 'string' && (title = this.getCellTitle(key))) {
        if (children.length > 0) {
          column = { title, columns: this.getColumnsToExportColumns(children) };
          columns.push({ parent: column });
        } else {
          column = { title, fieldName: key, width };
          if (control) {
            const { format, summary } = control;
            if (control.nodeName === 'number') {
              column.numberFormat = format || '#,##0.00';
              if (summary) {
                column.summary = { numberFormat: summary.format || '#,##0.00' };
              }
            } else if (control.nodeName === 'datetime') {
              column.dateFormat = format || 'yyyy-MM-dd';
            }
          }
          columns.push({ item: column });
        }
      }
    }
    return columns;
  }

  private buildExportColumns() {
    let columns: Array<IDictionary> = this.getColumnsToExportColumns(this.store.state.columns);
    return columns;
  }

  /**
   * 生成导出文件所需要的额外参数，一般是筛选条件和排序等
   */
  protected buildExportFileExtraParams(): IDataSourceParameters | undefined {
    return this.store.state.filterCondition;
  }

  async buildExportFileConfig(title: string) {
    const dataConfig = await DataSource.getDataInstanceConfig({
      page: this.page,
      data: this.store.state.config.data,
      param: {},
    }),
      extra = this.buildExportFileExtraParams();
    if (!dataConfig) return;
    if (extra) {
      // 添加排序、筛选等相关信息
      dataConfig.params = { ...dataConfig.params, ...extra };
    }

    return {
      data: dataConfig,
      pageControlMode: this.pageControlMode,
      config: { title, columns: this.buildExportColumns() },
    };
  }

  findCell(cellId: string) {
    return this._cells[cellId];
  }

  protected get cells() {
    return this._cells;
  }

  protected get buttons() {
    return this._buttons;
  }

  get operation() {
    return this._operation;
  }

  __registerTableOperationBefore(workflow: any, buttonItems: any) {
    if (workflow) {
      Service.getWorkflowMode({ businessType: workflow.businessType })
        .then((result: any) => {
          if (result === 1) {
            const btnIds = workflow.approveBtnId.split(',');
            console.info('__registerTableOperationBefore.buttonItems', buttonItems);
            for (const key in buttonItems) {
              console.info('__registerTableOperationBefore.buttonItems.key', key);

              if (btnIds.indexOf(buttonItems[key].id) != -1) {
                buttonItems[key].displayControl = "1 != 1";
              }
            }
          }

          const operation = new OperationContent('_operation', this, buttonItems);
          this.__registerTableOperation(operation);
        });
    } else {
      const operation = new OperationContent('_operation', this, buttonItems);
      this.__registerTableOperation(operation);
    }
  }

  ___cleanButtons() {
    for (const key in this._buttons) {
      delete this.controls[key];
    }
    this._buttons = {};
  }

  ___cleanSpecialCells() {
    for (const key in this._specialCells) {
      delete this.controls[key];
    }
    this._specialCells = {};
  }

  __registerSpecialCell(instance: InstanceBase) {
    this._specialCells[instance.id] = instance;
  }

  __registerTableCell(cellId: string, item: TCell) {
    this._cells[cellId] = item;
  }

  __registerTableOperation(operation: OperationContent) {
    this._operation = operation;
  }

  __registerTableCellTitle(cellId: string, item: CellTitle) {
    this._cellTitles[cellId] = item;
    if (this._cellTitleStores[cellId]) {
      const { title } = this._cellTitleStores[cellId];
      if (title) item.setTitle(title);
    }
  }

  __findCellTitle(cellId: string) {
    return this._cellTitles[cellId];
  }

  __createControlByItem(config: ITableBaseConfigItem, disabled?: boolean) {
    const { id: controlId, title, control } = config;
    if (control) {
      if (!control.nodeName) {
        console.error(`容器控件GridEdit-${this.id} 中的控件 ${controlId} 中不存在nodeName节点`);
        return null;
      }
      control['title'] = title;
      control['autoFocus'] = true;
      control['disabled'] = disabled;
      return this.__createControl(controlId, control);
    } else {
      return this.page.createControl(controlId);
    }
  }

  __createControlByOperation(rowId: number, config: ControlConfig) {
    if (config.nodeName === 'button') {
      config['theme'] = 'anchor';
      config['icon'] = 'icon-blank';
      const instance = this.__createControl(config.id + rowId, config);
      if (instance) this._buttons[config.id + rowId] = instance;
      return instance;
    } else {
      console.error(`该容器控件-${this.id} 中的buttons节点不支持button以外的控件类型`);
      return null;
    }
  }

  __isReviseEditId(actionId: string, row: any) {
    const { revise } = this.store.state;

    if (revise && revise.actionIds) {
      if (row && row.approveState == 9) {
        return (',' + (revise.actionIds[0] || '') + ',').indexOf(',' + actionId + ',') >= 0;
      } else {
        return (',' + revise.actionIds.join(',') + ',').indexOf(',' + actionId + ',') >= 0;
      }

    }
    return false;
  }

  /**
   * 批量添加列
   * @param columns 要添加的列
   * @param isClear 添加前是否需要清空原有的列
   */
  batchAddColumn<TConfig extends ITableBaseConfigItemSet>(
    columns: IBatchAddColumnItem<TConfig>[],
    isClear: boolean = false,
  ) {
    (this.store as Store<S, TableBaseActionType>).dispatch('batchAddColumn', {
      columns,
      isClear,
    });
  }

  /**
   * 批量删除列
   */
  batchRemoveColumn(columns: IBatchAddOrRemoveColumnItem[]) {
    (this.store as Store<S, TableBaseActionType>).dispatch('batchRemoveColumn', { columns });
  }

  getColumns() {
    return this.store.state.originalColumns;
  }

  /**
   * 批量设置列标题
   * @param cells 列设置集合
   */
  async batchSetCellTitle(cells: ITableBatchSetCellTitleItem[]) {
    for (const { cellId, title } of cells) {
      // TODO: 暂时避开通过store更新的模式，这是不对的
      if (!this._cellTitleStores[cellId]) this._cellTitleStores[cellId] = {};
      this._cellTitleStores[cellId].title = title;

      const cellTitle = this.__findCellTitle(cellId);
      if (cellTitle) {
        await cellTitle.setTitle(title);
      }
    }
    (this.store as Store<S, TableBaseActionType>).dispatch('batchSetCellTitle', { cells });
  }

  getCellTitle(cellId: string) {
    const cellTitle = this.__findCellTitle(cellId);
    if (cellTitle) return cellTitle.getTitle();
  }

  /**
   * 批量设置列是否显示
   * @param cells 列设置集合
   */
  batchSetColumn2Display(cells: ITabsSetColumn2DisplayItem[]) {
    (this.store as Store<S, TableBaseActionType>).dispatch('updateColumns', { cells });
  }

  getRowForRowId(rid: number): IDictionary | undefined {
    throw new Error('Method not implemented.');
  }
}
