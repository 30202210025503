import Store from '@mjcloud/redux';
import globalData from '@mjcloud/global-data';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import {
  IIframeState,
  IframeLoadedParams,
  IframeLoadErrorParams,
  IframeReplaceParams,
  IframePushParams,
  IframeInitialStateParams,
  IframeBackParams,
  IIframeConfig,
} from './typings';

export class IframeReduce extends ReduceBase<IIframeState> {
  public initialState(store: Store<IIframeState>, param: IframeInitialStateParams) {
    const { initConfig, pageMode, params } = param,
      { pageInfo, delay, emptyDisplayMode, displayMode } = initConfig,
      { params: _params, appId = globalData.appId, ...address } = pageInfo,
      state: IIframeState = {
        display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
        configIsFetching: false,
        Template: undefined,
        pageKey: undefined,
        config: initConfig,
        emptyDisplayMode,
        tabIndex: -1,
        history: [],
        delay,
        info: {
          address: { ...address, appId, authorityNeedAddress: !!params._authorityNeedAddress },
          params,
        },
      };
    return state;
  }

  public replace(store: Store<IIframeState>, params: IframeReplaceParams) {
    const {
      pageId,
      appId,
      moduleId,
      pageMode,
      params: _params = {},
      defaultPageMode,
      defaultappId,
    } = params;
    let state: IIframeState = {
      ...store.state,
      info: {
        address: {
          pageId: pageId || store.state.info.address.pageId,
          moduleId: moduleId || store.state.info.address.moduleId,
          appId: appId || defaultappId,
          pageMode: pageMode || defaultPageMode,
          authorityNeedAddress: !!_params._authorityNeedAddress,
        },
        params: _params,
      },
    };
    return state;
  }

  public push(store: Store<IIframeState>, params: IframePushParams) {
    const {
      pageId,
      appId,
      moduleId,
      pageMode,
      params: _params = {},
      defaultPageMode,
      defaultappId,
    } = params;
    let state: IIframeState = {
      ...store.state,
      info: {
        address: {
          pageId: pageId || store.state.info.address.pageId,
          moduleId: moduleId || store.state.info.address.moduleId,
          appId: appId || defaultappId,
          pageMode: pageMode || defaultPageMode,
          authorityNeedAddress: !!_params._authorityNeedAddress,
        },
        params: _params,
      },
    };
    return state;
  }

  public back(store: Store<IIframeState>, params: IframeBackParams) {
    const { destroyTemplate } = params;
    let state: IIframeState = { ...store.state };
    if (state.history.length >= 2) {
      destroyTemplate(state.history[state.history.length - 1].pageKey);
      state.history.pop();
      const { pageKey, Template } = state.history[state.history.length - 1];
      state.Template = Template;
      state.pageKey = pageKey;
      return state;
    } else {
      return store.state;
    }
  }

  public startLoad(store: Store<IIframeState>) {
    const state: IIframeState = { ...store.state, Template: undefined, isFetching: true };
    return state;
  }

  /**
   * 获取模板页加载完成，并创建了模板页实例，通过该实例获取模板页的store，并放置到history
   * @description:
   * @param {type}
   * @return:
   */
  public loaded(store: Store<IIframeState>, params: IframeLoadedParams): IIframeState {
    const { Template, refreshType, pageKey, getTemplate, destroyTemplate } = params;
    let state: IIframeState = { ...store.state, isFetching: false, Template, pageKey };
    switch (refreshType) {
      case 'replace':
        destroyTemplate(state.history[state.history.length - 1].pageKey);
        state.history[state.history.length - 1] = { pageKey, Template };
        break;
      case 'push':
        const page = getTemplate(pageKey);
        page.__setPreviousPage(state.history[state.history.length - 1].pageKey);
        state.history.push({ pageKey, Template });
        break;
      default:
        state.history.push({ pageKey, Template });
        break;
    }
    return state;
  }

  public loadError(store: Store<IIframeState>, params: IframeLoadErrorParams): IIframeState {
    const { errorMessage } = params;
    const state: IIframeState = { ...store.state, isFetching: false, errorMessage };
    return state;
  }
}

export default new IframeReduce();
