import React from 'react';
import Store from '@mjcloud/redux';
import classNames from 'classnames';
import { WhiteSpace } from '@mjcloud/ui';
import { ArrayHelper } from '@mjcloud/utils';
import { Divider, Row, Col } from 'antd';
import FormItemControl from '../../form-item/pc';
import { IFormSectionState, IFormSectionProps } from '../typings';

import styles from './index.less';

interface ISectionTitleProps {
  id: string;
  showTitle?: boolean;
  prefix: string;
  title: string;
}

const SectionTitle: React.FC<ISectionTitleProps> = ({ id, showTitle, prefix, title }) =>
  showTitle ? (
    <div id={`${prefix}${id}`} className={styles.title}>
      {title}
    </div>
  ) : null;

const SectionDivider: React.FC<{ showDivider: boolean }> = ({ showDivider }) =>
  showDivider ? (
    <div className={styles.divider}>
      <Divider style={{ marginTop: 32, marginBottom: 32 }} />
    </div>
  ) : (
    <div style={{ height: 32 }} />
  );

class FormSectionControl extends React.Component<IFormSectionProps, IFormSectionState> {
  constructor(props: IFormSectionProps) {
    super(props);
    this.state = props.store.state;
    props.store.bindViewListen(this.handStateChange);
  }

  componentWillReceiveProps(nextProps: IFormSectionProps) {
    if (this.props.store !== nextProps.store) {
      this.props.store.unbindViewListen(this.handStateChange);
      nextProps.store.bindViewListen(this.handStateChange);
      this.setState(nextProps.store.state);
    }
  }

  componentWillUnmount() {
    this.props.store.unbindViewListen(this.handStateChange);
  }

  private handStateChange = (state: IFormSectionState, cb?: () => void) => {
    this.setState(state, cb);
  };

  render() {
    const {
      id,
      cols,
      title,
      display,
      showTitle,
      showDivider,
      labelNumber,
      labelWidth,
      digestDisplay,
      items,
    } = this.state;
    if (!display) return null;
    const { pageKey, parentId } = this.props;
    if (cols === 1) {
      return (
        <div
          className={classNames({ [styles.sectionCols1]: true, [styles.digest]: digestDisplay })}
          key={id}
        >
          <SectionTitle id={id} title={title} showTitle={showTitle} prefix="form_" />
          {!digestDisplay && <WhiteSpace />}
          {items.map(item => {
            if (item instanceof Store) {
              return (
                <FormItemControl
                  store={item}
                  pageKey={pageKey}
                  id={item.state.id}
                  key={item.state.id}
                  parentId={parentId}
                  labelWidth={labelWidth}
                  labelNumber={labelNumber}
                />
              );
            }
            return <div id={item.id} key={item.id} />;
          })}
          {/* <SectionDivider showDivider={showDivider} /> */}
        </div>
      );
    } else {
      const _items = ArrayHelper.oneArray2twoArray(items, cols, item => {
        if (item instanceof Store) return item.state.colspan;
        return item.colspan == null ? 1 : item.colspan;
      });
      return (
        <Row
          key={id}
          className={classNames({
            [styles.sectionColsOther]: true,
            [styles.digest]: digestDisplay,
          })}
        >
          <SectionTitle id={id} title={title} showTitle={showTitle} prefix="form_" />
          {!digestDisplay && <WhiteSpace />}
          {_items.map((__items, i) => {
            return (
              <Row key={i.toString()}>
                {__items.map(item => {
                  if (item instanceof Store) {
                    const { id: itemId, colspan } = item.state;
                    let span = (24 / cols) * colspan;
                    span = span > 24 ? 24 : span;
                    return (
                      <FormItemControl
                        key={itemId}
                        id={itemId}
                        store={item}
                        pageKey={pageKey}
                        parentId={parentId}
                        labelWidth={labelWidth}
                        labelNumber={labelNumber}
                        span={span}
                      />
                    );
                  }
                  let span = (24 / cols) * (item.colspan == null ? 1 : item.colspan);
                  span = span > 24 ? 24 : span;
                  return <Col span={span} id={item.id} key={item.id} />;
                })}
              </Row>
            );
          })}
          {/* <SectionDivider showDivider={showDivider} /> */}
        </Row>
      );
    }
  }
}

export default FormSectionControl;
