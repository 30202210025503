import React from 'react';
import Icon from '@mjcloud/icon';
import { Upload } from 'antd';
import classNames from 'classnames';
import JsApiHelper from '@mjcloud/jsapi';
import styled from 'styled-components';
import Image from '../../component/Image';
import ControlBase from '../../core/base';
import { px2rem, COLOR } from '../../constant';
import { UploadProps, UploadChangeParam } from 'antd/lib/upload';
import {
  IFileManagerState,
  IUploadFile,
  IFileManageUpdateFileListParams,
} from '@mjcloud/mcontrols/dist/controls/file-manager/typings';

import styles from './index.less';

const ITEMSIZE = px2rem(120);
// const ITEMICONSIZE = px2rem(40);
// const REMOVESIZE = px2rem(32);

// const Container = styled.div``;

const Avatar = styled(Image)`
  width: ${ITEMSIZE};
  font-size: ${ITEMSIZE};
  height: ${ITEMSIZE};
  border-radius: 4px;
  border: 1px solid ${COLOR.MINOR};
`;

const AvatarUpload = styled(Upload)`
  width: 100%;
  display: inline-block;
  > .ant-upload {
    width: 100%;
  }
`;

const CustomizeImage = styled(Image)`
  width: ${ITEMSIZE};
  font-size: ${ITEMSIZE};
  height: ${ITEMSIZE};
`;

export default class FileManagerControl extends ControlBase<IFileManagerState> {
  private previewFile = (file: any) => {
    JsApiHelper.previewFile(file);
  };

  private previewImage = (file: IUploadFile) => {
    const urls = this.state.fileList.filter(f => !!f.url).map(f => f.url) as string[];
    JsApiHelper.previewImage({ urls: [], current: file.url || urls[0] });
  };

  private removeImage = (file: IUploadFile) => {
    this.instance.store.dispatch('removeFile', { file });
  };

  private handleChange = (info: UploadChangeParam) => {
    const fileList = [...info.fileList],
      { name, status } = info.file;
    if (status === 'error') {
      JsApiHelper.showToast({ content: `${name}上传失败!`, type: 'fail' });
    }
    this.instance.store.dispatch<IFileManageUpdateFileListParams>('updateFileList', {
      fileList,
      loading: status === 'uploading',
    });
  };

  renderContent() {
    const { fileList = [], readonly, listType, placeholder, maxFileCount } = this.state;
    const uploadProps: UploadProps = { ...this.state.uploadProps, multiple: true };
    uploadProps.onChange = this.handleChange;
    if (listType === 'avatar') {
      const file = fileList[0],
        onClick = (e: any) => {
          e.stopPropagation();
          if (file) this.previewImage(file);
        };

      return (
        <AvatarUpload
          {...uploadProps}
          fileList={fileList}
          showUploadList={false}
          accept="image/*"
          listType="text"
        >
          <div className={classNames(styles.avatar, styles.container)}>
            <div className={styles.placeholder}>
              {file ? (
                <Avatar src={file.url} alt={file.name} status={file.status} onClick={onClick} />
              ) : (
                placeholder
              )}
            </div>
            {!readonly && <Icon className={styles.arrow} type="icon-right" />}
          </div>
        </AvatarUpload>
      );
    } else if (listType === 'picture' || listType === 'picture-card') {
      const showAdd = readonly ? false : maxFileCount > 0 ? fileList.length < maxFileCount : true;
      return (
        <div className={classNames(styles.picture, styles.container)}>
          {fileList.map(file => {
            const onClick = () => this.previewImage(file),
              onRemoveClick = () => this.removeImage(file);
            return (
              <div key={file.uid} className={styles.item}>
                {!readonly && (
                  <div className={styles.remove} onClick={onRemoveClick}>
                    <Icon type="icon-close" />
                  </div>
                )}
                <CustomizeImage
                  src={file.url}
                  alt={file.name}
                  status={file.status}
                  onClick={onClick}
                />
              </div>
            );
          })}
          {showAdd && (
            <Upload
              {...uploadProps}
              fileList={fileList}
              showUploadList={false}
              className={styles.add}
              accept="image/*"
              listType="text"
            >
              <Icon type="icon-add" />
            </Upload>
          )}
        </div>
      );
    }
    return (
      <div className={classNames(styles.normal, styles.container)}>
        {fileList.map(file => {
          const onClick = () => this.previewFile(file);
          return (
            <div key={file.uid} className={styles.item} onClick={onClick}>
              <Icon className={styles.icon} style={{ color: file.color }} type={file.icon} />
              <div className={classNames('dy-font-elipsis', styles.name)}>{file.name}</div>
            </div>
          );
        })}
      </div>
    );
  }
}
