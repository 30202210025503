import React from 'react';
import Icon from '@mjcloud/icon';
// import FooterView from '../Footer';
import classNames from 'classnames';
import { ObjectHelper } from '@mjcloud/utils';
import PageHelper from '@mjcloud/page-helper';
import globalData from '@mjcloud/global-data';
import { withRouter } from 'react-router';
import GlobalHeaderRight from './RightContent';
import { LocationDescriptorObject } from 'history';
import { Route } from '@ant-design/pro-layout/lib/typings';
import styled, { createGlobalStyle } from 'styled-components';
import { PADDING, SIDERWIDTH, MAINPATH } from '../../constant';
import { IAppInfo, IAppMenuConfig, PageModeEnum } from '@mjcloud/types';
import { HeaderViewProps } from '@ant-design/pro-layout/lib/Header';
import RouterHelper, { IRouteComponentProps } from '@mjcloud/router-helper';
import ProLayout, {
  BasicLayoutProps as ProLayoutProps,
  MenuDataItem,
} from '@ant-design/pro-layout';
import AddressBookAPI from '../../components/AddressBookAPI';

const Container = styled.div`
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  height: 1px;
`;

// injectGlobal``;

interface IIndexLayoutProps extends IRouteComponentProps {
  config: IAppInfo;
}
interface IIndexLayoutState extends ProLayoutProps {
  height: string | number;
}

function formatMenu(menus: IAppMenuConfig[], appId: string, parentPath: string = ''): Route[] {
  let routes: Route[] = [];
  for (const menu of menus) {
    const { moduleId, name, code, icon, target } = menu;
    let path = `/${appId}${parentPath}/${moduleId}`,
      route: Route = { name, icon, path, target, moduleCode: code };

    if (moduleId === globalData.DataCockpit) {
      route.path = `${window.location.origin}/${window.location.search}#${path}`;
    }
    if (moduleId === RouterHelper.getModuleId()) {
      globalData.__setBreadcrumbList([
        {
          name,
          location: { pathname: path, state: { pageMode: PageModeEnum.none, moduleCode: code } },
        },
      ]);
    }
    if (menu.children && menu.children.length > 0) {
      route.routes = formatMenu(menu.children, appId, `${parentPath}/${moduleId}`);
    } else {
      PageHelper.pushMenuPath(appId, moduleId, path);
    }
    routes.push(route);
  }
  return routes;
}

class IndexLayout extends React.PureComponent<IIndexLayoutProps, IIndexLayoutState> {
  private resizeTimeout: any = null;
  constructor(props: IIndexLayoutProps) {
    super(props);
    const height = window.innerHeight - PADDING,
      { appId } = globalData.rbCore.config,
      { title, logo, layout } = globalData.rbCore.config.appSettingList[appId];
    this.state = {
      logo,
      title,
      layout,
      height,
      navTheme: 'dark',
      fixedHeader: true,
      fixSiderbar: false,
      footerRender: false,
      autoHideHeader: false,
      contentWidth: 'Fluid',
      siderWidth: SIDERWIDTH,
      style: { height: window.innerHeight },
      menuItemRender: this.menuItemRender,
      menuHeaderRender: this.menuHeaderRender,
      rightContentRender: this.rightContentRender,
      // menuProps: { style: { height } },
      // iconfontUrl: '//at.alicdn.com/t/font_1269860_7z9l2c3mrf3.js',
      route: { path: `/${appId}`, routes: formatMenu(props.config.menus, appId) },
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener(
      'orientationchange' in window ? 'orientationchange' : 'resize',
      this.resizeThrottler,
      false,
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      'orientationchange' in window ? 'orientationchange' : 'resize',
      this.resizeThrottler,
      false,
    );
  }

  resizeThrottler = () => {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.actualResizeHandler();
        // The actualResizeHandler will execute at a rate of 15fps
      }, 66);
    }
  };

  actualResizeHandler = () => {
    const height = window.innerHeight - PADDING;
    this.setState({ height, style: { height: window.innerHeight } });
  };

  rightContentRender = (rightProps: HeaderViewProps) => <GlobalHeaderRight {...rightProps} />;

  menuHeaderRender = (logo: React.ReactNode, title: React.ReactNode) => {
    const { appId } = globalData.rbCore.config,
      to = appId ? `/${appId}${MAINPATH}` : '/',
      onClick = () => {
        globalData.__setBreadcrumbList([
          { name: '首页', location: { pathname: to, state: { pageMode: PageModeEnum.none } } },
        ]);
      };
    // let children =
    //   globalData.corpId === 'dingc44a1de26ff1f75cf5bf40eda33b7ba0' ? (
    //     <img src="images/gllogo.png" style={{ marginLeft: -24, height: 64 }} />
    //   ) : (
    //     <>
    //       {logo}
    //       {title}
    //     </>
    //   );
    if (appId === 'taskmanage') {
      return (
        <a href={'#' + to} onClick={onClick}>
          <img src="images/taskmanage_logo.png" style={{ marginLeft: -24, height: 70 }} />
        </a>
      );
    }
    return (
      <a href={'#' + to} onClick={onClick}>
        {/* {children} */}
        {logo}
        {title}
      </a>
    );
  };

  menuItemRender = (menuItemProps: MenuDataItem, defaultDom: React.ReactNode) => {
    const {
        name = '',
        path = '',
        itemPath,
        moduleCode,
        icon: iconType,
        target = '_blank',
      } = menuItemProps,
      isUrl = path.search(/[a-zA-z]+:\/\/[^s]*/) === 0,
      icon = iconType ? <Icon type={iconType} /> : undefined;
    if (menuItemProps.isUrl || menuItemProps.children) {
      return defaultDom;
    }
    if (isUrl) {
      return (
        <a href={itemPath} target={target}>
          {iconType && icon}
          <span>{name}</span>
        </a>
      );
    }
    const handleClick = () => {
      const location: LocationDescriptorObject<any> = {
        pathname: path,
        state: { pageMode: PageModeEnum.none },
        search: ObjectHelper.params2search({ _moduleCode: moduleCode }),
      };
      globalData.__setBreadcrumbList([{ name, location }]);
      this.props.history.push(location);
    };
    return (
      <a onClick={handleClick}>
        {iconType && icon}
        <span>{name}</span>
      </a>
    );
  };

  // footerRender = () => {
  //   const { appId } = this.props.match.params;
  //   const { copyright } = globalData.rbCore.config.appSettingList[appId];
  //   return <FooterView {...copyright} />;
  // };

  render() {
    const { children, config, ...props } = this.props;
    const { height, ...proLayoutProps } = this.state;
    return (
      <ProLayout {...props} {...proLayoutProps}>
        <Container
          style={{ height }}
          className={classNames(this.state.contentWidth === 'Fixed' && 'wide')}
        >
          <AddressBookAPI />
          <Content>{children}</Content>
        </Container>
      </ProLayout>
    );
  }
}

export default withRouter(IndexLayout);
