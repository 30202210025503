import React from 'react';
import { InputItem } from 'antd-mobile';
import ControlBase from '../../core/base';
import { InputEventHandler } from 'antd-mobile/lib/input-item/PropsType';
import { INumberState } from '@mjcloud/mcontrols/dist/controls/number/typings';

export default class NumberControl extends ControlBase<INumberState> {
  private handChange = (value: string) => {
    this.instance.store.dispatch('updateValue', {
      text: value,
      value: value,
      actionSourceSign: this.instance.store,
    });
  };

  private handFocus: InputEventHandler = value => {
    this.instance.store.dispatch('updateInputValue', {
      value,
      text: value,
      active: true,
      actionSourceSign: this.instance.store,
    });
  };
  private handBlur: InputEventHandler = value => {
    this.instance.store.dispatch('updateInputValue', {
      value,
      text: value,
      active: false,
      actionSourceSign: this.instance.store,
    });
  };

  renderContent() {
    const { _value, text, readonly, placeholder } = this.state;
    if (readonly) return <div className="dy-font-elipsis">{text}</div>;
    return (
      <InputItem
        clear
        value={_value}
        placeholder={placeholder}
        onChange={this.handChange}
        onFocus={this.handFocus}
        onBlur={this.handBlur}
      />
    );
  }
}
