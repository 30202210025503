import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import { ButtonSize } from 'antd/lib/button';
import ControllerHelper from '@mjcloud/controller';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { PageAddress } from '@mjcloud/page/dist/typings';
import { ControlDisplayModeEnum, PageModeEnum } from '@mjcloud/types';
import {
  IButtonState,
  ButtonThemeType,
  IButtonUpdateThemeParams,
  IButtonInitialStateParams,
  IButtonConfig,
} from './typings';

function getButtonType(theme: string = 'default', active: boolean = false): ButtonThemeType {
  if (active) return 'primary';
  switch (theme) {
    case 'icon':
      return 'icon';
    case 'anchor':
      return 'anchor';
    case 'primary':
      return 'primary';
    case 'ghost':
      return 'ghost';
    case 'dashed':
      return 'dashed';
    case 'danger':
      return 'danger';
    case 'default':
    default:
      return 'default';
  }
}

function getButtonSize(size: string = 'default'): ButtonSize {
  switch (size) {
    case 'large':
      return 'large';
    case 'small':
      return 'small';
    default:
      return undefined;
  }
}

export function initialState(
  initConfig: IButtonConfig,
  address: PageAddress,
  pageMode = PageModeEnum.none,
) {
  const { id, code, title, tip, icon, confirm, theme, size } = initConfig,
    { controllerId, displayControl, active, confirmTitle } = initConfig,
    { styleName = theme } = initConfig,
    {
      displayMode = pageMode === PageModeEnum.none
        ? undefined
        : ControlDisplayModeEnum['add&modify'],
    } = initConfig;
  let display: boolean = true,
    isAuthority = true;
  if (controllerId && code) isAuthority = ControllerHelper.hasRights(address, code);
  if (display) display = PageModeHelper.displayMode2boolean(pageMode, displayMode);
  const _theme = getButtonType(styleName, active);

  const state: IButtonState = {
    id,
    tip,
    icon,
    display,
    isAuthority,
    controllerId,
    tabIndex: 0,
    theme: _theme,
    displayControl,
    loading: false,
    disabled: false,
    _oldTheme: _theme,
    config: initConfig,
    title: title || '',
    configIsFetching: false,
    size: getButtonSize(size),
    confirm: confirm ? { title: confirmTitle } : false,
  };
  return state;
}

class ButtonReduce extends ReduceBase<IButtonState> {
  initialState(store: Store<IButtonState>, params: IButtonInitialStateParams) {
    const { initConfig, pageMode = PageModeEnum.none, address } = params;
    return initialState(initConfig, address, pageMode);
  }

  clickAfter(store: Store<IButtonState>, params: {}) {
    if (store.state.loading) {
      return store.state;
    }
    return { ...store.state, loading: true };
  }

  clickDone(store: Store<IButtonState>, params: {}) {
    if (!store.state.loading) {
      return store.state;
    }
    return { ...store.state, loading: false };
  }

  updateLoad(store: Store<IButtonState>, params: { loading: boolean }) {
    if (store.state.loading === params.loading) {
      return store.state;
    }
    return { ...store.state, loading: params.loading };
  }

  updateTheme(store: Store<IButtonState>, params: IButtonUpdateThemeParams) {
    const state: IButtonState = { ...store.state };
    state._oldTheme = state.theme;
    state.theme = params.theme;
    return state;
  }

  updateDisabled(store: Store<IButtonState>, params: { disabled: boolean }) {
    if (store.state.disabled === params.disabled) {
      return store.state;
    }
    return { ...store.state, disabled: params.disabled };
  }
}

export default new ButtonReduce();
