import React from 'react';
import GridView from '..';
import GridViewCard from './card';
import GridViewList from './list';
import GridViewCardList from './cardList';
import ControlBase from '../../base';
import { findDOMNode } from 'react-dom';
import globalData from '@mjcloud/global-data';
import Table from '../../common/table/pc';
import ResizeObserver from 'resize-observer-polyfill';
import GridViewCellControl from '../gridview-cell/pc';
import { ITableBaseRow } from '../../common/table/typings';
import OperationContentControl from '../../common/table/operationContent/pc';
import { SCROLLMINHEIGHT, PARENTMINHEIGHT, PAGINATIONSIZE } from '../../constant';
import {
  IGridViewState,
  IGridViewStartLoadParams,
  IGridViewUpdateSelectedRowsParams,
} from '../typings';
import {
  TableRowSelection,
  TablePaginationConfig,
  SorterResult,
  SelectionSelectFn,
  TableLocale,
} from 'antd/lib/table/interface';

import styles from './index.less';

const TableCell: React.FC<any> = props => {
  if (props.cellId === '_operation') {
    return <OperationContentControl {...props} />;
  } else if (props.store) {
    return <GridViewCellControl {...props} />;
  } else {
    const { rowId, cellId, record, store, children, ...restProps } = props;
    return <td {...restProps}>{children}</td>;
  }
};

const components = {
  body: {
    cell: TableCell,
  },
};

class GridViewControl extends ControlBase<IGridViewState, GridView> {
  private target: Element | null = null;
  private container: Element | null = null;
  private resizeObserver: ResizeObserver | null = null;

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.resizeObserver && this.container) {
      this.resizeObserver.unobserve(this.container);
      this.resizeObserver.disconnect();
      this.container = null;
    }
  }

  private onResize: ResizeObserverCallback = () => {
    if (this.container && this.target) {
      const { pagination } = this.state;
      const parentElementHeight = this.container.clientHeight;
      const parentHeight =
        pagination === false ? parentElementHeight : parentElementHeight - PAGINATIONSIZE;
      const scrollHeight = parentElementHeight > PARENTMINHEIGHT ? parentHeight : SCROLLMINHEIGHT;

      this.instance.store.dispatch('updateScrollHeight', { scrollHeight });
    }
  };

  private readyContainer = (instance: any | null) => {
    const container = findDOMNode(instance);
    if (container instanceof Element && container.parentElement) {
      const parentElementHeight = container.parentElement.clientHeight;

      if (parentElementHeight > PARENTMINHEIGHT) {
        this.target = container;
        this.container = container.parentElement;
        this.resizeObserver = new ResizeObserver(this.onResize);
        this.resizeObserver.observe(container.parentElement);
      }
    }
  };

  private handleLoadMore = (pagination: TablePaginationConfig) => {
    const { current, pageSize } = pagination;
    const params: IGridViewStartLoadParams = {
      pageIndex: current,
      pageSize: pageSize,
    };
    this.instance.store.dispatch<IGridViewStartLoadParams>('startLoad', params);
  };

  private handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (React.Key | boolean)[] | null>,
    sorter: SorterResult<ITableBaseRow> | SorterResult<ITableBaseRow>[],
  ) => {
    const { current, pageSize } = pagination;
    const params: IGridViewStartLoadParams = {
      pageIndex: current,
      pageSize: pageSize,
    };
    params.orderBy = [];
    if (sorter instanceof Array) {
      // TODO: sorter in Array
    } else if (sorter && sorter.field && sorter.order && sorter.column) {
      const { dataIndex } = sorter.column;
      params.orderBy = [{ name: dataIndex as string, sortType: sorter.order === 'ascend' ? 1 : 2 }];
    }
    this.instance.store.dispatch<IGridViewStartLoadParams>('startLoad', params);
  };

  private handleSelectChange: SelectionSelectFn<ITableBaseRow> = (
    record,
    selected,
    selectedRows: any[],
  ) => {
    this.instance.store.dispatch<IGridViewUpdateSelectedRowsParams>('updateSelectedRows', {
      row: record,
      checked: selected,
      selectedRows,
    });
  };

  private handleSelectAllChange = (
    selected: boolean,
    selectedRows: ITableBaseRow[],
    changeRows: ITableBaseRow[],
  ) => {
    this.instance.store.dispatch('updateSelectedRows', { selectedRows });
  };

  private handleTableRowClick = (record: ITableBaseRow, index: number, event: React.MouseEvent) => {
    event.preventDefault();
    const { selectedRows = [] } = this.state;
    if (selectedRows[0] && selectedRows[0] === record) return;
    this.instance.store.dispatch<IGridViewUpdateSelectedRowsParams>('updateSelectedRows', {
      row: record,
      checked: true,
      selectedRows: [record],
    });
  };

  private handleTableRow = (
    record: ITableBaseRow,
    index?: number,
  ): React.HTMLAttributes<HTMLElement> => {
    const { rowSelection } = this.state;
    return {
      onClick:
        rowSelection && rowSelection.type === 'radio' && index != null
          ? event => this.handleTableRowClick(record, index, event)
          : undefined,
    };
  };

  rowClassName = (record: ITableBaseRow, index: number) => {
    const { rowSelection, selectedRows = [] } = this.state;
    if (rowSelection && rowSelection.type === 'radio') {
      if (selectedRows.length > 0 && selectedRows[0]._rid === record._rid) {
        return `ant-table-row-selected ${styles.rowSelected} ${styles.rowRadio}`;
      }
      return styles.rowRadio;
    }
    return '';
  };

  renderContent() {
    const {
      card,
      list,
      cardList,
      columns,
      pagination,
      dataSource,
      isFetching,
      showSummary,
      rowSelection,
      errorMessage,
      scrollHeight,
      rowClickController,
      summaryDataSource,
      selectedRows = [],
      configErrorMessage,
    } = this.state;
    if (configErrorMessage) return <>{configErrorMessage}</>;
    const { formatMessage } = globalData;
    const locale: TableLocale = {
      emptyText: errorMessage ? errorMessage : formatMessage({ id: 'table.locale.emptyText' }),
    };
    if (cardList) {
      return (
        <GridViewCardList<ITableBaseRow>
          config={cardList}
          locale={locale}
          columns={columns}
          loading={isFetching}
          dataSource={dataSource}
          components={components}
          instance={this.instance}
          onChange={this.handleTableChange}
          pagination={pagination}
          onLoadMore={this.handleLoadMore}
          onRowClick={
            rowClickController
              ? row => this.instance.store.dispatch('rowClickAfter', { row })
              : undefined
          }
        />
      );
    }
    if (list) {
      return (
        <GridViewList<ITableBaseRow>
          config={list}
          locale={locale}
          columns={columns}
          loading={isFetching}
          dataSource={dataSource}
          components={components}
          instance={this.instance}
          onChange={this.handleTableChange}
          pagination={pagination}
        />
      );
    }
    if (card) {
      return (
        <GridViewCard<ITableBaseRow>
          ref={this.readyContainer}
          card={card}
          locale={locale}
          columns={columns}
          loading={isFetching}
          dataSource={dataSource}
          components={components}
          instance={this.instance}
          scrollHeight={scrollHeight}
          onChange={this.handleTableChange}
          pagination={pagination}
        />
      );
    }
    const _rowSelection: TableRowSelection<ITableBaseRow> | undefined =
      rowSelection && rowSelection.type === 'checkbox'
        ? {
            ...rowSelection,
            // columnTitle: '选择',
            selectedRowKeys: selectedRows.map(row => row._rid),
            onSelect: this.handleSelectChange,
            onSelectAll: this.handleSelectAllChange,
            // onChange: this.handleSelectChange,
          }
        : undefined;

    return (
      <Table<ITableBaseRow>
        // ref={this.readyContainer}
        sticky
        bordered
        locale={locale}
        columns={columns}
        loading={isFetching}
        pagination={pagination}
        components={components}
        dataSource={dataSource}
        className={styles.table}
        showSummary={showSummary}
        rowSelection={_rowSelection}
        scroll={{ x: 'max-content' }}
        summaryDataSource={summaryDataSource}
        rowClassName={this.rowClassName}
        onChange={this.handleTableChange}
        onRow={this.handleTableRow}
        rowKey="_rid"
        size="small"
      />
    );
  }
}
export default GridViewControl;
