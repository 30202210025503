import React from 'react';
import Icon from '@mjcloud/icon';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { COLOR } from '../../../constant';
import { RequiredTypeEnum } from '@mjcloud/types';

interface ICellTitleProps {
  title?: string;
  tip?: string;
  tipIcon?: string;
  isModify?: boolean;
  requiredType?: RequiredTypeEnum;
  style?: React.CSSProperties;
}

export interface ICellTitleState {
  title?: string;
  requiredType?: RequiredTypeEnum;
  tip?: string;
  tipIcon?: string;
}

const TheadTitle = styled.div<{ required: boolean }>`
  max-width: 100%;

  &:after {
    display: ${({ required }) => (required ? 'inline-block' : 'none')};
    margin-left: 4px;
    content: '*';
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: ${COLOR.RED};
  }
`;

class CellTitle extends React.Component<ICellTitleProps, ICellTitleState> {
  constructor(props: ICellTitleProps) {
    super(props);
    const { title, tip, tipIcon, requiredType } = props;
    this.state = { title, tip, tipIcon, requiredType };
  }

  updateRequiredType(requiredType: RequiredTypeEnum) {
    this.setState({ requiredType });
  }

  getTitle() {
    return this.state.title;
  }

  setTitle(title: string) {
    return new Promise<void>(resolve => {
      this.setState({ title }, resolve);
    });
  }

  setTip(tip: string | undefined, tipIcon?: string) {
    return new Promise<void>(resolve => {
      this.setState({ tip, tipIcon }, resolve);
    });
  }

  render() {
    const { title, tip, tipIcon = 'icon-question-circle', requiredType } = this.state,
      { isModify, style } = this.props,
      required = isModify ? requiredType === RequiredTypeEnum.required : false,
      label: React.ReactNode = tip ? (
        <span>
          {title}&nbsp;
          <Tooltip title={tip}>
            <Icon type={tipIcon} style={{ color: 'rgba(0,0,0,.45)' }} />
          </Tooltip>
        </span>
      ) : (
        title
      );
    return (
      <TheadTitle required={required} style={style}>
        {label}
      </TheadTitle>
    );
  }
}

export default CellTitle;
