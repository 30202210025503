import Reduce from './reduce';
import { ControlConfig } from '@mjcloud/types';
import Store from '@mjcloud/redux';
import { InstanceBase } from '@mjcloud/instance';
import { ExtendStoreBase } from '@mjcloud/extend-store';
import {
  ISeparateState,
  SeparateEventType,
  SeparateActionType,
  ISeparateConfig,
  ISeparateConfigStartLoadParams,
} from './typings';

class SeparateExtendStore extends ExtendStoreBase<Separate> {}

class Separate extends InstanceBase<ISeparateState, SeparateEventType, SeparateActionType> {
  __createStore() {
    return new Store<ISeparateState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new SeparateExtendStore(this),
    });
  }

  initialState(initConfig: ISeparateConfig) {
    this.store.dispatch<ISeparateConfigStartLoadParams>('configStartLoad', {
      initConfig,
    });
  }
}

export default Separate;
