import React from 'react';
import { Tooltip } from 'antd';
import globalData from '@mjcloud/global-data';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { IGridViewCellState, IGridViewCellProps } from '../typings';

import styles from './index.less';

export default class GridViewCellControl extends React.Component<
  IGridViewCellProps,
  IGridViewCellState
> {
  constructor(props: IGridViewCellProps) {
    super(props);
    const { store } = props;
    this.state = store.state;
    store.bindViewListen(this.handStateChange);
    this.init();
  }

  componentWillUnmount() {
    const { store } = this.props;
    store.unbindViewListen(this.handStateChange);
  }

  private async init() {
    const { colorCommandFn, rowId, record } = this.props;
    if (colorCommandFn) {
      this.props.store.dispatch('updateColor', { color: await colorCommandFn(rowId, record) });
    }
  }

  private handStateChange = (state: IGridViewCellState, cb?: () => void) => {
    this.setState(state, cb);
  };

  render() {
    const { ControlFactory } = globalData;
    const { text = '', color, special } = this.state;
    const {
      id,
      rowId,
      store,
      style,
      cellId,
      isTree,
      record,
      isCard,
      pageKey,
      parentId,
      cellType,
      children,
      parentModify,
      width: _width,
      colorCommandFn,
      isVirtualizedCell,
      ...restProps
    } = this.props;
    let width = _width,
      placement: TooltipPlacement = 'topLeft';
    if (this.props.style && this.props.style.textAlign === 'right') {
      placement = 'topRight';
    }
    if (typeof width === 'number') {
      width = width - 17;
    }
    if (isCard) {
      return special ? (
        <ControlFactory id={`${id}${rowId}`} parentId={parentId} pageKey={pageKey} />
      ) : (
        <div className={styles.valueWrap} dangerouslySetInnerHTML={{ __html: text }}></div>
      );
    } else {
      return (
        <td {...restProps} style={{ ...style, color }}>
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: text }} />} placement={placement}>
            <div style={{ width }} className={styles.valueWrap}>
              {children}
              {special ? (
                <ControlFactory id={`${id}${rowId}`} parentId={parentId} pageKey={pageKey} />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: text }}></div>
              )}
            </div>
          </Tooltip>
        </td>
      );
    }
  }
}
