import React from 'react';
import { Skeleton } from 'antd';
import ControlBase from '../../core/base';
import { Carousel } from 'antd-mobile';
import { ICarouselState } from '@mjcloud/mcontrols/dist/controls/carousel/typings';

import styles from './index.less';

const Image: React.FC<{ src: string }> = ({ src }) => (
  <div className={styles.carousel} style={{ backgroundImage: `url(${src})` }}></div>
);

export default class CarouselControl extends ControlBase<ICarouselState> {
  renderContent() {
    const { dataSource } = this.state;
    if (dataSource && dataSource.length > 1) {
      return (
        <Carousel autoplay={true} infinite>
          {dataSource.map(({ id, imageUrl }) => (
            <Image key={id} src={imageUrl} />
          ))}
        </Carousel>
      );
    }
    if (dataSource[0]) {
      return <Image src={dataSource[0].imageUrl} />;
    }
    return <Skeleton className={styles.loading} active />;
  }
}
