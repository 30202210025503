import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { AppId } from './constant';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import AppExtendStore from './extendStore';
import { InstanceBase } from '@mjcloud/instance';
import { IDictionary, PageModeEnum } from '@mjcloud/types';
import { ObjectHelper, KeyboardManager } from '@mjcloud/utils';
import { KeyboardFn } from '@mjcloud/utils/dist/keyboardManager';
import { IPageRefreshParams } from '@mjcloud/page/dist/typings';
import { Action, LocationDescriptorObject } from 'history';
import { IAppState, AppEventType, IAppRefreshParams, AppStoreActionType } from './typings';

export default class App extends InstanceBase<IAppState, AppEventType, AppStoreActionType> {
  private keyboardManager = new KeyboardManager();

  constructor() {
    super(AppId, { page: null } as any);
    // this.keyboardManager.add(this.keyboardCallback);
  }

  private _nextTab() {
    if (this.currentPage) this.currentPage._nextTab();
  }

  private _prevTab() {
    if (this.currentPage) this.currentPage._prevTab();
  }

  private keyboardCallback: KeyboardFn = e => {
    const event = e.data;
    if (event.keyCode == 9) {
      // 按下Tab键
      event.preventDefault ? event.preventDefault() : (event.returnValue = false);

      if (!event.shiftKey) {
        this._nextTab();
      } else {
        // 按下 shift + Tab键
        this._prevTab();
      }
    }

    // if (event.keyCode == 13) {
    //   // 按下Enter键
    //   event.preventDefault ? event.preventDefault() : (event.returnValue = false);
    //   // that.instance.store.dispatch('downEnter', { getTemplate });
    // }
  };

  public __createStore() {
    return new Store<IAppState>({
      id: AppId,
      reduce: Reduce,
      extendStore: new AppExtendStore(this),
    });
  }

  public get currentPage() {
    const { pageKey } = this.store.state;
    if (pageKey) {
      const page = globalData.rbCore.getPage(pageKey);
      if (page) return page
    }
    return undefined
  }

  public get page() {
    const { parentPageKey } = this.store.state;
    if (parentPageKey) {
      const _parent = globalData.rbCore.getPage(parentPageKey);
      if (_parent) return _parent;
    }
    return this as any;
  }

  public findControl(controlId: string) {
    return this;
  }

  /**
   * 刷新页面
   */
  public refresh() {
    // TODO 刷新页面
  }

  private commRefresh(
    params: IPageRefreshParams,
    action: Action,
  ): LocationDescriptorObject | undefined {
    const { origin } = window.location,
      { info, pageKey } = this.store.state,
      {
        title,
        pageId,
        params: state = {},
        pageMode = PageModeEnum.none,
        moduleId = info.address.moduleId,
        appId = globalData.rbCore.config.appId,
      } = params,
      _state: IDictionary = {},
      _params: IDictionary = {};
    if (pageKey) state.__parentPageKey = pageKey;
    const address = { pageId, appId, moduleId, pageMode };
    if (PageHelper.addressIsEqual(this.store.state.info.address, address)) {
      this.store.dispatch<IAppRefreshParams>('refresh', {
        ...address,
        action: 'REPLACE',
        params: { ...state, pageMode },
      });
      return;
    }
    for (const key in state) {
      if (typeof state[key] === 'string') {
        _params[key] = state[key];
      } else {
        _state[key] = state[key];
      }
    }
    const result = {
      pathname: PageHelper.formatMemuPath({ appId, moduleId }),
      search: ObjectHelper.params2search({ pageId, _title: title, pageMode, ..._params }),
      state: _state,
    };
    console.log(
      `%cNavigated to %c${origin}/#${result.pathname}${result.search}`,
      'color: #598af7',
      'color: #ababab',
    );
    return result;
  }

  /**
   * 在不影响 history 长度的情况下（如一个重定向），用新的页面替换当前页面。
   */
  public replace(params: IPageRefreshParams) {
    const { history } = this.store.state.config;
    const location = this.commRefresh(params, 'REPLACE');
    if (location) history.replace(location);
  }

  /**
   * 跳转至一个新的页面
   */
  public push(params: IPageRefreshParams) {
    const { history } = this.store.state.config;
    const location = this.commRefresh(params, 'PUSH');
    if (location) {
      const { title = '未知标题' } = params;
      globalData.__pushBreadcrumbList({ name: title, location });
      history.push(location);
    }
  }

  /**
   * 在 history 中后退
   */
  public back(param: IDictionary = {}) {
    globalData.__backParam = param;
    const { history } = this.store.state.config;
    history.goBack();
  }

  reLaunch() {
    // TODO: 需要实现reLaunch功能，方便业务开发使用
    const { reLaunchIndex, history = [], config } = this.store.state;
    config.history.go(reLaunchIndex - history.length);
  }

  /**
   * 关闭页面
   * @param param
   */
  close(param: IDictionary = {}): void {
    if (window.opener) {
      window.close();
    } else {
      console.warn('Scripts may close only the windows that were opened by it.');
    }
  }
}
