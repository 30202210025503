// import 'javascript-detect-element-resize';
import './global/icon';
import './global/load';
import './global/page';
import './global/define';
import 'dayjs/locale/zh-cn';
import 'antd/dist/antd.less';
import './global/exception_init';
import './global/globaldata_init';
import 'antd-mobile/dist/antd-mobile.css';
import 'video-react/dist/video-react.css';

import * as platform from '.';
import * as page from '@mjcloud/page';
import * as core from '@mjcloud/core';
import * as icon from '@mjcloud/icon';
import * as jsapi from '@mjcloud/jsapi';
import * as types from '@mjcloud/types';
import * as utils from '@mjcloud/utils';
import * as eappCore from '@mjcloud/eapp-core';
import * as exception from '@mjcloud/exception';
import * as dataModel from '@mjcloud/data-model';
import * as globalData from '@mjcloud/global-data';
import { ExceptionHelper } from '@mjcloud/exception';
import { betterJs, ConsoleHelper } from '@mjcloud/utils';

// window['_dd'] = window['dd']
window['dd'] = undefined;
if (wx) wx.isJsSDK = true;
ConsoleHelper.isEnable = false;
ConsoleHelper.debugger = false;
window['ConsoleHelper'] = ConsoleHelper;

window['__dingyun_require__'] = (name: string) => {
  switch (name) {
    case '@dy/icon':
    case '@mjcloud/icon':
      return icon;
    case '@dy/core':
    case '@mjcloud/core':
      return core;
    case '@dy/page':
    case '@mjcloud/page':
      return page;
    case '@dy/eapp-core':
    case '@mjcloud/eapp-core':
      return eappCore;
    case '@dy/platform-types':
    case '@mjcloud/platform-types':
      return platform;
    case '@dy/global-data':
    case '@mjcloud/global-data':
      return globalData;
    case '@dy/data-model':
    case '@mjcloud/data-model':
      return dataModel;
    case '@dy/exception':
    case '@mjcloud/exception':
      return exception;
    case '@dy/types':
    case '@mjcloud/types':
      return types;
    case '@dy/jsapi':
    case '@mjcloud/jsapi':
      return jsapi;
    case '@dy/utils':
    case '@mjcloud/utils':
      return utils;
    default:
      const ex = ExceptionHelper.notSupportException(
        `平台不支持 ${name} 库，若需要请联系平台开发者~`,
      );
      ExceptionHelper.dispose(ex);
      return {};
  }
};

betterJs.init({
  ajaxError: false,
  sendError(error) {
    const { title, msg } = error;
    if (title === 'ResizeObserver loop limit exceeded') return;
    ExceptionHelper.dispose(msg);
  },
});
