import React from 'react';
import { Tooltip } from 'antd';
import globalData from '@mjcloud/global-data';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { ITreeViewCellState, ITreeViewCellProps } from '../typings';

import styles from './index.less';

export default class TreeViewCellControl extends React.Component<
  ITreeViewCellProps,
  ITreeViewCellState
> {
  constructor(props: ITreeViewCellProps) {
    super(props);
    const { store } = props;
    this.state = store.state;
    store.bindViewListen(this.handStateChange);
    this.init();
  }

  componentWillUnmount() {
    const { store } = this.props;
    store.unbindViewListen(this.handStateChange);
  }

  private async init() {
    const { colorCommandFn, rowId, record } = this.props;
    if (colorCommandFn) {
      this.props.store.dispatch('updateColor', { color: await colorCommandFn(rowId, record) });
    }
  }

  private handStateChange = (state: ITreeViewCellState, cb?: () => void) => {
    this.setState(state, cb);
  };

  render() {
    const { ControlFactory } = globalData;
    const { text, color, special } = this.state;
    const {
      id,
      rowId,
      store,
      style,
      cellId,
      isTree,
      record,
      pageKey,
      parentId,
      cellType,
      children,
      parentModify,
      width: _width,
      colorCommandFn,
      isVirtualizedCell,
      ...restProps
    } = this.props;
    let width = _width,
      placement: TooltipPlacement = 'topLeft';
    if (this.props.style && this.props.style.textAlign === 'right') {
      placement = 'topRight';
    }
    if (typeof width === 'number') {
      width = width - 17;
    }
    const _children = (
      <div style={{ width }} className={styles.valueWrap}>
        {children}
        <Tooltip title={text} placement={placement}>
          <div className={styles.valueWrapFlex}>
            {special ? (
              <ControlFactory id={`${id}${rowId}`} parentId={parentId} pageKey={pageKey} />
            ) : (
              text
            )}
          </div>
        </Tooltip>
      </div>
    );
    // return (
    //   <td {...restProps} style={{ ...style, color }}>
    //     {_children}
    //   </td>
    // );
    return (
      <div {...restProps} style={{ ...style, color }}>
        {_children}
      </div>
    );
  }
}
