import { ITreeEditConfig } from './typings';
import controlDefaults from '../button/controlDefaults';
import { IButtonConfig } from '../button/typings';

const config: ITreeEditConfig = {
  data: {},
  expandLevel: 9,
  items: { items: [] },
  mayCustom: false,
  showPager: false,
  showOrdinal: true,
  showSummary: false,
  collapseMenu: false,
  rowSelection: false,
  orderby: [],
  fastnessCount: 0,
  rowSelectionType: 'checkbox',
  buttons: undefined,
  modifyMode: undefined,
  displayMode: undefined,
  disabledMode: undefined,
};

export const addRowButtonControlDefaults: IButtonConfig = {
  ...controlDefaults,
  title: '添加',
  icon: 'plus',
};

export default config;
