import React from 'react';
import { IHyperlinkState } from '../typings';
import ControlBase from '../../base';

class HyperlinkControl extends ControlBase<IHyperlinkState> {
  private handleClick = event => {
    if (event) event.preventDefault();
    if (this.state.loading) return;
    this.instance.store.dispatch('clickAfter', {});
  };

  renderContent() {
    const { value, text } = this.state;
    if (value == null) return null;
    return (
      <a id={this.props.id} onClick={this.handleClick}>
        {text}
      </a>
    );
  }
}
export default HyperlinkControl;
