import React from 'react';
import ControlBase from '../../core/base';
import { ILabelState } from '@mjcloud/mcontrols/dist/controls/label/typings';

export default class LabelControl extends ControlBase<ILabelState> {
  renderContent() {
    const { text = this.state.value } = this.state;
    return <div className="dy-font-elipsis">{text}</div>;
  }
}
