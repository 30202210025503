import React from 'react';
import classNames from 'classnames';
import TimelineInstance from '..';
import ControlBase from '../../base';
import { Steps, Skeleton } from 'antd';
import { ITimelineState } from '../typings';

import styles from './index.less';

function getStepClassName(status: string): string | undefined {
  switch (status) {
    case 'blue':
      return styles.timelineBlue;
    case 'red':
      return styles.timelineRed;
    case 'green':
      return styles.timelineGreen;
    case 'yellow':
      return styles.timelineYellow;

    case 'gray':
    default:
      return undefined;
  }
}

function getStepTailClassName(status: string): string | undefined {
  switch (status) {
    case 'blue':
      return styles.tailBlue;
    case 'red':
      return styles.tailRed;
    case 'green':
      return styles.tailGreen;
    case 'yellow':
      return styles.tailYellow;

    case 'gray':
    default:
      return undefined;
  }
}

class TimelineControl extends ControlBase<ITimelineState, TimelineInstance> {
  private handleChange = (row: any) => {
    this.instance.store.dispatch('rowClickAfter', { row });
  };

  renderContent() {
    const { dataSource, isFetching, rowClickController } = this.state;
    if (isFetching)
      return (
        <div className={styles.timeline}>
          <Skeleton title={false} />
        </div>
      );
    return (
      <Steps
        progressDot
        current={-1}
        className={classNames(styles.timeline, !!rowClickController && styles.timelineClick)}
      >
        {dataSource.map((row, index) => {
          const { id = index, _title, _date, _status } = row;
          const nextRow = dataSource[index + 1];
          const tailType = nextRow ? getStepTailClassName(nextRow._status.type) : undefined;
          const className = classNames(getStepClassName(_status.type), tailType);
          return (
            <Steps.Step
              key={id}
              className={className}
              title={_title.text}
              description={_date.text}
              onClick={() => this.handleChange(row)}
            />
          );
        })}
      </Steps>
    );
  }
}
export default TimelineControl;
