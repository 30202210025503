import React from 'react';
import G2Chart from './G2Chart';

const Bar4line2: React.FC<{ data: any; lineName: string }> = ({ data, lineName }) => {
  return (
    <G2Chart
      onlyChangeData
      data={data}
      updateConfig={chart => {
        chart.axis('time', {
          tickLine: null,
        });

        chart.axis('value', {
          label: {
            formatter: text => {
              return text.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            },
          },
          title: {
            offset: 80,
            style: {
              fill: '#aaaaaa',
            },
          },
        });
        chart.legend({
          position: 'top',
        });

        chart.tooltip({
          shared: true,
          showMarkers: false,
        });

        chart
          .interval()
          .adjust('stack')
          .position('x*y')
          .color('name')
          .adjust([{ marginRatio: 0, type: 'dodge', dodgeBy: 'type' }])
          .size('name', xVal => {
            if (xVal === lineName.split(',')[0] || xVal === lineName.split(',')[1]) {
              return undefined as any;
            }
            return 48;
          });
        chart
          .line()
          .position('x*y')
          .color('name')
          .size('name', xVal => {
            if (xVal === lineName.split(',')[0] || xVal === lineName.split(',')[1]) {
              return 4;
            }
            return 0;
          });
        chart
          .point()
          .position('x*y')
          .color('name')
          .shape('circle')
          .size('name', xVal => {
            if (xVal === lineName.split(',')[0] || xVal === lineName.split(',')[1]) {
              return 6;
            }
            return 0;
          });
      }}
    />
  );
};

export default Bar4line2;
