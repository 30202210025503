import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { IControlConfig } from '@mjcloud/types';
import ComplexSearchExtendStore from './extendStore';
import { ContainerInstanceBase } from '@mjcloud/instance';
import {
  IComplexSearchState,
  IComplexSearchConfig,
  ComplexSearchEventType,
  ComplexSearchActionType,
} from './typings';

class ComplexSearch extends ContainerInstanceBase<
  IComplexSearchState,
  ComplexSearchEventType,
  ComplexSearchActionType
> {
  __createStore() {
    return new Store<IComplexSearchState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new ComplexSearchExtendStore(this),
    });
  }

  initialState(initConfig: IComplexSearchConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  createControl(config: IControlConfig) {
    // if (config.nodeName === 'dateTimeRange') {
    //   config.startFieldName = config.id;
    //   config.endFieldName = config.id;
    // } else if (config.nodeName === 'numberRange') {
    //   config.startFieldName = config.id;
    //   config.endFieldName = config.id;
    // }
    config.needFast = true;
    return this.__createControl(config.id, config);
  }

  /**
   * 重置子控件值
   */
  __reset() {
    for (const key in this.controls) {
      this.__deleteAdvancedItem(key);
    }
  }

  __deleteAdvancedItem(key: string) {
    const control = this.controls[key];
    if (control) {
      if (control instanceof Array) {
        for (const item of control) {
          if (item['setValue']) item['setValue'](undefined);
        }
      } else {
        if (control['setValue']) control['setValue'](undefined);
      }
    }
  }

  get filter() {
    return this.store.state._filter;
  }

  get value() {
    return this.store.state.searchValue;
  }
}

export default ComplexSearch;
