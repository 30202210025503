import React from 'react';
import { SPACING } from './constant';
import styled, { StyledComponent } from 'styled-components';
import { Card as AntCard } from 'antd';
import { CardProps } from 'antd/lib/card';

interface ISiderProps {
  width?: string | number;
}

const Layout = styled(AntCard)`
  width: calc(100% - ${SPACING * 2}px);
  height: calc(100% - ${SPACING * 2}px);
  margin: ${SPACING}px;
  /* background: #f0f2f5; */
  /* background: transparent; */

  > .ant-card-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: ${SPACING}px;
    /* background: #fff; */
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: ${SPACING * 2}px;
`;

const Middle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Sider = styled.div<ISiderProps>(({ width = 200 }) => ({
  width,
  height: '100%',
  padding: SPACING,
  marginRight: SPACING,
  display: 'flex',
  flexDirection: 'column',
}));

const Content = styled.div`
  width: 100%;
  height: 100%;
  min-width: 400px;
  padding-top: ${SPACING}px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LeftRightLayout: StyledComponent<typeof AntCard, any, CardProps, any> & {
  Header: StyledComponent<'div', any, {}, any>;
  Middle: StyledComponent<'div', any, {}, any>;
  Sider: StyledComponent<'div', any, ISiderProps, any>;
  Content: StyledComponent<'div', any, {}, any>;
} = Layout as any;

LeftRightLayout.Header = Header;
LeftRightLayout.Middle = Middle;
LeftRightLayout.Sider = Sider;
LeftRightLayout.Content = Content;

export default LeftRightLayout;
