import { ISelectTreeConfig } from './typings';

const config: ISelectTreeConfig = {
  data: {},
  title: '',
  expandLevel: 9,
  showIcon: false,
  autoFocus: false,
  iconControl: undefined,
  alwaysLoad: false,
  isAutoSelection: false,
  textFieldName: 'text',
  valueFieldName: 'id',
  multiSelect: false,
  textTokenSeparator: '、',
  vaueleTokenSeparator: ',',
  tokenSeparators: [',', '、'],
  modifyMode: undefined,
  placeholder: undefined,
  displayMode: undefined,
  disabledMode: undefined,
  disabledControl: undefined,
};

export default config;
