import React from 'react';
import Icon from '@mjcloud/icon';
import classNames from 'classnames';
import ControlBase from '../../base';
import { ButtonType, ButtonProps } from 'antd/lib/button';
import { Button, Popconfirm, Tooltip } from 'antd';
import { IButtonState } from '../typings';

import styles from './index.less';

class ButtonControl extends ControlBase<IButtonState> {
  private handleClick = event => {
    if (event) event.preventDefault();
    if (this.state.loading) return;
    this.instance.store.dispatch('clickAfter', {});
  };

  private renderButton = (onClick?: (e: any) => void) => {
    const { id, size, tip, disabled, loading, config } = this.state;
    // TODO: GridView操作区内按钮store更新不触发react渲染
    let { icon = config.icon, theme = config.theme, title = config.title } = this.state;
    const iconType = loading ? 'loading' : icon;
    const iconNode = iconType ? <Icon type={iconType} spin={loading} /> : null;
    const buttonProps: ButtonProps = { id, size, disabled, icon: iconNode };

    if (theme === 'icon') {
      return iconType ? <Icon type={iconType} spin={loading} onClick={onClick} /> : <div />;
    }
    if (theme === 'danger') {
      theme = 'default';
      buttonProps.danger = true;
    }

    const type: ButtonType = theme === 'anchor' || theme === 'titleLink' ? 'link' : theme;
    const classes = classNames({
      ['ant-btn-loading']: theme === 'anchor' ? false : !!loading,
      [styles.loading]: !!loading,
      [styles[theme]]: true,
    });
    const _button = (
      <Button {...buttonProps} key={id} type={type} onClick={onClick} className={classes}>
        {title}
      </Button>
    );
    if (tip) {
      return <Tooltip title={tip}>{_button}</Tooltip>;
    }
    return _button;
  };

  renderContent() {
    const { confirm, isAuthority } = this.state;
    if (!isAuthority) return null;
    if (!confirm) {
      return this.renderButton(this.handleClick);
    }
    return (
      <Popconfirm
        title={confirm.title}
        onConfirm={this.handleClick}
        okText="确定"
        cancelText="取消"
      >
        {this.renderButton()}
      </Popconfirm>
    );
  }
}
export default ButtonControl;
