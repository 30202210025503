import App from './app';
import Page from '@mjcloud/page';
import Service from '@mjcloud/service';
import { AppId } from './constant';
import { PageModeEnum } from '@mjcloud/types';
import { ObjectHelper } from '@mjcloud/utils';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import { ControlBase } from '../controls';
import RouterHelper from '@mjcloud/router-helper';
import { ExceptionHelper } from '@mjcloud/exception';
import JsApiHelper, { ENV, APP_TYPE } from '@mjcloud/jsapi';
import Store, { ActionAfterEventArg } from '@mjcloud/redux';
import {
  IAppState,
  IAppBackParams,
  IAppRefreshParams,
  AppStoreActionType,
  IAppInitialStateParams,
  IAppUpdateAppInfoParams,
  IAppInstallTemplateParams,
} from './typings';

export default class AppExtendStore {
  public constructor(protected instance: App) {}

  public handleInitialStateAfter(
    e: ActionAfterEventArg<IAppState, AppStoreActionType, IAppInitialStateParams>,
  ) {
    const { initConfig } = e.params,
      { match, location } = e.params.initConfig,
      { query = {}, state = {} } = location,
      { appId } = match.params,
      pageId = RouterHelper.getPageId(initConfig),
      moduleId = RouterHelper.getModuleId(initConfig),
      params = { ...query, ...state };
    this.init(e.newState, {
      appId,
      pageId,
      params,
      moduleId,
      action: 'POP',
    });
  }

  public handleRefreshAfter(
    e: ActionAfterEventArg<IAppState, AppStoreActionType, IAppRefreshParams>,
  ) {
    const { action } = e.params;
    switch (action) {
      case 'POP':
        this.back(e.newState, e.params);
        break;
      case 'PUSH':
      case 'REPLACE':
        this.init(e.newState, e.params);
        break;
    }
  }

  handleBackAfter(e: ActionAfterEventArg<IAppState, AppStoreActionType, IAppBackParams>) {
    const { pageKey, info, iframePageKey } = e.newState.state,
      { param } = e.params;
    if (info.address.moduleId === '_extra') return;
    if (pageKey) {
      const page = globalData.rbCore.getPage(pageKey);
      globalData.rbCore.registerTopPage(pageKey, page);
      if (page) page.eventManager.trigger('back', param);
    }
    if (iframePageKey) {
      const page = globalData.rbCore.getPage(iframePageKey);
      if (page) page.eventManager.trigger('back', param);
    }
  }

  async handleUpdateAppInfoAfter(
    e: ActionAfterEventArg<IAppState, AppStoreActionType, IAppUpdateAppInfoParams>,
  ) {
    const { action, appId, moduleId, pageId, params } = e.params.param,
      { state } = e.newState,
      { appInfo } = state;
    if (appInfo) {
      try {
        let pageMode = params.pageMode;
        if (typeof pageMode != 'number') {
          pageMode = Number(pageMode);
          if (isNaN(pageMode)) {
            pageMode = PageModeEnum.none;
          }
        }
        const info = state.info;
        info.params = { ...info.params, ...params, pageMode };
        if (params.pageMode) info.address = { ...info.address, pageMode };
        const Template = await Service.fetchTemplatePage<typeof ControlBase>(info.address);
        const pageKey = globalData.rbCore.nextPageKey();

        let parent: any = this.instance;
        // if (historyLength > 0 && historyList[historyLength - 1]) {
        //   parent = globalData.rbCore.getPage(historyList[historyLength - 1].pageKey);
        //   if (!parent) parent = this.instance;
        // }
        const page = new Page(pageKey, AppId, parent, info);
        globalData.rbCore.registerTopPage(pageKey, page);
        if (params.afterCallback) params.afterCallback();
        e.newState.dispatch<IAppInstallTemplateParams>('installTemplate', {
          pageKey,
          Template,
          action,
          getTemplate: globalData.rbCore.getPage.bind(globalData.rbCore),
          destroyTemplate: globalData.rbCore.destroyPage.bind(globalData.rbCore),
        });
      } catch (error) {
        e.newState.dispatch('fetchTemplateError', {
          showInDev: !globalData.debugger,
          errorMessage: '获取模板页失败！',
        });
        // ExceptionHelper.dispose(error);
      }
    }
  }

  private async init(store: Store<IAppState, AppStoreActionType>, param: IAppRefreshParams) {
    if (param.moduleId === '_extra') {
      const { pageKey } = param.params;
      store.dispatch('pushExtraPage', {
        pageKey,
        getPage: globalData.rbCore.getPage.bind(globalData.rbCore),
        registerPage: globalData.rbCore.registerPage.bind(globalData.rbCore),
      });
      return;
    }
    try {
      const { appId, moduleId, pageId } = param,
        { history } = store.state.config,
        search = ObjectHelper.search2params(window.location.search),
        { accesstoken, appUrl, tId, name, pwd } = search;
      if (accesstoken) {
        await Service.autoSignin(accesstoken);
      } else if (appUrl && tId && name && pwd) {
        await Service.signin({ appUrl, appId, tenantId: tId, username: name, password: pwd });
      }

      const isLogin = await Service.isLogin();
      if (!isLogin) {
        // TODO: 如果要支持准确的preLoginAddress定位，需要考虑params内带有类实例的情况
        await JsApiHelper.setStorage('preLoginAddress', {
          pathname: PageHelper.formatMemuPath({ appId, moduleId }),
          // search: ObjectHelper.params2search({ pageId }),
          // state: param.params,
        });
        if (moduleId === globalData.DataCockpit) {
          history.replace(`/${appId}/lslogin`);
          return;
        }
        switch (ENV.appType) {
          case APP_TYPE.WEBVIEW_IN_DDAPP:
            history.replace(`/${appId}/ddlogin`);
            break;
          case APP_TYPE.WEBVIEW_IN_WWAPP:
          case APP_TYPE.WEBVIEW_IN_WXAPP:
            history.replace(`/${appId}/wxlogin`);
            break;
          default:
            history.replace(`/${appId}/login`);
            break;
        }
        return;
      }
      await Service.userInfo(appId);
      const appInfo = await Service.appInfo(appId);
      await Service.fetchProjectCommonScripts();
      try {
        await Service.permissionInfo(appId);
      } catch (error) {
        store.dispatch('fetchTemplateError', { errorMessage: '获取应用权限列表失败！' });
        ExceptionHelper.dispose(error);
        return;
      }
      if (appInfo) {
        store.dispatch<IAppUpdateAppInfoParams>('updateAppInfo', { appInfo, param });
      } else {
        throw ExceptionHelper.notFoundObjectException('GET /info/app', appId, '获取应用信息失败！');
      }
    } catch (error) {
      store.dispatch('fetchTemplateError', { errorMessage: '获取应用信息失败！' });
      ExceptionHelper.dispose(error);
    }
  }

  private back(newState: Store<IAppState, AppStoreActionType>, params: IAppRefreshParams) {
    const { appId, moduleId, pageId } = params,
      historyList = newState.state.history,
      historyLength = historyList.length,
      address = { appId, moduleId, pageId },
      getTemplate = globalData.rbCore.getPage.bind(globalData.rbCore);
    let n = -1,
      addressIsEqual: boolean = false,
      backPageKey: string | undefined = undefined;
    if (historyLength >= 2) {
      for (let i = historyLength - 2; i >= 0; i--) {
        const { pageKey } = historyList[i];
        if (pageKey.search('_extra') === 0) {
          addressIsEqual = true;
        } else {
          addressIsEqual = PageHelper.addressIsEqual(getTemplate(pageKey).address, address);
        }
        if (addressIsEqual) {
          n = i;
          backPageKey = pageKey;
          const breadcrumbList = globalData.__getBreadcrumbList(),
            breadcrumbLength = breadcrumbList.length,
            start = breadcrumbLength - (historyLength - n) + 1,
            deleteCount = historyLength - n - 1;
          breadcrumbList.splice(start, deleteCount);
          globalData.__setBreadcrumbList(breadcrumbList);
          break;
        }
      }
    }
    if (addressIsEqual && backPageKey) {
      const { __backParam: param = {} } = globalData;
      newState.dispatch<IAppBackParams>('back', {
        n,
        backPageKey,
        param: param === false ? {} : param,
        destroyTemplate: globalData.rbCore.destroyPage.bind(globalData.rbCore),
      });
    } else {
      this.init(newState, params);
    }
    globalData.__backParam = false;
  }
}
