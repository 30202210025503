import Store from '@mjcloud/redux';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { INumberRangeState, INumberRangeInitialStateParams } from './typings';
import { IDictionary } from '@mjcloud/types';

class NumberRangeReduce extends ValueReduceBase<IDictionary<number>, INumberRangeState> {
  initialState(store: Store<INumberRangeState>, params: INumberRangeInitialStateParams) {
    const { initConfig, pageMode } = params,
      { value, text = '' } = store.state,
      { title, autoFocus, modifyMode, startFieldName, endFieldName } = initConfig,
      { startPlaceholder = `最小${title}值`, endPlaceholder = `最大${title}值` } = initConfig,
      { format, max: _max, min: _min, displayMode, disabledMode } = initConfig,
      max = typeof _max === 'string' ? parseInt(_max) : _max,
      min = typeof _min === 'string' ? parseInt(_min) : _min;
    const state: INumberRangeState = {
      max,
      min,
      text,
      title,
      value,
      format,
      autoFocus,
      tabIndex: -1,
      config: initConfig,
      configIsFetching: false,
      startFieldName,
      endFieldName,
      startPlaceholder,
      endPlaceholder,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };

    return state;
  }
}

export default new NumberRangeReduce();
