import React from 'react';
import { Screen } from './util';
import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import { MAINPATH } from './constant';
import { IDictionary } from '@mjcloud/types';
import globalData from '@mjcloud/global-data';
import { ObjectHelper } from '@mjcloud/utils';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import { handleException, IErrorInfo, ErrorException } from './util';

interface IDDLoginPageState {
  error?: IErrorInfo;
}

enum ScopeEnum {
  /**
   * [静默授权](https://work.weixin.qq.com/api/doc/10028#10028/%E9%9D%99%E9%BB%98%E6%8E%88%E6%9D%83%E4%B8%8E%E6%89%8B%E5%8A%A8%E6%8E%88%E6%9D%83)，可获取成员的的基础信息（UserId与DeviceId）；
   */
  base = 'snsapi_base',
  /**
   * [静默授权](https://work.weixin.qq.com/api/doc/10028#10028/%E9%9D%99%E9%BB%98%E6%8E%88%E6%9D%83%E4%B8%8E%E6%89%8B%E5%8A%A8%E6%8E%88%E6%9D%83)，可获取成员的详细信息，但不包含手机、邮箱；
   */
  userinfo = 'snsapi_userinfo',
  /**
   * [手动授权](https://work.weixin.qq.com/api/doc/10028#10028/%E9%9D%99%E9%BB%98%E6%8E%88%E6%9D%83%E4%B8%8E%E6%89%8B%E5%8A%A8%E6%8E%88%E6%9D%83)，可获取成员的详细信息，包含手机、邮箱
   */
  privateinfo = 'snsapi_privateinfo',
}

class WxLoginPage extends React.Component<IRouteComponentProps, IDDLoginPageState> {
  constructor(props: IRouteComponentProps) {
    super(props);
    this.state = {};
    this.init();
  }

  async init() {
    const { search, origin } = window.location,
      { code } = ObjectHelper.search2params(search);
    if (code && globalData.__WxAcquiredCode) {
      this.wxSignin(code);
    } else {
      const redirect_uri = await this.getCodeUrl(`${origin}/${search}`);
      if (redirect_uri) window.location.replace(redirect_uri);
    }
  }

  async wxSignin(authCode: string) {
    const { match, history } = this.props,
      appId = match.params.appId,
      corpId = globalData.corpId,
      { search, origin } = window.location,
      preLoginAddress: any = await JsApiHelper.getStorage('preLoginAddress');
    if (preLoginAddress) {
      JsApiHelper.removeStorage('preLoginAddress');
      // console.log('wxSignin', preLoginAddress);
    }
    try {
      // await Service.logout();
      await Service.wxSignin({ appId, corpId, authCode });
      Service.wxConfig({ appId, corpId, url: encodeURIComponent(origin + search) });
      history.replace(preLoginAddress ? preLoginAddress : `/${appId}${MAINPATH}`);
    } catch (ex) {
      const state = handleException(ex);
      if (state) this.setState(state);
    }
  }

  async getCodeUrl(redirectUri: string, scope: ScopeEnum = ScopeEnum.base) {
    // 原理查看： https://work.weixin.qq.com/api/doc/10028
    redirectUri = encodeURI(redirectUri);
    const { suiteId } = await Service.getWxInfo();
    if (suiteId) {
      const url = 'https://open.weixin.qq.com/connect/oauth2/authorize',
        search: IDictionary = {
          scope, // 应用授权作用域。
          appid: suiteId, // 第三方应用id（即ww或wx开头的suite_id）。注意与企业的网页授权登录不同
          redirect_uri: redirectUri, // 授权后重定向的回调链接地址，请使用urlencode对链接进行处理
          response_type: 'code', // 返回类型，此时固定为：code
          state: `wechat_redirect-${globalData.appId}`, // 重定向后会带上state参数，企业可以填写a-zA-Z0-9的参数值，长度不可超过128个字节
        };
      return url + ObjectHelper.params2search(search) + '#wechat_redirect';
    }
    return undefined;
  }

  render() {
    const { error } = this.state;
    return error ? <ErrorException error={error} /> : <Screen />;
  }
}

export default WxLoginPage;
