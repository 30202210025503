import Store from '@mjcloud/redux';
import { IDictionary } from '@mjcloud/types';
import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import DataSource, { ITreeDataSourceItem } from '@mjcloud/data-source-helper';
import {
  ITreeListState,
  ITreeListInitialStateParams,
  TreeListUpdateSelectedKeysParams,
  ITreeListLoadedParams,
  TreeListUpdateExpandedKeysParams,
} from './typings';

interface IFormatDataParams {
  source: ITreeDataSourceItem[];
  disabledFn: (row: IDictionary) => boolean;
}

function formatData(params: IFormatDataParams) {
  const { source, disabledFn } = params;
  for (const row of source) {
    row._disabled = disabledFn(row);
    if (row._children && row._children.length > 0) {
      formatData({ source: row._children, disabledFn });
    }
  }
}

export class TreeListReduce extends DataReduceBase<ITreeListState> {
  initialState(store: Store<ITreeListState>, params: ITreeListInitialStateParams) {
    const { initConfig, pageMode } = params,
      { title, disabledControl, styleName, expandLevel, displayMode } = initConfig;
    const state: ITreeListState = {
      title,
      expandLevel,
      tabIndex: -1,
      rowIdCount: 1,
      dataSource: [],
      disabledControl,
      originalData: [],
      expandedKeys: [],
      theme: styleName,
      selectedKeys: [],
      selectedRows: [],
      config: initConfig,
      showTitle: !!title,
      oldSelectedKeys: [],
      searchValue: undefined,
      originalDataSource: [],
      autoExpandParent: true,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  loaded(store: Store<ITreeListState>, params: ITreeListLoadedParams) {
    let { expandLevel, rowIdCount, config } = store.state,
      { disabledFn } = params,
      { data = {} } = config,
      ordinalIdCount = 0;
    const { aggs, rows = [] } = params.dataSource;
    const originalDataSource = DataSource.formatTreeDataSource(
      data,
      rows,
      row => false,
      row => ({ _rid: ++rowIdCount, _ordinalId: ++ordinalIdCount }),
    );
    let { dataSource, treeDefaultExpandedKeys: expandedKeys } = DataSource.formatTreeData(
      originalDataSource,
      expandLevel,
      '_rid',
    );
    if (disabledFn) formatData({ source: dataSource, disabledFn });
    const state: ITreeListState = {
      ...store.state,
      originalDataSource,
      originalData: rows,
      isFetching: false,
      dataSource,
      rowIdCount,
      expandedKeys: expandedKeys.map(k => k.toString()),
    };
    return state;
  }

  updateSelectedKeys(store: Store<ITreeListState>, params: TreeListUpdateSelectedKeysParams) {
    const { selectedKeys, selectedRows } = params;
    const state: ITreeListState = { ...store.state, selectedKeys, selectedRows };
    return state;
  }

  updateExpandedKeys(store: Store<ITreeListState>, params: TreeListUpdateExpandedKeysParams) {
    const { expandedKeys, autoExpandParent } = params;
    const state: ITreeListState = { ...store.state, expandedKeys, autoExpandParent };
    return state;
  }

  updateScrollHeight(store: Store<ITreeListState>, params: { scrollHeight?: number }) {
    const { scrollHeight } = params;
    if (scrollHeight === store.state.scrollHeight) {
      return store.state;
    }
    return { ...store.state, scrollHeight: params.scrollHeight };
  }
}

export default new TreeListReduce();
