import React from 'react';
import TabsInstance from '..';
import ControlBase from '../../base';
import { Tabs as AntTabs } from 'antd';
import styled from 'styled-components';
import globalData from '@mjcloud/global-data';
import { TabsProps } from 'antd/lib/tabs';
import { ITabsState, ITabsUpdateActiveKeyParams } from '../typings';

interface ITabsProps extends TabsProps {
  isShowTabPane?: boolean;
}
const Tabs = styled(AntTabs)<ITabsProps>`
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-tabs-nav {
    display: ${({ isShowTabPane }) => (isShowTabPane ? 'flex' : 'none')};
  }

  .ant-tabs-content-holder,
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
    overflow: hidden;
  }

  // TODO： fix 莫名其妙ant-tabs-tab样式异常
  .ant-tabs-tab {
    margin-right: 16px !important;
  }

  .dy-tab-pane {
    overflow: auto;
  }
`;

class TabsControl extends ControlBase<ITabsState, TabsInstance> {
  private handChange = (activeKey: string) => {
    this.instance.store.dispatch<ITabsUpdateActiveKeyParams>('updateActiveKey', { activeKey });
  };

  renderContent() {
    const { ControlFactory } = globalData;
    const { activeKey, items, isShowTabPane, tabPaneHeight } = this.state;
    return (
      <Tabs
        // size="small"
        tabBarGutter={0}
        activeKey={activeKey}
        isShowTabPane={isShowTabPane}
        // animated={{ inkBar: true, tabPane: true }}
        onChange={this.handChange}
      >
        {items
          .map((controlProps, i) => {
            if (controlProps.display)
              return (
                <Tabs.TabPane
                  tab={controlProps.title}
                  key={controlProps.id}
                  className="dy-tab-pane"
                  style={{ height: controlProps.id === activeKey ? tabPaneHeight : undefined }}
                >
                  <ControlFactory {...controlProps} />
                </Tabs.TabPane>
              );
            return undefined;
          })
          .filter(item => !!item)}
      </Tabs>
    );
  }
}
export default TabsControl;
