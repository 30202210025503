import Timeline from '.';
import { IDictionary } from '@mjcloud/types';
import PageHelper from '@mjcloud/page-helper';
import ControllerHelper from '@mjcloud/controller';
import { ExceptionHelper } from '@mjcloud//exception';
import { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import { ContainerDataExtendStoreBase } from '@mjcloud/extend-store';
import { ITimelineState,TimelineEventType, ITimelineLoadedParams } from './typings';

interface HandleClickParams {
  afterClick: TimelineEventType;
  beforeClick: TimelineEventType;
  params: IDictionary;
  controllerId: string | undefined;
}

export default class TimelineExtendStore extends ContainerDataExtendStoreBase<Timeline> {
  protected isNeedNotificationPage = true;

  private async _handleClick(param: HandleClickParams) {
    const { params, controllerId, afterClick, beforeClick } = param;
    const exclusiveLockId = this.instance.page.applyExclusiveLock('正在执行点击行操作...');
    if (exclusiveLockId === 0) {
      this.instance.store.dispatch('clickDone', {});
      return;
    }
    let before = async (data: IDictionary) => {
        if (this.instance.eventManager.getHandlerCount(afterClick) > 0) {
          await this.instance.eventManager.trigger(afterClick, data);
        }
        return data;
      },
      after = async (data: IDictionary) => {
        if (this.instance.eventManager.getHandlerCount(beforeClick) > 0) {
          await this.instance.eventManager.trigger(beforeClick, data);
        }
        return data;
      };

    try {
      if (controllerId) {
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId,
          data: params,
          before,
          after,
        });
      } else {
        await before(params);
        await after(params);
      }

      this.instance.store.dispatch('clickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
    } catch (e) {
      ExceptionHelper.dispose(e);
      this.instance.store.dispatch('clickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
    }
  }

  handleLoadedBefore(e: ActionBeforeEventArg<ITimelineLoadedParams>) {
    e.params.handleColorCommand = (colorCommand, row) => {
      if (colorCommand) {
        const e = {
            sender: this.instance,
            eventType: 'colorCommand',
            data: { row },
          },
          fn = PageHelper.createPageScriptFunction(this.instance.page, colorCommand, true);
        return fn(e);
      } else {
        return undefined;
      }
    };
  }

  async handleRowClickAfterAfter(e: ActionAfterEventArg<ITimelineState, string, any>) {
    const { rowClickController } = e.newState.state,
      { row } = e.params;
    await this._handleClick({
      params: { row },
      afterClick: 'rowAfterClick',
      beforeClick: 'rowBeforeClick',
      controllerId: rowClickController,
    });
  }
}
