import React from 'react';
import Icon from '@mjcloud/icon';
import classNames from 'classnames';
import globalData from '@mjcloud/global-data';
import { Dropdown, Menu } from 'antd';
import { IOperationContentProps, IOperationContentState } from '../typings';

import styles from './index.less';

/**
 * 表单操作区按钮集合
 */
export default class OperationContentControl extends React.Component<
  IOperationContentProps,
  IOperationContentState
> {
  constructor(props: IOperationContentProps) {
    super(props);
    if (props.store) {
      this.state = props.store.state;
      props.store.bindViewListen(this.handStateChange);
    }
  }

  componentWillUnmount() {
    if (this.props.store) this.props.store.unbindViewListen(this.handStateChange);
  }

  private handStateChange = (state: IOperationContentState, cb?: () => void) => {
    this.setState(state, cb);
  };

  shouldComponentUpdate(
    nextProps: Readonly<IOperationContentProps>,
    nextState: Readonly<IOperationContentState>,
  ) {
    if (nextState !== this.state) return true;
    if (nextProps !== this.props) return true;
    return false;
  }

  handleClick = (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
    e.stopPropagation();
    const { onClick } = this.props;
    if (onClick) onClick(e);
  };

  render() {
    const { ControlFactory } = globalData;
    const {
      rowId,
      store,
      isCard,
      record,
      cellId,
      children,
      collapse,
      onClick,
      style = {},
      isVirtualizedCell,
      ...restProps
    } = this.props;
    if (!store) return null;
    const { size, ChildrenControls } = this.state;
    let height = style.height;
    if (size && size.height) {
      height = size.height;
    }
    const _operations: React.ReactNode[] = ChildrenControls.map(controlProps => {
      if (controlProps.display) {
        return collapse ? (
          <ControlFactory key={controlProps.id} {...controlProps} />
        ) : (
          <Menu.Item key={controlProps.id}>
            <ControlFactory {...controlProps} />
          </Menu.Item>
        );
      }
      return null;
    }).filter(operation => !!operation);
    const count = _operations.length;
    const operations: React.ReactNode[] = _operations.map((operation, i) => {
      const _operation = [operation];
      if (i !== count - 1 && !collapse) _operation.push(<Menu.Divider key={`divider-${i}`} />);
      return _operation;
    });
    const className = classNames({
      [styles.operationContent]: true,
      [styles.collapse]: count > 0 && !collapse,
      [styles.notOperations]: count <= 0 && !collapse,
    });
    const content = collapse ? (
      <div className={className}>{operations}</div>
    ) : count > 0 ? (
      <Dropdown overlay={<Menu>{operations}</Menu>} placement="bottomCenter">
        <div className={className}>
          <Icon type="ellipsis" />
        </div>
      </Dropdown>
    ) : (
      <div className={className}>
        <Icon type="ellipsis" />
      </div>
    );
    return isCard
      ? content
      : React.createElement(
          isVirtualizedCell ? 'div' : 'td',
          { ...restProps, style: { ...style, height }, onClick: this.handleClick },
          content,
        );
    // <td {...restProps} style={{ ...style, height }} onClick={this.handleClick}>
    //   {content}
    // </td>
  }
}
