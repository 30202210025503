import "antd/es/icon/style";
import _Icon from "antd/es/icon";
import "antd/es/layout/style";
import _Layout from "antd/es/layout";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { Fragment } from 'react';
import GlobalFooter from './GlobalFooter';
var Footer = _Layout.Footer;
var defaultLinks = [{
  key: 'Ant Design Pro',
  title: 'Ant Design Pro',
  href: 'https://pro.ant.design',
  blankTarget: true
}, {
  key: 'github',
  title: React.createElement(_Icon, {
    type: "github"
  }),
  href: 'https://github.com/ant-design/ant-design-pro',
  blankTarget: true
}, {
  key: 'Ant Design',
  title: 'Ant Design',
  href: 'https://ant.design',
  blankTarget: true
}];
var defaultCopyright = '2019 蚂蚁金服体验技术部出品';

var FooterView = function FooterView(_ref) {
  var links = _ref.links,
      copyright = _ref.copyright,
      style = _ref.style,
      className = _ref.className;
  return React.createElement(Footer, {
    className: className,
    style: _objectSpread({
      padding: 0
    }, style)
  }, React.createElement(GlobalFooter, {
    links: links !== undefined ? links : defaultLinks,
    copyright: copyright === false ? null : React.createElement(Fragment, null, "Copyright ", React.createElement(_Icon, {
      type: "copyright"
    }), " ", copyright || defaultCopyright)
  }));
};

export default FooterView;