/**
 * 修订状态
 */
var InvoiceExecStatusEnum;

(function (InvoiceExecStatusEnum) {
  /** 已锁定 */
  InvoiceExecStatusEnum[InvoiceExecStatusEnum["lock"] = 0] = "lock";
  /** 已打回 */

  InvoiceExecStatusEnum[InvoiceExecStatusEnum["reject"] = 1] = "reject";
  /** 已解锁 */

  InvoiceExecStatusEnum[InvoiceExecStatusEnum["unlock"] = 2] = "unlock";
  /** 调整非关键信息 */

  InvoiceExecStatusEnum[InvoiceExecStatusEnum["unkeyMsg"] = 6] = "unkeyMsg";
  /** 调整关键信息 */

  InvoiceExecStatusEnum[InvoiceExecStatusEnum["keyMsg"] = 7] = "keyMsg";
  /** 已作废 */

  InvoiceExecStatusEnum[InvoiceExecStatusEnum["invalid"] = 9] = "invalid";
})(InvoiceExecStatusEnum || (InvoiceExecStatusEnum = {}));
/**
 * 页面模式枚举
 */


var PageModeEnum;

(function (PageModeEnum) {
  /** 默认 */
  PageModeEnum[PageModeEnum["none"] = 0] = "none";
  /** 新增 */

  PageModeEnum[PageModeEnum["add"] = 1] = "add";
  /** 修改 */

  PageModeEnum[PageModeEnum["modify"] = 2] = "modify";
  /** 查看 */

  PageModeEnum[PageModeEnum["look"] = 4] = "look";
})(PageModeEnum || (PageModeEnum = {}));
/** 大控件类型 */


var PageControlMode;

(function (PageControlMode) {
  /** 表单控件 */
  PageControlMode[PageControlMode["form"] = 0] = "form";
  /** 搜索栏控件 */

  PageControlMode[PageControlMode["find"] = 1] = "find";
  /** 表格编辑控件 */

  PageControlMode[PageControlMode["gridEdit"] = 2] = "gridEdit";
  /** 表格视图控件 */

  PageControlMode[PageControlMode["gridView"] = 3] = "gridView";
  /** 树表格视图控件 */

  PageControlMode[PageControlMode["treeView"] = 4] = "treeView";
  /** 树表格编辑控件 */

  PageControlMode[PageControlMode["treeEdit"] = 5] = "treeEdit";
  /** 树列表控件 */

  PageControlMode[PageControlMode["treeList"] = 6] = "treeList";
  /** 列表控件 */

  PageControlMode[PageControlMode["gridList"] = 7] = "gridList";
  /** 工具栏控件 */

  PageControlMode[PageControlMode["toolbar"] = 8] = "toolbar";
  /** 文档控件 */

  PageControlMode[PageControlMode["docs"] = 9] = "docs";
})(PageControlMode || (PageControlMode = {}));
/** 当前表单子项必填类别 */


var RequiredTypeEnum;

(function (RequiredTypeEnum) {
  /** 不必填 */
  RequiredTypeEnum["normal"] = "normal";
  /** 必填 */

  RequiredTypeEnum["required"] = "required";
  /** 建议填 */

  RequiredTypeEnum["advise"] = "advise";
})(RequiredTypeEnum || (RequiredTypeEnum = {}));
/**
 * 页面状态枚举
 */


var PageStatusEnum;

(function (PageStatusEnum) {
  PageStatusEnum[PageStatusEnum["configLoading"] = 1] = "configLoading";
  PageStatusEnum[PageStatusEnum["configLoaded"] = 2] = "configLoaded";
  PageStatusEnum[PageStatusEnum["initing"] = 3] = "initing";
  PageStatusEnum[PageStatusEnum["inited"] = 4] = "inited";
  PageStatusEnum[PageStatusEnum["dataLoading"] = 5] = "dataLoading";
  PageStatusEnum[PageStatusEnum["dataLoaded"] = 6] = "dataLoaded";
  PageStatusEnum[PageStatusEnum["done"] = 7] = "done";
})(PageStatusEnum || (PageStatusEnum = {}));
/**
 * 打开窗口模式枚举
 */


var OpenModeEnum;

(function (OpenModeEnum) {
  /** 在当前页面通过弹出对话框打开一个页面 */
  OpenModeEnum["tier"] = "modalTier";
  /** 在当前页面通过屏幕边缘滑出的浮层面板打开一个页面 */

  OpenModeEnum["drawer"] = "drawer";
  /** 在浏览器中新的标签页打开一个页面 */

  OpenModeEnum["openWindow"] = "openWindow";
  /** 替换当前页面 */

  OpenModeEnum["self"] = "self";
  /** 替换父级页面 */

  OpenModeEnum["parent"] = "parent";
  /** 替换顶级页面 */

  OpenModeEnum["top"] = "top";
})(OpenModeEnum || (OpenModeEnum = {}));
/**
 * 数据源请求模式枚举
 */


var DataModeEnum;

(function (DataModeEnum) {
  /** 全部 */
  DataModeEnum["all"] = "all";
  /** 首条记录 */

  DataModeEnum["one"] = "one";
  /** 分页 */

  DataModeEnum["pagination"] = "page";
})(DataModeEnum || (DataModeEnum = {}));
/**
 * 行数据源状态枚举
 */


var DataStateEnum;

(function (DataStateEnum) {
  /**
   * 自动新增行
   */
  DataStateEnum[DataStateEnum["autoNew"] = 0] = "autoNew";
  /**
   * 新增状态
   */

  DataStateEnum[DataStateEnum["added"] = 1] = "added";
  /**
   * 修改状态
   */

  DataStateEnum[DataStateEnum["changed"] = 2] = "changed";
  /**
   * 删除状态
   */

  DataStateEnum[DataStateEnum["deleted"] = 4] = "deleted";
  /**
   * 未修改状态
   */

  DataStateEnum[DataStateEnum["unchanged"] = 8] = "unchanged";
  /**
   * 已更改的所有类型数据
   */

  DataStateEnum[DataStateEnum["allchanged"] = 7] = "allchanged";
  /**
   * 所有未删除数据
   */

  DataStateEnum[DataStateEnum["undeleted"] = 11] = "undeleted";
  /**
   * 所有数据
   */

  DataStateEnum[DataStateEnum["all"] = 15] = "all";
  /**
   * 未新增的数据
   */

  DataStateEnum[DataStateEnum["unadded"] = 16] = "unadded";
})(DataStateEnum || (DataStateEnum = {}));
/**
 * 控件有效显示模式
 */


var ControlDisplayModeEnum;

(function (ControlDisplayModeEnum) {
  /** 控件始终不可显示 */
  ControlDisplayModeEnum[ControlDisplayModeEnum["none"] = 0] = "none";
  /** 页面新增模式可显示 */

  ControlDisplayModeEnum[ControlDisplayModeEnum["add"] = 1] = "add";
  /** 页面修改模式可显示 */

  ControlDisplayModeEnum[ControlDisplayModeEnum["modify"] = 2] = "modify";
  /** 页面查看模式可显示 */

  ControlDisplayModeEnum[ControlDisplayModeEnum["look"] = 4] = "look";
  /** 页面新增、修改模式可显示 */

  ControlDisplayModeEnum[ControlDisplayModeEnum["add&modify"] = 3] = "add&modify";
  /** 页面新增、查看模式可显示 */

  ControlDisplayModeEnum[ControlDisplayModeEnum["add&look"] = 5] = "add&look";
  /** 页面修改、查看模式可显示 */

  ControlDisplayModeEnum[ControlDisplayModeEnum["modify&look"] = 6] = "modify&look";
  /** 控件始终可显示 */

  ControlDisplayModeEnum[ControlDisplayModeEnum["all"] = 7] = "all";
})(ControlDisplayModeEnum || (ControlDisplayModeEnum = {}));
/**
 * 控件有效可编辑模式
 */


var ControlModifyModeEnum;

(function (ControlModifyModeEnum) {
  /** 控件始终不可编辑 */
  ControlModifyModeEnum[ControlModifyModeEnum["none"] = 0] = "none";
  /** 页面新增模式可编辑 */

  ControlModifyModeEnum[ControlModifyModeEnum["add"] = 1] = "add";
  /** 页面修改模式可编辑 */

  ControlModifyModeEnum[ControlModifyModeEnum["modify"] = 2] = "modify";
  /** 页面查看模式可编辑 */

  ControlModifyModeEnum[ControlModifyModeEnum["look"] = 4] = "look";
  /** 页面新增、修改模式可编辑 */

  ControlModifyModeEnum[ControlModifyModeEnum["add&modify"] = 3] = "add&modify";
  /** 页面新增、查看模式可编辑 */

  ControlModifyModeEnum[ControlModifyModeEnum["add&look"] = 5] = "add&look";
  /** 页面修改、查看模式可编辑 */

  ControlModifyModeEnum[ControlModifyModeEnum["modify&look"] = 6] = "modify&look";
  /** 控件始终可编辑 */

  ControlModifyModeEnum[ControlModifyModeEnum["all"] = 7] = "all";
})(ControlModifyModeEnum || (ControlModifyModeEnum = {}));
/**
 * 控件在什么模式下显示成摘要模式
 */


var ControlDigestDisplayModeEnum;

(function (ControlDigestDisplayModeEnum) {
  /** 控件始终不显示成摘要模式 */
  ControlDigestDisplayModeEnum[ControlDigestDisplayModeEnum["none"] = 0] = "none";
  /** 页面新增模式显示成摘要模式 */

  ControlDigestDisplayModeEnum[ControlDigestDisplayModeEnum["add"] = 1] = "add";
  /** 页面修改模式显示成摘要模式 */

  ControlDigestDisplayModeEnum[ControlDigestDisplayModeEnum["modify"] = 2] = "modify";
  /** 页面查看模式显示成摘要模式 */

  ControlDigestDisplayModeEnum[ControlDigestDisplayModeEnum["look"] = 4] = "look";
  /** 页面新增、修改模式显示成摘要模式 */

  ControlDigestDisplayModeEnum[ControlDigestDisplayModeEnum["add&modify"] = 3] = "add&modify";
  /** 页面新增、查看模式显示成摘要模式 */

  ControlDigestDisplayModeEnum[ControlDigestDisplayModeEnum["add&look"] = 5] = "add&look";
  /** 页面修改、查看模式显示成摘要模式 */

  ControlDigestDisplayModeEnum[ControlDigestDisplayModeEnum["modify&look"] = 6] = "modify&look";
  /** 控件始终显示成摘要模式 */

  ControlDigestDisplayModeEnum[ControlDigestDisplayModeEnum["all"] = 7] = "all";
})(ControlDigestDisplayModeEnum || (ControlDigestDisplayModeEnum = {}));
/**
 * 控件禁用状态模式
 */


var ControlDisabledModeEnum;

(function (ControlDisabledModeEnum) {
  /** 控件始终不会禁用 */
  ControlDisabledModeEnum[ControlDisabledModeEnum["none"] = 0] = "none";
  /** 页面新增模式下控件禁用 */

  ControlDisabledModeEnum[ControlDisabledModeEnum["add"] = 1] = "add";
  /** 页面修改模式下控件禁用 */

  ControlDisabledModeEnum[ControlDisabledModeEnum["modify"] = 2] = "modify";
  /** 页面查看模式下控件禁用 */

  ControlDisabledModeEnum[ControlDisabledModeEnum["look"] = 4] = "look";
  /** 页面新增、修改模式下控件禁用 */

  ControlDisabledModeEnum[ControlDisabledModeEnum["add&modify"] = 3] = "add&modify";
  /** 页面新增、查看模式下控件禁用 */

  ControlDisabledModeEnum[ControlDisabledModeEnum["add&look"] = 5] = "add&look";
  /** 页面修改、查看模式下控件禁用 */

  ControlDisabledModeEnum[ControlDisabledModeEnum["modify&look"] = 6] = "modify&look";
  /** 控件始终禁用 */

  ControlDisabledModeEnum[ControlDisabledModeEnum["all"] = 7] = "all";
})(ControlDisabledModeEnum || (ControlDisabledModeEnum = {}));

export { ControlDigestDisplayModeEnum, ControlDisabledModeEnum, ControlDisplayModeEnum, ControlModifyModeEnum, DataModeEnum, DataStateEnum, InvoiceExecStatusEnum, OpenModeEnum, PageControlMode, PageModeEnum, PageStatusEnum, RequiredTypeEnum };
