import Store from '@mjcloud/redux';
import { IReduce } from '@mjcloud/redux';
import TreeEditCellBaseReduce from '../treeedit-cell-base/reduce';
import { IEditableComplexCellState, IEditableComplexCellInitialStateParams } from './typings';

class TreeEditCellReduce extends TreeEditCellBaseReduce
  implements IReduce<IEditableComplexCellState> {
  initialState(
    store: Store<IEditableComplexCellState>,
    params: IEditableComplexCellInitialStateParams,
  ) {
    const state: IEditableComplexCellState = this.__initialState(params);
    return state;
  }
}

export default new TreeEditCellReduce();
