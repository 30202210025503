import React from 'react';
import globalData from '@mjcloud/global-data';
import { getInstance } from '@mjcloud/mcontrols';
// import controlTypes from './controlTypes';
import { IControlBaseProps } from '@mjcloud/types';

import FormControl from '../controls/form';
import TabsControl from '../controls/tabs';
import LabelControl from '../controls/label';
import ChartControl from '../controls/chart';
import ModuleControl from '../controls/module';
import SelectControl from '../controls/select';
import NumberControl from '../controls/number';
import ButtonControl from '../controls/button';
import TextboxControl from '../controls/textbox';
import ToolbarControl from '../controls/toolbar';
import DatetimeControl from '../controls/datetime';
import CarouselControl from '../controls/carousel';
import TextAreaControl from '../controls/text-area';
import ListEditControl from '../controls/list-edit';
import GridViewControl from '../controls/grid-view';
import WorkbenchControl from '../controls/workbench';
import HyperlinkControl from '../controls/hyperlink';
import FilterbarControl from '../controls/filterbar';
import WaterfallControl from '../controls/waterfall';
import AccordionControl from '../controls/accordion';
import StatisticsControl from '../controls/statistics';
import FileManagerControl from '../controls/file-manager';
import SimpleSearchControl from '../controls/simple-search';
import RadioButtonsControl from '../controls/radio-buttons';

class ControlFactory extends React.Component<IControlBaseProps> {
  private _instance: any;
  constructor(props: IControlBaseProps) {
    super(props);
    const { pageKey, parentId, controlId } = this.props;
    this._instance = getInstance({ pageKey, parentId, controlId });
  }

  public get instance() {
    return this._instance;
  }

  render() {
    const instance = this.instance,
      { controlTypes } = globalData,
      { pageKey, parentId, controlId } = this.props,
      controlProps: IControlBaseProps = { pageKey, parentId, controlId };
    if (instance instanceof controlTypes.Waterfall) {
      return <WaterfallControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Carousel) {
      return <CarouselControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Module) {
      return <ModuleControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Filterbar) {
      return <FilterbarControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Select) {
      return <SelectControl {...controlProps} />;
    } else if (instance instanceof controlTypes.SimpleSearch) {
      return <SimpleSearchControl {...controlProps} />;
    } else if (instance instanceof controlTypes.GridView) {
      return <GridViewControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Form) {
      return <FormControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Label) {
      return <LabelControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Datetime) {
      return <DatetimeControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Number) {
      return <NumberControl {...controlProps} />;
    } else if (instance instanceof controlTypes.FileManager) {
      return <FileManagerControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Toolbar) {
      return <ToolbarControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Button) {
      return <ButtonControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Tabs) {
      return <TabsControl {...controlProps} />;
    } else if (instance instanceof controlTypes.ListEdit) {
      return <ListEditControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Textbox) {
      return <TextboxControl {...controlProps} />;
    } else if (instance instanceof controlTypes.TextArea) {
      return <TextAreaControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Chart) {
      return <ChartControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Workbench) {
      return <WorkbenchControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Hyperlink) {
      return <HyperlinkControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Hyperlink) {
      return <HyperlinkControl {...controlProps} />;
    } else if (instance instanceof controlTypes.RadioButtons) {
      return <RadioButtonsControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Accordion) {
      return <AccordionControl {...controlProps} />;
    } else if (instance instanceof controlTypes.Statistics) {
      return <StatisticsControl {...controlProps} />;
    }
    return <>Control Not Find</>;
  }
}

export default ControlFactory;
