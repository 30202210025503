import Store, { IReduce } from '@mjcloud/redux';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { FormSectionInitialStateParams, IFormSectionState } from './typings';

class FormSectionReduce implements IReduce<IFormSectionState> {
  initialState(store: Store<IFormSectionState>, params: FormSectionInitialStateParams) {
    const { initConfig: config, pageMode, items } = params;
    const { cols, showDivider, itemTitleWidth, displayMode, digestDisplayMode } = config;
    const id = config.id || '',
      index = config.index == null ? 0 : config.index,
      title = config.title || '',
      showTitle = title ? (config.showTitle == null ? true : config.showTitle) : false;
    let state: IFormSectionState = {
      id,
      index,
      title,
      items,
      config,
      showTitle,
      tabIndex: -1,
      configIsFetching: false,
      cols: cols == null ? 2 : cols,
      labelNumber: itemTitleWidth == null ? 5 : undefined,
      showDivider: showDivider == null ? true : showDivider,
      labelWidth: itemTitleWidth == null ? false : itemTitleWidth,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      digestDisplay: PageModeHelper.digestDisplayMode2boolean(pageMode, digestDisplayMode),
    };
    return state;
  }

  updateDisplay(store: Store<IFormSectionState>, params: { display: boolean }) {
    if (params.display === store.state.display) {
      return store.state;
    }
    const state: IFormSectionState = { ...store.state, display: params.display };
    return state;
  }

  updateReadonly(store: Store<IFormSectionState>, params: { readonly: boolean }) {
    return store.state;
  }

  updateTitle(store: Store<IFormSectionState>, params: { title: string }) {
    if (params.title === store.state.title) {
      return store.state;
    }
    const state: IFormSectionState = { ...store.state, title: params.title };
    return state;
  }
}

export default new FormSectionReduce();
