import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import {
  ITabsItem,
  ITabsState,
  ITabsInitialStateParams,
  ITabsUpdateActiveKeyParams,
  ITabsSetTabPane2DisplayParams,
  ITabsBatchAddTabPane2IframeParams,
} from './typings';

function traverseTabPanes2Right(items: ITabsItem[], activeIndex: number) {
  let activeKey: string | undefined;
  if (activeIndex < 0) return activeKey;
  for (let index = activeIndex + 1; index < items.length; index++) {
    const { id, display } = items[index];
    if (display) {
      activeKey = id;
      break;
    }
  }
  return activeKey;
}

export class TabsReduce extends ReduceBase {
  initialState(store: Store<ITabsState>, params: ITabsInitialStateParams) {
    const { initConfig, pageMode, items, controls, texts, activeKey } = params,
      { displayMode, isShowTabPane = true } = initConfig;
    let state: ITabsState = {
      items,
      texts,
      controls,
      activeKey,
      tabIndex: -1,
      isShowTabPane,
      theme: 'line',
      widthType: 'full',
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  updateTabPaneHeight(store: Store<ITabsState>, params: { height: string | number | undefined }) {
    return { ...store.state, tabPaneHeight: params.height };
  }

  updateActiveKey(store: Store<ITabsState>, params: ITabsUpdateActiveKeyParams) {
    return { ...store.state, activeKey: params.activeKey };
  }

  batchAddTabPane2Iframe(store: Store<ITabsState>, params: ITabsBatchAddTabPane2IframeParams) {
    const { tabPanes, pageKey, parentId } = params,
      { items, texts } = store.state;
    tabPanes.map(({ title, tabPaneId }) => {
      texts.push(title);
      items.push({ id: tabPaneId, pageKey, parentId, display: true, title });
    });
    return { ...store.state, items, texts };
  }

  setTabPane2Display(store: Store<ITabsState>, params: ITabsSetTabPane2DisplayParams) {
    let { tabPanes } = params,
      { items, activeKey } = store.state,
      oldActiveKey = activeKey,
      activeIndex = -1,
      isUpdate = false;
    items = items.map((item, i) => {
      for (const { controlId, display } of tabPanes) {
        if (controlId === item.id && display !== item.display) {
          item.display = display;
          isUpdate = true;
          if (display === false && controlId === activeKey) {
            activeIndex = i;
          }
        }
      }
      return item;
    });
    if (activeIndex === 0) {
      // 如果激活项为第一个则往右遍历找到显示的tab并激活
      const _activeKey = traverseTabPanes2Right(items, activeIndex);
      if (_activeKey != null) {
        activeKey = _activeKey;
      }
    } else if (activeIndex !== -1) {
      // 其他情况
      // 先往左遍历找到显示的tab并激活
      for (let index = activeIndex - 1; index >= 0; index--) {
        const { id, display } = items[index];
        if (display) {
          activeKey = id;
          break;
        }
      }
      if (oldActiveKey === activeKey) {
        // 如果往左遍历没找到可激活的tab，则往右遍历找到显示的tab并激活
        const _activeKey = traverseTabPanes2Right(items, activeIndex);
        if (_activeKey != null) {
          activeKey = _activeKey;
        }
      }
    }
    if (isUpdate) {
      return { ...store.state, items, activeKey };
    }
    return store.state;
  }
}

export default new TabsReduce();
