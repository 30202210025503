import React from 'react';
import { IState } from '@mjcloud/redux';
import { getInstance } from '@mjcloud/mcontrols';
import { IControlBaseProps } from '@mjcloud/types';
import { InstanceBase } from '@mjcloud/instance';

/**
 * 控件基类
 * @description 控件通过类组件的形态创建
 */
export default abstract class ControlBase<
  S extends IState,
  I extends InstanceBase = InstanceBase,
  P extends IControlBaseProps = IControlBaseProps
> extends React.Component<P, S> {
  instance: I;
  constructor(props: P) {
    super(props);
    const { controlId, parentId, pageKey } = props;
    this.instance = getInstance<I>({ pageKey, parentId, controlId });
    this.state = this.instance.store.state as any;
    this.instance.store.bindViewListen(this.handStateChange);
  }

  public componentWillUnmount() {
    this.instance.store.unbindViewListen(this.handStateChange);
  }

  shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>) {
    if (nextState !== this.state) return true;
    return false;
  }

  private handStateChange = (state: S, cb?: () => void) => {
    this.setState(state, cb);
  };

  public renderContent(): React.ReactNode {
    throw new Error('Method not implemented.');
  }

  public render(): React.ReactNode {
    if (this.state.configIsFetching) return null;
    if (!this.state.display) return null;
    if (this.state.configErrorMessage) return this.state.configErrorMessage;
    return this.renderContent();
  }
}
