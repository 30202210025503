import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { ISeparateState, ISeparateInitialStateParams } from './typings';

export class SeparateReduce extends ReduceBase {
  initialState(store: Store<ISeparateState>, params: ISeparateInitialStateParams) {
    const { initConfig, pageMode } = params,
      { type, displayMode } = initConfig;
    let state: ISeparateState = {
      type,
      tabIndex: -1,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }
}

export default new SeparateReduce();
