import React from 'react';
import Icon from '@mjcloud/icon';
import classNames from 'classnames';
import ControlBase from '../../core/base';
import { Row, Col, Empty, Spin } from 'antd';
import {
  IStatisticsState,
  IStatisticsQuota,
  IStatisticsInfo,
  IStatisticsItem,
} from '@mjcloud/mcontrols/dist/controls/statistics/typings';

import styles from './index.less';

export default class StatisticsControl extends ControlBase<IStatisticsState> {
  private handleCollapse = () => {
    this.instance.store.dispatch('updateCollapse', { collapse: !this.state.collapse });
  };

  private handleClick = (row: any) => {
    this.instance.store.dispatch('rowClickAfter', { row });
  };

  renderNormal() {
    const { cols, _dataSource, showLine, collapse, showCollapse } = this.state,
      span = 24 / cols;

    return (
      <>
        {_dataSource.map((items, key) => (
          <Row key={key} gutter={24} style={{ width: '100%' }}>
            {items.map((_row, index) => {
              const { _briefList } = _row;
              const handleClick = () => this.handleClick(_row);
              return (
                <Col span={span} key={index} style={{ padding: 0 }} onClick={handleClick}>
                  <div className={styles.itemContainer}>
                    <div className={styles.item}>
                      {_briefList.map(({ style, text }, k) => (
                        <div key={k} style={style} className={styles.itemSon}>
                          {text}
                        </div>
                      ))}
                    </div>
                    {showLine && !!((index + 1) % cols) && <div className={styles.line}></div>}
                  </div>
                </Col>
              );
            })}
          </Row>
        ))}
        {showCollapse && (
          <div className={styles.collapse} onClick={this.handleCollapse}>
            <Icon type={collapse ? 'down' : 'up'} />
          </div>
        )}
      </>
    );
  }

  renderQuota2Blue(row: IStatisticsItem) {
    const { _quotaBriefList } = row,
      span = 24 / this.state.cols;
    return (
      <Row gutter={24} style={{ width: '100%' }}>
        {_quotaBriefList.map((_row, index) => {
          const { val, interval, status } = _row;
          const statusStyle = classNames(status == 0 && styles.decline, status == 1 && styles.rise);
          const handleClick = () => this.handleClick(_row);
          return (
            <Col span={span} key={index} style={{ padding: 0 }} onClick={handleClick}>
              <div className={styles.itemContainer}>
                <div className={styles.item}>
                  {val && <div className={styles.itemSon}>{val.title}</div>}
                  {val && (
                    <span
                      className={classNames(statusStyle, styles.quotaValue, styles.itemSon)}
                      style={val.style}
                    >
                      {status == 0 && <Icon type="icon-caret-down" />}
                      {status == 1 && <Icon type="icon-caret-up" />}
                      <span className={styles.quotaValueMid}>{val.text}</span>
                    </span>
                  )}
                  {interval ? (
                    <span className={classNames(styles.intervalContainer, styles.itemSon)}>
                      <span className={styles.intervalTitle}>{interval.title}</span>
                      <span
                        className={classNames(statusStyle, styles.intervalValue)}
                        style={interval.style}
                      >
                        {interval.text}
                      </span>
                    </span>
                  ) : null}
                </div>
                {this.state.showLine && !!((index + 1) % this.state.cols) && (
                  <div className={styles.line}></div>
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    );
  }
  renderQuota2Norm(row: IStatisticsItem) {
    const { _quotaBriefList } = row,
      span = 24 / this.state.cols;
    return (
      <Row gutter={24} style={{ width: '100%' }}>
        {_quotaBriefList.map((_row, index) => {
          const { val } = _row;
          const handleClick = () => this.handleClick(_row);
          return (
            <Col span={span} key={index} style={{ padding: 0 }} onClick={handleClick}>
              <div className={styles.itemContainer}>
                <div className={styles.item}>
                  {val && (
                    <div className={classNames(styles.quotaTitle, styles.itemSon)}>{val.title}</div>
                  )}
                  {val && (
                    <span
                      className={classNames(styles.quotaValue, styles.itemSon)}
                      style={val.style}
                    >
                      <span className={styles.quotaValueMid}>{val.text}</span>
                    </span>
                  )}
                </div>
                {this.state.showLine && !!((index + 1) % this.state.cols) && (
                  <div className={styles.line}></div>
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    );
  }

  renderQuota(quota: IStatisticsQuota) {
    const { dataSource, collapse, showCollapse } = this.state;
    let children: React.ReactNode;
    switch (quota.styleName) {
      case 'blue':
        children = this.renderQuota2Blue(dataSource[0]);
        break;
      case 'norm':
      default:
        children = this.renderQuota2Norm(dataSource[0]);
        break;
    }
    return (
      <>
        {children}
        {showCollapse && (
          <div className={styles.collapse} onClick={this.handleCollapse}>
            <Icon type={collapse ? 'down' : 'up'} />
          </div>
        )}
      </>
    );
  }

  renderInfo(info: IStatisticsInfo) {
    const { dataSource } = this.state,
      { _infoBriefList } = dataSource[0];
    return (
      <div className={styles.infoContainer}>
        {_infoBriefList.map(_row => {
          const { val } = _row;
          if (!val) return null;
          const handleClick = () => this.handleClick(_row);
          return (
            <span className={styles.infoItem} onClick={handleClick}>
              <span>{val.title}</span>
              <span className={styles.infoItemVal} style={val.style}>
                {val.text}
              </span>
              <span>{val.unit}</span>
            </span>
          );
        })}
      </div>
    );
  }

  renderContent() {
    const { info, quota, size2rem, dataSource, isFetching } = this.state,
      blue = quota ? quota.styleName === 'blue' : false,
      norm = quota ? quota.styleName === 'norm' : false;
    if (dataSource.length === 0) {
      return (
        <div className={classNames(styles.empty, blue && styles.blue)}>
          <Empty />
        </div>
      );
    }
    return (
      <div
        style={{ height: info && size2rem }}
        className={classNames(styles.container, blue && styles.blue, norm && styles.norm)}
      >
        <Spin spinning={isFetching} style={{ width: '100%' }}>
          {quota ? this.renderQuota(quota) : info ? this.renderInfo(info) : this.renderNormal()}
        </Spin>
      </div>
    );
  }
}
