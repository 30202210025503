import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IButtonsState, IButtonsInitialStateParams } from './typings';

export class ButtonsReduce extends ReduceBase<IButtonsState> {
  initialState(store: Store<IButtonsState>, params: IButtonsInitialStateParams) {
    const { initConfig, pageMode, ChildrenControls } = params,
      { id, title, icon, displayMode } = initConfig;
    let state: IButtonsState = {
      id,
      icon,
      title,
      tabIndex: -1,
      disabled: false,
      ChildrenControls,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  updateDisabled(store: Store<IButtonsState>, params: { disabled: boolean }) {
    if (store.state.disabled === params.disabled) {
      return store.state;
    }
    return { ...store.state, disabled: params.disabled };
  }
}

export default new ButtonsReduce();
