import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import useChart from './useChart';
import { Chart } from '@antv/g2';

class ErrorBoundary extends React.Component<any> {
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  static getDerivedStateFromProps(nextProps: any, state: any) {
    if (state.children !== nextProps.children) {
      return {
        children: nextProps.children,
        hasError: false,
        error: undefined,
      };
    }
    return null;
  }

  state: {
    hasError: boolean;
    error?: Error;
  } = {
    hasError: false,
  };

  renderError = (e: Error) => {
    switch (e) {
      default:
        // fallback
        return <h5>组件出错了，请核查后重试： {e.message}</h5>;
    }
  };

  render() {
    if (this.state.hasError) {
      return this.renderError(this.state.error!);
    }
    return this.props.children;
  }
}

export interface ChartProps {
  chartRef?: React.MutableRefObject<Chart | undefined>;
  style?: React.CSSProperties;
  className?: string;

  data: any;
  onlyChangeData?: boolean;
  updateConfig: (chart: Chart) => void;
}

const G2Chart = forwardRef((props: ChartProps, ref) => {
  const { chartRef, style = {}, className, updateConfig, ...rest } = props;

  const { chart, container } = useChart(rest, updateConfig);
  useEffect(() => {
    if (chartRef) {
      chartRef.current = chart.current;
    }
  }, [chart.current]);
  useImperativeHandle(ref, () => ({
    getChart: () => chart.current,
  }));
  return (
    <ErrorBoundary>
      <div className={className} style={style} ref={container} />
    </ErrorBoundary>
  );
});

export default G2Chart;
