import React from 'react';
import { Tabs } from 'antd-mobile';
import styled from 'styled-components';
import ControlBase from '../../core/base';
import globalData from '@mjcloud/global-data';
import { ITabsState } from '@mjcloud/mcontrols/dist/controls/tabs/typings';

const Container = styled.div`
  width: 100%;
  height: 100%;
  .am-tabs-horizontal .am-tabs-pane-wrap-active {
    height: auto;
  }
`;

const Content = styled.div<{ height?: number | string }>(({ height }) => ({
  height: height ? height : '100%',
  minHeight: '100%',
}));

export default class TabsControl extends ControlBase<ITabsState> {
  private handleChange = (_: any, activeIndex: number) => {
    var activeItemId = this.state.items[activeIndex].controlId;
    if (this.instance.eventManager.getHandlerCount('tabClick') > 0) {
      this.instance.eventManager.trigger('tabClick', { activeIndex, activeItemId });
    }
    this.instance.store.dispatch('updateActiveIndex', { activeIndex });
  };

  renderContent() {
    const { page, items, texts, tabPaneHeight, activeIndex } = this.state,
      tabs = texts.map(t => ({ title: t })),
      tabBarTextStyle: React.CSSProperties = {
        padding: 0,
        display: 'block',
        overflow: 'hidden',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      };
    const { ControlFactory } = globalData;
    return (
      <Container>
        <Tabs
          tabs={tabs}
          swipeable={false}
          page={activeIndex}
          onChange={this.handleChange}
          prerenderingSiblingsNumber={0}
          tabBarTextStyle={tabBarTextStyle}
          renderTabBar={props => <Tabs.DefaultTabBar {...props} page={page} />}
        >
          {items.map(({ display, ...props }) => {
            return display ? (
              <Content height={tabPaneHeight}>
                <ControlFactory {...props} />
              </Content>
            ) : null;
          })}
        </Tabs>
      </Container>
    );
  }
}
