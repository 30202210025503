import { ITableBaseReviseConfig } from './../common/table/typings';
import { v4 as uuid } from 'uuid';
import Store from '@mjcloud/redux';
import { SCROLLMINHEIGHT } from '../constant';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { ITableBaseRow } from '../common/table/typings';
import { OPERATIONWIDTH } from '../common/table/constant';
import { TableRowSelection } from 'antd/lib/table/interface';
import TableReduceBase, { updateColumns } from '../common/table/reduce';
import { IColumnProps } from '../../components/VirtualizedTable/interface';
import {
  IGridViewState,
  GridViewActionType,
  IGridViewLoadedParams,
  IGridViewStartLoadParams,
  IGridViewInitialStateParams,
  IGridViewUpdateSelectedRowsParams,
} from './typings';

/**
 * TODO antd Table 若宽度为null会出现样式异常
 */
function widthNotZero(columns: IColumnProps<ITableBaseRow>[]) {
  columns.forEach(column => {
    if (column.width == null) {
      column.width = 1;
      if (column.children) widthNotZero(column.children);
    }
  });
}

export class GridViewReduce extends TableReduceBase<
  ITableBaseRow[],
  ITableBaseRow,
  IGridViewState
> {
  initialState(
    store: Store<IGridViewState, GridViewActionType>,
    params: IGridViewInitialStateParams,
  ) {
    const { initConfig, pageMode, onCell, onOperation, render, aggs, refs } = params,
      { mayCustom, showPager, modifyMode, showOrdinal, displayMode, showSummary } = initConfig,
      { card, list, cardList, rowClickController, orderby, fastnessCount } = initConfig,
      { isAutoSelection, rowSelection, rowSelectionType } = initConfig,
      { buttons, collapseMenu: collapse = true, revise: _revise } = initConfig,
      items = initConfig.items.items,
      showOperation = buttons ? buttons.items.length > 0 : false,
      isModify = PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      downMergeColumns = this.getDownMergeColumns(items);
    let revise: ITableBaseReviseConfig | undefined;
    if (_revise) {
      const {
        statusField = 'executingState',
        approveField = 'approveState',
        currentUserCanEditField = 'currentUserCanEdit',
        actionIds = _revise.actionId ? _revise.actionId.split(';') : [],
        ...outRevise
      } = _revise;
      revise = { ...outRevise, actionIds, statusField, approveField, currentUserCanEditField };
    }
    const { columns, isParent, totalWidth } = this.getColumns({
      refs,
      store,
      items,
      render,
      pageMode,
      collapse,
      isModify,
      showOrdinal,
      showOperation,
      fastnessCount,
      revise,
      onCell,
      onOperation,
    });
    let _rowSelection: TableRowSelection<ITableBaseRow> | undefined = undefined;
    if (rowSelection) {
      _rowSelection = { type: rowSelectionType };
    }
    widthNotZero(columns);
    const state: IGridViewState = {
      aggs,
      card,
      list,
      revise,
      orderby,
      columns,
      collapse,
      cardList,
      totalWidth,
      tabIndex: -1,
      showOperation,
      rowIdCount: 0,
      dataSource: [],
      modify: isModify,
      downMergeColumns,
      isFetching: false,
      config: initConfig,
      clickLoading: false,
      rowClickController,
      entireColumns: columns,
      originalColumns: items,
      mayCustom: !!mayCustom,
      selectedRows: undefined,
      configIsFetching: false,
      showSummary: !!showSummary,
      rowSelection: _rowSelection,
      scrollHeight: SCROLLMINHEIGHT,
      operationWidth: OPERATIONWIDTH,
      isAutoSelection: !!isAutoSelection,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      pagination:
        showPager == null || showPager == true
          ? {
              current: 1,
              pageSize: 10,
              showSizeChanger: true,
              showQuickJumper: true,
              // hideOnSinglePage: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: (total, range) => `总共 ${total} 条`,
            }
          : false,
    };
    state.columns = updateColumns(state.columns);
    // state.summaryDataSource = state.showSummary ? [{}] : undefined;
    return state;
  }

  startLoad(store: Store<IGridViewState>, params: IGridViewStartLoadParams) {
    const state = super.startLoad(store, params) as IGridViewState;
    params.revise = state.revise && !!state.revise.enable;
    // if (params.pageIndex == null && state.pagination) params.pageIndex = state.pagination.current;
    return state;
  }

  loaded(store: Store<IGridViewState>, params: IGridViewLoadedParams) {
    const { cardList } = store.state;
    let { pagination, rowIdCount, summaryDataSource } = store.state;
    const { callbackfn } = params,
      { isAutoSelection, rowSelection } = store.state,
      { aggs, rows, count, pageIndex, pageSize } = params.dataSource;

    if (pagination != false) {
      // TODO 返回的值本应该是 int,不需要 parseInt
      pagination.total = parseInt(count as any);
      pagination.current = parseInt(pageIndex as any);
      pagination.pageSize = parseInt(pageSize as any);
      // const { pageSize: _pageSize, current } = pagination;
      // ordinalIdCount = (_pageSize as number) * (current as number);
      // TODO: 当前页码为0的时候会导致空数据异常
      if (pagination.current === 0) pagination.current = 1;
    }
    const newDataSource: ITableBaseRow[] = rows.map((item, index, array) => {
      item._rid = ++rowIdCount;
      if (callbackfn) {
        return callbackfn(item, index, array);
      }
      return item;
    }) as any[];
    if (aggs) {
      summaryDataSource = [{ ...aggs, _rid: uuid().replace(/-/g, '') }] as any[];
    }
    const state: IGridViewState = {
      ...store.state,
      pagination,
      rowIdCount,
      summaryDataSource,
      isFetching: false,
      selectedRows: undefined,
    };
    if (cardList) {
      state.dataSource = state.dataSource.concat(newDataSource);
    } else {
      state.dataSource = newDataSource;
    }
    if (isAutoSelection && rowSelection && rowSelection.type === 'radio' && state.dataSource[0]) {
      state.selectedRows = [state.dataSource[0]];
    }
    return state;
  }

  updateSelectedRows(store: Store<IGridViewState>, params: IGridViewUpdateSelectedRowsParams) {
    const state: IGridViewState = store.state;
    state.selectedRows = params.selectedRows;
    return { ...state };
  }

  rowClickAfter(store: Store<IGridViewState>, params: {}) {
    store.state.clickLoading = true;
    return store.state;
  }

  clickDone(store: Store<IGridViewState>, params: {}) {
    if (!store.state.clickLoading) {
      return store.state;
    }
    store.state.clickLoading = false;
    return store.state;
    // return { ...store.state, loading: false };
  }
}

export default new GridViewReduce();
