import React from 'react';
import { Cascader } from 'antd';
import Select from 'antd/lib/select';
import ControlBase from '../../base';
import globalData from '@mjcloud/global-data';
import { ICascaderState } from '../typings';
import { CascaderOptionType } from 'antd/lib/cascader';
import { IValueControlUpdateValueParams } from '@mjcloud/instance/dist/valueInstanceBase';

function splitBySeparators(str: string, separators: string[]) {
  if (!str) return [];
  const reg = new RegExp(`[${separators.join()}]`);
  return `${str}`.split(reg).filter(token => token);
}

class CascaderControl extends ControlBase<ICascaderState> {
  private handleChange = (
    value: Array<number | string>,
    selectedOptions: CascaderOptionType[] = [],
  ) => {
    const { textTokenSeparator, vaueleTokenSeparator } = this.state,
      textArr: string[] = selectedOptions.map(item => item._text);
    this.instance.store.dispatch<IValueControlUpdateValueParams>('updateValue', {
      value: value.join(vaueleTokenSeparator),
      text: textArr.join(textTokenSeparator),
      actionSourceSign: this.instance.store,
    });
  };

  private handleDropdownVisibleChange = (open: boolean) => {
    if (open) this.instance.store.dispatch('startLoad', {});
  };

  renderContent() {
    if (this.state.errorMessage) return <Select disabled value={this.state.errorMessage} />;
    if (this.state.readonly) return <>{this.state.text || ''}</>;
    const { formatMessage } = globalData;
    const { tokenSeparators } = this.state;
    const { dataSource = [], isFetching, disabled, placeholder, value, text = '' } = this.state;
    let options = dataSource;
    const _value = splitBySeparators(value, tokenSeparators);
    if (options.length <= 0 && _value.length > 0) {
      options.push({ _rid: 0, _value: _value[0], _text: text, _disabled: false });
    }
    return (
      <Cascader
        allowClear
        showSearch
        value={_value}
        disabled={disabled}
        options={options}
        placeholder={placeholder}
        onChange={this.handleChange}
        // onDropdownVisibleChange={this.handleDropdownVisibleChange}
        fieldNames={{ value: '_value', label: '_text', children: '_children' }}
        notFoundContent={isFetching ? formatMessage({ id: 'global.data.loading' }) : undefined}
      />
    );
  }
}
export default CascaderControl;
