import React from 'react';
import Icon from '@mjcloud/icon';
import GlobalFooter, { FooterProps } from '@ant-design/pro-layout/lib/Footer';
import { IAppCopyright } from '@mjcloud/types';

interface IFooterViewProps extends IAppCopyright {}

const FooterView: React.SFC<IFooterViewProps> = ({ year, company, links = [] }) => {
  const globalFooterProps: FooterProps = {
    links: links.map(({ key, icon, title, href, blankTarget }) => ({
      key,
      href,
      blankTarget,
      title: icon ? <Icon type={icon} /> : title,
    })),
    copyright: `${year} ${company}`,
  };
  return <GlobalFooter {...globalFooterProps} />;
};
export default FooterView;
