import WorkbenchPart from '.';
import { PageStatusEnum } from '@mjcloud/types';
import { ExceptionHelper } from '@mjcloud/exception';
import { getWorkbenchPartConfig } from './util';
import { ActionAfterEventArg, ActionBeforeEventArg, IConfigLoadErrorParams } from '@mjcloud/redux';
import {
  IWorkbenchPartState,
  IWorkbenchPartConfigLoadedParams,
  IWorkbenchPartInitialStateParams,
} from './typings';

export default class WorkbenchPartExtendStore {
  constructor(private instance: WorkbenchPart) {}

  async handleConfigStartLoadAfter(e: ActionAfterEventArg<IWorkbenchPartState, string, any>) {
    try {
      const { initConfig } = e.params,
        { name } = initConfig;
      await this.instance.__initLogic(name);

      const part = await getWorkbenchPartConfig(name);

      if (part) {
        const params: IWorkbenchPartConfigLoadedParams = { initConfig: part };
        e.newState.dispatch<IWorkbenchPartConfigLoadedParams>('configLoaded', params);
      }
    } catch (error) {
      e.newState.dispatch<IConfigLoadErrorParams>('configLoadError', {
        errorMessage: '获取页面配置失败！',
      });
      ExceptionHelper.dispose(error);
    }
  }

  handleInitialStateBefore(e: ActionBeforeEventArg<IWorkbenchPartInitialStateParams>) {
    e.params.createControl = config => this.instance.__createControl(config);
    e.params.controlProps = {
      parentId: this.instance['workbench']['id'],
      pageKey: this.instance.page['id'],
    };
  }

  handleConfigLoadedAfter(
    e: ActionAfterEventArg<IWorkbenchPartState, string, IWorkbenchPartConfigLoadedParams>,
  ) {
    e.newState.dispatch('initialState', {
      ...e.params,
      pageMode: this.instance.page.pageMode,
    });
  }

  public handleDecreaseControlInitCountAfter(e: ActionAfterEventArg<IWorkbenchPartState>) {
    const { controlInitCount, status } = e.newState.state;
    if (controlInitCount === 0 && status === PageStatusEnum.initing) {
      e.newState.dispatch('statusUpdated', {
        newStatus: PageStatusEnum.inited,
      });
    }
  }

  public handleDecreaseControlLoadCountAfter(e: ActionAfterEventArg<IWorkbenchPartState>) {
    const { controlLoadCount, status } = e.newState.state;
    if (controlLoadCount === 0 && status === PageStatusEnum.dataLoading) {
      e.newState.dispatch('statusUpdated', {
        newStatus: PageStatusEnum.dataLoaded,
      });
    }
  }

  public async handleStatusUpdatedAfter(e: ActionAfterEventArg<IWorkbenchPartState, string, any>) {
    const { newStatus } = e.params;
    const state = e.newState.state;
    switch (newStatus) {
      case PageStatusEnum.configLoaded:
        e.newState.dispatch('statusUpdated', {
          newStatus: PageStatusEnum.initing,
        });
        this.instance.eventManager.trigger('configLoaded', state);
        break;
      case PageStatusEnum.inited:
        e.newState.dispatch('statusUpdated', {
          newStatus: PageStatusEnum.dataLoaded,
        });
        this.instance.eventManager.trigger('inited', state);
        break;
      case PageStatusEnum.dataLoaded:
        e.newState.dispatch('statusUpdated', {
          newStatus: PageStatusEnum.done,
        });
        this.instance.eventManager.trigger('dataLoaded', state);
        break;
      case PageStatusEnum.done:
        this.instance.__partLoadComplete();
        await this.instance.eventManager.trigger('done', state);
        break;
    }
  }
}
