import React from 'react';
import RadioList from '..';
import { Spin } from 'antd';
import TagSelect from './TagSelect';
import ControlBase from '../../base';
import { findDOMNode } from 'react-dom';
import { IRadioListState } from '../typings';
import ResizeObserver from 'resize-observer-polyfill';
import { IValueControlUpdateValueParams } from '@mjcloud/instance/dist/valueInstanceBase';

import styles from './index.less';
import { IDictionary } from '@mjcloud/types';
import { ISelectBaseDataSource } from '../../common/select/typings';

function splitBySeparators(str: string, separators: string[]) {
  if (!str) return [];
  const reg = new RegExp(`[${separators.join()}]`);
  return `${str}`.split(reg).filter(token => token);
}

class RadioListControl extends ControlBase<IRadioListState, RadioList> {
  private container: Element | null = null;
  private resizeObserver: ResizeObserver | null = null;

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.resizeObserver && this.container) {
      this.resizeObserver.unobserve(this.container);
      this.resizeObserver.disconnect();
      this.container = null;
    }
  }

  private handleResize = () => {
    if (this.container) {
      this.instance.store.dispatch('updateClientWidth', {
        clientWidth: this.container.clientWidth,
      });
    }
  };

  private handleChange = (selectValue: string[]) => {
    const { dataSource, textTokenSeparator, vaueleTokenSeparator } = this.state;
    const keyEntities: IDictionary<ISelectBaseDataSource> = {};
    dataSource.forEach(row => {
      keyEntities[row._value] = row;
    });
    const texts = selectValue.map(value => keyEntities[value]._text);
    this.instance.store.dispatch<IValueControlUpdateValueParams>('updateValue', {
      value: selectValue.join(vaueleTokenSeparator),
      text: texts.join(textTokenSeparator),
      actionSourceSign: this.instance.store,
    });
  };

  private renderTagSelect = (instance: any) => {
    const container = findDOMNode(instance);
    if (container instanceof Element) {
      this.container = container;
      this.resizeObserver = new ResizeObserver(this.handleResize);
      this.resizeObserver.observe(container);
    }
  };

  renderContent() {
    if (this.state.readonly) return <>{this.state.text || ''}</>;
    const { dataSource, isFetching, tokenSeparators, expandable, value } = this.state;
    if (this.state.configErrorMessage) return <>{this.state.configErrorMessage}</>;
    const valueArr: string[] = splitBySeparators(value, tokenSeparators);
    return (
      <Spin spinning={isFetching}>
        <TagSelect
          value={valueArr}
          ref={this.renderTagSelect}
          onChange={this.handleChange}
          className={styles.radiolist}
          expandable={expandable}
        >
          {dataSource.map(item => {
            return (
              <TagSelect.Option key={item._value} value={String(item._value)}>
                {item._text}
              </TagSelect.Option>
            );
          })}
        </TagSelect>
      </Spin>
    );
  }
}

export default RadioListControl;
