import React from 'react';
import ComplexSearch from '..';
import ControlBase from '../../base';
import styled from 'styled-components';
import globalData from '@mjcloud/global-data';
import { SPACING } from '../../constant';
import { Modal, Input, Button, Tag } from 'antd';
import FormItem from '../../../components/FormItem';
import {
  IComplexSearchState,
  IComplexSearchFindParams,
  IComplexSearchUpdateValueParams,
  IComplexSearchAdvancedData,
} from '../typings';

const Container = styled.div`
  width: 100%;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > *:not(.search) {
    margin-left: ${SPACING}px;
  }
  .dy-complex-search-input {
    max-width: 250px;
  }
`;

const SelectedContainer = styled.div`
  max-height: 56px;
  overflow: auto;

  .ant-tag {
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 17px;
    position: relative;

    .ant-tag-close-icon {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
`;

const Advanced = styled.div`
  margin-left: ${SPACING}px;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-calendar-picker,
  .ant-input-number {
    max-width: 240px;
  }
`;

class ComplexSearchControl extends ControlBase<IComplexSearchState, ComplexSearch> {
  private handSearch = (value: string) => {
    this.instance.store.dispatch<IComplexSearchFindParams>('find', { value, isReset: true });
  };

  private handChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.instance.store.dispatch<IComplexSearchUpdateValueParams>('updateValue', {
      value: event.currentTarget.value,
    });
  };

  private handReset = () => {
    this.instance.store.dispatch<IComplexSearchUpdateValueParams>('updateValue', {
      value: undefined,
    });
    this.instance.store.dispatch<IComplexSearchFindParams>('find', {
      value: undefined,
      isReset: true,
    });
  };

  private showModal = () => {
    this.instance.store.dispatch('updateExpand', { expand: true });
  };

  private handleOk = () => {
    this.instance.store.dispatch<IComplexSearchFindParams>('find', {
      value: this.state.searchValue,
      expand: false,
    });
  };

  private handleCancel = () => {
    this.instance.store.dispatch('updateExpand', { expand: false });
  };

  private handSelectedClose = (item: IComplexSearchAdvancedData) => {
    this.instance.store.dispatch('deleteAdvancedItem', { item });
  };

  renderAdvanced() {
    const { ControlFactory } = globalData;
    const { advanced, placeholder, searchValue } = this.state;
    return (
      <Advanced>
        <FormItem label="关键词" cols={1} labelTextAlign="left">
          <Input
            allowClear
            value={searchValue}
            placeholder={placeholder}
            onChange={this.handChange}
          />
        </FormItem>

        {advanced.map(({ id, title, controlProps }) => {
          return (
            <FormItem key={id} id={id} label={title} cols={1} labelTextAlign="left">
              <ControlFactory {...controlProps} />
            </FormItem>
          );
        })}
      </Advanced>
    );
  }

  renderContent() {
    const { expand, advanced, selectedItems = [] } = this.state;
    const { searchText, placeholder, searchValue } = this.state;
    const isExist2advanced = advanced.length > 0;

    return (
      <Container>
        <Input.Search
          allowClear
          enterButton
          value={searchValue}
          className="dy-complex-search-input"
          placeholder={placeholder}
          onChange={this.handChange}
          onSearch={this.handSearch}
        />
        {isExist2advanced && (
          <Button type="link" style={{ padding: 0 }} onClick={this.showModal}>
            高级
          </Button>
        )}
        {selectedItems.length > 0 && (
          <SelectedContainer>
            {selectedItems.map(item => {
              return (
                <Tag key={item.id} closable onClose={() => this.handSelectedClose(item)}>
                  <span>
                    {item.title}: {item.text}
                  </span>
                </Tag>
              );
            })}
          </SelectedContainer>
        )}
        <Modal
          width="60%"
          title="高级搜索"
          visible={expand}
          style={{ minWidth: 700 }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              取消
            </Button>,
            <Button key="reset" onClick={this.handReset}>
              重置
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              {searchText}
            </Button>,
          ]}
        >
          {this.renderAdvanced()}
        </Modal>
      </Container>
    );
  }
}
export default ComplexSearchControl;
