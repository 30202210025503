import { ITreeListConfig } from './typings';

const config: ITreeListConfig = {
  expandLevel: 9,
  styleName: 'menu',
  displayMode: undefined,
  disabledMode: undefined,
  disabledControl: undefined,
};

export default config;
