import React from 'react';
import { Button } from 'antd-mobile';
import ControlBase from '../../core/base';
import { IButtonState } from '@mjcloud/mcontrols/dist/controls/button/typings';

import styles from './index.less';

export default class ButtonControl extends ControlBase<IButtonState> {
  private handleClick = event => {
    if (event) event.preventDefault();
    if (this.state.loading) return;
    this.instance.store.dispatch('clickAfter', {});
  };

  renderContent() {
    const { size, theme, title, loading } = this.state;
    let type: 'primary' | 'warning' | 'ghost' | undefined = undefined;
    if (theme === 'primary') type = 'primary';
    else if (theme === 'ghost') type = 'ghost';
    return (
      <Button
        className={styles.button}
        type={type}
        loading={loading}
        size={size}
        onClick={this.handleClick}
      >
        {title}
      </Button>
    );
  }
}
