import React from 'react';
import classNames from 'classnames';
import { supportRef } from 'rc-util/lib/ref';
import { FixedType } from './interface';

import styles from './VirtualizedCell.less';

type Key = React.Key;
type DefaultRecordType = Record<string, any>;
export type CellEllipsisType =
  | {
      showTitle?: boolean;
    }
  | boolean;
// =================== Column ===================
interface CellType<RecordType> {
  key?: Key;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  column?: ColumnsType<RecordType>[number];
  colSpan?: number;
  rowSpan?: number;

  /** Only used for table header */
  hasSubColumns?: boolean;
  colStart?: number;
  colEnd?: number;
}

interface RenderedCell<RecordType> {
  props?: CellType<RecordType>;
  children?: React.ReactNode;
}

type DataIndex = string | number | (string | number)[];

interface ColumnSharedType<RecordType> {
  title?: React.ReactNode;
  key?: Key;
  className?: string;
  fixed?: FixedType;
  onHeaderCell?: GetComponentProps<ColumnsType<RecordType>[number]>;
  ellipsis?: boolean;
  align?: AlignType;
}

interface ColumnGroupType<RecordType> extends ColumnSharedType<RecordType> {
  children: ColumnsType<RecordType>;
}

type AlignType = 'left' | 'center' | 'right';

interface ColumnType<RecordType> extends ColumnSharedType<RecordType> {
  colSpan?: number;
  dataIndex?: DataIndex;
  render?: (
    value: any,
    record: RecordType,
    index: number,
  ) => React.ReactNode | RenderedCell<RecordType>;
  rowSpan?: number;
  width?: number | string;
  onCell?: GetComponentProps<RecordType>;
  /** @deprecated Please use `onCell` instead */
  onCellClick?: (record: RecordType, e: React.MouseEvent<HTMLElement>) => void;
}

type ColumnsType<RecordType = unknown> = (ColumnGroupType<RecordType> | ColumnType<RecordType>)[];

// ================= Customized =================
type GetComponentProps<DataType> = (
  data: DataType,
  index?: number,
) => React.HTMLAttributes<HTMLElement>;

type Component<P> =
  | React.ComponentType<P>
  | React.ForwardRefExoticComponent<P>
  | React.FC<P>
  | keyof React.ReactHTML;

type CustomizeComponent<
  P extends React.HTMLAttributes<HTMLElement> = React.HTMLAttributes<HTMLElement>
> = Component<P>;

function toArray<T>(arr: T | T[]): T[] {
  if (arr === undefined || arr === null) {
    return [];
  }

  return Array.isArray(arr) ? arr : [arr];
}

export function getPathValue<ValueType, ObjectType extends object>(
  record: ObjectType,
  path: DataIndex,
): ValueType | null {
  // Skip if path is empty
  if (!path && typeof path !== 'number') {
    return (record as unknown) as ValueType;
  }

  const pathList = toArray(path);

  let current: ValueType | ObjectType = record;

  for (let i = 0; i < pathList.length; i += 1) {
    if (!current) {
      return null;
    }

    const prop = pathList[i];
    current = current[prop];
  }

  return current as ValueType;
}

function isRenderCell<RecordType>(
  data: React.ReactNode | RenderedCell<RecordType>,
): data is RenderedCell<RecordType> {
  return !!(
    data &&
    typeof data === 'object' &&
    !Array.isArray(data) &&
    !React.isValidElement(data)
  );
}

function isRefComponent(component: CustomizeComponent) {
  // String tag component also support ref
  if (typeof component === 'string') {
    return true;
  }
  return supportRef(component);
}

export interface ICellProps<RecordType extends DefaultRecordType> {
  id?: string;
  prefixCls?: string;
  className?: string;
  style?: React.CSSProperties;
  record: RecordType;
  /** `record` index. Not `column` index. */
  index: number;
  dataIndex: DataIndex;
  isTree?: boolean;
  render?: ColumnType<RecordType>['render'];
  component?: CustomizeComponent;
  children?: React.ReactNode;
  colSpan?: number;
  rowSpan?: number;
  ellipsis?: CellEllipsisType;
  align?: AlignType;

  onExpand?: (expanded: boolean, record: RecordType) => void;
  onClick?: (e: any) => void;

  onMouseEnter?: () => void;
  onMouseLeave?: () => void;

  // Additional
  /** @private Used for `expandable` with nest tree */
  appendNode?: React.ReactNode;
  additionalProps?: React.HTMLAttributes<HTMLElement>;
}

function Cell<RecordType extends DefaultRecordType>(
  {
    prefixCls = 'ant-table',
    className,
    style = {},
    record,
    index,
    dataIndex,
    isTree,
    render,
    children,
    component: Component = 'td',
    colSpan,
    rowSpan,
    appendNode,
    additionalProps = {},
    ellipsis,
    align,
    onExpand,
    onClick,
    onMouseEnter,
    onMouseLeave,
  }: ICellProps<RecordType>,
  ref: React.Ref<any>,
): React.ReactElement {
  const cellPrefixCls = `${prefixCls}-cell`;

  // ==================== Child Node ====================
  let cellProps: CellType<RecordType> | undefined;
  let childNode: React.ReactNode;

  if (children) {
    childNode = children;
  } else {
    const value = getPathValue<object | React.ReactNode, RecordType>(record, dataIndex);

    // Customize render node
    childNode = value;
    if (render) {
      const renderData = render(value, record, index);

      if (isRenderCell(renderData)) {
        childNode = renderData.children;
        cellProps = renderData.props;
      } else {
        childNode = renderData;
      }
    }
  }

  // Not crash if final `childNode` is not validate ReactNode
  if (
    typeof childNode === 'object' &&
    !Array.isArray(childNode) &&
    !React.isValidElement(childNode)
  ) {
    childNode = null;
  }

  if (ellipsis) {
    childNode = <span className={`${cellPrefixCls}-content`}>{childNode}</span>;
  }

  const { colSpan: cellColSpan, rowSpan: cellRowSpan } = cellProps || {};
  const mergedColSpan = cellColSpan !== undefined ? cellColSpan : colSpan;
  const mergedRowSpan = cellRowSpan !== undefined ? cellRowSpan : rowSpan;

  if (mergedColSpan === 0 || mergedRowSpan === 0) {
    return null as any;
  }

  // ====================== Align =======================
  const alignStyle: React.CSSProperties = {};
  if (align) {
    alignStyle.textAlign = align;
  }

  // ====================== Render ======================
  let title: string = '';
  if (ellipsis) {
    if (typeof childNode === 'string') {
      title = childNode;
    } else if (React.isValidElement(childNode) && typeof childNode.props.children === 'string') {
      title = childNode.props.children;
    }
  }

  // ====================== Tree =======================
  let treeNode: React.ReactNode;

  if (isTree) {
    const nodeMarginLeft = 20,
      treeStyle: React.CSSProperties = { userSelect: 'none' };

    if (record._deepness != null) {
      const deepness = record._deepness - 1;
      const isTop = record._parentRowKey == '0';
      // const marginLeft = record._last && deepness && !isTop ? 8 : 0;
      const marginLeft = 0;
      const onExpandClick = onExpand ? () => onExpand(record._expanded, record) : undefined;
      treeStyle.paddingLeft = deepness * nodeMarginLeft + marginLeft;
      treeNode = (
        <>
          <span
            className={classNames(styles.indent, `indent-level-${deepness}`)}
            style={treeStyle}
          />
          <span
            className={classNames({
              [styles.expandIcon]: true,
              [styles.expanded]: !record._last && record._expanded,
              [styles.collapsed]: !record._last && !record._expanded,
              [styles.spaced]: record._last,
              // [styles.spacedOne]: record._last && !isTop,
              // [styles.spacedTop]: record._last && isTop,
            })}
            onClick={e => {
              e.stopPropagation();
              if (onExpandClick) onExpandClick();
            }}
          />
        </>
      );
    }
  }

  const componentProps = {
    title,
    ...additionalProps,
    isVirtualizedCell: true,
    colSpan: mergedColSpan && mergedColSpan !== 1 ? mergedColSpan : undefined,
    rowSpan: mergedRowSpan && mergedRowSpan !== 1 ? mergedRowSpan : undefined,
    className: classNames(
      cellPrefixCls,
      className,
      {
        [`${cellPrefixCls}-ellipsis`]: ellipsis,
        [`${cellPrefixCls}-with-append`]: appendNode,
      },
      additionalProps.className,
    ),
    style: { ...additionalProps.style, ...alignStyle, ...style },
    ref: isRefComponent(Component) ? ref : null,
    onClick,

    onMouseEnter,
    onMouseLeave,
  };

  return (
    <Component {...componentProps}>
      {appendNode}
      {treeNode}
      {childNode}
    </Component>
  );
}

const RefCell = React.forwardRef(Cell);
RefCell.displayName = 'Cell';

const MemoCell = React.memo(RefCell);
MemoCell.displayName = 'Cell';

export default MemoCell;
