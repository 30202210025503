import React from 'react';
import Icon from '@mjcloud/icon';
import { Spin, Row, Col } from 'antd';
import styled from 'styled-components';
import ControlBase from '../../base';
import { IModuleState } from '../typings';
import { AutoSizer } from 'react-virtualized';
import { SPACING, isDevicesSize, DevicesSizeEnum } from '../../constant';

const Container = styled(Row)`
  width: 100%;
  min-height: 110px;
`;

const Item = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: ${SPACING}px auto;
`;
const ItemIcon = styled(Icon)<{ color: string }>`
  margin-top: ${SPACING * 2}px;
  font-size: 64px;
  color: ${({ color }) => color};
`;

function getItemSpan(width: number): number {
  const size = isDevicesSize(width);
  switch (size) {
    case DevicesSizeEnum.xs:
      return 12;
    case DevicesSizeEnum.sm:
      return 8;

    default:
      return 6;
  }
}

// const Underconstruction = styled(({ className }) => (
//   <Icon type="underconstruction" className={className} />
// ))`
//   width: 100%;
//   font-size: 128px;
//   margin: ${SPACING}px auto;
// `;

export default class ModuleControl extends ControlBase<IModuleState> {
  private handleClick = (item: any) => {
    const { name, appId, moduleId, pageId, openMode, pageMode } = item;
    // TODO: 缺失openMode的实现
    this.instance.page.refresh({ appId, moduleId, pageId, pageMode, title: name });
  };

  renderContent() {
    const { dataSource, isFetching } = this.state;
    return (
      <Spin spinning={isFetching}>
        <AutoSizer disableHeight>
          {({ width }) => {
            const span = getItemSpan(width);
            return (
              <Container style={{ width }}>
                {dataSource.map(item => {
                  const { id, name, icon, color } = item,
                    handleClick = () => this.handleClick(item);
                  return (
                    <Item span={span} key={id} onClick={handleClick}>
                      <ItemIcon type={icon} color={color} />
                      <span>{name}</span>
                    </Item>
                  );
                })}
              </Container>
            );
          }}
        </AutoSizer>
      </Spin>
    );
  }
}
