import Icon from '@mjcloud/icon';
import JsApiHelper from '@mjcloud/jsapi';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Upload, Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import Service, { IFileInfo } from '@mjcloud/service';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';

interface IImportFileModalProps {
  action: string;
  headers: any;
  accept?: string;
  exportId?: string;
  templateLabel?: string;
  getTemplateFile: (exportId: string) => Promise<IFileInfo | undefined>;
  onChange: (file: any) => void;
  /** 点击确定回调 */
  onOk: (e: React.MouseEvent<HTMLElement>) => void;
  /** 点击模态框右上角叉、取消按钮、Props.maskClosable 值为 true 时的遮罩层或键盘按下 Esc 时的回调 */
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const SettingTips = styled.div`
  background-color: #fffae2;
  border: 1px solid #fbdd7a;
  font-size: 14px;
  color: #753e00;
  padding: 12px 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;
const SettingTipsText = styled.div`
  float: left;
  line-height: 26px;
`;
const SettingTipsButton = styled(Button)`
  float: right;
`;

function accept2hint(accept: string) {
  return accept
    .split(',')
    .map(r => r.substring(1))
    .join('、');
}

function accept2icon(accept: string) {
  switch (accept) {
    case '.xls,.xlsx':
    case '.xls':
    case '.xlsx':
      return 'icon-file-ecel';
    case '.csv':
      return 'icon-file-csv';
    case '.doc,.docx':
    case '.doc':
    case '.docx':
      return 'icon-file-word';
    case '.ppt,.pptx':
    case '.ppt':
    case '.pptx':
      return 'icon-file-ppt';
    case '.pdf':
      return 'icon-file-pdf';

    default:
      return 'icon-file-other';
  }
}
const ImportFileModal: React.FC<IImportFileModalProps> = ({
  action,
  headers,
  exportId,
  getTemplateFile,
  templateLabel = '',
  accept = '.xls,.xlsx',
  onChange,
  onOk,
  onCancel,
}) => {
  let templateFile: IFileInfo | undefined;
  const [okButtonProps, setOkButtonProps] = useState<ButtonProps>({
    disabled: true,
    loading: false,
  });
  const [fileName, setFileName] = useState<string | undefined>(undefined);

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileName(info.file.name);
    if (info.file.status === 'uploading') {
      setOkButtonProps({ disabled: true, loading: true });
    } else if (info.file.status === 'done' && info.file.response) {
      onChange(info.file.response.data);
      setOkButtonProps({ disabled: false, loading: false });
    } else {
      setOkButtonProps({ disabled: true, loading: false });
    }
  };
  const onDownload = async () => {
    if (exportId) {
      if (!templateFile) {
        templateFile = await getTemplateFile(exportId);
      }
      if (templateFile) {
        JsApiHelper.downloadFile({
          url: Service.getUrl(templateFile.url),
          name: templateFile.name,
        });
      }
    }
  };

  return (
    <div>
      <Modal
        visible
        title="批量导入"
        okText="导入"
        okButtonProps={okButtonProps}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Container>
          {exportId && (
            <SettingTips>
              <SettingTipsText>{`请下载${templateLabel}模版，按格式修改后导入`}</SettingTipsText>
              <SettingTipsButton size="small" onClick={onDownload}>
                下载模版
              </SettingTipsButton>
            </SettingTips>
          )}
          <Upload.Dragger
            accept={accept}
            action={action}
            headers={headers}
            showUploadList={false}
            onChange={handleChange}
          >
            <p className="ant-upload-drag-icon">
              <Icon type={accept2icon(accept)} />
            </p>
            {fileName && <p className="ant-upload-hint">{fileName}</p>}
            {!fileName && <p className="ant-upload-text">点击或将文件拖拽到此区域</p>}
            {!fileName && (
              <p className="ant-upload-hint">{`仅支持${accept2hint(accept)}格式文件`}</p>
            )}
          </Upload.Dragger>
        </Container>
      </Modal>
    </div>
  );
};

export default ImportFileModal;
