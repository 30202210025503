import Icon from '@mjcloud/icon';
import Service from '@mjcloud/service';
import { ObjectHelper } from '@mjcloud/utils';
import globalData from '@mjcloud/global-data';
import { withRouter } from 'react-router';
import React, { useState, useEffect } from 'react';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import { IUserInfoConfig, PageModeEnum } from '@mjcloud/types';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { HeaderViewProps } from '@ant-design/pro-layout/lib/Header';
import { Spin, Menu, Dropdown, Avatar, message, Breadcrumb } from 'antd';

import styles from './index.less';

export interface IGlobalHeaderRightProps extends HeaderViewProps, IRouteComponentProps {
  // flatMenuKeys?: string[];
  // fixSiderbar?: boolean;
  // collapsed?: boolean;
  // title?: string;
  // logo?: string;
  // navTheme: MenuTheme;
  // contentWidth?: string;
  // onMenuClick?: (param: ClickParam) => void;
}
const AVATARCLICKCOUNT = 5;
var count = 1,
  timer: any;

function isDev() {
  const query = ObjectHelper.search2params(window.location.search);
  if (query.eruda) return true;
  return false;
}

function avatarClick2Dev() {
  const { host, pathname, search, hash } = window.location;
  const query = ObjectHelper.search2params(search);
  delete query._t;
  query.eruda = true;
  const url = host + pathname + ObjectHelper.params2search(query) + hash;
  window.location.replace(url);
}

const GlobalHeaderRight: React.FC<IGlobalHeaderRightProps> = ({ history, layout, navTheme }) => {
  let className = styles.right;
  if (navTheme === 'dark' && layout === 'topmenu') {
    className = `${styles.right}  ${styles.dark}`;
  }
  const [currentUser, setCurrentUser] = useState<IUserInfoConfig | false | undefined>(undefined);
  const [draftTip, setDraftTip] = useState<any>(undefined);
  const [breadcrumbList, setBreadcrumbList] = useState<any[]>(globalData.__getBreadcrumbList());
  globalData.__setDraftTip = setDraftTip;
  globalData.__setBreadcrumbList = setBreadcrumbList;
  globalData.__getBreadcrumbList = () => breadcrumbList;
  globalData.__pushBreadcrumbList = (...items: any[]) => {
    breadcrumbList.push(...items);
    setBreadcrumbList(breadcrumbList);
  };
  globalData.__updateLastBreadcrumb = (item: any) => {
    const index = breadcrumbList.length - 1;
    breadcrumbList[index] = { ...breadcrumbList[index], ...item };
    setBreadcrumbList([...breadcrumbList]);
  };
  const userInfoRetry = async () => {
    const userInfo = await Service.userInfo(globalData.appId, true);
    setCurrentUser(userInfo);
  };
  useEffect(() => {
    (async () => {
      const userInfo = await Service.userInfo(globalData.appId);
      setCurrentUser(userInfo);
    })();
  });
  const onMenuClick: MenuClickEventHandler = param => {
    switch (param.key) {
      case 'logout':
        Service.logout();
        history.replace(`/${globalData.appId}/login`);
        break;
      case 'viewAuthorizing':
        if (globalData.rbCore.userInfo) {
          globalData.rbCore.top.popupModal({
            pageInfo: {
              title: '查看权限',
              appId: globalData.appId,
              moduleId: 'Authorize',
              pageId: 'Look',
              pageMode: PageModeEnum.look,
              width: 1000
            },
            params: { id: globalData.rbCore.userInfo.id, type: 1 },
          });
        }
        break;
      default:
        message.info('该功能开发中……');
        break;
    }
  };
  const onSearchClick = () => {
    history.replace(`/${globalData.appId}/Find`);
  };
  const avatarClick = () => {
    if (isDev()) return;
    if (count < AVATARCLICKCOUNT) {
      if (timer) {
        clearTimeout(timer);
      }
      count++;
      timer = setTimeout(function() {
        count = 1;
      }, 500);
    } else if (count === AVATARCLICKCOUNT) {
      count = 1;
      clearTimeout(timer);
      avatarClick2Dev();
    }
  };
  const renderAvatar = () => {
    if (currentUser) {
      const { avatar, name } = currentUser,
        menu = (
          <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
            <Menu.Item key="viewAuthorizing">
              <Icon type="eye" />
              {'查看权限'}
            </Menu.Item>
            {/* <Menu.Item key="changePassword">
              <Icon type="lock" />
              {'修改密码'}
            </Menu.Item> */}
            {/* TODO: 退出登录在钉钉容器内不合理，暂时注释，以后支持其他客户端再考虑通过客户端环境进行判断是否呈现 */}
            {/* <Menu.Divider />
            <Menu.Item key="logout">
              <Icon type="logout" />
              {'退出登录'}
            </Menu.Item> */}
          </Menu>
        );
      return (
        <Dropdown overlay={menu}>
          <span className={`${styles.action} ${styles.account}`} onClick={avatarClick}>
            {avatar ? (
              <Avatar size="small" className={styles.avatar} src={avatar} alt="avatar" />
            ) : (
              <Avatar size="small" className={`${styles.avatar} ${styles.avatarText}`} alt="avatar">
                {name.charAt(0)}
              </Avatar>
            )}
            <span className={styles.name}>{name}</span>
          </span>
        </Dropdown>
      );
    } else if (currentUser === false) {
      return (
        <span className={`${styles.action} ${styles.account}`} onClick={userInfoRetry}>
          <Avatar
            size="small"
            className={styles.avatar}
            style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
            icon="exclamation"
            alt="avatar"
          />
          <span className={styles.name}>点击重试</span>
        </span>
      );
    } else {
      return <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />;
    }
  };
  const breadcrumbLength = breadcrumbList.length;
  return (
    <div className={className}>
      <Breadcrumb className={styles.breadcrumb}>
        {breadcrumbList.map(({ name }, i) => {
          const onClick = () => {
            if (i < breadcrumbLength - 1) {
              const n = 1 - breadcrumbLength + i;
              if (n <= -1) {
                history.go(n);
              }
            }
          };
          return (
            <Breadcrumb.Item key={name} onClick={onClick}>
              {i < breadcrumbLength - 1 ? <a>{name}</a> : name}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      {draftTip && (
        <div className={styles.editorSaveTip} dangerouslySetInnerHTML={{ __html: draftTip }} />
      )}
      <div className={styles.middle} />
      {/* <span className={styles.search} onClick={onSearchClick}>
        <Icon type="search" />
      </span> */}
      {renderAvatar()}
    </div>
  );
};

export default withRouter(GlobalHeaderRight);
