import React from 'react';
import { Tooltip } from 'antd';
import ReactDom from 'react-dom';
import classNames from 'classnames';
import FormItem from '../../../../components/FormItem';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { IEditableCellBaseProps, IEditableCellBaseState } from '../typings';

import styles from './index.less';

export default abstract class EditableCellBaseControl<
  P extends IEditableCellBaseProps,
  S extends IEditableCellBaseState
> extends React.Component<P, S> {
  valueWrapClassName = styles.valueWrap;
  private container: Element | null = null;
  constructor(props: P) {
    super(props);
    const { store, rowId } = props;
    this.state = store.state as any;
    store.bindViewListen(this.handStateChange);
    this.init();
  }

  componentWillUnmount() {
    const { store } = this.props;
    store.unbindViewListen(this.handStateChange);
  }

  targetingToCurrent() {
    if (this.container) {
      this.container.scrollIntoView();
    }
  }

  private async init() {
    const { colorCommandFn, rowId, record } = this.props;
    if (colorCommandFn) {
      this.props.store.dispatch('updateColor', {
        rowId,
        colorCommandFn,
        color: await colorCommandFn(rowId, record),
      });
    }
  }

  private handStateChange = (state: S, cb?: () => void) => {
    this.setState(state, cb);
  };

  readyContainer = (el: HTMLTableDataCellElement | null) => {
    const container = ReactDom.findDOMNode(el);
    if (container instanceof Element) {
      this.container = container;
      // const height = (this.container as Element).clientHeight;
      // this.props.store.dispatch('updateSize', {
      //   size: { width: undefined, height },
      //   noUpdate: true,
      // });
    }
  };

  abstract handleClick: (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => void;

  abstract renderContent(): JSX.Element;

  renderText(): JSX.Element {
    return <>{this.state.text}</>;
  }

  render() {
    const { text, color, readonly, actionSourceSign, size, validateStatus } = this.state;
    const {
      id,
      store,
      rowId,
      cellId,
      isTree,
      record,
      onClick,
      pageKey,
      children,
      parentId,
      cellType,
      style = {},
      className,
      parentModify,
      colorCommandFn,
      isVirtualizedCell,
      ...restProps
    } = this.props;
    let height = style.height,
      placement: TooltipPlacement = 'topLeft';
    if (this.props.style && this.props.style.textAlign === 'right') {
      placement = 'topRight';
    }
    if (size && size.height) {
      height = size.height;
    }
    // TODO: 校验错误定位
    // if (validateStatus && actionSourceSign === this.props.store) {
    //   this.targetingToCurrent();
    // }
    // console.log('gridedit-cell-base', rowId, cellId);
    const tdProps = {
        ref: this.readyContainer,
        onClick: this.handleClick,
        style: { ...style, color, height },
        className: classNames(className, styles.cell),
        ...restProps,
      },
      _children =
        !parentModify || readonly ? (
          <Tooltip title={text} placement={placement}>
            <div className={styles.otherWrap}>{this.renderText()}</div>
          </Tooltip>
        ) : (
          <FormItem
            className={styles.valueItem}
            style={{ marginBottom: 0, color }}
            validateStatus={validateStatus}
          >
            {this.renderContent()}
          </FormItem>
        );
    // return <td {...tdProps}>{_children}</td>;
    return <div {...tdProps}>{_children}</div>;
  }
}
