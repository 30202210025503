import { IDateTimeRangeConfig } from './typings';

const config: IDateTimeRangeConfig = {
  title: '',
  needFast: false,
  autoFocus: false,
  displayMode: undefined,
  disabledMode: undefined,
  startFieldName: 'startDate',
  endFieldName: 'endDate',
  startPlaceholder: '开始日期',
  endPlaceholder: '结束日期',
  min: undefined,
  max: undefined,
  format: 'yyyy-MM-dd',
  businessType: undefined,
};

export default config;
