import React from 'react';
import { notification, Button } from 'antd';

interface IAlertResult {
  buttonIndex?: number;
}

let __alertKeyCount = 0;

export default function(message: string, description?: string, buttonLabels?: string[]) {
  return new Promise<IAlertResult>((resolve, fail) => {
    let btn: React.ReactNode, buttonIndex: number | undefined;
    const key = ++__alertKeyCount + '',
      onClose = () => {
        resolve({ buttonIndex });
      };
    if (buttonLabels) {
      btn = buttonLabels.map((buttonLabel, i) => {
        const close = () => {
          buttonIndex = i;
          notification.close(key);
          resolve({ buttonIndex });
        };
        return (
          <Button key={buttonLabel} type="link" size="small" onClick={close}>
            {buttonLabel}
          </Button>
        );
      });
    }
    notification.open({
      key,
      btn,
      onClose,
      message,
      description,
      duration: null,
    });
  });
}
