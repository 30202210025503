import { IRadioConfig } from './typings';

const config: IRadioConfig = {
  title: '',
  autoFocus: false,
  alwaysLoad: false,
  textFieldName: 'text',
  modifyMode: undefined,
  placeholder: undefined,
  displayMode: undefined,
  disabledMode: undefined,
  disabledControl: undefined,
  isAutoSelection: false,
};

export default config;
