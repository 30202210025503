import React from 'react';
import { Screen } from './util';
import { withRouter } from 'react-router';
import { ListEdit } from '../../mcontrols';
import { IRouteComponentProps } from '@mjcloud/router-helper';

const ExtraPage: React.FC<IRouteComponentProps> = ({ location }) => {
  const { type, pageKey, parentId, controlId } = location.query,
    props = { pageKey, parentId, controlId };
  switch (type) {
    case 'ListEdit':
      return <ListEdit {...props} />;
  }
  return <Screen />;
};

export default withRouter(ExtraPage);
