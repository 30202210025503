import React from 'react';
import Icon from '@mjcloud/icon';
import FileManager from '..';
import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import classNames from 'classnames';
import ControlBase from '../../base';
import styled from 'styled-components';
import { SPACING, SCROLLMINHEIGHT } from '../../constant';
import { ColumnProps } from 'antd/lib/table';
import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { Avatar, Table, Upload, Button, Spin, Divider } from 'antd';
import {
  IUploadFile,
  IFileManagerState,
  IFileManageUpdateFileListParams,
  IFileManageUpdateFileList2GroupParams,
} from '../typings';

import styles from './index.less';

const GroupAddBtn = styled.div`
  padding: ${SPACING * 2}px 0;
  width: 100%;

  .ant-upload {
    width: 100%;
  }
`;

class FileManagerControl extends ControlBase<IFileManagerState, FileManager> {
  private _key = 0;
  private handleChange = (info: UploadChangeParam) => {
    const fileList = [...info.fileList],
      { name, status } = info.file;
    if (status === 'error') {
      JsApiHelper.showToast({ content: `${name}上传失败!`, type: 'fail' });
    }
    this.instance.store.dispatch<IFileManageUpdateFileListParams>('updateFileList', {
      fileList,
      loading: status === 'uploading',
    });
  };

  private handleAddChange = (info: UploadChangeParam) => {
    this.instance.store.dispatch('addFile2Group', { file: info.file });
  };

  private handleChange2Group = (info: UploadChangeParam, record: IUploadFile, index: number) => {
    this.instance.store.dispatch<IFileManageUpdateFileList2GroupParams>('updateFileList2Group', {
      loading: status === 'uploading',
      file: info.file,
      record,
      index,
    });
  };

  private removeFile2Group = (record: IUploadFile, index: number) => {
    this.instance.store.dispatch('removeFile2Group', { record, index });
  };

  private previewFile = (file: any) => {
    if (file) {
      if (file.fileId) file.id = file.fileId;
      this.instance.store.dispatch('previewFile', { file });
    }
  };

  private downloadFile = (file: any) => {
    JsApiHelper.downloadFile({ url: Service.getUrl(file.url), name: file.name });
  };

  renderUpload() {
    // TODO: mode === 'append' 场景待实现
    let { readonly, fileList, listType, maxFileCount, placeholder } = this.state;
    const uploadProps: UploadProps = {
      ...this.state.uploadProps,
      fileList,
      multiple: true,
      onPreview: this.previewFile,
      onChange: this.handleChange,
      onDownload: this.downloadFile,
    };
    const uploadButton = readonly ? null : (
      <div>
        <Icon type={this.state.loading ? 'icon-loading' : 'icon-plus'} spin={this.state.loading} />
        <div className={styles.text}>{this.state.loading ? '上传中……' : placeholder}</div>
      </div>
    );
    if (listType === 'dragger') {
      return (
        <Upload.Dragger {...uploadProps}>
          {!readonly && <Button>{placeholder}</Button>}
        </Upload.Dragger>
      );
    } else if (listType === 'normal') {
      return (
        <Upload {...uploadProps} listType="text">
          {!readonly && <Button>{placeholder}</Button>}
        </Upload>
      );
    } else if (listType === 'avatar') {
      const uploadContent =
        fileList.length > 0 && !this.state.loading ? (
          <Avatar src={fileList[0].url} shape="square" size={64} />
        ) : (
          uploadButton
        );
      if (readonly)
        return (
          <div className={styles.avatar} onClick={() => this.previewFile(fileList[0])}>
            {uploadContent}
          </div>
        );
      return (
        <Upload {...uploadProps} accept="image/*" listType="picture-card" showUploadList={false}>
          {uploadContent}
        </Upload>
      );
    } else if (listType === 'picture-card') {
      return (
        <Upload {...uploadProps} accept="image/*" listType="picture-card">
          {maxFileCount > 0
            ? fileList.length >= maxFileCount
              ? null
              : uploadButton
            : uploadButton}
        </Upload>
      );
    } else if (listType === 'picture') {
      return (
        <Upload {...uploadProps} accept="image/*" listType="picture">
          {maxFileCount > 0
            ? fileList.length >= maxFileCount
              ? null
              : uploadButton
            : uploadButton}
        </Upload>
      );
    }
    return <Upload {...uploadProps}>{!readonly && <Button>{placeholder}</Button>}</Upload>;
  }

  renderGroup() {
    const { isFetching, readonly, dataSource } = this.state;
    const columns: ColumnProps<IUploadFile>[] = [
      {
        title: '序号',
        width: 60,
        align: 'center',
        dataIndex: '_ordinalId',
        key: 'ordinalId',
        render: (text, record, index) => index + 1,
      },
      {
        title: '标题',
        width: 240,
        dataIndex: 'groupName',
        key: 'groupName',
        render: (text, record, index) => (
          <span className={classNames(record.isRequired && styles.titleRequired)}>{text}</span>
        ),
      },
      {
        title: '文档',
        dataIndex: 'fileName',
        key: 'fileName',
        render: (text, record, index) => {
          const previewFile = () => this.previewFile(record);
          if (text) {
            if (record.info) {
              const { status, percent } = record.info;
              return (
                <a
                  key="a"
                  onClick={previewFile}
                  className={classNames(
                    status === 'uploading' && styles.disabled,
                    status === 'error' && styles.error,
                  )}
                >
                  {status === 'uploading' && (
                    <Icon spin type="loading" style={{ marginRight: 8 }} />
                  )}
                  {text}
                </a>
              );
            }
            return <a onClick={previewFile}>{text}</a>;
          } else if (record.status === 'error') {
            return <span className={styles.error}>请上传文件</span>;
          }

          return null;
        },
      },
      {
        title: '操作',
        width: 140,
        dataIndex: '_operation',
        key: '_operation',
        render: (text, record, index) => {
          const { groupId, fileId } = record,
            downloadFile = () => fileId && this.downloadFile(record),
            removeFile = () => this.removeFile2Group(record, index),
            handleChange = (info: UploadChangeParam) =>
              this.handleChange2Group(info, record, index);
          if (record.isReadonly) return <div className={styles.operation} />;
          return (
            <div className={styles.operation}>
              {!readonly && (
                <Upload
                  {...this.state.uploadProps}
                  listType="text"
                  showUploadList={false}
                  onChange={handleChange}
                >
                  <a>上传</a>
                </Upload>
              )}
              {!readonly && <Divider type="vertical" />}
              <a className={classNames(!fileId && styles.disabled)} onClick={downloadFile}>
                下载
              </a>
              {!readonly && !groupId && <Divider type="vertical" />}
              {!readonly && !groupId && <a onClick={removeFile}>删除</a>}
            </div>
          );
        },
      },
    ];
    return (
      <>
        <Table<IUploadFile>
          size="middle"
          columns={columns}
          pagination={false}
          loading={isFetching}
          dataSource={dataSource.map(r => {
            r.key = ++this._key + '';
            return r;
          })}
          scroll={{ x: 'max-content', y: SCROLLMINHEIGHT }}
        />
        {!readonly && (
          <GroupAddBtn>
            <Upload
              {...this.state.uploadProps}
              listType="text"
              showUploadList={false}
              onChange={this.handleAddChange}
            >
              <Button block type="dashed">
                <Icon type="plus" />
                添加
              </Button>
            </Upload>
          </GroupAddBtn>
        )}
      </>
    );
  }

  renderContent() {
    const { isFetching, listType } = this.state;
    return (
      <Spin delay={600} spinning={isFetching}>
        {listType === 'group' ? this.renderGroup() : this.renderUpload()}
      </Spin>
    );
  }
}

export default FileManagerControl;
