import { message } from 'antd';

export default function(
  type?: 'none' | 'success' | 'fail' | 'exception',
  content?: string,
  duration: number = 3000,
) {
  const _duration = duration / 1000;
  switch (type) {
    case 'success':
      message.success(content, _duration);
      break;
    case 'fail':
      message.error(content, _duration);
      break;
    case 'exception':
      message.warn(content, _duration);
      break;
    case 'none':
    default:
      message.info(content, _duration);
      break;
  }
}
