import Store from '@mjcloud/redux';
import Service from '@mjcloud/service';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { mapFileType } from './tool';
import {
  IUploadFile,
  IFileManagerState,
  IFileManageLoadedParams,
  IFileManageLoadErrorParams,
  IFileManagerInitialStateParams,
  IFileManageUpdateFileListParams,
  IFileManageUpdateFileList2GroupParams,
} from './typings';

export class FileManagerReduce extends ReduceBase {
  initialState(
    store: Store<IFileManagerState>,
    params: IFileManagerInitialStateParams,
  ): IFileManagerState {
    const { initConfig, pageMode } = params,
      { maxFileCount, modifyMode, displayMode, title, styleName: listType, mode } = initConfig,
      { placeholder = `点击上传${title || ''}` } = initConfig,
      { businessType, businessId } = initConfig;
    const state: IFileManagerState = {
      mode,
      title,
      listType,
      businessId,
      placeholder,
      fileList: [],
      businessType,
      maxFileCount,
      tabIndex: -1,
      dataSource: [],
      loading: false,
      uploadProps: {},
      config: initConfig,
      configIsFetching: false,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  startLoad(store: Store<IFileManagerState>, params: { uploadProps: UploadProps }) {
    const state: IFileManagerState = {
      ...store.state,
      isFetching: true,
    };
    if (params.uploadProps) state.uploadProps = params.uploadProps;
    return state;
  }

  loaded(store: Store<IFileManagerState>, params: IFileManageLoadedParams) {
    const { mode } = store.state;
    const dataSource: IUploadFile[] = params.dataSource.map((file, index) => {
        const _file: IUploadFile = {
          ...file,
          uid: file.id,
          fileName: file.name,
          isReadonly: mode === 'append',
        };
        return _file;
      }),
      fileList: IUploadFile[] = params.dataSource.map(file => {
        const { id, size, type, url } = file,
          _url = Service.getUrl(url);
        const _file: IUploadFile = {
          ...file,
          size,
          uid: id,
          url: _url,
          status: 'done',
          thumbUrl: _url,
          type: mapFileType(type),
          isReadonly: mode === 'append',
        };
        return _file;
      });
    const state: IFileManagerState = { ...store.state, fileList, dataSource, isFetching: false };
    return state;
  }

  loadError(store: Store<IFileManagerState>, params: IFileManageLoadErrorParams) {
    return {
      ...store.state,
      errorMessage: params.errorMessage,
      isFetching: false,
    } as IFileManagerState;
  }

  updateFileList(store: Store<IFileManagerState>, params: IFileManageUpdateFileListParams) {
    const { maxFileCount, listType } = store.state;
    let fileList = [...params.fileList];
    fileList = fileList
      .filter(file => file.status != 'error')
      .map(file => {
        if (file.status != 'uploading' && file.response && file.response.data) {
          const { url, id } = file.response.data;
          file = { ...file, ...file.response.data };
          file.url = Service.getUrl(url);
          file.thumbUrl = file.url;
          file['previewUrl'] = file.url;
          file.uid = id;
        }
        return file;
      });
    if (listType === 'avatar') {
      fileList = [fileList[fileList.length - 1]];
    } else if (maxFileCount > 0) {
      fileList = fileList.slice(-maxFileCount);
    }
    return { ...store.state, fileList, loading: params.loading } as IFileManagerState;
  }

  updateFileList2Group(
    store: Store<IFileManagerState>,
    params: IFileManageUpdateFileList2GroupParams,
  ) {
    const state = store.state,
      { file, record, index } = params,
      currentFile = state.dataSource[index];
    currentFile.fileName = file.name;
    currentFile.info = file;
    if (file.status != 'uploading' && file.response && file.response.data) {
      const { id, url, name, type } = file.response.data;
      currentFile.fileId = id;
      currentFile.name = name;
      currentFile.type = type;
      currentFile.url = url;
    }
    state.dataSource[index] = currentFile;
    // state.dataSource = [...state.dataSource];
    return { ...state, loading: params.loading };
  }

  addFile2Group(store: Store<IFileManagerState>, params: { file: UploadFile }) {
    let index = -1;
    const state = store.state,
      { file } = params,
      currentFile: any = { groupId: 0, groupName: '其他', ...file };
    currentFile.fileName = file.name;
    currentFile.info = file;
    currentFile.uid = file.uid;
    if (file.status != 'uploading' && file.response && file.response.data) {
      const { id, url, name } = file.response.data;
      currentFile.fileId = id;
      currentFile.name = name;
      currentFile.url = url;
    }
    for (let i = 0; i < state.dataSource.length; i++) {
      if (state.dataSource[i].uid === currentFile.uid) {
        index = i;
        break;
      }
    }
    if (state.dataSource[index]) {
      state.dataSource[index] = currentFile;
    } else {
      state.dataSource.push(currentFile);
    }

    return { ...state, loading: currentFile.status === 'uploading' };
  }

  updateFileList2Status(store: Store<IFileManagerState>, params) {
    const state = store.state;
    state.dataSource = state.dataSource.map(file => {
      if (!file.fileId && file.isRequired) {
        file.status = 'error';
      }
      return file;
    });
    return { ...state };
  }

  removeFile2Group(store: Store<IFileManagerState>, params) {
    const state = store.state,
      { index } = params;
    state.dataSource.splice(index, 1);
    return { ...state };
  }

  previewFile(store: Store<IFileManagerState>, params) {
    return { ...store.state, isFetching: true };
  }

  previewFileDone(store: Store<IFileManagerState>, params) {
    return { ...store.state, isFetching: false };
  }
}

export default new FileManagerReduce();
