import Service from '@mjcloud/service';
import { ENV } from '@mjcloud/jsapi';
import { ObjectHelper } from '@mjcloud/utils';

interface GenerateWeworkAddressUrlParams {
  secret: string;
  token: string;
  encodingAESKey: string;
}

/**
 * 获取当前租户是否已设置企业微信通讯录API
 */
export async function getWeworkAddressApiStatus(): Promise<boolean> {
  if (ENV.isWeChat) {
    const { corpId } = ObjectHelper.search2params(window.location.search);
    const res = await Service.requestService<boolean>('/wechatappsuit/getsuiteinfo', { corpId });

    return !res;
  }
  return false;
}

/** 生成接收事件服务器URL */
export async function generateWeworkAddressUrl(params: GenerateWeworkAddressUrlParams) {
  const { corpId } = ObjectHelper.search2params(window.location.search);
  const appSuiteId = await Service.requestService('/wechatappsuit/savesuiteinfo', {
    corpId,
    ...params,
  });
  const url = `https://login.yuandingyun.vip/wechat/callback/dataAPI?_id=${appSuiteId}&_corpId=${corpId}`;

  return url;
}
