import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { NumberHelper } from '@mjcloud/utils';
import { ActionBeforeEventArg } from '@mjcloud/redux';
import { ValueInstanceBase } from '@mjcloud/instance';
import { INumberConfig, INumberState } from './typings';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { IValueControlUpdateValueParams } from '@mjcloud/instance/dist/valueInstanceBase';

class NumberExtendStore extends ValueExtendStoreBase<number, Number> {
  handleUpdateValueBefore(e: ActionBeforeEventArg<IValueControlUpdateValueParams>) {
    super.handleUpdateValueBefore(e);
    e.params.text = this.instance.format(e.params.value as any);
  }
}

class Number extends ValueInstanceBase<number, INumberState> {
  get valueType(): ValidationRuleType {
    return 'number';
  }

  __createStore() {
    return new Store<INumberState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new NumberExtendStore(this),
    });
  }

  initialState(initConfig: INumberConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  format(value?: number, config: any = { format: '#,##0.00' }): string {
    if (value == null) return '';
    let _value = '';
    const { format = config.format } = this.store.state;
    _value = NumberHelper.format(value, format);
    return _value;
  }
}

export default Number;
