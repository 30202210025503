import Store from '@mjcloud/redux';
import PageModeHelper from '@mjcloud/page-mode-helper';
import SelectTreeReduceBase from '../common/select/reduceTree';
import { ICascaderState, ICascaderInitialStateParams } from './typings';

export class CascaderReduce extends SelectTreeReduceBase {
  initialState(store: Store<ICascaderState>, params: ICascaderInitialStateParams) {
    const { initConfig, pageMode } = params,
      { title, autoFocus, modifyMode, textFieldName, displayMode, disabledMode } = initConfig,
      { multiSelect, tokenSeparators, textTokenSeparator, vaueleTokenSeparator } = initConfig,
      { isAutoSelection, placeholder = `请选择${title || ''}` } = initConfig;
    let state: ICascaderState = {
      title,
      value: '',
      autoFocus,
      multiSelect,
      placeholder,
      tabIndex: 0,
      rowIdCount: 1,
      textFieldName,
      dataSource: [],
      isValid: false,
      tokenSeparators,
      originalData: [],
      selectedRows: [],
      config: initConfig,
      textTokenSeparator,
      vaueleTokenSeparator,
      // TODO: defaultOpen配置值待实现
      defaultOpen: false,
      originalDataSource: [],
      configIsFetching: false,
      isAutoSelection: !!isAutoSelection,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    state.tokenSeparators = state.tokenSeparators
      .concat([state.textTokenSeparator, state.vaueleTokenSeparator])
      .filter((tokenSeparator, index, self) => self.indexOf(tokenSeparator) === index);
    return state;
  }
}

export default new CascaderReduce();
