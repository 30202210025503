import React from 'react';
import { Screen } from './util';
import JsApiHelper from '@mjcloud/jsapi';
import { Result, Button } from 'antd';
import { ObjectHelper } from '@mjcloud/utils';
import globalData from '@mjcloud/global-data';
import { ExceptionHelper } from '@mjcloud/exception';
import Service, { IFileInfo } from '@mjcloud/service';
import { IRouteComponentProps } from '@mjcloud/router-helper';

interface IFileDetailPageState {
  isMac: boolean;
  backInfo?: any;
  loading: boolean;
  errorMsg?: string;
  fileInfo?: IFileInfo;
}

export default class FileDetailPage extends React.Component<
  IRouteComponentProps,
  IFileDetailPageState
> {
  constructor(props: IRouteComponentProps) {
    super(props);
    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    this.state = { isMac, loading: true };
    this.init();
  }

  async init() {
    const { match } = this.props,
      appId = match.params.appId,
      { search, origin } = window.location,
      { corpId, fileId } = ObjectHelper.search2params(search);
    globalData.corpId = corpId;
    globalData.appId = appId;
    let state: any = { loading: false },
      businessId: string | undefined;
    try {
      const { code } = await JsApiHelper.requestAuthCode({ corpId });
      await Service.ddSignin({ appId, corpId, authCode: code });
      Service.ddConfig({ appId, corpId, url: encodeURIComponent(origin + search) });
      const fileInfo = await Service.getFileDetail(fileId);
      if (fileInfo) {
        businessId = fileInfo.businessId;
        await JsApiHelper.previewFile(fileInfo);
      }
      state.loading = false;
      state.fileInfo = fileInfo;
    } catch (error) {
      state.errorMsg = error.message ? error.message : '未知错误';
      ExceptionHelper.dispose(error);
    }
    if (businessId && JsApiHelper.ENV_ENUM.pc === JsApiHelper.ENV.platform && !this.state.isMac) {
      try {
        const backInfo = await Service.requestService<any>('/workflow/finddingprocessinstance', {
          id: businessId,
        });
        state.backInfo = backInfo;
      } catch (error) {
        ExceptionHelper.dispose(error);
      }
    }

    this.setState(state);
  }

  backClick = () => {
    if (this.state.backInfo) {
      const { title, url } = this.state.backInfo;
      if (url && title) {
        JsApiHelper.openSlidePanel(title, url);
      }
    }
  };

  handlePreviewClick = async () => {
    if (this.state.fileInfo) {
      this.setState({ loading: false });
      await JsApiHelper.previewFile(this.state.fileInfo);
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, isMac, errorMsg } = this.state,
      backButton = JsApiHelper.ENV_ENUM.pc === JsApiHelper.ENV.platform && !isMac && (
        <Button type="primary" key="back" onClick={this.backClick}>
          返回
        </Button>
      );

    if (errorMsg) return <Result status="500" subTitle={errorMsg} extra={backButton} />;
    const previewButton = (
        <Button
          type={backButton ? 'default' : 'primary'}
          key="preview"
          onClick={this.handlePreviewClick}
        >
          重新预览
        </Button>
      ),
      extra = backButton ? [previewButton, backButton] : previewButton;
    if (!loading) return <Result status="success" subTitle="预览成功" extra={extra} />;
    return <Screen />;
  }
}
