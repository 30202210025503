import React from 'react';
import Icon from '@mjcloud/icon';
import classNames from 'classnames';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import { ObjectHelper } from '@mjcloud/utils';
import { withRouter } from 'react-router';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import { AppMenuConfig } from '@mjcloud/mcontrols/dist/controls/module/typings';

import styles from './Menu.less';

interface IMenuProps extends IRouteComponentProps {
  tile?: boolean;
  menus?: AppMenuConfig[];
  onModuleTap?(menu: AppMenuConfig): void;
}

const Menu: React.FC<IMenuProps> = ({
  history,
  menus = [],
  tile = true,
  onModuleTap = function() {},
}) => {
  const onMenuTap = (menu: AppMenuConfig) => {
    let { moduleId, pageId, url, name: _title } = menu;
    if (url) {
      // /labo/ProjectWorkerMobile/Main?tab=projectRecord
      let address: any = {},
        urlArr = url.split('?');
      if (urlArr.length === 2) {
        url = urlArr[0];
        address.params = ObjectHelper.search2params(urlArr[1]);
      }
      urlArr = url.split('/');
      if (urlArr.length >= 3) {
        address.appId = urlArr[1];
        address.moduleId = urlArr[2];
        address.pageId = urlArr[3] || 'Main';
      }
      address.params = ObjectHelper.params2search(address.params);
      const { params, pageId, ...info } = address;
      history.push({
        pathname: PageHelper.formatMemuPath(info),
        search: ObjectHelper.params2search({ pageId, _title }),
        state: params,
      });
    } else {
      history.push({
        pathname: PageHelper.formatMemuPath({ appId: globalData.appId, moduleId }),
        search: ObjectHelper.params2search({ pageId, _title }),
        state: {},
      });
    }
    onModuleTap(menu);
  };
  if (tile)
    return (
      <div className={styles.dyModuleTile}>
        {menus.map(menu => {
          if (menus.length > 1) {
            return (
              <div key={menu.moduleId} className={styles.dyMoreBlock}>
                <div className={styles.dyMoreBlockTitle}>{menu.name}</div>
                <div className={styles.dyMoreBlockContainer}>
                  {menu.children &&
                    menu.children.map(_menu => {
                      const { name, color, moduleId } = _menu,
                        onTap = () => onMenuTap(_menu),
                        nameClassName = classNames(styles.dyMoreBlockItemName, 'dy-font-elipsis'),
                        className = classNames(styles.dyMoreBlockItemIcon, `dy-linear-${color}`);
                      return (
                        <div key={moduleId} className={styles.dyMoreBlockItem} onClick={onTap}>
                          <div className={className}>
                            <Icon
                              className={styles.dyMoreBlockItemIconText}
                              type={`icon-${moduleId}`}
                            />
                          </div>
                          <div className={nameClassName}>{name}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          } else {
            return (
              <div key={menu.moduleId} className={styles.dyMoreContainer}>
                {menu.children &&
                  menu.children.map(_menu => {
                    const { name, color, moduleId } = _menu,
                      onTap = () => onMenuTap(_menu),
                      nameClassName = classNames(styles.dyMoreBlockItemName, 'dy-font-elipsis'),
                      className = classNames(styles.dyMoreItemIcon, `dy-linear-${color}`);
                    return (
                      <div key={moduleId} className={styles.dyMoreItem} onClick={onTap}>
                        <div className={className}>
                          <Icon className={styles.dyMoreItemIconText} type={`icon-${moduleId}`} />
                        </div>
                        <div className={nameClassName}>{name}</div>
                      </div>
                    );
                  })}
              </div>
            );
          }
        })}
      </div>
    );
  return (
    <div className={styles.dyModuleContainer}>
      {menus.map(menu => {
        const nameClassName = classNames(styles.dyModuleItemName, 'dy-font-elipsis'),
          onClick = () => onMenuTap(menu);
        return (
          <div className={styles.dyModuleItem} onClick={onClick}>
            <div className={classNames(styles.dyModuleItemIcon, `dy-linear-${menu.color}`)}>
              <Icon className={styles.dyModuleItemIconText} type={`icon-${menu.moduleId}`} />
            </div>
            <div className={nameClassName}>{menu.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default withRouter(Menu);
