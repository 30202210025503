import "antd/es/layout/style";
import _Layout from "antd/es/layout";

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import './BasicLayout.less';
import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import warning from 'warning';
import { useMediaQuery } from 'react-responsive';
import Omit from 'omit.js';
import Header from './Header';
import defaultGetPageTitle from './getPageTitle';
import defaultSettings from './defaultSettings';
import getLocales from './locales';
import Footer from './Footer';
import RouteContext from './RouteContext';
import SiderMenu from './SiderMenu';
import { getBreadcrumbProps } from './utils/getBreadcrumbProps';
import getMenuData from './utils/getMenuData';
import { isBrowser } from './utils/utils';
var Content = _Layout.Content;
var MediaQueryEnum = {
  'screen-xs': {
    maxWidth: 575
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599
  },
  'screen-xxl': {
    minWidth: 1600
  }
};
/**
 * loop query screen className
 * Array.find will throw a error
 * `Rendered more hooks than during the previous render.`
 * So should use Array.forEach
 */

var getScreenClassName = function getScreenClassName() {
  var className = '';
  Object.keys(MediaQueryEnum).forEach(function (key) {
    if (useMediaQuery(MediaQueryEnum[key])) {
      className = key;
    }
  });
  return className;
};

var headerRender = function headerRender(props) {
  if (props.headerRender === false) {
    return null;
  }

  return React.createElement(Header, Object.assign({}, props));
};

var footerRender = function footerRender(props) {
  if (props.footerRender === false) {
    return null;
  }

  if (props.footerRender) {
    return props.footerRender(_objectSpread({}, props), React.createElement(Footer, null));
  }

  return null;
};

var renderSiderMenu = function renderSiderMenu(props) {
  var layout = props.layout,
      isMobile = props.isMobile,
      menuRender = props.menuRender;

  if (props.menuRender === false) {
    return null;
  }

  if (layout === 'topmenu' && !isMobile) {
    return null;
  }

  if (menuRender) {
    return menuRender(props, React.createElement(SiderMenu, Object.assign({}, props)));
  }

  return React.createElement(SiderMenu, Object.assign({}, props, props.menuProps));
};

var defaultPageTitleRender = function defaultPageTitleRender(pageProps, props) {
  var pageTitleRender = props.pageTitleRender;
  var defaultPageTitle = defaultGetPageTitle(pageProps);

  if (pageTitleRender === false) {
    return props.title || '';
  }

  if (pageTitleRender) {
    var title = pageTitleRender(pageProps, defaultPageTitle);

    if (typeof title === 'string') {
      return title;
    }

    warning(typeof title === 'string', 'pro-layout: renderPageTitle return value should be a string');
  }

  return defaultPageTitle;
};

function useCollapsed(collapsed, onCollapse) {
  warning(collapsed === undefined === (onCollapse === undefined), 'pro-layout: onCollapse and collapsed should exist simultaneously');

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      nativeCollapsed = _useState2[0],
      setCollapsed = _useState2[1];

  if (collapsed !== undefined && onCollapse) {
    return [collapsed, onCollapse];
  }

  if (collapsed !== undefined && !onCollapse) {
    return [collapsed, undefined];
  }

  if (collapsed === undefined && onCollapse) {
    return [undefined, onCollapse];
  }

  return [nativeCollapsed, setCollapsed];
}

var getPaddingLeft = function getPaddingLeft(hasLeftPadding, collapsed, siderWidth) {
  if (hasLeftPadding) {
    return collapsed ? 80 : siderWidth;
  }

  return undefined;
};
/**
 * 🌃 Powerful and easy to use beautiful layout
 * 🏄‍ Support multiple topics and layout types
 * @param props
 */


var BasicLayout = function BasicLayout(props) {
  var children = props.children,
      propsOnCollapse = props.onCollapse,
      _props$location = props.location,
      location = _props$location === void 0 ? {
    pathname: '/'
  } : _props$location,
      fixSiderbar = props.fixSiderbar,
      navTheme = props.navTheme,
      contentStyle = props.contentStyle,
      PropsLayout = props.layout,
      _props$route = props.route,
      route = _props$route === void 0 ? {
    routes: []
  } : _props$route,
      style = props.style,
      _props$siderWidth = props.siderWidth,
      siderWidth = _props$siderWidth === void 0 ? 256 : _props$siderWidth,
      menu = props.menu,
      propsIsChildrenLayout = props.isChildrenLayout,
      menuDataRender = props.menuDataRender;
  /**
   * init variables
   */

  var _useState3 = useState(useMediaQuery({
    maxWidth: 767
  }, undefined, function (match) {
    if (!props.disableMobile) {
      setIsMobile(match);
    }
  }) && !props.disableMobile),
      _useState4 = _slicedToArray(_useState3, 2),
      isMobile = _useState4[0],
      setIsMobile = _useState4[1];

  useEffect(function () {
    warning(false, "\nPro-Layout \u5728 4.7 \u4E2D\u652F\u6301\u4E86 subMenu \u7684 render, \u4F1A\u5BFC\u81F4 menu \u53D8\u6210\u84DD\u8272\u7684\u95EE\u9898\u3002\n\u89E3\u51B3\u65B9\u6848\u5982\u4E0B\uFF1Ahttps://github.com/ant-design/ant-design-pro-layout/issues/186\n");
  }, []);

  var formatMessage = function formatMessage(_ref) {
    var id = _ref.id,
        defaultMessage = _ref.defaultMessage,
        rest = _objectWithoutProperties(_ref, ["id", "defaultMessage"]);

    if (props.formatMessage) {
      return props.formatMessage(_objectSpread({
        id: id,
        defaultMessage: defaultMessage
      }, rest));
    }

    var locales = getLocales();

    if (locales[id]) {
      return locales[id];
    }

    if (defaultMessage) {
      return defaultMessage;
    }

    return id;
  };

  var _route$routes = route.routes,
      routes = _route$routes === void 0 ? [] : _route$routes;

  var _getMenuData = getMenuData(routes, menu, formatMessage, menuDataRender),
      breadcrumb = _getMenuData.breadcrumb,
      menuData = _getMenuData.menuData; // If it is a fix menu, calculate padding
  // don't need padding in phone mode


  var hasLeftPadding = fixSiderbar && PropsLayout !== 'topmenu' && !isMobile; // whether to close the menu

  var _useCollapsed = useCollapsed(props.collapsed, propsOnCollapse),
      _useCollapsed2 = _slicedToArray(_useCollapsed, 2),
      collapsed = _useCollapsed2[0],
      onCollapse = _useCollapsed2[1]; // Splicing parameters, adding menuData and formatMessage in props


  var defaultProps = Omit(_objectSpread({}, props, {
    formatMessage: formatMessage,
    breadcrumb: breadcrumb
  }), ['className', 'style']); // gen page title

  var pageTitle = defaultPageTitleRender(_objectSpread({
    pathname: location.pathname
  }, defaultProps), props); // gen breadcrumbProps, parameter for pageHeader

  var breadcrumbProps = getBreadcrumbProps(_objectSpread({}, defaultProps, {
    breadcrumb: breadcrumb
  })); // render sider dom

  var siderMenuDom = renderSiderMenu(_objectSpread({}, defaultProps, {
    menuData: menuData,
    onCollapse: onCollapse,
    isMobile: isMobile,
    theme: navTheme,
    collapsed: collapsed
  })); // render header dom

  var headerDom = headerRender(_objectSpread({}, defaultProps, {
    menuData: menuData,
    isMobile: isMobile,
    collapsed: collapsed,
    onCollapse: onCollapse
  })); // render footer dom

  var footerDom = footerRender(_objectSpread({
    isMobile: isMobile,
    collapsed: collapsed
  }, defaultProps));

  var _useContext = useContext(RouteContext),
      contextIsChildrenLayout = _useContext.isChildrenLayout; // 如果 props 中定义，以 props 为准


  var isChildrenLayout = propsIsChildrenLayout !== undefined ? propsIsChildrenLayout : contextIsChildrenLayout; // gen className

  var className = classNames(getScreenClassName(), props.className, 'ant-design-pro', 'ant-pro-basicLayout', {
    'ant-pro-basicLayout-topmenu': PropsLayout === 'topmenu',
    'ant-pro-basicLayout-is-children': isChildrenLayout
  });
  var genLayoutStyle = {
    paddingLeft: getPaddingLeft(!!hasLeftPadding, collapsed, siderWidth),
    height: '100%',
    position: 'relative',
    minHeight: '100vh'
  }; // if is some layout children，don't need min height

  if (isChildrenLayout || contentStyle && contentStyle.minHeight) {
    genLayoutStyle.minHeight = 0;
  }

  var contentClassName = classNames('ant-pro-basicLayout-content', {
    'ant-pro-basicLayout-has-header': headerDom
  });
  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, pageTitle)), React.createElement("div", {
    className: className
  }, React.createElement(_Layout, {
    style: style,
    hasSider: true
  }, siderMenuDom, React.createElement(_Layout, {
    style: genLayoutStyle
  }, headerDom, React.createElement(Content, {
    className: contentClassName,
    style: contentStyle
  }, React.createElement(RouteContext.Provider, {
    value: _objectSpread({}, defaultProps, {
      breadcrumb: breadcrumbProps,
      menuData: menuData,
      isMobile: isMobile,
      collapsed: collapsed,
      isChildrenLayout: true,
      title: pageTitle.split('-')[0].trim()
    })
  }, children)), footerDom))));
};

BasicLayout.defaultProps = _objectSpread({
  logo: 'https://gw.alipayobjects.com/zos/antfincdn/PmY%24TNNDBI/logo.svg'
}, defaultSettings, {
  location: isBrowser() ? window.location : undefined
});
export default BasicLayout;