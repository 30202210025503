import Reduce from './reduce';
import Store from '@mjcloud/redux';
import Iframe from '../iframe';
import { getIframeId } from './utils';
import { InstanceBase } from '@mjcloud/instance';
import { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import { ExtendStoreBase } from '@mjcloud//extend-store';
import { ControlConfig, XmlParam } from '@mjcloud/types';
import { ModalConfig, IModalInitialStateParams } from '@mjcloud/modal-helper';
import { IModalState, ModalActionType } from './typings';
import { IframeConfig } from '../iframe/typings';

class ModalExtendStore extends ExtendStoreBase<Modal> {
  handleInitialStateBefore(e: ActionBeforeEventArg<IModalInitialStateParams>) {
    const { id, pageInfo, params } = e.params.initConfig,
      { pageId, pageMode, moduleId, appId } = pageInfo,
      iframeId = getIframeId(id),
      paramsArr: XmlParam[] = [];
    for (const name in params) {
      paramsArr.push({ dymaic: false, name, value: params[name] });
    }
    const iframe = this.instance.page.__createControl<IframeConfig>(iframeId, {
      id: iframeId,
      nodeName: 'iframe',
      pageInfo: {
        pageId,
        pageMode,
        moduleId,
        appId,
        params: paramsArr,
      },
    }) as Iframe;
    this.instance.__setIframe(iframe);
  }

  handleCloseModalAfter(e: ActionAfterEventArg<IModalState>) {
    const { isDrawer, afterClose } = e.newState.state;
    if (isDrawer && afterClose) afterClose();
  }
}

export default class Modal extends InstanceBase<IModalState, string, ModalActionType> {
  private _iframe: Iframe | undefined;
  __createStore() {
    return new Store<IModalState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new ModalExtendStore(this),
    });
  }

  initialState(initConfig: ModalConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  __setIframe(iframe: Iframe) {
    this._iframe = iframe;
  }

  get iframe() {
    return this._iframe as Iframe;
  }

  close(cb: () => void) {
    this.store.dispatch('closeModal', { cb });
  }
}
