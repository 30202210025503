import globalData from '@mjcloud/global-data';
import { InstanceBase, ContainerInstanceBase } from '@mjcloud/instance';

export function getInstance<I extends InstanceBase>(
  controlId: string,
  parentId: string,
  pageKey: string,
  workbenchId?: string,
): I {
  let instance: any;
  const page = globalData.rbCore.getPage(pageKey);
  if (pageKey === controlId) {
    // 当前控件为页面控件
    instance = page;
  } else {
    if (parentId === pageKey) {
      // 当前控件的父级控件为页面控件
      instance = page.findControl(controlId);
      if (!instance) instance = page.createControl(controlId);
    } else {
      // 当前控件的父级控件为非页面级别的容器控件
      let parent: any;
      if (workbenchId) {
        const workbench: any = page.findControl(workbenchId);
        if (workbench) parent = workbench.findControl(parentId);
      } else {
        parent = page.findControl(parentId);
      }
      if (parent instanceof ContainerInstanceBase) {
        instance = parent.findControl(controlId);
      }
    }
  }
  return instance;
}

export function getInstance2New<I extends InstanceBase>(paths: string[]) {
  let instance: any;
  return instance as I;
}
