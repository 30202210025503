import React from 'react';
import { Switch } from 'antd';
import { ISwitchState } from '../typings';
import ControlBase from '../../base';

class SwitchControl extends ControlBase<ISwitchState> {
  private handChange = (checked: boolean, event: MouseEvent) => {
    const { trueValue, falseValue } = this.state,
      { trueText = trueValue, falseText = falseValue } = this.state,
      value = checked ? trueValue : falseValue,
      text = checked ? trueText : falseText;
    this.instance.store.dispatch('updateValue', {
      value,
      text,
      actionSourceSign: this.instance.store,
    });
  };

  renderContent() {
    // if (this.state.readonly) return <>{this.state.text || ''}</>;
    const { value, trueValue, trueText, falseText, disabled, readonly } = this.state;
    const _value = trueValue == value;
    return (
      <Switch
        checked={_value}
        disabled={readonly ? true : disabled}
        checkedChildren={trueText}
        unCheckedChildren={falseText}
        onChange={this.handChange}
      />
    );
  }
}
export default SwitchControl;
