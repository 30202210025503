import React from 'react';
import { Chart } from '@mjcloud/mcontrols';
import { findDOMNode } from 'react-dom';
import globalData from '@mjcloud/global-data';
import { Spin, Empty, Result } from 'antd';
import ControlBase from '../../core/base';
import { IChartState } from '@mjcloud/mcontrols/dist/controls/chart/typings';

import styles from './index.less';

export default class ChartControl extends ControlBase<IChartState, Chart> {
  private ref = (instance: any | null) => {
    const el = findDOMNode(instance);
    if (el) {
      const { AntV } = globalData,
        pixelRatio = window.devicePixelRatio,
        chart = new AntV.Chart({ el, pixelRatio });
      this.instance.__createChart(chart);
    }
  };

  renderContent() {
    const { size2rem, isFetching, errorMessage, dataSource } = this.state,
      { tooltipItems, tooltipStyle } = this.state;
    if (errorMessage) return <Result subTitle={errorMessage} />;
    if (dataSource.length > 0) {
      return (
        <Spin spinning={isFetching}>
          <div className={styles.dyChart} style={{ width: size2rem, height: size2rem }}>
            <div style={tooltipStyle} className={styles.dyChartTooltip}>
              {tooltipItems.map(({ name, value }, index) => (
                <div key={index}>
                  {name}: {value}
                </div>
              ))}
            </div>
            <canvas
              ref={this.ref}
              className={styles.dyChartCanvas}
              style={{ width: size2rem, height: size2rem }}
            ></canvas>
          </div>
        </Spin>
      );
    } else {
      return (
        <div className={styles.dyChartEmpty} style={{ minHeight: size2rem }}>
          <Empty />
        </div>
      );
    }
  }
}
