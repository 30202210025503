import Store from '@mjcloud/redux';
import SelectReduceBase from '../common/select/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IRadioListState, IRadioListInitialStateParams } from './typings';

export class RadioListReduce extends SelectReduceBase {
  initialState(store: Store<IRadioListState>, params: IRadioListInitialStateParams) {
    const { initConfig, pageMode } = params,
      { tokenSeparators, textTokenSeparator, vaueleTokenSeparator } = initConfig,
      { title, autoFocus, modifyMode, alwaysLoad } = initConfig,
      { multiSelect, displayMode, disabledMode, textFieldName, disabledControl } = initConfig,
      { isAutoSelection, placeholder = `请选择${title || ''}` } = initConfig;
    let state: IRadioListState = {
      title,
      autoFocus,
      alwaysLoad,
      placeholder,
      open: false,
      multiSelect,
      tabIndex: -1,
      textFieldName,
      rowIdCount: 1,
      dataSource: [],
      isValid: false,
      disabledControl,
      tokenSeparators,
      originalData: [],
      selectedRows: [],
      expandable: false,
      config: initConfig,
      textTokenSeparator,
      vaueleTokenSeparator,
      value: store.state.value,
      // TODO: defaultOpen配置值待实现
      defaultOpen: false,
      originalDataSource: [],
      configIsFetching: false,
      isAutoSelection: !!isAutoSelection,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    state.tokenSeparators = state.tokenSeparators
      .concat([state.textTokenSeparator, state.vaueleTokenSeparator])
      .filter((tokenSeparator, index, self) => self.indexOf(tokenSeparator) === index);
    return state;
  }

  updateClientWidth(store: Store<IRadioListState>, params) {
    const state = store.state,
      { clientWidth } = params,
      { tagsWidth, dataSource } = state;
    state.clientWidth = params.clientWidth;
    if (clientWidth != null && tagsWidth != null) {
      const expandable = clientWidth < tagsWidth;
      if (expandable !== state.expandable) {
        state.expandable = expandable;
        return { ...state };
      }
    } else if (clientWidth != null) {
      let width = 8 + 46 + 50 + dataSource.length * 24;
      for (const row of dataSource) {
        width += 18 + 14 * row._text.length;
      }
      state.tagsWidth = width;
      state.expandable = clientWidth < width;
      return { ...state };
    }

    return state;
  }

  loaded(store: Store<IRadioListState>, params) {
    const state = super.loaded(store, params) as IRadioListState,
      { dataSource, clientWidth } = state;
    if (clientWidth != null) {
      let width = 8 + 46 + 50 + dataSource.length * 24;
      for (const row of dataSource) {
        width += 18 + 14 * row._text.length;
      }
      state.tagsWidth = width;
      state.expandable = clientWidth < width;
    }

    return state;
  }
}

export default new RadioListReduce();
