import React from 'react';
import Icon from '@mjcloud/icon';
import Service from '@mjcloud/service';
import { Rule } from 'antd/lib/form';
import { MAINPATH } from './constant';
import globalData from '@mjcloud/global-data';
import { ObjectHelper } from '@mjcloud/utils';
import { Form, Input, Button } from 'antd';
import UserLayout from './layouts/UserLayout';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import { handleException, IErrorInfo, ErrorException } from './util';

import styles from './login.less';

interface IDevLoginPageState {
  submitting: boolean;
  error?: IErrorInfo;
}
const userNameRules: Rule[] = [
  {
    required: true,
    message: '请输入用户名',
  },
  {
    min: 2,
    max: 20,
    message: '密码至少3位字符且不能超过20位字符',
  },
];
const passwordRules: Rule[] = [
  {
    required: true,
    message: '请输入密码',
  },
  {
    min: 8,
    max: 20,
    message: '密码至少8位字符且不能超过20位字符',
  },
];
const appUrlRules: Rule[] = [
  {
    type: 'url',
    message: '请输入正确的网址格式',
  },
];

class DevLoginPage extends React.Component<IRouteComponentProps, IDevLoginPageState> {
  constructor(props: IRouteComponentProps) {
    super(props);
    this.state = { submitting: false };
  }

  private headleFinish = async (values: any) => {
    const { submitting } = this.state,
      { appId } = globalData.rbCore.config,
      { history } = this.props;
    if (submitting) return;
    try {
      this.setState({ submitting: true });
      const { shadow } = ObjectHelper.search2params(window.location.search);
      await Service.signin({ ...values, tenantId: 'tenantId', appId, shadow });
      this.setState({ submitting: false });
      history.replace(`/${appId}${MAINPATH}`);
    } catch (ex) {
      this.setState({ submitting: false, ...handleException(ex) });
    }
  };

  render() {
    const { appId } = this.props.match.params;
    const { title, desc, copyright } = globalData.rbCore.config.appSettingList[appId];
    const { error } = this.state;
    const prefixIconStyle: React.CSSProperties = { color: 'rgba(0,0,0,.25)' };
    if (error) return <ErrorException error={error} />;
    return (
      <UserLayout className={styles.main} title={title} desc={desc} copyright={copyright}>
        <Form onFinish={this.headleFinish}>
          <Form.Item name="username" rules={userNameRules}>
            <Input
              size="large"
              placeholder="请输入用户名"
              prefix={<Icon type="user" style={prefixIconStyle} />}
            />
          </Form.Item>

          <Form.Item name="password" rules={passwordRules}>
            <Input.Password
              size="large"
              placeholder="请输入密码"
              prefix={<Icon type="lock" style={prefixIconStyle} />}
              // onPressEnter={this.headlePressEnter}
            />
          </Form.Item>

          {/* <Form.Item name="appUrl" rules={appUrlRules}>
            <Input
              size="large"
              placeholder="请输入后端地址"
              prefix={<Icon type="link" style={prefixIconStyle} />}
            />
          </Form.Item> */}

          <Form.Item>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={this.state.submitting}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </UserLayout>
    );
  }
}

export default DevLoginPage;
