import DyChart from '..';
import React from 'react';
import Bar4line2 from './Bar4line2';
import ControlBase from '../../base';
import { Spin, Empty, Result } from 'antd';
import Pie, { PieConfig } from './charts/pie';
import Radar, { RadarConfig } from './charts/radar';
import Column, { ColumnConfig } from './charts/column';
import GroupedColumnLine, { GroupedColumnLineConfig } from './charts/groupedColumnLine';
import {
  IChartState,
  IChartBarlineConfig,
  IChartBarConfig,
  IChartRadarConfig,
  IChartBasePieConfig,
} from '../typings';

import styles from './index.less';

export default class ChartControl extends ControlBase<IChartState, DyChart> {
  renderBarline(dataSource: any[], barline: IChartBarlineConfig) {
    const { lineName, rotate } = barline;
    let key: any;
    const transformData: any = [],
      transformObj: any = {};
    for (const row of dataSource) {
      const { x, z } = row;
      key = `${x},${z}`;
      if (!transformObj[key]) {
        transformObj[row] = true;
        transformData.push({ x, [lineName]: z });
      }
    }
    const config: GroupedColumnLineConfig = {
      data: [dataSource, transformData],
      xField: 'x',
      yField: ['y', lineName],
      columnGroupField: 'name',
      xAxis: { label: { rotate: 0, autoRotate: true } },
    };
    return <GroupedColumnLine {...config} />;
  }

  renderBar(data: any[], bar: IChartBarConfig) {
    const config: ColumnConfig = {
      forceFit: true,
      data,
      padding: 'auto',
      xField: 'x',
      yField: 'y',
      xAxis: { title: { visible: false } },
      meta: {
        y: { alias: data[0] && data[0].name },
      },
    };
    return <Column {...config} />;
  }

  renderRadar(data: any[], radar: IChartRadarConfig) {
    const config: RadarConfig = {
      data,
      angleField: 'x',
      radiusField: 'y',
      seriesField: 'z',
      radiusAxis: { grid: { line: { type: 'line' } } },
      line: { visible: true },
      point: {
        visible: true,
        shape: 'circle',
      },
      legend: {
        visible: true,
        position: 'bottom-center',
      },
    };
    return <Radar {...config} />;
  }

  renderBasePie(dataSource: any[], basePie: IChartBasePieConfig) {
    const config: PieConfig = {
      forceFit: true,
      radius: 0.8,
      data: dataSource,
      angleField: 'y',
      colorField: 'x',
      label: {
        visible: true,
        type: 'outer',
      },
      legend: {
        position: 'bottom-center',
      },
    };
    return <Pie {...config} />;
  }

  renderBar4line2(dataSource: any[], bar4line2: IChartBarlineConfig) {
    const { lineName } = bar4line2;
    return <Bar4line2 data={dataSource} lineName={lineName} />;
  }

  renderChart() {
    const { dataSource, series } = this.state;

    if (series.barline) return this.renderBarline(dataSource, series.barline);
    else if (series.bar) return this.renderBar(dataSource, series.bar);
    else if (series.radar) return this.renderRadar(dataSource, series.radar);
    else if (series.basePie) return this.renderBasePie(dataSource, series.basePie);
    else if (series.bar4line2) return this.renderBar4line2(dataSource, series.bar4line2);
    return <div>series is NULL</div>;
  }

  renderContent() {
    const { width, height, isFetching, errorMessage, dataSource } = this.state;
    if (errorMessage) return <Result subTitle={errorMessage} />;
    if (dataSource.length > 0) {
      return (
        <div className={styles.dyChart} style={{ width, height }}>
          {this.renderChart()}
        </div>
      );
    } else {
      return (
        <div className={styles.dyChartEmpty} style={{ minHeight: height }}>
          <Empty />
        </div>
      );
    }
  }
}
