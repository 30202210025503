import { useMount } from 'ahooks';
import React, { useState } from 'react';
import { Alert, Button, Modal } from 'antd';
import { getWeworkAddressApiStatus } from './util';
import SetAddressBookAPI from './SetAddressBookAPI';
import styled, { StyledComponent } from 'styled-components';

const Message: StyledComponent<'div', any, {}, any> & {
  Content: StyledComponent<'div', any, {}, any>;
  Button: StyledComponent<typeof Button, any, {}, any>;
} = styled.div`
  display: flex;
` as any;

Message.Content = styled.div`
  flex: 1;
`;

Message.Button = styled(Button)`
  margin-right: 16px;
`;

const AddressBookAPI: React.FC = () => {
  const [status, setStatus] = useState(false);
  const [visible, setVisible] = useState(false);
  const setWeworkAddressApiStatus = async () => {
    const _status = await getWeworkAddressApiStatus();
    setStatus(_status);
  };
  useMount(setWeworkAddressApiStatus);
  if (!status) return null;
  return (
    <>
      <Alert
        message={
          <Message>
            <Message.Content>
              未设置企业微信通讯录同步API接口，无法同步企业微信的通讯录信息。
            </Message.Content>
            <Message.Button size="small" onClick={() => setVisible(true)}>
              马上设置
            </Message.Button>
          </Message>
        }
        banner
        closable
      />
      <Modal
        title="设置企业微信通讯录同步API接口"
        footer={false}
        destroyOnClose
        visible={visible}
        bodyStyle={{ padding: '8px 16px' }}
        onCancel={() => {
          setVisible(false);
          setWeworkAddressApiStatus();
        }}
      >
        <SetAddressBookAPI />
      </Modal>
    </>
  );
};

export default AddressBookAPI;
