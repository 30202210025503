import { Result } from 'antd';
import { MAINPATH } from './constant';
import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import globalData from '@mjcloud/global-data';
import React, { useState, useEffect } from 'react';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import { handleException, IErrorInfo, ErrorException } from './util';

const LoginPage: React.FC<IRouteComponentProps> = props => {
  const { match, history } = props,
    appId = match.params.appId;

  const [error, setError] = useState<IErrorInfo>();
  const [loginInfo, setLoginInfo] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        if (appId === 'taskmanage' && loginInfo == null) {
          const preLoginAddress: any = await JsApiHelper.getStorage('preLoginAddress');
          if (preLoginAddress) {
            JsApiHelper.removeStorage('preLoginAddress');
            // console.log('login', preLoginAddress);
          }
          // 登陆
          const loginInfo = await Service.requestServiceByLogin<any>('/taskmanage/login', {});
          // 登陆成功后，设置应用请求的api host
          globalData.rbCore.config.setApiHost(loginInfo.appUrl);
          // 设置 cookie
          await JsApiHelper.setStorage(
            `YDY_ACCESSTOKEN_taskmanage`,
            encodeURIComponent(JSON.stringify(loginInfo)),
            'cookie',
          );
          // 储存设置信息，防止多次调用
          setLoginInfo(loginInfo);
          history.replace(preLoginAddress ? preLoginAddress : `/${appId}${MAINPATH}`);
        }
      } catch (ex) {
        const { error } = handleException(ex);
        setError(error);
      }
    })();
  });

  if (appId === 'taskmanage') {
    return error ? <ErrorException error={error} /> : null;
  }
  return <Result status="403" title="403" subTitle="抱歉，暂不支持在当前容器登录" />;
};

export default LoginPage;
