import React from 'react';
import Icon from '@mjcloud/icon';
import { Blank } from '@mjcloud/ui';
import { Divider } from 'antd';
import globalData from '@mjcloud/global-data';
import { IOperationTitleProps, IOperationTitleState } from './typings';

import styles from './index.less';

/**
 * 表单操作区标题
 */
export default class OperationTitle extends React.PureComponent<
  IOperationTitleProps,
  IOperationTitleState
> {
  constructor(props: IOperationTitleProps) {
    super(props);
    const { collapse } = props;
    this.state = { collapse: !collapse };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IOperationTitleProps) {
    const { collapse } = nextProps;
    this.setState({ collapse: !collapse });
  }

  private handleMenuClick = () => {
    const { menuClick } = this.props;
    if (menuClick) menuClick();
  };

  render() {
    const { collapse } = this.state,
      { formatMessage } = globalData,
      { mayCustom, isCollapse, settingClick, showTitle } = this.props,
      _showTitle = isCollapse ? !collapse && showTitle : true;
    return (
      <div className={styles.operationTitle}>
        {_showTitle && (
          <span className={styles.title}>{formatMessage({ id: 'table.operation.title' })}</span>
        )}
        <Blank size="auto" />
        {isCollapse && (
          <a onClick={this.handleMenuClick}>
            <Icon type={collapse ? 'icon-outdent' : 'icon-indent'} />
          </a>
        )}
        {mayCustom && (
          <>
            <Divider type="vertical" />
            <a onClick={settingClick}>
              <Icon type="icon-setting" />
            </a>
          </>
        )}
      </div>
    );
  }
}
