import Store from '@mjcloud/redux';
import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IModuleState, IModuleInitialStateParams } from './typings';
const colors = ['#7a93e9', '#ecad6b', '#90c186', '#a498ee'],
  length = colors.length;

class ModuleReduce extends DataReduceBase<IModuleState> {
  initialState(store: Store<IModuleState>, params: IModuleInitialStateParams) {
    let { initConfig, pageMode } = params,
      { displayMode } = initConfig;
    const state: IModuleState = {
      span: 0,
      tabIndex: -1,
      dataSource: [],
      isFetching: false,
      config: initConfig,
      configIsFetching: false,
      errorMessage: undefined,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  loaded(store: Store<IModuleState>, params) {
    let _i = 0;
    const state: IModuleState = super.loaded(store, params) as any;
    state.dataSource = state.dataSource.map((item, index) => {
      if (!item.color) {
        if (index >= length) {
          _i = index - Math.floor(index / length) * length;
        } else {
          _i = index;
        }
        item.color = colors[_i];
      }
      return item;
    });
    return state;
  }

  updateSpan(store: Store<IModuleState>, params) {
    return { ...store.state, span: params.span };
  }
}

export default new ModuleReduce();
