import Icon from '@mjcloud/icon';
import { Player } from 'video-react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import React, { useState } from 'react';

interface OperGuideProps {
  src?: string;
}

const VideoPlayer = styled(Player)`
  .video-react .video-react-big-play-button {
    top: calc(50% - 0.75em);
    left: calc(50% - 1.5em);
  }
`;

const OperGuide: React.FC<OperGuideProps> = ({ children, src }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        type="link"
        style={{ padding: 0 }}
        icon={<Icon type="play-circle" />}
        onClick={() => setVisible(true)}
      >
        {children}
      </Button>
      <Modal
        width="60%"
        footer={false}
        destroyOnClose
        visible={visible}
        bodyStyle={{ padding: 0 }}
        onCancel={() => setVisible(false)}
      >
        <VideoPlayer autoPlay>
          <source src={src} />
        </VideoPlayer>
      </Modal>
    </>
  );
};

export default OperGuide;
