import Store from '@mjcloud/redux';
import { SCROLLMINHEIGHT } from '../constant';
import { IViewModelRow } from '@mjcloud/data-model';
import { addRowButtonControlDefaults } from './controlDefaults';
import { initialState } from '../button/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { PageAddress } from '@mjcloud/page/dist/typings';
import { OPERATIONWIDTH } from '../common/table/constant';
import { IButtonState, IButtonConfig } from '../button/typings';
import ViewModelHelper, { IViewModelCollection } from '@mjcloud/data-model';
import TableReduceBase, { updateColumns } from '../common/table/reduce';
import { IDictionary, ControlDisplayModeEnum, PageModeEnum, DataStateEnum } from '@mjcloud/types';
import {
  IGridEditState,
  IActiveCellParams,
  IGridEditLoadedParams,
  IGridEditStartLoadParams,
  IGridEditInitialStateParams,
  IGridEditUpdateCellSizeParams,
} from './typings';

function formatAddRowButtonConfig(
  address: PageAddress,
  pageMode: PageModeEnum,
  initConfig?: IButtonConfig,
  addRowDisplayMode?: ControlDisplayModeEnum,
): IButtonState {
  const config: IButtonConfig = {} as any;
  if (!initConfig) initConfig = { displayMode: addRowDisplayMode } as any;
  if (initConfig) {
    for (const key in addRowButtonControlDefaults) {
      config[key] = initConfig[key] == null ? addRowButtonControlDefaults[key] : initConfig[key];
    }
  }
  return initialState(config, address, pageMode);
}

export class GridEditReduce extends TableReduceBase<
  IViewModelCollection,
  IViewModelRow,
  IGridEditState
> {
  initialState(store: Store<IGridEditState>, params: IGridEditInitialStateParams) {
    const { initConfig, pageMode, address, onCell, onOperation, aggs, refs } = params,
      { dataModel = ViewModelHelper.createViewModelCollection() } = params,
      { aggModel = ViewModelHelper.createViewModel() } = params,
      { mayCustom, modifyMode, showOrdinal, displayMode, showSummary } = initConfig,
      { orderby, buttons, enableRevise, fastnessCount } = initConfig,
      { addRowDisplayMode, addRowButton, autoGenerateId } = initConfig,
      items = initConfig.items.items,
      showOperation = buttons ? buttons.items.length > 0 : false,
      isModify = PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      // collapse = collapseMenu == null ? true : collapseMenu,
      collapse = false, // TODO: 针对操作列样式异常临时处理
      { columns, isParent, totalWidth } = this.getColumns({
        refs,
        store,
        items,
        pageMode,
        collapse,
        isModify,
        showOrdinal,
        showOperation,
        fastnessCount,
        allowSort: false,
        // TODO: 针对操作列样式异常临时处理
        isCollapse: false,
        onCell,
        onOperation,
      }),
      state: IGridEditState = {
        aggs,
        orderby,
        columns,
        collapse,
        aggModel,
        totalWidth,
        tabIndex: 0,
        cellSize: {},
        rowIdCount: 0,
        showOperation,
        autoGenerateId,
        _dataSource: [],
        modify: isModify,
        isGridEdit: true,
        isFetching: false,
        config: initConfig,
        activeCellId: null,
        dataSource: dataModel,
        entireColumns: columns,
        originalColumns: items,
        mayCustom: !!mayCustom,
        configIsFetching: false,
        showSummary: !!showSummary,
        scrollHeight: SCROLLMINHEIGHT,
        operationWidth: OPERATIONWIDTH,
        addRowButton: formatAddRowButtonConfig(address, pageMode, addRowButton, addRowDisplayMode),
        display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
        // addRowDisplay: PageModeHelper.displayMode2boolean(pageMode, addRowDisplayMode),
      };
    state.columns = updateColumns(state.columns);
    state.summaryDataSource = state.showSummary ? [aggModel as any] : undefined;
    return state;
  }

  loaded(store: Store<IGridEditState>, params: IGridEditLoadedParams) {
    if (params.dataModel) {
      const state: IGridEditState = { ...store.state, isFetching: false };
      state._dataSource = state.dataSource.toArray(true).map(r => r.toJSON());
      return state;
    }
    let { dataSource, aggs: _aggs, aggModel, summaryDataSource } = store.state,
      { dataState = DataStateEnum.unchanged } = params;
    const { aggs, rows = [] } = params.dataSource;
    const _dataSource = rows.map(({ _rid, ...row }) => row);
    dataSource.initDatas(_dataSource, dataState);
    console.log('loaded', _dataSource, aggs);

    if (aggs) {
      const aggData: any = { ...aggs };
      if (_aggs) {
        _aggs.forEach(agg => {
          if (aggData[agg.name] == null) aggData[agg.name] = 0;
        });
      }
      aggModel.initDatas(aggData);
      summaryDataSource = [aggModel] as any[];
    }
    let state = {
      ...store.state,
      isFetching: false,
      dataSource,
      summaryDataSource,
      _dataSource: dataSource.toArray(true).map(r => r.toJSON()),
    };

    return state;
  }

  addClickAfter(store: Store<IGridEditState>, params) {
    const state = { ...store.state };
    state.addRowButton.loading = true;
    return state;
  }

  addClickDone(store: Store<IGridEditState>, params) {
    const state = { ...store.state };
    state.addRowButton.loading = false;
    return state;
  }

  updateAddBtnLoad(store: Store<IGridEditState>, params: { loading: boolean }) {
    const state = { ...store.state };
    state.addRowButton.loading = params.loading;
    return state;
  }

  batchAddRow(store: Store<IGridEditState>, params: { rows: IDictionary[] }) {
    let { rows } = params,
      state = this.addClickDone(store, {});
    for (const { _rid, ...rowData } of rows) {
      state.dataSource.addRow(rowData, store);
    }
    state._dataSource = state.dataSource.toArray(true).map(r => r.toJSON());
    return state;
  }

  deleteRow(store: Store<IGridEditState>, params: { rid: number }) {
    const { dataSource } = store.state;
    dataSource.removeRow(params.rid, store);
    return { ...store.state, _dataSource: dataSource.toArray(true).map(r => r.toJSON()) };
  }

  deleteAllRow(store: Store<IGridEditState>, params: { isKeepDataState: boolean }) {
    const { dataSource } = store.state;
    if (params.isKeepDataState) {
      const list = dataSource.toArray(true);
      for (const item of list) {
        dataSource.removeRow(item._rid, store);
      }
    } else {
      dataSource.clear();
    }

    return {
      ...store.state,
      _dataSource: dataSource.toArray(true).map(r => r.toJSON()),
    };
  }

  updateDataModel(store: Store<IGridEditState>, params) {
    const { dataSource } = store.state;
    const _dataSource = dataSource.toArray(true).map(r => r.toJSON());
    console.log('updateDataModel', _dataSource);

    return {
      ...store.state,
      _dataSource,
    };
  }

  activeCell(store: Store<IGridEditState>, params: IActiveCellParams) {
    const { cellId } = params;
    const state = store.state;
    state.activeCellId = cellId;
    return state;
  }

  updateCellSize(store: Store<IGridEditState>, params: IGridEditUpdateCellSizeParams) {
    const state = store.state,
      { rowId, size } = params;
    const _size = state.cellSize[rowId];
    params.isUpdate = true;
    if (_size) {
      const { width, height } = size,
        { width: oldwidth, height: oldheight } = _size;
      if (oldwidth === width && height === oldheight) {
        params.isUpdate = false;
      }
    }
    state.cellSize[rowId] = size;
    return state;
  }

  updateModifyMode(store: Store<IGridEditState>, params) {
    let state: IGridEditState = store.state,
      { showOperation } = state;
    if (showOperation) {
      state = this.updateColumns(store, {
        cells: [{ cellId: '_operation', display: params.modify }],
      });
    }
    state.modify = params.modify;
    return { ...state };
  }
}

export default new GridEditReduce();
