import Textbox from '.';
import ControllerHelper from '@mjcloud/controller';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { ExceptionHelper } from '@mjcloud/exception';
import { PageModeEnum, IDictionary } from '@mjcloud/types';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import { ITextboxState, TextBoxActionType } from './typings';

// var code = 0;

export default class TextboxExtendStore extends ValueExtendStoreBase<string, Textbox> {
  private _controller: any = null;

  async handleInitialStateAfter(e: ActionAfterEventArg<ITextboxState, TextBoxActionType, any>) {
    super.handleInitialStateAfter(e);
    // const { ruleKey, isAutoCode } = e.newState.state;
    // if (ruleKey && isAutoCode && this.instance.page.pageMode === PageModeEnum.add) {
    //   const value = ++code;
    //   e.newState.dispatch('updateValue', {
    //     value,
    //     text: value,
    //     isUUID: true,
    //     notUpdateState: true,
    //     actionSourceSign: this.instance.store,
    //   });
    // }
    // const { button } = e.newState.state;
    // if (button && button.controllerId) {
    //   this._controller = await ControllerHelper.create(this.instance, button.controllerId);
    // }
  }

  async handleButtonClickAfterAfter(e: ActionAfterEventArg<ITextboxState, TextBoxActionType>) {
    const { button } = e.newState.state;
    if (button) {
      const { tip, title, controllerId } = button;
      const exclusiveLockId = this.instance.page.applyExclusiveLock(
        tip || `正在执行${title}操作...`,
      );
      if (exclusiveLockId === 0) {
        e.newState.dispatch('buttonClickDone', {});
        return;
      }
      const params: IDictionary = { ...e.params },
        before = async (data: IDictionary, extra: IDictionary = {}) => {
          extra._before = () => e.newState.dispatch('updateButtonLoad', { loading: true });
          extra._after = () => e.newState.dispatch('updateButtonLoad', { loading: false });
          if (this.instance.eventManager.getHandlerCount('onButtonBeforeClick') > 0) {
            await this.instance.eventManager.trigger('onButtonBeforeClick', data, extra);
          }
          return data;
        },
        after = async (data: IDictionary, extra: IDictionary = {}) => {
          if (this.instance.eventManager.getHandlerCount('onButtonAfterClick') > 0) {
            await this.instance.eventManager.trigger('onButtonAfterClick', data, extra);
          }
          return data;
        };
      try {
        if (controllerId) {
          const _params = { data: params, before, after };
          if (this._controller) {
            await ControllerHelper.executeByCreated({
              controller: this._controller,
              ..._params,
            });
          } else {
            await ControllerHelper.execute({
              sender: this.instance,
              controllerId,
              ..._params,
            });
          }
        } else {
          await before(params);
          await after(params);
        }

        this.instance.page.releaseExclusiveLock(exclusiveLockId);
        e.newState.dispatch('buttonClickDone', {});
      } catch (error) {
        e.newState.dispatch('buttonClickDone', {});
        this.instance.page.releaseExclusiveLock(exclusiveLockId);
        ExceptionHelper.dispose(error);
      }
    }
  }
}
