import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox, Spin } from 'antd';
import Collection from './Collection';
import { IDictionary } from '@mjcloud/types';
import { findDOMNode } from 'react-dom';
import { AUTOCELLWIDTH } from './constant';
import { conductCheck } from './conductUtil';
import { parseCheckedKeys } from './treeUtil';
// import ResizeObserver from 'rc-resize-observer';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import { NumberHelper, FormulaHelper } from '@mjcloud/utils';
import VirtualizedSelection from './VirtualizedSelection';
import { TableRowSelection } from 'antd/lib/table/interface';
import VirtualizedCell, { ICellProps } from './VirtualizedCell';
import { Grid, Index, ScrollSync, Size, ScrollSyncChildProps } from 'react-virtualized';
import {
  Key,
  FixedType,
  ICellState,
  ITableProps,
  ITableState,
  IColumnProps,
  CellRenderer,
  IHeaderCellType,
  GetBodyRowHeight,
  IFormatDataParams,
  ContainerRenderer,
  ITableColumnProps,
  HeaderCellRenderer,
  ITableDataSourceItem,
  HeaderCellSizeAndPositionGetter,
} from './interface';

import styles from './VirtualizedTable.less';

const widthColumnFormula = FormulaHelper.create(
  'data.isScrollX ? data.AUTOCELLWIDTH : (( data.tableWidth - ( data.bodyWidth - data.autoColumnCount * data.AUTOCELLWIDTH ) ) / data.autoColumnCount)',
);
const headerNestedNumber = 5;

function align2style(align?: 'left' | 'right' | 'center') {
  const style: React.CSSProperties = { textAlign: align };
  switch (align) {
    case 'center':
      style.alignItems = 'center';
      style.textAlign = 'center';
      break;
    case 'right':
      // style.alignItems = 'flex-end';
      style.textAlign = 'right';
      break;
    default:
      // style.alignItems = 'flex-start';
      style.textAlign = 'left';
      break;
  }
  return style;
}

function planishColumns<T>(columns: IColumnProps<T>[]) {
  let tieColumns: ITableColumnProps<T>[] = [];
  for (const { children = [], ...column } of columns) {
    if (children.length > 0) {
      tieColumns = [...tieColumns, ...planishColumns<T>(children)];
    } else {
      tieColumns.push(column);
    }
  }
  return tieColumns;
}

function parseHeaderRows<RecordType>(
  rootColumns: IColumnProps<RecordType>[],
  autoCellWidth: number,
  defaultHeaderRowHeight: number,
  headerCells: IDictionary<IHeaderCellType<RecordType>>,
  headerCellsMaxHeight: IDictionary<number>,
) {
  let rows: IHeaderCellType<RecordType>[][] = [];
  // console.log('parseHeaderRows', headerCells);

  function fillRowCells(
    columns: IColumnProps<RecordType>[],
    colIndex: number,
    rowIndex: number = 0,
    colFixed?: FixedType,
  ): IFillRowCellsResult[] {
    // Init rows
    rows[rowIndex] = rows[rowIndex] || [];

    let currentColIndex = colIndex;
    const result: IFillRowCellsResult[] = columns.map(column => {
      if (column.colSpan === 0) return { colSpan: 0, width: 0 };
      const {
          key,
          title,
          align,
          isTree,
          children = [],
          className = '',
          width: columnWidth = autoCellWidth,
        } = column,
        cell: any = {
          key,
          isTree,
          className,
          children: title,
          column,
          rowSpan: 1,
          colStart: currentColIndex,
        };

      let colSpan: number = 1,
        style: React.CSSProperties = align2style(align),
        width: number = NumberHelper.parse(columnWidth),
        fixed = rowIndex === 0 ? column.fixed : colFixed;

      if (children.length > 0) {
        const res = fillRowCells(children, currentColIndex, rowIndex + 1, fixed).reduce(
          (total, count) => ({
            colSpan: total.colSpan + count.colSpan,
            width: total.width + count.width,
          }),
          { colSpan: 0, width: 0 },
        );
        width = res.width;
        colSpan = res.colSpan;
        cell.hasSubColumns = true;
        style.textAlign = 'center';
        style.alignItems = 'center';
      }

      if (column.colSpan) {
        colSpan = column.colSpan;
      }

      cell.width = width;
      cell.fixed = fixed;
      cell.style = style;
      cell.colSpan = colSpan;
      cell.colEnd = cell.colStart + colSpan - 1;

      rows[rowIndex].push(cell);
      if (children.length === 0) {
        const _cell = { ...cell, _placeholder: true };
        for (let i = 1; i <= headerNestedNumber; i++) {
          rows[rowIndex + i] = rows[rowIndex + i] || [];
          rows[rowIndex + i].push(_cell);
        }
      }

      currentColIndex += colSpan;

      return { colSpan, width };
    });

    return result;
  }

  // Generate `rows` cell data
  fillRowCells(rootColumns, 0);

  // Backup rows
  const oldRows = [...rows];

  // Handle x
  let cellWidthCount = 0,
    rightCellWidthCount = 0;
  rows = rows.map(row => {
    cellWidthCount = 0;
    rightCellWidthCount = 0;
    return row.map((cell: any) => {
      if (cell.fixed === 'right') {
        cell.rightX = rightCellWidthCount;
        rightCellWidthCount += cell.width;
      }
      cell.x = cellWidthCount;
      cellWidthCount += cell.width;
      return cell;
    });
  });
  rows = rows
    .map(row => {
      return row.filter((cell: any) => !cell._placeholder);
    })
    .filter(row => row.length > 0);

  // Handle `rowSpan`
  let y = 0,
    lastRowIndex = 0,
    cellHeight: number | undefined = 0;
  const rowCount = rows.length,
    _headerCellsMaxHeight: number[] = Array.from(
      { length: rowCount },
      (_, k) => headerCellsMaxHeight[k] || defaultHeaderRowHeight,
    );
  for (let rowIndex = 0; rowIndex < rowCount; rowIndex += 1) {
    if (rowIndex > 0) {
      y += _headerCellsMaxHeight[rowIndex - 1];
    }
    cellHeight = _headerCellsMaxHeight[rowIndex];
    for (const cell of rows[rowIndex]) {
      if (!cell.hasSubColumns) {
        cell.rowSpan = rowCount - rowIndex;
      }
      cell.y = y;
      cell.rowIndex = rowIndex;
      if (cell.rowSpan === 1) {
        cell.height = cellHeight;
      } else {
        lastRowIndex = rowIndex + cell.rowSpan;
        cell.height = _headerCellsMaxHeight
          .map((h, i) => (i >= rowIndex && i < lastRowIndex ? h : 0))
          .reduce((total, currentValue) => total + currentValue);
      }
    }
  }

  // Generate leftRowsCount and rightRowsCount
  const lrows = oldRows[rowCount - 1],
    leftRowsCount = lrows.filter(cell => cell.fixed === 'left').length,
    rightRowsCount = lrows.filter(cell => cell.fixed === 'right').length;

  const leftRows = rows.map(row => row.filter(cell => cell.fixed === 'left')),
    rightRows = rows.map(row =>
      row.filter(cell => cell.fixed === 'right').map((cell: any) => ({ ...cell, x: cell.rightX })),
    );
  return { rows, leftRows, rightRows, leftRowsCount, rightRowsCount, headerHeight: y + cellHeight };
}

function updateExpandedRow(
  record: ITableDataSourceItem,
  data: ITableDataSourceItem[],
  display: boolean,
) {
  for (const row of data) {
    // TODO: 需要注意开源后该处逻辑问题
    if (row._parentRowKey === record._rowKey) {
      row._display = display;
      updateExpandedRow(row, data, display ? row._expanded : display);
    }
  }
}

interface IFillRowCellsResult {
  colSpan: number;
  width: number;
}

export default class Table<T> extends React.Component<ITableProps<T>, ITableState<T>> {
  static propTypes = {
    size: PropTypes.string,
    style: PropTypes.object,
    bordered: PropTypes.bool,
    locale: PropTypes.object,
    columns: PropTypes.array,
    scroll: PropTypes.object,
    className: PropTypes.string,
    dataSource: PropTypes.array,
    showSummary: PropTypes.bool,
    rowSelection: PropTypes.object,
    sortDirections: PropTypes.array,
    overscanRowCount: PropTypes.number,
    summaryDataSource: PropTypes.array,
    overscanColumnCount: PropTypes.number,
    expandedRowKeys: PropTypes.array,
    childrenColumnName: PropTypes.string,
    rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

    onChange: PropTypes.func,
    onExpand: PropTypes.func,
    onExpandedRowsChange: PropTypes.func,
  };

  static defaultProps: ITableProps<any> = {
    locale: {},
    columns: [],
    rowKey: 'key',
    rowHeight: 40,
    dataSource: [],
    expandLevel: 9,
    loading: false,
    components: {},
    keyEntities: {},
    bordered: false,
    size: 'default',
    pagination: false,
    showSummary: false,
    expandedRowKeys: [],
    overscanRowCount: 5,
    headerRowHeight: 36,
    expandLevel2Keys: {},
    summaryDataSource: [],
    overscanColumnCount: 0,
    childrenColumnName: 'children',
    scroll: { x: 'max-content', y: 300 },
    onChange: () => {},
    onExpand: () => {},
    onExpandedRowsChange: () => {},
  };

  private container: Element | null = null;
  private hoverRows: IDictionary<boolean> = {};
  private headerCells: IDictionary<IHeaderCellType<T>> = {};
  private headerCellsMaxHeight: IDictionary<number> = {};

  // static getDerivedStateFromProps<T>(nextProps: ITableProps<T>, prevState: ITableState<T>) {
  //   console.log('getDerivedStateFromProps', nextProps, prevState);
  //   return null;
  // }

  constructor(props: ITableProps<T>) {
    super(props);
    const state = this.setDataSource(props);
    this.state = {
      ...state,
      ...this.setSpinProps(props),
      ...this.setSummary(props),
      ...this.setSelection(state.dataSource, props),
    };
  }

  componentWillReceiveProps(nextProps: ITableProps<T>) {
    // this.setState(this.props2state(nextProps));
    let updateState = false,
      state: ITableState<T> = { ...this.state };

    if (this.props.loading !== nextProps.loading) {
      updateState = true;
      state = { ...state, ...this.setSpinProps(nextProps) };
    }

    if (
      this.props.dataSource !== nextProps.dataSource ||
      this.props.expandedRowKeys !== nextProps.expandedRowKeys
    ) {
      updateState = true;
      state = { ...state, ...this.setDataSource(nextProps) };
    }

    if (
      this.props.rowHeight !== nextProps.rowHeight ||
      this.props.showSummary !== nextProps.showSummary ||
      this.props.summaryDataSource !== nextProps.summaryDataSource
    ) {
      updateState = true;
      state = { ...state, ...this.setSummary(nextProps) };
    }

    if (this.props.rowSelection !== nextProps.rowSelection) {
      updateState = true;
      state = {
        ...state,
        ...this.setSelection(state.tileDataSource as any[], nextProps),
      };
    }

    if (this.props.columns !== nextProps.columns) {
      updateState = true;
      state.columns = nextProps.columns;
    }

    if (updateState) {
      // console.log('componentWillReceiveProps', nextProps.id, state);
      this.setState(state);
    }
  }

  private getCellState(tableWidth: number): ICellState<T> {
    const { scroll, headerRowHeight, rowHeight } = this.props,
      { footerRowCount, footerHeight, columns, dataSource } = this.state,
      tieColumns = planishColumns<T>(columns).filter(({ colSpan }) => colSpan !== 0),
      { y: maxHeight } = scroll;
    let bodyWidth = 0,
      autoColumnCount = 0;
    // 通过批量平铺的 columns 获取 bodyWidth autoColumnCount，进而求得自适配列宽度
    for (const column of tieColumns) {
      const { width } = column;
      let _width = NumberHelper.parse(width);
      if (width == null || width == 0) {
        autoColumnCount++;
        _width = AUTOCELLWIDTH;
      }
      bodyWidth += _width;
    }
    const isScrollX = bodyWidth > tableWidth,
      widthColumnFormulaData = { isScrollX, bodyWidth, tableWidth, AUTOCELLWIDTH, autoColumnCount },
      autoCellWidth = widthColumnFormula.calc(widthColumnFormulaData),
      { rows, leftRows, rightRows, leftRowsCount, rightRowsCount, headerHeight } = parseHeaderRows(
        columns,
        autoCellWidth,
        headerRowHeight,
        this.headerCells,
        this.headerCellsMaxHeight,
      ),
      headerRowCount = rows.length,
      // headerHeight = headerRowHeight * headerRowCount,
      bodyHeight = headerHeight + dataSource.length * rowHeight + footerHeight,
      isScrollY = maxHeight < bodyHeight;

    const state: ICellState<T> = {
      isScrollX,
      isScrollY,
      tableWidth,
      headerHeight,
      autoCellWidth,
      headerRowCount,
      columns: tieColumns,
      columnCount: tieColumns.length,
      leftColumnCount: leftRowsCount,
      rightColumnCount: rightRowsCount,
      headerRows: rows.reduce((a, b) => a.concat(b)),
      bodyHeight: isScrollY ? maxHeight : bodyHeight,
      headerLeftRows: leftRows.reduce((a, b) => a.concat(b)),
      headerRightRows: rightRows.reduce((a, b) => a.concat(b)),
      rowCount: dataSource.length + headerRowCount + footerRowCount,
      leftColumnWidth: leftRows[0].map(r => r.width).reduce((a, b) => a + b, 0),
      rightColumnWidth: rightRows[0].map(r => r.width).reduce((a, b) => a + b, 0),
    };
    return state;
  }

  private formatData(params: IFormatDataParams<T>) {
    const { childrenColumnName, rowKey } = this.props,
      { parent, parentRowKey = '0', deepness = 0, display = true } = params,
      { source, data, expandedRowKeys, disabledFn } = params;
    const _deepness = deepness + 1;
    for (let rowIndex = 0; rowIndex < source.length; rowIndex++) {
      const record = source[rowIndex];
      const _rowKey = typeof rowKey === 'string' ? rowKey : rowKey(record, rowIndex),
        { [_rowKey]: _rid, [childrenColumnName]: _children = [] } = record as any,
        _expanded = !!expandedRowKeys[_rid];
      const _row: ITableDataSourceItem = {
        ...record,
        _expanded,
        // _sort: 10,
        _last: false,
        _rowKey: _rid,
        _display: display,
        _deepness: _deepness,
        _parentRowKey: parentRowKey,
        // _disabled: disabledFn ? disabledFn(record) : false,
      };
      data.push(_row);
      if (_children.length <= 0) {
        _row._last = true;
      } else {
        this.formatData({
          source: _children,
          data,
          parent: _row,
          expandedRowKeys,
          parentRowKey: _rid,
          deepness: _deepness,
          display: _row._expanded,
          disabledFn,
        });
      }
    }
  }

  private setDataSource(props: ITableProps<T>) {
    let tileDataSource: ITableDataSourceItem[] = [];
    const expandedRowKeys: IDictionary<boolean> = {};
    props.expandedRowKeys.forEach(k => (expandedRowKeys[k] = true));

    this.formatData({
      source: props.dataSource,
      data: tileDataSource,
      expandedRowKeys,
    });

    // tileDataSource = tileDataSource.map((r, _sort) => ({ ...r, _sort }));

    return {
      loading: false,
      spinning: false,
      tileDataSource,
      dataSource: tileDataSource.filter(r => r._display),
    };
  }

  private setSpinProps(props: ITableProps<T>): Pick<ITableState<T>, 'spinProps'> {
    const { loading } = props;
    return {
      spinProps: typeof loading === 'boolean' ? { spinning: loading } : { ...loading },
    };
  }

  private setSummary(
    props: ITableProps<T>,
  ): Pick<ITableState<T>, 'footerHeight' | 'footerRowCount'> {
    const { rowHeight, showSummary, summaryDataSource } = props,
      footerRowCount = showSummary ? summaryDataSource.length : 0;
    return {
      footerRowCount,
      footerHeight: rowHeight * footerRowCount,
    };
  }

  private renderSelectionTitle = (
    tileDataSource: ITableDataSourceItem[],
    props: ITableProps<T>,
    rowSelection: TableRowSelection<T>,
  ) => {
    const { rowKey } = props,
      { selectedRowKeys = [], onChange = () => {} } = rowSelection,
      checked = selectedRowKeys.length > 0 && tileDataSource.length === selectedRowKeys.length,
      indeterminate = checked ? false : selectedRowKeys.length > 0;
    return (
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        onChange={e => {
          if (e.target.checked) {
            onChange(
              tileDataSource.map((record, rowIndex) => {
                const _rowKey =
                  typeof rowKey === 'string' ? rowKey : rowKey(record as any, rowIndex);
                return record[_rowKey];
              }),
              tileDataSource as any[],
            );
          } else {
            onChange([], []);
          }
        }}
      />
    );
  };

  private setSelection(
    tileDataSource: ITableDataSourceItem[],
    props: ITableProps<T>,
  ): Pick<ITableState<T>, 'columns' | 'tileDataSource' | 'dataSource'> {
    let { columns, keyEntities, rowKey, rowSelection } = props;
    if (rowSelection) {
      const { fixed, type = 'checkbox', columnWidth = '60px' } = rowSelection;

      if (type === 'checkbox') {
        let checkedKeys: Key[] = [],
          halfCheckedKeys: Key[] = [];
        const { strictly, selectedRowKeys = [], halfSelectedKeys = [], onSelect } = rowSelection;
        if (strictly) {
          checkedKeys = [...selectedRowKeys];
          halfCheckedKeys = [...halfSelectedKeys];
        } else {
          const checkedKeyEntity = parseCheckedKeys(selectedRowKeys);
          if (checkedKeyEntity) {
            const conductKeys = conductCheck(checkedKeyEntity.checkedKeys, true, keyEntities);
            ({ checkedKeys, halfCheckedKeys } = conductKeys);
          }
        }

        const selectionColumn: IColumnProps<T> = {
          key: '_rowSelection',
          dataIndex: '_rowSelection',
          width: columnWidth,
          align: 'center',
          fixed: fixed ? 'left' : undefined,
          title: '选择',
          // title: () => this.renderSelectionTitle(tileDataSource, props, rowSelection),
          render: (_, record: T, rowIndex: number) => (
            <VirtualizedSelection
              {...{
                record,
                rowKey,
                strictly,
                rowIndex,
                keyEntities,
                checkedKeys,
                halfCheckedKeys,
                onSelect,
              }}
            />
          ),
        };
        columns = [selectionColumn, ...columns];
      }
    }
    return {
      columns,
      tileDataSource,
      dataSource: tileDataSource.filter(r => r._display),
    };
  }

  private columnWidthFn = ({ index }: Index, { columns, autoCellWidth }: ICellState<T>) => {
    const { width = autoCellWidth } = columns[index];
    return NumberHelper.parse(width);
  };
  private columnRightWidthFn = (
    { index }: Index,
    { columns, autoCellWidth, columnCount, rightColumnCount }: ICellState<T>,
  ) => {
    const columnIndex = columnCount - index - rightColumnCount,
      { width = autoCellWidth } = columns[columnIndex];
    return NumberHelper.parse(width);
  };

  readyContainer = (instance: any | null) => {
    const el = findDOMNode(instance);
    if (el instanceof Element) {
      this.container = el;
      addResizeListener(this.container, this.resizeContainerCallback);
    }
  };

  resizeContainerCallback = () => {
    if (this.container && this.container.clientWidth) {
      this.setState({ width: this.container.clientWidth });
    }
  };

  private handleExpand = (expanded: boolean, record: any) => {
    const { tileDataSource } = this.state;
    record._expanded = !record._expanded;
    updateExpandedRow(record as any, tileDataSource, record._expanded);
    const state = {
      tileDataSource,
      dataSource: Object.getOwnPropertyNames(tileDataSource)
        .map(k => tileDataSource[k])
        .filter(r => r._display),
    };
    this.setState(state);
  };

  renderTable = (props: ScrollSyncChildProps, width?: number) => {
    if (width) {
      const state = this.getCellState(width - 2),
        { dataSource, footerRowCount } = this.state,
        { scrollLeft, scrollWidth, clientWidth } = props,
        showFooter = footerRowCount > 0 && dataSource.length > 0,
        { bodyHeight, isScrollX, isScrollY, leftColumnCount, rightColumnCount } = state,
        scrollHeight = isScrollX ? bodyHeight + scrollbarSize() + 2 : bodyHeight + 2,
        rightScrollbarSize = isScrollY ? scrollbarSize() : 0;

      return (
        <div className={styles.tableRow} style={{ width, height: scrollHeight }}>
          {leftColumnCount > 0 && (
            <div className={classNames(styles.tableLeftColumn, scrollLeft > 0 && styles.fixed)}>
              {this._renderLeftHeaderContainer(props, state)}
              {this._renderLeftSideContainer(props, state)}
              {showFooter && this._renderLeftFooterContainer(props, state)}
            </div>
          )}

          <div className={styles.tableColumn}>
            {this._renderBodyHeaderContainer(props, state)}
            {this._renderBodyMainContainer(props, state)}
            {showFooter && this._renderBodyFooterContainer(props, state)}
          </div>

          {rightColumnCount > 0 && (
            <div
              className={classNames(
                styles.tableRightColumn,
                isScrollX &&
                  scrollWidth > scrollLeft + clientWidth + rightScrollbarSize &&
                  styles.fixed,
              )}
              style={{ right: rightScrollbarSize }}
            >
              {this._renderRightHeaderContainer(props, state)}
              {this._renderRightSideContainer(props, state)}
              {showFooter && this._renderRightFooterContainer(props, state)}
            </div>
          )}
        </div>
      );
    } else {
      return <div className={styles.tableRow}></div>;
    }
  };

  render() {
    const { width, spinProps } = this.state;
    return (
      <Spin {...spinProps} ref={this.readyContainer}>
        <ScrollSync>{props => this.renderTable(props, width)}</ScrollSync>
      </Spin>
    );
  }

  private _handHeaderCellResize = (headerRow: IHeaderCellType<T>, info: Size) => {
    // const headerCells = { ...this.state.headerCells };
    const { headerRowHeight } = this.props,
      { rowIndex, rowSpan } = headerRow,
      newMaxHeight = (info.height + 16) / rowSpan,
      height = rowSpan * headerRowHeight;
    let oldMaxHeight = this.headerCellsMaxHeight[rowIndex];
    if (!oldMaxHeight) oldMaxHeight = headerRowHeight;

    if (info.height > height) {
      headerRow.height = info.height + 16;
      this.headerCells[headerRow.key] = headerRow;
    }
    if (newMaxHeight > oldMaxHeight) {
      this.headerCellsMaxHeight[rowIndex] = newMaxHeight;
      // TODO: 通过延时处理样式异常，会损耗大量性能
      this.setState({ spinning: true });
      setTimeout(() => this.setState({ spinning: false }), 0);
    }
  };

  private _renderLeftHeaderContainer: ContainerRenderer<T> = (_, state) => {
    const { overscanColumnCount } = this.props,
      { headerHeight, headerLeftRows, leftColumnWidth } = state;
    return (
      <div
        className={styles.tableHeaderContainer}
        style={{ height: headerHeight, width: leftColumnWidth }}
      >
        <Collection
          cellCount={headerLeftRows.length}
          cellRenderer={params =>
            this._renderLeftHeaderCell({ ...params, state, headerRows: headerLeftRows })
          }
          cellSizeAndPositionGetter={params =>
            this._headerCellSizeAndPositionGetter({ ...params, headerRows: headerLeftRows })
          }
          className={styles.tableHeader}
          height={headerHeight}
          horizontalOverscanSize={overscanColumnCount}
          verticalOverscanSize={overscanColumnCount}
          width={leftColumnWidth}
        />
      </div>
    );
  };
  private _renderLeftSideContainer: ContainerRenderer<T> = ({ scrollTop }, state) => {
    const { rowCount, bodyHeight, leftColumnWidth, leftColumnCount, isScrollX } = state,
      { overscanColumnCount, overscanRowCount } = this.props;
    // height = isScrollX ? bodyHeight - scrollbarSize() : bodyHeight;
    return (
      <div style={{ width: leftColumnWidth, height: bodyHeight }}>
        {!this.state.spinning && (
          <Grid
            className={styles.tableLeftSideColumn}
            overscanColumnCount={overscanColumnCount}
            overscanRowCount={overscanRowCount}
            cellRenderer={props => this._renderLeftSideCell({ ...props, state })}
            columnWidth={p => this.columnWidthFn(p, state)}
            columnCount={leftColumnCount}
            height={bodyHeight}
            rowHeight={params => this._getBodyRowHeight({ ...params, state })}
            rowCount={rowCount}
            scrollTop={scrollTop}
            width={leftColumnWidth}
          />
        )}
      </div>
    );
  };
  private _renderLeftFooterContainer: ContainerRenderer<T> = (_, state) => {
    const { rowHeight } = this.props,
      { footerHeight, footerRowCount } = this.state,
      { leftColumnCount, leftColumnWidth, isScrollX } = state,
      bottom = isScrollX ? -scrollbarSize() : 0;
    return (
      <div
        className={styles.tableFooterContainer}
        style={{ height: footerHeight, width: leftColumnWidth, bottom }}
      >
        <Grid
          cellRenderer={props => this._renderLeftFooterCell({ ...props, state })}
          className={styles.tableFooter}
          width={leftColumnWidth}
          height={footerHeight}
          rowHeight={rowHeight}
          columnWidth={p => this.columnWidthFn(p, state)}
          rowCount={footerRowCount}
          columnCount={leftColumnCount}
        />
      </div>
    );
  };

  private _renderRightHeaderContainer: ContainerRenderer<T> = (_, state) => {
    const { overscanColumnCount } = this.props,
      { headerHeight, headerRightRows, rightColumnWidth } = state;
    return (
      <div
        className={styles.tableHeaderContainer}
        style={{ height: headerHeight, width: rightColumnWidth }}
      >
        <Collection
          cellCount={headerRightRows.length}
          cellRenderer={params =>
            this._renderRightHeaderCell({ ...params, state, headerRows: headerRightRows })
          }
          cellSizeAndPositionGetter={params =>
            this._headerCellSizeAndPositionGetter({ ...params, headerRows: headerRightRows })
          }
          className={styles.tableHeader}
          height={headerHeight}
          horizontalOverscanSize={overscanColumnCount}
          verticalOverscanSize={overscanColumnCount}
          width={rightColumnWidth}
        />
      </div>
    );
  };
  private _renderRightSideContainer: ContainerRenderer<T> = ({ scrollTop }, state) => {
    const { overscanColumnCount, overscanRowCount } = this.props,
      { isScrollX, rowCount, bodyHeight, rightColumnCount, rightColumnWidth } = state,
      height = isScrollX ? bodyHeight - scrollbarSize() : bodyHeight;
    return (
      <div style={{ width: rightColumnWidth, height }}>
        {!this.state.spinning && (
          <Grid
            className={styles.tableRightSideColumn}
            overscanColumnCount={overscanColumnCount}
            overscanRowCount={overscanRowCount}
            cellRenderer={props => this._renderRightSideCell({ ...props, state })}
            columnWidth={p => this.columnRightWidthFn(p, state)}
            columnCount={rightColumnCount}
            height={height}
            rowHeight={params => this._getBodyRowHeight({ ...params, state })}
            rowCount={rowCount}
            scrollTop={scrollTop}
            width={rightColumnWidth}
          />
        )}
      </div>
    );
  };
  private _renderRightFooterContainer: ContainerRenderer<T> = (_, state) => {
    const { rowHeight } = this.props,
      { footerHeight, footerRowCount } = this.state,
      { rightColumnCount, rightColumnWidth, isScrollX } = state,
      bottom = isScrollX ? -scrollbarSize() : 0;
    return (
      <div
        className={styles.tableFooterContainer}
        style={{ height: footerHeight, width: rightColumnWidth, bottom }}
      >
        <Grid
          cellRenderer={props => this._renderRightFooterCell({ ...props, state })}
          className={styles.tableFooter}
          width={rightColumnWidth}
          height={footerHeight}
          rowHeight={rowHeight}
          columnWidth={p => this.columnRightWidthFn(p, state)}
          rowCount={footerRowCount}
          columnCount={rightColumnCount}
        />
      </div>
    );
  };

  private _renderBodyHeaderContainer: ContainerRenderer<T> = ({ scrollLeft }, state) => {
    const { tableWidth, isScrollY, headerHeight, headerRows } = state,
      { overscanColumnCount } = this.props,
      width = isScrollY ? tableWidth - scrollbarSize() : tableWidth;
    return (
      <div className={styles.tableHeaderContainer} style={{ height: headerHeight, width }}>
        <Collection
          cellCount={headerRows.length}
          cellRenderer={params => this._renderBodyHeaderCell({ ...params, state, headerRows })}
          cellSizeAndPositionGetter={params =>
            this._headerCellSizeAndPositionGetter({ ...params, headerRows })
          }
          className={styles.tableHeader}
          height={headerHeight}
          horizontalOverscanSize={overscanColumnCount}
          verticalOverscanSize={overscanColumnCount}
          scrollLeft={scrollLeft}
          width={width}
        />
      </div>
    );
  };
  private _renderBodyMainContainer: ContainerRenderer<T> = ({ onScroll }, state) => {
    const { isScrollX, tableWidth: width, columnCount, bodyHeight, rowCount } = state,
      { overscanColumnCount, overscanRowCount } = this.props,
      height = isScrollX ? bodyHeight + scrollbarSize() : bodyHeight;
    return (
      <div style={{ height, width }}>
        {!this.state.spinning && (
          <Grid
            className={classNames(styles.bodyContainer, !isScrollX && styles.overflowX)}
            columnWidth={p => this.columnWidthFn(p, state)}
            columnCount={columnCount}
            height={height}
            onScroll={onScroll}
            overscanColumnCount={overscanColumnCount}
            overscanRowCount={overscanRowCount}
            cellRenderer={props => this._renderBodyCell({ ...props, state })}
            rowHeight={params => this._getBodyRowHeight({ ...params, state })}
            rowCount={rowCount}
            width={width}
          />
        )}
      </div>
    );
  };
  private _renderBodyFooterContainer: ContainerRenderer<T> = ({ scrollLeft }, state) => {
    const { isScrollX, isScrollY, columnCount, tableWidth } = state,
      { overscanColumnCount, rowHeight } = this.props,
      { footerRowCount, footerHeight } = this.state,
      bottom = isScrollX ? scrollbarSize() : 0,
      width = isScrollY ? tableWidth - scrollbarSize() : tableWidth;
    return (
      <div className={styles.tableFooterContainer} style={{ height: footerHeight, width, bottom }}>
        <Grid
          className={styles.tableFooter}
          columnWidth={p => this.columnWidthFn(p, state)}
          columnCount={columnCount}
          height={footerHeight}
          overscanColumnCount={overscanColumnCount}
          cellRenderer={props => this._renderBodyFooterCell({ ...props, state })}
          rowHeight={rowHeight}
          rowCount={footerRowCount}
          scrollLeft={scrollLeft}
          width={width}
        />
      </div>
    );
  };

  private _headerCellSizeAndPositionGetter: HeaderCellSizeAndPositionGetter<T> = ({
    index,
    headerRows,
  }) => {
    const { headerRowHeight } = this.props,
      { width, x, rowIndex, rowSpan } = headerRows[index],
      { height = rowSpan * headerRowHeight } = headerRows[index],
      { y = rowIndex * headerRowHeight } = headerRows[index];
    return { width, height, x, y };
  };

  private _getBodyRowHeight: GetBodyRowHeight<T> = ({ index: rowIndex, state }) => {
    const { rowHeight, headerRowHeight } = this.props,
      { headerRowCount } = state;
    if (rowIndex < headerRowCount) {
      // 如果该列属于Header则返回Header行高
      // return headerRowHeight;
      return this.headerCellsMaxHeight[rowIndex] || headerRowHeight;
    }
    return rowHeight;
  };

  private _renderExpandLevel = (children: JSX.Element) => {
    const { expandLevel2Keys, expandLevel, onExpandedRowsChange } = this.props,
      _expandLevel2Keys = Object.getOwnPropertyNames(expandLevel2Keys);
    if (_expandLevel2Keys.length > 1) {
      return (
        <div className={styles.tableTreeHeaderContainer}>
          <div className={styles.tableTreeHeader}>
            {children}
            {_expandLevel2Keys.map(k => (
              <div
                key={k}
                className={classNames(
                  styles.tableTreeHeaderTag,
                  expandLevel == parseInt(k) && styles.selected,
                )}
                onClick={() => {
                  const expandedRowKeys: any[] = expandLevel2Keys[k];
                  onExpandedRowsChange(expandedRowKeys, parseInt(k));
                }}
              >
                {parseInt(k) + 1}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return children;
  };

  private _renderHeaderCell: HeaderCellRenderer<T> = props => {
    const { index, state, headerRows } = props,
      { children, style, isTree, key = props.key } = headerRows[index],
      _style: React.CSSProperties = { ...props.style, ...style },
      _children = typeof children === 'function' ? children() : children;
    // handResize = (info: Size) => this._handHeaderCellResize(headerRows[index], info);

    return (
      <div key={key} style={_style} className={classNames(styles.headerCell)}>
        {isTree ? this._renderExpandLevel(_children) : _children}
        {/* <ResizeObserver onResize={handResize}>
          <div>{_children}</div>
        </ResizeObserver> */}
      </div>
    );
  };
  private _renderCell: CellRenderer<T> = props => {
    const { columnIndex, rowIndex, key, className, state } = props,
      { components, rowKey, rowSelection = {} } = this.props,
      { type, selectedRowKeys = [], onSelect } = rowSelection,
      { dataSource } = this.state,
      { body = {} } = components,
      record = dataSource[rowIndex],
      column = state.columns[columnIndex],
      _style: React.CSSProperties = { ...props.style, ...align2style(column.align) },
      _rowKey = typeof rowKey === 'string' ? rowKey : rowKey(record as any, rowIndex),
      _className = classNames(
        className,
        rowIndex != 0 && styles.noOne,
        this.hoverRows[rowIndex] && styles.tableRowHover,
      ),
      disabled = !!record['_disabled'],
      _key = record[_rowKey] ? `${record[_rowKey]}-${key}` : key,
      _selected = (selectedRowKeys as number[]).indexOf(record[_rowKey]) >= 0;

    if (column.dataIndex != null) {
      const cellProps: ICellProps<any> = {
        style: _style,
        prefixCls: undefined,
        className: classNames(
          _className,
          column.className,
          type === 'radio' && styles.tableRowRadio,
          _selected && styles.tableRowSelected,
          disabled && styles.tableRowDisabled,
        ),
        record,
        dataIndex: column.dataIndex,
        index: rowIndex,
        render: column.render,
        component: body.cell as any,
        children: undefined,
        colSpan: column.colSpan,
        rowSpan: undefined,
        isTree: column.isTree,
        ellipsis: column.ellipsis,
        align: column.align,

        onMouseEnter: () => {
          if (!this.hoverRows[rowIndex]) {
            this.hoverRows[rowIndex] = true;
            this.setState({});
          }
        },
        onMouseLeave: () => {
          if (!!this.hoverRows[rowIndex]) {
            this.hoverRows[rowIndex] = false;
            this.setState({});
          }
        },
        onExpand: this.handleExpand,
        onClick: e => {
          if (
            typeof column.dataIndex === 'string' &&
            column.dataIndex.charAt(0) !== '_' &&
            type === 'radio' &&
            !disabled &&
            !_selected
          ) {
            if (onSelect) onSelect(record as any, !_selected, [record[_rowKey]], []);
          }
        },
      };
      cellProps.additionalProps = column.onCell && column.onCell(cellProps.record, cellProps.index);
      return <VirtualizedCell key={_key} {...cellProps} />;
    }
    return <div className={_className} key={_key} style={_style} />;
  };
  private _renderFooterCell: CellRenderer<T> = props => {
    const { columnIndex, rowIndex, key, className, state } = props,
      { summaryDataSource } = this.props,
      column = state.columns[columnIndex],
      record: any = summaryDataSource[rowIndex],
      style = { ...props.style, ...align2style(column.align) };
    if (style.height) {
      style.display = 'block';
      if (typeof style.height === 'number') {
        style.lineHeight = `${style.height}px`;
      } else {
        style.lineHeight = style.height;
      }
    }
    if (typeof column.dataIndex === 'string' || typeof column.dataIndex === 'number') {
      let children: React.ReactNode = record[column.dataIndex];
      if (columnIndex === 0) {
        children = '合计';
      } else if (column.summaryRender) {
        children = column.summaryRender(record[column.dataIndex], record, rowIndex);
      }
      return (
        <div className={className} key={key} style={style}>
          {children}
        </div>
      );
    }

    return <div className={className} key={key} style={style} />;
  };

  private _renderBodyHeaderCell: HeaderCellRenderer<T> = props => {
    return this._renderHeaderCell(props);
  };
  private _renderBodyCell: CellRenderer<T> = props => {
    const { columnIndex, rowIndex, state } = props,
      { headerRowCount, columnCount, rowCount, leftColumnCount, rightColumnCount } = state,
      { footerRowCount } = this.state;
    if (columnIndex < leftColumnCount) {
      // 如果该列属于LeftSide则不渲染
      return null;
    }

    if (columnCount - columnIndex - 1 < rightColumnCount) {
      // 如果该列属于RightSide则不渲染
      return null;
    }

    if (rowIndex < headerRowCount) {
      // 如果该列属于Header则不渲染
      return null;
    }

    if (rowCount - rowIndex - 1 < footerRowCount) {
      // 如果该列属于Footer则不渲染
      return null;
    }

    return this._renderCell({
      ...props,
      rowIndex: rowIndex - headerRowCount,
      className: classNames(styles.cell),
    });
  };
  private _renderBodyFooterCell: CellRenderer<T> = props => {
    const { columnIndex } = props,
      { columnCount, leftColumnCount, rightColumnCount } = props.state;
    if (columnIndex < leftColumnCount) {
      // 如果该列属于LeftSide则不渲染
      return null;
    }

    if (columnCount - columnIndex - 1 < rightColumnCount) {
      // 如果该列属于RightSide则不渲染
      return null;
    }

    return this._renderFooterCell({ ...props, className: classNames(styles.footerCell) });
  };

  private _renderLeftHeaderCell: HeaderCellRenderer<T> = props => {
    return this._renderHeaderCell(props);
  };
  private _renderLeftSideCell: CellRenderer<T> = props => {
    const { rowIndex } = props,
      { rowCount, headerRowCount } = props.state,
      { footerRowCount } = this.state;
    if (rowIndex < headerRowCount) {
      // 如果该列属于Header则不渲染
      return null;
    }
    if (rowCount - rowIndex - 1 < footerRowCount) {
      // 如果该列属于Footer则不渲染
      return null;
    }
    return this._renderCell({
      ...props,
      rowIndex: rowIndex - headerRowCount,
      className: classNames(styles.cell),
    });
  };
  private _renderLeftFooterCell: CellRenderer<T> = props => {
    return this._renderFooterCell({ ...props, className: classNames(styles.footerCell) });
  };

  private _renderRightHeaderCell: HeaderCellRenderer<T> = props => {
    return this._renderHeaderCell(props);
  };
  private _renderRightSideCell: CellRenderer<T> = props => {
    const { rowIndex } = props,
      { footerRowCount } = this.state,
      { rowCount, columnCount, headerRowCount, rightColumnCount } = props.state;
    if (rowIndex < headerRowCount) {
      // 如果该列属于Header则不渲染
      return null;
    }
    if (rowCount - rowIndex - 1 < footerRowCount) {
      // 如果该列属于Footer则不渲染
      return null;
    }
    return this._renderCell({
      ...props,
      rowIndex: rowIndex - headerRowCount,
      className: classNames(styles.cell),
      columnIndex: columnCount - props.columnIndex - rightColumnCount,
    });
  };
  private _renderRightFooterCell: CellRenderer<T> = props => {
    const { columnCount, rightColumnCount } = props.state;
    return this._renderFooterCell({
      ...props,
      className: classNames(styles.footerCell),
      columnIndex: columnCount - props.columnIndex - rightColumnCount,
    });
  };
}
