import TreeEdit from '.';
import Service from '@mjcloud/service';
import { sleep } from '@mjcloud/utils';
import PageHelper from '@mjcloud/page-helper';
import { EventListening } from '@mjcloud/utils';
import EditableCell from './treeedit-cell';
import ControllerHelper from '@mjcloud/controller';
import { ExceptionHelper } from '@mjcloud/exception';
import EditableComplexCell from './treeedit-complex-cell';
import TableExtendStoreBase from '../common/table/extendStore';
import { IBatchAddColumnParams } from '../common/table/typings';
import { IDictionary, DataStateEnum, PageModeEnum } from '@mjcloud/types';
import { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import {
  RowValueChangeArg,
  RowDataStateChangeArg,
  CollectionDataStateChangeArg,
  IViewModelCollection,
  IViewModelRow,
} from '@mjcloud/data-model';
import {
  ITreeEditState,
  ITreeEditInitialStateParams,
  TreeEditActionType,
  ITreeEditConfigItem,
  IActiveCellParams,
  ITreeEditLoadedParams,
  ITreeEditUpdateCellSizeParams,
  ITreeEditUpdateSelectedRowsParams,
} from './typings';

export default class TreeEditExtendStore extends TableExtendStoreBase<
  IViewModelCollection,
  IViewModelRow,
  ITreeEditState,
  TreeEdit
> {
  private isFirstLoad = false;
  private isExistDraft = false;

  private ids: string[] = [];
  private generateIdsLoading = false;
  private async generateIds() {
    this.generateIdsLoading = true;
    const ids = await Service.requestService<string[]>('/createid/newids', { quantity: 100 });

    if (ids) {
      this.ids.push(...ids);
    }
    this.generateIdsLoading = false;
  }

  private getGenerateId() {
    // TODO: 非异步如果一次性添加超过50次会有问题
    const id = this.ids.shift();
    if (this.ids.length <= 50 && !this.generateIdsLoading) {
      this.generateIds();
    }
    return id;
  }

  private initDataModel(dataModel: IViewModelCollection) {
    dataModel.bind('rowValueChange', this.handDataModelRowValueChange.bind(this));
    dataModel.bind('rowDataStateChange', this.handDataModelRowDataStateChangeEvent.bind(this));
    dataModel.bind('dataStateChange', this.handDataModelDataStateChangeEvent.bind(this));
  }

  protected __createCell(item: ITreeEditConfigItem) {
    const controlName = item && item.control && item.control.nodeName;
    if (controlName === 'switch') {
      return new EditableComplexCell(item.id, this.instance, item);
    }
    return new EditableCell(item.id, this.instance, item);
  }

  handleInitialStateBefore(e: ActionBeforeEventArg<ITreeEditInitialStateParams>) {
    super.handleInitialStateBefore(e);
    const key = this.instance.id,
      pageModel = this.instance.page.dataModel;
    this.isExistDraft = !!pageModel[key];
    if (pageModel[key]) e.params.dataModel = pageModel[key];
  }

  async handleInitialStateAfter(
    e: ActionAfterEventArg<ITreeEditState, TreeEditActionType, ITreeEditInitialStateParams>,
  ) {
    super.handleInitialStateAfter(e);
    const { autoGenerateId, dataSource } = e.newState.state;
    if (autoGenerateId) await this.generateIds();
    this.initDataModel(dataSource);
  }

  async handleStartLoadAfter(e: ActionAfterEventArg<any, any, any>) {
    const key = this.instance.id,
      pageModel = this.instance.page.dataModel;
    if (this.isExistDraft) {
      if (!this.isFirstLoad) {
        this.instance.__loadStart();
        this.isFirstLoad = true;
        await sleep(66);
        e.newState.dispatch('loaded', {
          dataModel: pageModel[key],
        });
        this.instance.__loadComplete();
      } else {
        await super.handleStartLoadAfter(e);
      }
    } else {
      if (!pageModel[key]) pageModel[key] = this.instance.dataModel;
      await super.handleStartLoadAfter(e);
    }
  }

  handleLoadedBefore(e: ActionBeforeEventArg<ITreeEditLoadedParams>) {
    super.handleLoadedBefore(e);
    const { pageMode } = this.instance.page.address;
    e.params.dataState =
      pageMode === PageModeEnum.add ? DataStateEnum.added : DataStateEnum.unchanged;

    const { disabledControl } = this.instance.store.state;
    if (disabledControl) {
      e.params.disabledFn = row => {
        const fn = PageHelper.createPageScriptFunction(this.instance.page, disabledControl, true),
          _e = EventListening.getHandlerArg(this.instance, { row });
        return fn(_e);
      };
    }
  }

  handleBatchAddColumnAfter(
    e: ActionAfterEventArg<
      ITreeEditState,
      TreeEditActionType,
      IBatchAddColumnParams<IViewModelRow>
    >,
  ) {
    const { oldAggs = [], aggs = [] } = e.newState.state;
    oldAggs.map(agg => e.newState.state.dataSource.removeAggProperty(agg.name));
    aggs.map(agg =>
      e.newState.state.dataSource.addAggProperties([
        { ...agg, propertyName: agg.name, aggType: 1 },
      ]),
    );
  }

  async handleAddClickAfterAfter(e: ActionAfterEventArg<ITreeEditState, TreeEditActionType>) {
    const { autoGenerateId, addRowButton } = e.newState.state,
      { tip, title, controllerId } = addRowButton,
      isAddBeforeClick = this.instance.eventManager.getHandlerCount('onAddBeforeClick') > 0,
      isAddAfterClick = this.instance.eventManager.getHandlerCount('onAddAfterClick') > 0;
    if (!controllerId && !isAddBeforeClick && !isAddAfterClick) {
      const addRow: IDictionary = { _isBtnAdd: true };
      if (autoGenerateId) addRow.id = this.getGenerateId();
      e.newState.dispatch('batchAddRow', {
        rows: [addRow],
        isBtnClick: true,
      });
      return;
    }
    const exclusiveLockId = this.instance.page.applyExclusiveLock(tip || `正在执行${title}操作...`);
    if (exclusiveLockId === 0) {
      e.newState.dispatch('addClickDone', {});
      return;
    }
    let params: IDictionary = { ...e.params },
      before = async (data: IDictionary, extra: IDictionary = {}) => {
        extra._before = () => e.newState.dispatch('updateAddBtnLoad', { loading: true });
        extra._after = () => e.newState.dispatch('updateAddBtnLoad', { loading: false });
        if (isAddBeforeClick) {
          console.warn('按钮的onBeforeClick事件即将废弃');
          await this.instance.eventManager.trigger('onAddBeforeClick', data, extra);
        }
        return data;
      },
      after = async (data: IDictionary, extra: IDictionary = {}) => {
        if (isAddAfterClick)
          await this.instance.eventManager.trigger('onAddAfterClick', data, extra);
        return data;
      };
    try {
      if (controllerId) {
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId,
          data: params,
          before,
          after,
        });
      } else {
        await before(params);
        await after(params);
      }

      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      e.newState.dispatch('addClickDone', {});
    } catch (error) {
      e.newState.dispatch('addClickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      ExceptionHelper.dispose(error);
    }
  }

  handleUpdateCellSizeAfter(
    e: ActionAfterEventArg<ITreeEditState, TreeEditActionType, ITreeEditUpdateCellSizeParams>,
  ) {
    const { rowId, size, isUpdate } = e.params;
    if (isUpdate) this.instance.__updateCellSize(rowId, size);
  }

  handleBatchAddRowBefore(e) {
    const { disabledControl } = this.instance.store.state;
    if (disabledControl) {
      e.params.disabledFn = row => {
        const fn = PageHelper.createPageScriptFunction(this.instance.page, disabledControl, true),
          _e = EventListening.getHandlerArg(this.instance, { row });
        return fn(_e);
      };
    }
  }

  private rids2Rows(data: any[], _selectedRows: IDictionary<boolean>) {
    let selectedRows: any[] = [];
    data.forEach(row => {
      if (_selectedRows[row._rid]) {
        selectedRows.push(row);
      }
      if (row && row._children && row._children.length > 0) {
        selectedRows = selectedRows.concat(this.rids2Rows(row._children, _selectedRows));
      }
    });
    return selectedRows;
  }

  handleUpdateSelectedRowsAfter(
    e: ActionAfterEventArg<ITreeEditState, TreeEditActionType, ITreeEditUpdateSelectedRowsParams>,
  ) {
    const { selectedRows = [], halfSelectedRows = [], dataSource } = e.newState.state,
      { row, checked, actionSourceSign } = e.params;
    if (actionSourceSign === this.instance) return;
    if (row) {
      const eventManager = this.instance.eventManager;
      const _selectedRows: IDictionary<boolean> = {};
      selectedRows.forEach(row => {
        _selectedRows[row._rid] = true;
      });

      if (eventManager.getHandlerCount('selectedChange') > 0) {
        eventManager.trigger('selectedChange', {
          checked,
          row: dataSource[row._rid],
          halfSelectedRows,
          selectedRows: this.rids2Rows(dataSource.toArray(), _selectedRows),
        });
      }
    } else {
      const eventManager = this.instance.eventManager;
      const _selectedRows: IDictionary<boolean> = {};
      selectedRows.forEach(row => {
        _selectedRows[row._rid] = true;
      });
      const selectRows = this.rids2Rows(dataSource.toArray(), _selectedRows);
      if (eventManager.getHandlerCount('selectedChange') > 0) {
        for (const row of selectRows) {
          eventManager.trigger('selectedChange', {
            checked: true,
            row,
            selectedRows: selectRows,
            halfSelectedRows,
          });
        }
      }
    }
  }

  handleLoadedAfter(e: ActionAfterEventArg<ITreeEditState, TreeEditActionType>) {
    super.handleLoadedAfter(e);
    const { dataSource } = e.newState.state;
    const rids = dataSource
      .toArray()
      .map(r => r._rid)
      .filter(r => !!r);
    for (const rid of rids) {
      this.instance.__createRow2cells(rid);
    }
    // const dataSource = e.newState.state.dataSource.toArray(true);
    // for (const key in dataSource) {
    //   const row = dataSource[key];
    //   for (const k in row.toJSON()) {
    //     const cell = this.instance.findCell(k);
    //     if (cell) {
    //       cell.setCellValue(row, true);
    //     }
    //   }
    // }
  }

  handleActiveCellBefore(e: ActionBeforeEventArg<IActiveCellParams>) {
    const { cellId, rowId } = e.params;
    this.instance.unActiveCell();
    const cell = this.instance.findCell(cellId);
    if (cell instanceof EditableCell) {
      cell.active(rowId);
    }
  }

  handleUpdateModifyModeAfter(e: ActionAfterEventArg<ITreeEditState, TreeEditActionType, any>) {
    if (e.params.modify) {
      // 编辑模式
    } else {
      // 只读模式
    }
  }

  private handDataModelRowValueChange(e: RowValueChangeArg) {
    if (e.eventSourceSign === this.instance) return;
    for (const key in e.data.values) {
      const value = e.data.values[key];
      if (value !== undefined) {
        const cell = this.instance.findCell(key);
        if (cell) {
          cell.setCellValue(e.data.row);
        }
      }
    }
    const eventManager = this.instance.eventManager;
    if (eventManager.getHandlerCount('rowChange') > 0) {
      eventManager.trigger('rowChange', { ...e.data });
    }
  }

  private handDataModelRowDataStateChangeEvent(e: RowDataStateChangeArg) {
    if (e.eventSourceSign === this) return;
    let isUpdateDataModel = false;
    switch (e.data.dataState) {
      case DataStateEnum.added:
        if (!e.data.row.id) {
          e.data.row.id = this.getGenerateId();
        }
        this.instance.__createRow2cells(e.data.row._rid);
        isUpdateDataModel = true;
        break;
      case DataStateEnum.deleted:
        this.instance.__deleteRow2cells(e.data.row._rid);
        isUpdateDataModel = true;
        break;
      case DataStateEnum.unchanged:
        return;
    }
    // if (e.eventSourceSign !== this.instance.store && isUpdateDataModel) {
    //   // TODO: 判断条件不全，会导致死循环
    //   this.instance.store.dispatch('updateDataModel', {});
    // }
    const eventManager = this.instance.eventManager;
    if (eventManager.getHandlerCount('rowChange') > 0) {
      eventManager.trigger('rowChange', { ...e.data });
    }
  }

  private handDataModelDataStateChangeEvent(e: CollectionDataStateChangeArg) {
    if (e.data.isChange) {
      this.instance.page.controlDataUpdated(this.instance.id);
    }
  }
}
