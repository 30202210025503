import FormInstanceBase from '.';
import { ContainerDataExtendStoreBase } from '@mjcloud/extend-store';

abstract class FormExtendStoreBase<
  TInstance extends FormInstanceBase = FormInstanceBase
> extends ContainerDataExtendStoreBase<TInstance> {
  protected isNeedNotificationPage = true;
}

export default FormExtendStoreBase;
