import React from 'react';
import styled, { StyledComponent } from 'styled-components';
import { Card as AntCard } from 'antd';
import { CardProps } from 'antd/lib/card';
import { SPACING } from './constant';

interface ICardProps extends CardProps {
  isScroll?: boolean;
}

interface ISiderProps {
  width?: number;
}

const Layout = styled.div`
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Content = styled(AntCard)<ICardProps>`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: ${SPACING}px;
  overflow-x: hidden;
  overflow-y: ${({ isScroll = true }) => (isScroll ? 'auto' : 'hidden')};
  /* background: #f0f2f5; */
  background: transparent;

  > .ant-card-body {
    width: 100%;
    min-height: 100%;
    padding: ${SPACING * 2}px;
    background: #fff;
  }
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: ${SPACING * 2}px;
`;

const Sider = styled.div<ISiderProps>(({ width = 200 }) => ({
  width,
  height: '100%',
  marginRight: SPACING,
}));

const Footer = styled.div`
  width: 100%;
  background-color: #fff;
  border-top: 1px solid rgba(0, 21, 41, 0.08);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UpDownLayout: React.FC & {
  Header: StyledComponent<'div', any, {}, any>;
  Content: StyledComponent<typeof AntCard, any, ICardProps, any>;
  Sider: StyledComponent<'div', any, ISiderProps, any>;
  Footer: StyledComponent<'div', any, {}, any>;
} = Layout as any;
UpDownLayout.Header = Header;
UpDownLayout.Sider = Sider;
UpDownLayout.Content = Content;
UpDownLayout.Footer = Footer;

export default UpDownLayout;
