import { IFormConfig } from './typings';

const config: IFormConfig = {
  revise: undefined,
  data: {},
  sections: { items: [] },
  displayMode: undefined,
};

export default config;
