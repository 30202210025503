import Reduce from './reduce';
import Store from '@mjcloud/redux';
import TreeListExtendStore from './extendStore';
import { DataInstanceBase } from '@mjcloud/instance';
import { IDictionary, DataModeEnum } from '@mjcloud/types';
import {
  ITreeListConfig,
  ITreeListState,
  TreeListEventType,
  TreeListActionType,
  TreeListUpdateSelectedKeysParams,
} from './typings';

class TreeList extends DataInstanceBase<ITreeListState, TreeListEventType, TreeListActionType> {
  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  __createStore() {
    return new Store<ITreeListState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new TreeListExtendStore(this),
    });
  }

  initialState(initConfig: ITreeListConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  /**
   * 获取当前选中的行数据
   */
  getSelectedRows(): IDictionary[] {
    return this.store.state.selectedRows;
  }

  /**
   * 获取当前选中的行Id
   */
  getSelectedIds(idFieldName = 'id'): string[] {
    return this.store.state.selectedRows.map(({ [idFieldName]: id }) => id);
  }

  getAllData() {
    return this.store.state.dataSource;
  }

  /**
   * 设置要选中的行
   */
  setSelectedRows(rows: IDictionary[] = [], actionSourceSign?: any) {
    this.store.dispatch<TreeListUpdateSelectedKeysParams>('updateSelectedKeys', {
      selectedKeys: rows.map(({ _rid }) => `${_rid}`),
      selectedRows: rows,
      actionSourceSign,
    });
  }

  private ids2Rows(dataSource: IDictionary[], ids: string[], idFieldName = 'id') {
    const rows: IDictionary[] = [];
    for (const row of dataSource) {
      if (row._children && row._children.length > 0) {
        rows.push(...this.ids2Rows(row._children, ids, idFieldName));
      }
      for (const id of ids) {
        if (id === row[idFieldName]) {
          rows.push(row);
        }
      }
    }
    return rows;
  }

  setSelectedRows2Id(ids: string[], idFieldName = 'id', actionSourceSign?: any) {
    const selectedRows: IDictionary[] = this.ids2Rows(
      this.store.state.dataSource,
      ids,
      idFieldName,
    ) as IDictionary[];
    this.store.dispatch<TreeListUpdateSelectedKeysParams>('updateSelectedKeys', {
      selectedKeys: selectedRows.map(({ _rid }) => `${_rid}`),
      selectedRows,
      actionSourceSign,
    });
  }

  rowIsExistSubset(row: any) {
    const { _children = [] } = row;
    if (_children instanceof Array && _children.length > 0) {
      return true;
    }
    return false;
  }
}

export default TreeList;
