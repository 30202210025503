import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { ValueInstanceBase } from '@mjcloud/instance';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import {
  ITextAreaState,
  ITextAreaConfig,
  TextAreaEventType,
  ITextAreaConfigStartLoadParams,
  TextAreaActionType,
} from './typings';

class TextAreaExtendStore extends ValueExtendStoreBase<string, TextArea> {}

class TextArea extends ValueInstanceBase<
  string,
  ITextAreaState,
  TextAreaEventType,
  TextAreaActionType
> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store<ITextAreaState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new TextAreaExtendStore(this),
    });
  }

  initialState(initConfig: ITextAreaConfig) {
    this.store.dispatch<ITextAreaConfigStartLoadParams>('configStartLoad', {
      initConfig,
    });
  }
}

export default TextArea;
