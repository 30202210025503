import React from 'react';
import ControlBase from '../../core/base';
import globalData from '@mjcloud/global-data';
import { IToolbarState } from '@mjcloud/mcontrols/dist/controls/toolbar/typings';

import styles from './index.less';

export default class ToolbarControl extends ControlBase<IToolbarState> {
  renderContent() {
    const { items, cols } = this.state;
    const { ControlFactory } = globalData;
    return (
      <div className={styles.container}>
        {items
          .filter(r => r.display)
          .map(({ display, colspan, ...props }) => {
            return (
              <div
                key={props.controlId}
                className={styles.item}
                style={{ width: `${(100 / cols) * colspan}%` }}
              >
                <ControlFactory {...props} />
              </div>
            );
          })}
      </div>
    );
  }
}
