import TableInstanceBase from '.';
import TableCellBase from './cell';
import JsApiHelper from '@mjcloud/jsapi';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import { EventListening } from '@mjcloud/utils';
import ControllerHelper from '@mjcloud/controller';
import { ExceptionHelper } from '@mjcloud/exception';
import OperationContent from './operationContent';
import { ISigninAgg } from '@mjcloud/data-source-helper';
import { PageModeEnum, InvoiceExecStatusEnum } from '@mjcloud/types';
import { ContainerDataExtendStoreBase } from '@mjcloud/extend-store';
import { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import { IOperationContentProps } from './operationContent/typings';
import { InstanceBase, ContainerInstanceBase } from '@mjcloud/instance';
import {
  ITableBaseState,
  TableBaseActionType,
  ITableBaseConfigItem,
  ITableBaseConfigItemSet,
  ITableBaseUpdateOperationWidthParams,
  IAddColumnsBeforeParams,
  IAddColumnsBeforeResult,
  IBatchAddColumnParams,
} from './typings';
import Service from '@mjcloud/service';

export default abstract class TableExtendStoreBase<
  TDataSource,
  TItem,
  S extends ITableBaseState<TDataSource, TItem>,
  TInstance extends TableInstanceBase<TDataSource, TItem, S>
  > extends ContainerDataExtendStoreBase<TInstance> {
  protected isNeedNotificationPage = true;

  protected abstract __createCell(item: ITableBaseConfigItemSet): TableCellBase<any, any>;

  private createEditableCell(config: any) {
    let aggs: ISigninAgg[] = [];
    if (config.items) {
      config.items.forEach(item => {
        if (item.nodeName === 'item') {
          this.instance.__registerTableCell(item.id, this.__createCell(item));
          if (item.control && item.control.summary) {
            aggs.push({ ...item.control.summary, name: item.id, aggType: 'Sum' });
          }
        } else if (item.nodeName === 'parent') {
          aggs = [...aggs, ...this.createEditableCell(item)];
        }
      });
    }
    return aggs;
  }

  private addColumnsBefore(params: IAddColumnsBeforeParams): IAddColumnsBeforeResult {
    const { items, buttons } = params;

    if (buttons && buttons.items.length) {
      const { workflow } = this.instance.store.state.config;
      this.instance.__registerTableOperationBefore(workflow, buttons.items);
    }

    let aggs: ISigninAgg[] = [];
    items.map(item => {
      if (item.nodeName === 'item') {
        const _item: ITableBaseConfigItem = item as any;
        this.instance.__registerTableCell(item.id, this.__createCell(item));
        if (_item.control && _item.control.summary) {
          aggs.push({ ..._item.control.summary, name: item.id, aggType: 'Sum' });
        }
      } else if (item.nodeName === 'parent') {
        aggs = [...aggs, ...this.createEditableCell(item)];
      } else {
        console.error('table 下的 items 只能具备 item和parent 节点');
      }
    });

    return {
      pageMode: this.instance.page.pageMode,
      aggs: aggs.length > 0 ? aggs : undefined,
      onCell: (record, { key, isTree, colorCommand, colSpan, width }) => {
        const cell = this.instance.findCell(key as string);
        const { modify } = this.instance.store.state;
        const store = cell && cell.createStore(record._rid);
        const cellId = key as string;
        return {
          width,
          store,
          record,
          cellId,
          isTree,
          colSpan,
          id: cellId,
          rowId: record._rid,
          parentModify: modify,
          cellType: cell.cellType,
          parentId: this.instance.id,
          pageKey: this.instance.page.id,
          colorCommandFn: !colorCommand
            ? undefined
            : async (rowId, row) => {
              const fn = PageHelper.createPageScriptFunction(
                this.instance.page,
                colorCommand,
                true,
              );
              return fn(EventListening.getHandlerArg(this.instance, { rowId, row }));
            },
        };
      },
      onOperation: (record, collapse, { key }) => {
        const operation = this.instance.operation as OperationContent;
        const store = operation && operation.createStore(record._rid);
        const props: IOperationContentProps = {
          store,
          record,
          collapse,
          rowId: record._rid,
          cellId: key as string,
          id: `${key}-${record._rid}`,
        };
        return props;
      },
      refs: (key, instance) => {
        if (instance) {
          this.instance.__registerTableCellTitle(key, instance);
        }
      },
    };
  }

  handleInitialStateBefore(e: ActionBeforeEventArg<any>) {
    const { initConfig } = e.params;
    const { items, buttons } = initConfig;

    const { aggs, refs, onCell, onOperation } = this.addColumnsBefore({
      items: items.items,
      buttons,
    });
    e.params.aggs = aggs;
    e.params.refs = refs;
    e.params.onCell = onCell;
    e.params.onOperation = onOperation;
  }

  handleStartLoadBefore(e: ActionBeforeEventArg<any>) {
    const { revise } = this.instance.store.state;
    if (revise && !!revise.enable) e.params.revise = true;
  }

  handleLoadedBefore(e: ActionBeforeEventArg) {
    this.instance.___cleanButtons();
    this.instance.___cleanSpecialCells();
  }

  handleBatchAddColumnBefore(e: ActionBeforeEventArg<IBatchAddColumnParams>) {
    const { buttons } = this.instance.store.state.config,
      { aggs, pageMode, refs, onCell, onOperation } = this.addColumnsBefore({
        items: e.params.columns.map(item => item.config),
        buttons,
      });
    e.params.aggs = aggs;
    e.params.pageMode = pageMode;
    e.params.refs = refs;
    e.params.onCell = onCell;
    e.params.onOperation = onOperation;
  }

  handleUpdateOperationWidthBefore(e: ActionBeforeEventArg<ITableBaseUpdateOperationWidthParams>) {
    if (this.instance.operation) {
      e.params.operations = this.instance.operation.stores;
    }
  }

  handleUpdateOperationCollapseAfter(
    e: ActionAfterEventArg<ITableBaseState<TDataSource, TItem>, TableBaseActionType>,
  ) {
    e.newState.dispatch('updateOperationWidth', {});
  }

  handleReviseClickBefore(e: ActionBeforeEventArg<any>) {
    const { revise } = this.instance.store.state;
    if (revise) {
      const { controllerId, code } = revise,
        { isLock, isAuthority } = e.params,
        authority = code ? ControllerHelper.hasRights(this.instance.page.address, code) : true;
      if (!isAuthority || !controllerId || !authority) {
        JsApiHelper.showToast({
          content: `你无当前行数据的${isLock ? '解锁' : '锁定'}权限`,
          type: 'exception',
        });
        e.params.notAuthority = true;
        return;
      }
    }
  }

  async handleReviseClickAfter(
    e: ActionAfterEventArg<ITableBaseState<TDataSource, TItem>, TableBaseActionType, any>,
  ) {
    const { revise } = e.newState.state,
      reviseControllerId = revise && revise.controllerId,
      { record, isLock, isApprove, notAuthority } = e.params;
    if (notAuthority) return;

    const exclusiveLockId = this.instance.page.applyExclusiveLock(
      `正在执行${isLock ? '解锁' : '锁定'}操作...`,
    );
    if (exclusiveLockId === 0) return;
    if (isLock) {
      await this.unlockReceipt(record, isApprove, reviseControllerId);
    } else {
      await this.lockReceipt(record, reviseControllerId);
    }

    this.instance.page.releaseExclusiveLock(exclusiveLockId);
  }

  async unlockReceipt(record: any, isApprove: boolean, reviseControllerId?: string) {
    const result = await this.instance.page.popupModal({
      pageInfo: {
        title: '解锁单据',
        pageMode: PageModeEnum.none,
        appId: globalData.appId,
        moduleId: 'Revise',
        pageId: 'Main',
        width: 560,
        height: 300,
      },
      params: {
        isApprove,
        bordered: false,
        parentAddress: this.instance.page.address,
      },
    });
    if (reviseControllerId && result.oper === 'ok') {
      try {
        const { type: reviseType, userId } = result.data;
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId: reviseControllerId,
          data: { row: record, revise: result.data },
          before: async data => {
            data.request.userId = userId;
            data.request.reviseType = reviseType;
            return data;
          },
        });
        this.instance.refresh({ location: record.id });
      } catch (error) {
        ExceptionHelper.dispose(error);
        this.instance.store.dispatch('reviseError', {});
      }
    }
  }

  async lockReceipt(record: any, reviseControllerId?: string) {
    if (reviseControllerId) {
      try {
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId: reviseControllerId,
          data: { row: record },
          before: async data => {
            data.request.reviseId = record._reviseId;
            data.request.userId = record._userId;
            data.request.reviseType = InvoiceExecStatusEnum.lock;
            return data;
          },
        });
        this.instance.refresh({ location: record.id });
      } catch (error) {
        ExceptionHelper.dispose(error);
        this.instance.store.dispatch('reviseError', {});
      }
    }
  }
}
