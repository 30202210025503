import GridEdit from '..';
import Store from '@mjcloud/redux';
import GridEditCellReduce from './reduce';
import { AsyncValidator, EventListening } from '@mjcloud/utils';
import EditableCellBase from '../gridedit-cell-base';
import { IGridEditConfigItem } from '../typings';
import { IDictionary, RequiredTypeEnum } from '@mjcloud/types';
import { ValueInstanceBase, InstanceBase } from '@mjcloud/instance';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import { IEditableCellState, GridEditCellActionType } from './typings';
import { IDataControlStartLoadParams } from '@mjcloud/instance/dist/dataInstanceBase';
import {
  IValueState,
  ValueControlActionType,
  IValueControlUpdateValueParams,
} from '@mjcloud/instance/dist/valueInstanceBase';
import PageHelper from '@mjcloud/page-helper';

class EditableCellItemExtendStore {
  constructor(private instance: EditableCell, private controlInstance: InstanceBase) {}

  handleUpdateValueBefore(e: ActionBeforeEventArg<IValueControlUpdateValueParams>) {
    const rowId = this.instance['_activeRowId'];
    if (rowId) {
      e.params.row = this.instance.parent.dataModel[rowId];
    }
  }

  handleUpdateValueAfter(
    e: ActionAfterEventArg<
      IValueState,
      ValueControlActionType,
      IValueControlUpdateValueParams<any>
    >,
  ) {
    const rowId = this.instance['_activeRowId'];
    if (rowId) {
      const { value, text } = e.newState.state as any;
      const field = this.instance.id;
      let updateData: IDictionary = { [field]: value };
      const textFieldName: string | undefined = e.newState.state['textFieldName'];
      // _isObject 是针对区间控件做的多值处理
      if (value && typeof value === 'object' && value._isObject) {
        const { _isObject, ...val } = value;
        updateData = { ...updateData, ...val };
      }
      if (textFieldName) {
        updateData[textFieldName] = text;
      }
      this.instance.stores[rowId].dispatch('updateValue', { rowId, value, text });
      this.instance.parent.dataModel[rowId].update(updateData, this.instance.parent);
      this.instance.valid(rowId, value);
    } else {
      // console.error('EditableCellItemExtendStore:', 'rowId is null');
    }
  }

  handleStartLoadBefore(e: ActionBeforeEventArg<IDataControlStartLoadParams>) {
    const rowId = this.instance['_activeRowId'];
    if (rowId) {
      const row = this.instance.parent.dataModel[rowId];
      e.params.data = { rowId, row };
    }
  }

  handleButtonClickAfterBefore(e: ActionBeforeEventArg<any>) {
    const rowId = this.instance['_activeRowId'];
    if (rowId) {
      const row = this.instance.parent.dataModel[rowId];
      e.params = { rowId, row };
    }
  }
}

export default class EditableCell extends EditableCellBase<
  IEditableCellState,
  GridEditCellActionType
> {
  controlInstance: InstanceBase | null = null;
  private _activeRowId: number | null = null;

  constructor(id: string, parent: GridEdit, protected config: IGridEditConfigItem) {
    super(id, parent, config);
    this.controlInstance = parent.__createControlByItem(config);
    if (this.controlInstance) {
      this.controlInstance.store.bindExtendStore(
        new EditableCellItemExtendStore(this, this.controlInstance),
      );
    }
  }

  getControlInstance(rowId: number): InstanceBase | null {
    return this.controlInstance;
  }

  getCellActiveStatus(store: Store<IEditableCellState, GridEditCellActionType>) {
    const { active } = store.state;
    return active;
  }

  protected __createStore(rowId: number) {
    const row = this.parent.dataModel[rowId];
    return new Store<IEditableCellState, GridEditCellActionType>({
      id: `cell-${this.id}`,
      reduce: GridEditCellReduce,
      extendStore: {
        handleInitialStateBefore: (e: ActionBeforeEventArg<any>) => {
          e.params.dataState = row._dataState;
        },
        handleInitialStateAfter: this.handleInitialStateAfter.bind(this),
        handleUpdateColorAfter: this.handleUpdateColorAfter.bind(this),
        // handleActiveAfter: this.handleActiveAfter.bind(this),
        // handleValidErrorAfter: this.handleValidErrorAfter.bind(this),
        // handleVisibleTooltipAfter: this.handleVisibleTooltipAfter.bind(this),
      },
    });
  }

  protected __createCommStore() {
    const _store = new Store<IEditableCellState, GridEditCellActionType>({
      id: `cell-${this.id}`,
      reduce: GridEditCellReduce,
      extendStore: {
        handleUpdateReadonlyAfter: (
          e: ActionAfterEventArg<IEditableCellState, GridEditCellActionType, { readonly: boolean }>,
        ) => {
          for (const key in this.stores) {
            const store = this.stores[key],
              { readonly } = e.params;
            store.dispatch('updateReadonly', {
              readonly,
            });
          }
        },
      },
    });
    return _store;
  }

  // private handleActiveAfter(e: ActionAfterEventArg<IEditableCellState, GridEditCellActionType>) {}

  // private handleValidErrorAfter(
  //   e: ActionAfterEventArg<IEditableCellState, GridEditCellActionType>,
  // ) {
  //   e.newState.dispatch('visibleTooltip', { visibleTooltip: true });
  // }

  // private handleVisibleTooltipAfter(
  //   e: ActionAfterEventArg<IEditableCellState, GridEditCellActionType, { visibleTooltip: boolean }>,
  // ) {
  //   const { visibleTooltip } = e.params;
  //   if (visibleTooltip) {
  //     setTimeout(() => {
  //       e.newState.dispatch('visibleTooltip', { visibleTooltip: false });
  //     }, 1000);
  //   }
  // }

  private async handleInitialStateAfter(e: ActionAfterEventArg<IEditableCellState>) {
    if (!this.validator) {
      const { title, requiredType, type, max, min, len } = e.newState.state,
        { message, whitespace, enum: _enum, pattern } = e.newState.state,
        required = requiredType === RequiredTypeEnum.required,
        _type: ValidationRuleType | undefined = type
          ? type
          : this.controlInstance instanceof ValueInstanceBase
          ? this.controlInstance.valueType
          : 'string';
      this.validator = new AsyncValidator(
        title,
        { type: _type, required, max, min, len, whitespace, enum: _enum, pattern, message },
        this.controlInstance ? this.controlInstance.eventManager : undefined,
      );
    }
    const { modifyControl } = this.config,
      { rowId } = e.newState.state,
      row = this.parent.dataModel[rowId],
      { readonly, isAddReadonly } = this.store.state;
    if (!isAddReadonly && !row._isBtnAdd) {
      e.newState.dispatch('updateReadonly', {
        readonly,
      });
    }
    if (modifyControl && row) {
      // TODO: 修复因QueueCallback去掉异步所引起的Bug
      row.bind('valueChange', async () => {
        const fn = PageHelper.createPageScriptFunction(this.parent.page, modifyControl, true);
        const readonly = await fn(
          EventListening.getHandlerArg(this.parent, { rowId, row: this.parent.dataModel[rowId] }),
        );
        e.newState.dispatch('updateReadonly', {
          readonly: !readonly,
        });
      });
      const fn = PageHelper.createPageScriptFunction(this.parent.page, modifyControl, true);
      const readonly = await fn(EventListening.getHandlerArg(this.parent, { rowId, row }));
      e.newState.dispatch('updateReadonly', {
        readonly: !readonly,
      });
    }
  }

  active(activeRowId: number) {
    this._activeRowId = activeRowId;
    const activeStore = this.stores[activeRowId];
    if (activeStore) {
      activeStore.dispatch('active', {});
      if (this.controlInstance) {
        const { value, text } = activeStore.state;
        this.controlInstance.store.dispatch('updateValue', {
          value,
          text,
          noTriggerEvent: true,
        });
      }
    } else {
      console.error('gridedit', '当前要激活的单元格不存在');
    }
  }

  unActive() {
    const rowId = this._activeRowId;
    if (rowId) {
      this._activeRowId = null;
      if (this.stores[rowId]) {
        this.stores[rowId].dispatch('unActive', {});
      }
    }
  }
}
