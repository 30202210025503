/*
 * @title: 核心库声明文件主入口
 * @description: 公开可使用的类声明和枚举、控件事件接口
 * @author: smk17
 * @LastEditors: Do not edit
 * @Date: 2019-04-12 22:55:12
 * @LastEditTime: 2019-11-15 17:45:05
 */

import React from 'react';
import Page from '@mjcloud/page';
import { Result } from 'antd';
import Prompt from 'umi/prompt';
import { ControlBase } from './controls';
import globalData from '@mjcloud/global-data';
import { PageStatusEnum } from '@mjcloud/types';

export { Tree } from './components';
export { SPACING } from './layout/constant';
export { PADDING } from './pages/app/constant';
import { IPageState } from '@mjcloud/page/dist/typings';
export { default as styled } from 'styled-components';
export { Flex, FlexItem, Card, Container, px2rem } from './layout';
export { LeftMiddleLayout, LeftRightLayout, MainLayout, UpDownLayout } from './layout';

export { IControlBaseProps } from './controls/base';
// let isFirstScreen = 0;

declare global {
  function addResizeListener(el: Element, resizeCallback: () => void): void;
  function removeResizeListener(el: Element, resizeCallback: () => void): void;
}

export abstract class PageControl<S extends IPageState = IPageState> extends ControlBase<S, Page> {
  actualResizeHandler?: () => void;
  private resizeTimeout: any = null;
  /**
   * 记录每次执行renderContent，在renderContent中调用renderControl的次数
   */
  private controlCount = 0;

  UNSAFE_componentWillMount() {
    if (this.actualResizeHandler) {
      window.addEventListener(
        'orientationchange' in window ? 'orientationchange' : 'resize',
        this.resizeThrottler,
        false,
      );
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.actualResizeHandler) {
      window.removeEventListener(
        'orientationchange' in window ? 'orientationchange' : 'resize',
        this.resizeThrottler,
        false,
      );
    }
  }

  resizeThrottler = () => {
    if (this.actualResizeHandler) {
      // ignore resize events as long as an actualResizeHandler execution is in the queue
      if (!this.resizeTimeout) {
        this.resizeTimeout = setTimeout(() => {
          if (this.actualResizeHandler) {
            this.resizeTimeout = null;
            this.actualResizeHandler();
          }
          // The actualResizeHandler will execute at a rate of 15fps
        }, 66);
      }
    }
  };

  public renderLoad(): React.ReactElement | null {
    const { Load = React.Fragment } = globalData;
    // const  children = isFirstScreen < 3 ? <div>"加载中"</div> : <Load />;
    // isFirstScreen++;
    return <Load />;
  }

  /**
   * 定义模板页要使用的控件
   * @description: 主要用在 renderContent 上
   * @param controlId
   * @return: ReactElement | null
   */
  public renderControl(controlId: string): React.ReactElement | null;
  public renderControl(controlId: string) {
    this.controlCount++;
    let instance: any = this.instance.findControl(controlId);
    if (!instance) instance = this.instance.createControl(controlId);
    if (instance) {
      const props = {
          controlId,
          id: controlId,
          pageKey: this.instance.id,
          parentId: this.instance.id,
        },
        { ControlFactory } = globalData;
      return <ControlFactory {...props} />;
    }

    return null;
  }

  private getContent() {
    this.controlCount = 0;
    const { status, modalList = [] } = this.instance.store.state,
      isDataUpdated = this.instance.__isDataUpdated(),
      content = this.renderContent();
    if (this.controlCount <= 0 && status === PageStatusEnum.initing) {
      this.instance.store.dispatch('statusUpdated', {
        newStatus: PageStatusEnum.inited,
      });
    }
    return (
      <>
        {content}
        {modalList.length > 0 &&
          modalList.map(modalId => (
            <React.Fragment key={modalId}>{this.renderControl(modalId)}</React.Fragment>
          ))}
        {isDataUpdated && <Prompt when={isDataUpdated} message={() => this.instance.__prompt()} />}
      </>
    );
  }

  private __renderContent() {
    const { configErrorMessage, status } = this.state;
    if (configErrorMessage) return configErrorMessage;
    const content = this.getContent();
    return status >= PageStatusEnum.inited ? content : this.renderLoad();
  }

  public render(): React.ReactNode {
    // return this.renderLoad();
    if (this.state.configIsFetching) return this.renderLoad();
    if (!this.state.display) return null;
    if (this.state.configErrorMessage)
      return <Result status="500" subTitle={this.state.configErrorMessage} />;
    return this.__renderContent();
  }
}
