import GridEdit from '.';
import Service from '@mjcloud/service';
import { sleep } from '@mjcloud/utils';
import EditableCell from './gridedit-cell';
import ControllerHelper from '@mjcloud/controller';
import { ExceptionHelper } from '@mjcloud/exception';
import EditableComplexCell from './gridedit-complex-cell';
import TableExtendStoreBase from '../common/table/extendStore';
import { IDictionary, DataStateEnum, PageModeEnum } from '@mjcloud/types';
import { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import {
  RowValueChangeArg,
  RowDataStateChangeArg,
  CollectionDataStateChangeArg,
  IViewModelCollection,
  IViewModelRow,
  AggValueChangeArg,
} from '@mjcloud/data-model';
import {
  IGridEditState,
  IGridEditInitialStateParams,
  IGridEditStartLoadParams,
  GridEditActionType,
  IGridEditConfigItem,
  IActiveCellParams,
  IGridEditLoadedParams,
  IGridEditUpdateCellSizeParams,
} from './typings';
import { IBatchAddColumnParams } from '../common/table/typings';

export default class GridEditExtendStore extends TableExtendStoreBase<
  IViewModelCollection,
  IViewModelRow,
  IGridEditState,
  GridEdit
> {
  private isFirstLoad = false;
  private isExistDraft = false;

  private ids: string[] = [];
  private generateIdsLoading = false;

  constructor(instance: GridEdit) {
    super(instance);
    instance.page.eventManager.add('dataLoaded', () => {
      // TODO： 不应该在dataLoaded再次触发，应该通过建模调整from和gridEdit两者之间加载数据源的顺序
      // 在这里应该是from数据源加载完成后才能加载gridEdit
      const { total, ...aggs } = instance.store.state.aggModel.toJSON();
      if (instance.eventManager.getHandlerCount('aggValueChange') > 0) {
        for (const key in aggs) {
          if (key.charAt(0) !== '_') {
            const value = aggs[key];
            const data = {
              propertyName: key,
              value,
              oldValue: value,
            };
            instance.eventManager.trigger('aggValueChange', data);
          }
        }
      }
    });
  }

  private async generateIds() {
    this.generateIdsLoading = true;
    const ids = await Service.requestService<string[]>('/createid/newids', { quantity: 100 });

    if (ids) {
      this.ids.push(...ids);
    }
    this.generateIdsLoading = false;
  }

  private getGenerateId() {
    // TODO: 非异步如果一次性添加超过50次会有问题
    const id = this.ids.shift();
    if (this.ids.length <= 50 && !this.generateIdsLoading) {
      this.generateIds();
    }
    return id;
  }

  private initDataModel(dataModel: IViewModelCollection) {
    const { aggs } = this.instance.store.state;
    dataModel.bind('rowValueChange', this.handDataModelRowValueChange.bind(this));
    dataModel.bind('rowDataStateChange', this.handDataModelRowDataStateChangeEvent.bind(this));
    dataModel.bind('dataStateChange', this.handDataModelDataStateChangeEvent.bind(this));
    if (aggs && aggs.length > 0) {
      for (const agg of aggs) {
        dataModel.addAggProperties([{ ...agg, propertyName: agg.name, aggType: 1 }]);
      }
      dataModel.bind('aggValueChange', this.handleDataModelAggValueChange.bind(this));
    }
  }

  protected __createCell(item: IGridEditConfigItem) {
    const controlName = item && item.control && item.control.nodeName;
    if (controlName === 'switch') {
      return new EditableComplexCell(item.id, this.instance, item);
    }
    return new EditableCell(item.id, this.instance, item);
  }

  handleInitialStateBefore(e: ActionBeforeEventArg<IGridEditInitialStateParams>) {
    super.handleInitialStateBefore(e);
    const key = this.instance.id,
      aggKey = `${key}_aggs`,
      pageModel = this.instance.page.dataModel;
    this.isExistDraft = !!pageModel[key];
    if (pageModel[key]) e.params.dataModel = pageModel[key];
    if (pageModel[aggKey]) e.params.aggModel = pageModel[aggKey];
  }

  async handleInitialStateAfter(
    e: ActionAfterEventArg<IGridEditState, GridEditActionType, IGridEditInitialStateParams>,
  ) {
    super.handleInitialStateAfter(e);
    const { autoGenerateId, dataSource } = e.newState.state;
    if (autoGenerateId) await this.generateIds();
    this.initDataModel(dataSource);
  }

  handleStartLoadBefore(e: ActionBeforeEventArg<IGridEditStartLoadParams>) {
    super.handleStartLoadBefore(e);
    e.params.address = this.instance.page.address;
  }

  async handleStartLoadAfter(e: ActionAfterEventArg<IGridEditState, any, any>) {
    const key = this.instance.id,
      aggKey = `${key}_aggs`,
      pageModel = this.instance.page.dataModel;
    if (this.isExistDraft) {
      if (!this.isFirstLoad) {
        this.instance.__loadStart();
        this.isFirstLoad = true;
        await sleep(66);
        e.newState.dispatch('loaded', {
          dataModel: pageModel[key],
        });
        this.instance.__loadComplete();
      } else {
        await super.handleStartLoadAfter(e);
      }
    } else {
      if (!pageModel[key]) pageModel[key] = this.instance.dataModel;
      if (!pageModel[aggKey]) pageModel[aggKey] = e.newState.state.aggModel;
      await super.handleStartLoadAfter(e);
    }
  }

  handleLoadedBefore(e: ActionBeforeEventArg<IGridEditLoadedParams>) {
    super.handleLoadedBefore(e);
    const { pageMode } = this.instance.page.address;
    e.params.dataState =
      pageMode === PageModeEnum.add ? DataStateEnum.added : DataStateEnum.unchanged;
  }

  handleLoadedAfter(e: ActionAfterEventArg<IGridEditState, GridEditActionType>) {
    super.handleLoadedAfter(e);
    const { dataSource } = e.newState.state;
    const rids = dataSource
      .toArray()
      .map(r => r._rid)
      .filter(r => !!r);
    for (const rid of rids) {
      this.instance.__createRow2cells(rid);
    }
    // const dataSource = e.newState.state.dataSource.toArray(true);
    // for (const key in dataSource) {
    //   const row = dataSource[key];
    //   for (const k in row.toJSON()) {
    //     const cell = this.instance.findCell(k);
    //     if (cell) {
    //       cell.setCellValue(row, true);
    //     }
    //   }
    // }
  }

  handleBatchAddColumnAfter(
    e: ActionAfterEventArg<
      IGridEditState,
      GridEditActionType,
      IBatchAddColumnParams<IViewModelRow>
    >,
  ) {
    const { oldAggs = [], aggs = [] } = e.newState.state;
    oldAggs.map(agg => e.newState.state.dataSource.removeAggProperty(agg.name));
    aggs.map(agg =>
      e.newState.state.dataSource.addAggProperties([
        { ...agg, propertyName: agg.name, aggType: 1 },
      ]),
    );
  }

  async handleAddClickAfterAfter(e: ActionAfterEventArg<IGridEditState, GridEditActionType>) {
    const { autoGenerateId, addRowButton } = e.newState.state,
      { tip, title, controllerId } = addRowButton,
      isAddBeforeClick = this.instance.eventManager.getHandlerCount('onAddBeforeClick') > 0,
      isAddAfterClick = this.instance.eventManager.getHandlerCount('onAddAfterClick') > 0;
    if (!controllerId && !isAddBeforeClick && !isAddAfterClick) {
      const addRow: IDictionary = { _isBtnAdd: true };
      if (autoGenerateId) addRow.id = this.getGenerateId();
      e.newState.dispatch('batchAddRow', {
        rows: [addRow],
        isBtnClick: true,
      });
      return;
    }
    const exclusiveLockId = this.instance.page.applyExclusiveLock(tip || `正在执行${title}操作...`);
    if (exclusiveLockId === 0) {
      e.newState.dispatch('addClickDone', {});
      return;
    }
    let params: IDictionary = { ...e.params },
      before = async (data: IDictionary, extra: IDictionary = {}) => {
        extra._before = () => e.newState.dispatch('updateAddBtnLoad', { loading: true });
        extra._after = () => e.newState.dispatch('updateAddBtnLoad', { loading: false });
        if (isAddBeforeClick) {
          console.warn('按钮的onBeforeClick事件即将废弃');
          await this.instance.eventManager.trigger('onAddBeforeClick', data, extra);
        }
        return data;
      },
      after = async (data: IDictionary, extra: IDictionary = {}) => {
        if (isAddAfterClick)
          await this.instance.eventManager.trigger('onAddAfterClick', data, extra);
        return data;
      };
    try {
      if (controllerId) {
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId,
          data: params,
          before,
          after,
        });
      } else {
        await before(params);
        await after(params);
      }

      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      e.newState.dispatch('addClickDone', {});
    } catch (error) {
      e.newState.dispatch('addClickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      ExceptionHelper.dispose(error);
    }
  }

  handleUpdateCellSizeAfter(
    e: ActionAfterEventArg<IGridEditState, GridEditActionType, IGridEditUpdateCellSizeParams>,
  ) {
    const { rowId, size, isUpdate } = e.params;
    if (isUpdate) this.instance.__updateCellSize(rowId, size);
  }

  handleActiveCellBefore(e: ActionBeforeEventArg<IActiveCellParams>) {
    const { cellId, rowId } = e.params;
    this.instance.unActiveCell();
    const cell = this.instance.findCell(cellId);
    if (cell instanceof EditableCell) {
      cell.active(rowId);
    }
  }

  handleUpdateModifyModeAfter(e: ActionAfterEventArg<IGridEditState, GridEditActionType, any>) {
    if (e.params.modify) {
      // 编辑模式
    } else {
      // 只读模式
    }
  }

  private handDataModelRowValueChange(e: RowValueChangeArg) {
    if (e.eventSourceSign === this.instance) return;
    for (const key in e.data.values) {
      const value = e.data.values[key];
      if (value !== undefined) {
        const cell = this.instance.findCell(key);
        if (cell) {
          cell.setCellValue(e.data.row);
        }
      }
    }
    const eventManager = this.instance.eventManager;
    if (eventManager.getHandlerCount('rowChange') > 0) {
      eventManager.trigger('rowChange', { ...e.data });
    }
  }

  private handDataModelRowDataStateChangeEvent(e: RowDataStateChangeArg) {
    if (e.eventSourceSign === this) return;
    let isUpdateDataModel = false;
    switch (e.data.dataState) {
      case DataStateEnum.added:
        if (!e.data.row.id) {
          e.data.row.id = this.getGenerateId();
        }
        this.instance.__createRow2cells(e.data.row._rid);
        isUpdateDataModel = true;
        break;
      case DataStateEnum.deleted:
        this.instance.__deleteRow2cells(e.data.row._rid);
        isUpdateDataModel = true;
        break;
      case DataStateEnum.unchanged:
        return;
    }
    if (e.eventSourceSign !== this.instance.store && isUpdateDataModel) {
      // TODO: 判断条件不全，会导致死循环
      this.instance.store.dispatch('updateDataModel', {});
    }
    const eventManager = this.instance.eventManager;
    if (eventManager.getHandlerCount('rowChange') > 0) {
      eventManager.trigger('rowChange', { ...e.data });
    }
  }

  private handleDataModelAggValueChange(e: AggValueChangeArg) {
    const { propertyName, value } = e.data;
    let { showSummary, aggModel } = this.instance.store.state;
    aggModel[propertyName] = value;
    if (showSummary) {
      this.instance.store.dispatch('updateSummaryDataSource', { summaryDataSource: [aggModel] });
    }
    if (
      // e.eventSourceSign !== true && // 如果是通过数据源初始化产生的改变则不触发aggValueChange事件
      this.instance.eventManager.getHandlerCount('aggValueChange') > 0
    ) {
      this.instance.eventManager.trigger('aggValueChange', e.data);
    }
  }

  private handDataModelDataStateChangeEvent(e: CollectionDataStateChangeArg) {
    if (e.data.isChange) {
      this.instance.page.controlDataUpdated(this.instance.id);
    }
  }
}
