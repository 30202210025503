import Reduce from './reduce';
import Store from '@mjcloud/redux';
import TreeViewCell from './treeview-cell';
import TableInstanceBase from '../common/table';
import TreeViewExtendStore from './extendStore';
import { ITableBaseRow } from '../common/table/typings';
import { ITreeDataSourceItem } from '@mjcloud/data-source-helper';
import { IDictionary, PageControlMode, DataModeEnum } from '@mjcloud/types';
import {
  ITreeViewState,
  ITreeViewConfig,
  TreeViewEventType,
  TreeViewActionType,
  ITreeViewUpdateSelectedRowsParams,
} from './typings';

class TreeView extends TableInstanceBase<
  ITableBaseRow[],
  ITableBaseRow,
  ITreeViewState,
  TreeViewCell,
  TreeViewEventType,
  TreeViewActionType
> {
  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  get pageControlMode(): PageControlMode {
    return PageControlMode.treeView;
  }

  __createStore() {
    return new Store<ITreeViewState, TreeViewActionType>({
      reduce: Reduce,
      id: this.id,
      extendStore: new TreeViewExtendStore(this),
    });
  }

  __getCellRecord(rowId: number) {
    let _record: IDictionary = {};
    for (const record of this.store.state.originalDataSource) {
      if (record._rid == rowId) _record = record;
    }
    return _record;
  }

  initialState(initConfig: ITreeViewConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  /**
   * 手动设置列表的选中项
   * @param selectedRows 要选中的行数据
   */
  setSelectedRows(selectedRows: ITableBaseRow[], actionSourceSign?: any) {
    this.store.dispatch<ITreeViewUpdateSelectedRowsParams>('updateSelectedRows', {
      halfSelectedKeys: [],
      selectedRowKeys: selectedRows.map(r => r._rid),
      actionSourceSign,
    });
  }

  private ids2Rows(dataSource: ITreeDataSourceItem[], ids: string[], idFieldName = 'id') {
    const rows: ITreeDataSourceItem[] = [];
    for (const row of dataSource) {
      if (row._children && row._children.length > 0) {
        rows.push(...this.ids2Rows(row._children, ids, idFieldName));
      }
      for (const id of ids) {
        if (id === row[idFieldName]) {
          rows.push(row);
        }
      }
    }
    return rows;
  }

  /**
   * 手动设置列表的选中项
   * @param ids 要选中的行数据Id集合
   * @param idFieldName 默认值: 'id'
   */
  setSelectedRows2Id(ids: string[], idFieldName = 'id', actionSourceSign?: any) {
    const selectedRows: ITableBaseRow[] = this.ids2Rows(
      this.store.state.dataSource,
      ids,
      idFieldName,
    ) as ITableBaseRow[];
    this.setSelectedRows(selectedRows, actionSourceSign);
  }

  getAllData(): IDictionary[] {
    const { dataSource = [] } = this.store.state;
    return dataSource;
  }

  getRowForRowId(rid: number) {
    return this.__getCellRecord(rid);
  }

  /**
   * 获取当前选中的行数据
   */
  getSelectedRows(containHalf = true): IDictionary[] {
    const { selectedRows = [], halfSelectedRows = [] } = this.store.state;
    if (containHalf) {
      return [...selectedRows, ...halfSelectedRows];
    }
    return selectedRows;
  }

  /**
   * 获取当前半选中的行数据
   */
  getHalfSelectedRows(): IDictionary[] {
    return this.store.state.halfSelectedRows || [];
  }

  /**
   * 获取当前选中的行Id
   */
  getSelectedIds(idFieldName = 'id', containHalf = false): string[] {
    const { selectedRows = [], halfSelectedRows = [] } = this.store.state;
    let _selectedRows: ITableBaseRow[] = [];
    if (containHalf) {
      _selectedRows = [...selectedRows, ...halfSelectedRows];
    } else {
      _selectedRows = [...selectedRows];
    }
    return _selectedRows.map(row => row[idFieldName]);
  }

  rowIsExistSubset(row: any) {
    const { _children = [] } = row;
    if (_children instanceof Array && _children.length > 0) {
      return true;
    }
    return false;
  }
}

export default TreeView;
