import Store, { IReduce } from '@mjcloud/redux';
import TreeEditCellBaseReduce from '../treeedit-cell-base/reduce';
import { IEditableCellState, IEditableCellInitialStateParams } from './typings';

class TreeEditCellReduce extends TreeEditCellBaseReduce implements IReduce<IEditableCellState> {
  initialState(store: Store<IEditableCellState>, params: IEditableCellInitialStateParams) {
    const state: IEditableCellState = {
      active: false,
      ...this.__initialState(params),
    };
    return state;
  }

  active(store: Store<IEditableCellState>, params: {}) {
    const state: IEditableCellState = { ...store.state, active: true };
    return state;
  }

  unActive(store: Store<IEditableCellState>, params: {}) {
    const state: IEditableCellState = { ...store.state, active: false };
    return state;
  }
}

export default new TreeEditCellReduce();
