import React from 'react';
import globalData from '@mjcloud/global-data';
import controlTypes from './controlTypes';
import { IControlBaseProps } from './base';

import IframeControl from './iframe/pc';
import ModalControl from './modal/pc';
import AutoCompleteControl from './auto-complete/pc';
import ButtonControl from './button/pc';
import ButtonsControl from './buttons/pc';
import CascaderControl from './cascader/pc';
import ComplexSearchControl from './complex-search/pc';
import DatetimeControl from './datetime/pc';
import DateTimeRangeControl from './datetime-range/pc';
import FileManagerControl from './filemanager/pc';
import FormControl from './form/pc';
import GridControl from './grid/pc';
import GridEditControl from './gridedit/pc';
import GridViewControl from './gridview/pc';
import HyperlinkControl from './hyperlink/pc';
import LabelControl from './label/pc';
import NumberControl from './number/pc';
import NumberRangeControl from './number-range/pc';
// import PasswordControl from './password/pc';
import RadioControl from './radio/pc';
import RadioButtonsControl from './radio-buttons/pc';
import RadioListControl from './radiolist/pc';
import RichTextboxControl from './rich-textbox/pc';
import SelectControl from './select/pc';
import SelectTreeControl from './select-tree/pc';
import SeparateControl from './separate/pc';
import SimpleSearchControl from './simple-search/pc';
import SwitchControl from './switch/pc';
import TabsControl from './tabs/pc';
import TextAreaControl from './textarea/pc';
import TextboxControl from './textbox/pc';
import TimelineControl from './timeline/pc';
import ToolBarControl from './toolbar/pc';
import TreeListControl from './treelist/pc';
import TreeViewControl from './treeview/pc';
import TreeEditControl from './treeedit/pc';
import StatisticsControl from './statistics/pc';
import WorkbenchControl from './workbench/pc';
import ChartControl from './chart/pc';
import ModuleControl from './module/pc';
import WaterfallControl from './waterfall/pc';
import { getInstance } from './util';

class ControlFactory extends React.Component<IControlBaseProps> {
  private _instance: any;
  constructor(props: IControlBaseProps) {
    super(props);
    const { pageKey, parentId, id, workbenchId } = this.props;
    this._instance = getInstance(id, parentId, pageKey, workbenchId);
  }

  public get instance() {
    return this._instance;
  }

  render() {
    const instance = this.instance,
      { pageKey, parentId, id, workbenchId } = this.props,
      controlProps = { pageKey, parentId, id, controlId: id, workbenchId };
    if (instance) {
      if (instance instanceof controlTypes.Iframe) {
        return <IframeControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Modal) {
        return <ModalControl {...controlProps} />;
      } else if (instance instanceof controlTypes.AutoComplete) {
        return <AutoCompleteControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Button) {
        return <ButtonControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Buttons) {
        return <ButtonsControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Cascader) {
        return <CascaderControl {...controlProps} />;
      } else if (instance instanceof controlTypes.ComplexSearch) {
        return <ComplexSearchControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Datetime) {
        return <DatetimeControl {...controlProps} />;
      } else if (instance instanceof controlTypes.DateTimeRange) {
        return <DateTimeRangeControl {...controlProps} />;
      } else if (instance instanceof controlTypes.FileManager) {
        return <FileManagerControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Form) {
        return <FormControl {...controlProps} />;
      } else if (instance instanceof controlTypes.GridEdit) {
        return <GridEditControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Grid) {
        return <GridControl {...controlProps} />;
      } else if (instance instanceof controlTypes.GridView) {
        return <GridViewControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Hyperlink) {
        return <HyperlinkControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Label) {
        return <LabelControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Number) {
        return <NumberControl {...controlProps} />;
      } else if (instance instanceof controlTypes.NumberRange) {
        return <NumberRangeControl {...controlProps} />;
      }
      // else if (instance instanceof controlTypes.Password) {
      //   return <PasswordControl {...controlProps} />;
      // }
      else if (instance instanceof controlTypes.Radio) {
        return <RadioControl {...controlProps} />;
      } else if (instance instanceof controlTypes.RadioButtons) {
        return <RadioButtonsControl {...controlProps} />;
      } else if (instance instanceof controlTypes.RadioList) {
        return <RadioListControl {...controlProps} />;
      } else if (instance instanceof controlTypes.RichTextbox) {
        return <RichTextboxControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Select) {
        return <SelectControl {...controlProps} />;
      } else if (instance instanceof controlTypes.SelectTree) {
        return <SelectTreeControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Separate) {
        return <SeparateControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Search) {
        return <SimpleSearchControl {...controlProps} />;
      } else if (instance instanceof controlTypes.SimpleSearch) {
        return <SimpleSearchControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Switch) {
        return <SwitchControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Tabs) {
        return <TabsControl {...controlProps} />;
      } else if (instance instanceof controlTypes.TextArea) {
        return <TextAreaControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Textbox) {
        return <TextboxControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Toolbar) {
        return <ToolBarControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Timeline) {
        return <TimelineControl {...controlProps} />;
      } else if (instance instanceof controlTypes.TreeList) {
        return <TreeListControl {...controlProps} />;
      } else if (instance instanceof controlTypes.TreeView) {
        return <TreeViewControl {...controlProps} />;
      } else if (instance instanceof controlTypes.TreeEdit) {
        return <TreeEditControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Statistics) {
        return <StatisticsControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Workbench) {
        return <WorkbenchControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Chart) {
        return <ChartControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Module) {
        return <ModuleControl {...controlProps} />;
      } else if (instance instanceof controlTypes.Waterfall) {
        return <WaterfallControl {...controlProps} />;
      }
      return <>Control Not Find</>;
    } else {
      return <>Control Not Find</>;
    }
  }
}

export default ControlFactory;
