"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _theme = _interopRequireDefault(require("./theme"));

var _ariaLang = _interopRequireDefault(require("./aria-lang.CN"));

exports.lang = _ariaLang["default"];

var _common = require("./util/common");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var Global = {
  version: '3.8.1',
  scales: {},
  widthRatio: {
    column: 1 / 2,
    rose: 0.999999,
    multiplePie: 3 / 4
  },
  lineDash: [4, 4],
  lang: _ariaLang["default"]
};

Global.setTheme = function (theme) {
  (0, _common.deepMix)(Global, theme);
};

Global.setTheme(_theme["default"]);
var _default = Global;
exports["default"] = _default;