import TreeView from '.';
import { IDictionary } from '@mjcloud/types';
import PageHelper from '@mjcloud/page-helper';
import TreeViewCell from './treeview-cell';
import { EventListening } from '@mjcloud/utils';
import { ITableBaseRow } from '../common/table/typings';
import TableExtendStoreBase from '../common/table/extendStore';
import { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import {
  ITreeViewState,
  TreeViewActionType,
  ITreeViewConfigItemSet,
  ITreeViewUpdateSelectedRowsParams,
} from './typings';

export default class TreeViewExtendStore extends TableExtendStoreBase<
  ITableBaseRow[],
  ITableBaseRow,
  ITreeViewState,
  TreeView
> {
  protected __createCell(item: ITreeViewConfigItemSet) {
    return new TreeViewCell(item.id, this.instance, item);
  }

  handleLoadedBefore(e: ActionBeforeEventArg<any>) {
    super.handleLoadedBefore(e);

    const { disabledControl } = this.instance.store.state;
    if (disabledControl) {
      e.params.disabledFn = row => {
        const fn = PageHelper.createPageScriptFunction(this.instance.page, disabledControl, true),
          _e = EventListening.getHandlerArg(this.instance, { row });
        return fn(_e);
      };
    }
  }

  private rids2Rows(data: ITableBaseRow[], _selectedRows: IDictionary<boolean>) {
    let selectedRows: any[] = [];
    data.forEach(row => {
      if (_selectedRows[row._rid]) {
        selectedRows.push(row);
      }
      if (row && row._children && row._children.length > 0) {
        selectedRows = selectedRows.concat(this.rids2Rows(row._children, _selectedRows));
      }
    });
    return selectedRows;
  }

  handleUpdateSelectedRowsAfter(
    e: ActionAfterEventArg<ITreeViewState, TreeViewActionType, ITreeViewUpdateSelectedRowsParams>,
  ) {
    const { selectedRows = [], halfSelectedRows = [], dataSource } = e.newState.state,
      { row, checked, actionSourceSign } = e.params;

    if (actionSourceSign === this.instance) return;
    if (row) {
      const eventManager = this.instance.eventManager;
      const _selectedRows: IDictionary<boolean> = {};
      selectedRows.forEach(row => {
        _selectedRows[row._rid] = true;
      });

      if (eventManager.getHandlerCount('selectedChange') > 0) {
        eventManager.trigger('selectedChange', {
          checked,
          row,
          halfSelectedRows,
          selectedRows: this.rids2Rows(dataSource, _selectedRows),
        });
      }
    } else {
      const eventManager = this.instance.eventManager;
      const _selectedRows: IDictionary<boolean> = {};
      selectedRows.forEach(row => {
        _selectedRows[row._rid] = true;
      });
      const selectRows = this.rids2Rows(dataSource, _selectedRows);

      if (eventManager.getHandlerCount('selectedChange') > 0) {
        for (const row of selectRows) {
          eventManager.trigger('selectedChange', {
            checked: true,
            row,
            selectedRows: selectRows,
            halfSelectedRows,
          });
        }
      }
    }
  }
}
