import "antd/es/drawer/style";
import _Drawer from "antd/es/drawer";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import classNames from 'classnames';
import Omit from 'omit.js';
import SiderMenu from './SiderMenu';
import { getFlatMenuKeys } from './SiderMenuUtils';

var SiderMenuWrapper = function SiderMenuWrapper(props) {
  var isMobile = props.isMobile,
      menuData = props.menuData,
      siderWidth = props.siderWidth,
      collapsed = props.collapsed,
      onCollapse = props.onCollapse,
      style = props.style,
      className = props.className;
  var flatMenuKeys = getFlatMenuKeys(menuData);
  var omitProps = Omit(props, ['className', 'style']);
  return isMobile ? React.createElement(_Drawer, {
    visible: !collapsed,
    placement: "left",
    className: classNames('ant-pro-sider-menu', className),
    onClose: function onClose() {
      return onCollapse && onCollapse(true);
    },
    style: _objectSpread({
      padding: 0,
      height: '100vh'
    }, style),
    width: siderWidth,
    bodyStyle: {
      height: '100vh'
    }
  }, React.createElement(SiderMenu, Object.assign({}, omitProps, {
    flatMenuKeys: flatMenuKeys,
    collapsed: isMobile ? false : collapsed
  }))) : React.createElement(SiderMenu, Object.assign({
    className: classNames('ant-pro-sider-menu', className)
  }, omitProps, {
    style: style,
    flatMenuKeys: flatMenuKeys
  }));
};

SiderMenuWrapper.defaultProps = {
  onCollapse: function onCollapse() {
    return undefined;
  }
};
export default SiderMenuWrapper;