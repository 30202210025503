import React from 'react';
import { Checkbox } from 'antd';
import { arrAdd, arrDel } from './treeUtil';
import { conductCheck } from './conductUtil';
import { Key, DataNode, DataEntity, SelectionSelectFn } from './interface';

interface ISelectionProps<T> {
  record: T;
  rowIndex: number;
  strictly?: boolean;
  checkedKeys: Key[];
  halfCheckedKeys: Key[];
  keyEntities: Record<Key, DataEntity>;
  rowKey: string | ((record: T, index: number) => string);

  onSelect?: SelectionSelectFn<T>;
}

const VirtualizedSelection: React.FC<ISelectionProps<any>> = ({
  record,
  rowKey,
  rowIndex,
  strictly,
  keyEntities,
  checkedKeys: oriCheckedKeys,
  halfCheckedKeys: oriHalfCheckedKeys,
  onSelect = () => {},
}) => {
  const _rowKey = typeof rowKey === 'string' ? rowKey : rowKey(record, rowIndex),
    key = record[_rowKey],
    checked = oriCheckedKeys.indexOf(key) >= 0,
    indeterminate = oriHalfCheckedKeys.indexOf(key) >= 0;

  return (
    <Checkbox
      key={`${record[_rowKey]}_rowSelection`}
      checked={checked}
      indeterminate={indeterminate}
      disabled={!!record['_disabled']}
      onChange={e => {
        if (strictly) {
          const checkedKeys = e.target.checked
            ? arrAdd(oriCheckedKeys, key)
            : arrDel(oriCheckedKeys, key);
          const halfCheckedKeys = arrDel(oriHalfCheckedKeys, key);

          onSelect(record, e.target.checked, checkedKeys, halfCheckedKeys);
        } else {
          // Always fill first
          let { checkedKeys, halfCheckedKeys } = conductCheck(
            [...oriCheckedKeys, key],
            true,
            keyEntities,
          );

          // If remove, we do it again to correction
          if (!e.target.checked) {
            const keySet = new Set(checkedKeys);
            keySet.delete(key);
            ({ checkedKeys, halfCheckedKeys } = conductCheck(
              Array.from(keySet),
              { checked: false, halfCheckedKeys },
              keyEntities,
            ));
          }
          onSelect(record, e.target.checked, checkedKeys, halfCheckedKeys);
        }
      }}
    />
  );
};

export default VirtualizedSelection;
