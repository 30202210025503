import Store from '@mjcloud/redux';
import { ValueDataReduceBase } from '@mjcloud/reduce';
import DataSource from '@mjcloud/data-source-helper';
import {
  ISelectBaseState,
  ISelectBaseDataSource,
  ISelectBaseLoadedParams,
  ISelectBaseUpdateValueParams,
} from './typings';

export default abstract class SelectReduceBase<TValue = string> extends ValueDataReduceBase<
  TValue,
  ISelectBaseState<TValue>
> {
  clearDataSource(store: Store<ISelectBaseState<TValue>>, params: {}) {
    let state = { ...store.state };
    return state;
  }

  startLoad(store: Store<ISelectBaseState<TValue>>, params) {
    const state = super.startLoad(store, params);
    if (params.open != null) {
      state.open = params.open;
    }
    return state;
  }

  updateOpen(store: Store<ISelectBaseState<TValue>>, params) {
    const state = store.state;
    if (params.open != null) {
      state.open = params.open;
      return { ...state };
    }
    return state;
  }

  loaded(store: Store<ISelectBaseState<TValue>>, params: ISelectBaseLoadedParams) {
    let state = store.state,
      { disabledFn = () => false } = params,
      { rowIdCount, value, textTokenSeparator } = state;
    const h: any = {},
      { rows } = params.dataSource,
      { config, isAutoSelection, vaueleTokenSeparator } = state,
      { data = {} } = config;

    const dataSource = DataSource.formatDataSource<ISelectBaseDataSource>(
      data,
      rows,
      disabledFn,
      row => {
        let _isExist = false;
        if (h[row._value]) _isExist = true;
        h[row._value] = true;
        return { _isExist, _rid: ++rowIdCount };
      },
    );
    if (isAutoSelection && value == null && dataSource[0]) {
      value = dataSource[0]._value as any;
    }
    const { texts, selectedRows } = this.setSelectedRows(
      dataSource,
      vaueleTokenSeparator,
      value as any,
    );
    state = {
      ...state,
      rowIdCount,
      dataSource,
      selectedRows,
      originalData: rows,
      isFetching: false,
      originalDataSource: dataSource,
    };
    if (params.isAdd) {
      state.text = texts.join(textTokenSeparator);
    }
    return state;
  }

  updateValue(
    store: Store<ISelectBaseState<TValue>>,
    params: ISelectBaseUpdateValueParams<TValue>,
  ) {
    const state = super.updateValue(store, params),
      { vaueleTokenSeparator, textTokenSeparator, dataSource } = state,
      value: string = state.value as any,
      { selectedRows, rowIds, texts } = this.setSelectedRows(
        dataSource,
        vaueleTokenSeparator,
        value,
        params.rowIds,
      );
    if (!params.text) state.text = texts.join(textTokenSeparator);
    state.selectedRows = selectedRows;
    params.rowIds = rowIds;
    params.selectedRows = selectedRows;
    return state;
  }

  private findSelectedRows(
    selectedRows: ISelectBaseDataSource[],
    rows: ISelectBaseDataSource[],
    value: string,
    valueArr: string[],
    rowIds: number[] = [],
  ) {
    for (const row of rows) {
      if (rowIds && rowIds.length > 0) {
        for (const rowId of rowIds) {
          if (row._rid == rowId) {
            selectedRows.push(row);
          }
        }
      } else {
        if (typeof value === 'string') {
          for (const rowVal of valueArr) {
            if (row._value == rowVal) {
              selectedRows.push(row);
            }
          }
        }
      }

      if (row._children && row._children.length > 0) {
        this.findSelectedRows(selectedRows, row._children, value, valueArr, rowIds);
      }
    }
  }

  protected setSelectedRows(
    dataSource: ISelectBaseDataSource[],
    vaueleTokenSeparator: string,
    value: string = '',
    rowIds?: number[],
  ) {
    const selectedRows: ISelectBaseDataSource[] = [];
    if (value) {
      const valueArr = `${value}`.split(vaueleTokenSeparator);
      // TODO: dataSource 为 undefined 的情况不应该出现，需要考虑state初始化的时机
      if (dataSource) this.findSelectedRows(selectedRows, dataSource, value, valueArr, rowIds);
    }

    return {
      rowIds: selectedRows.map(r => r._rid),
      texts: selectedRows.map(r => r._text),
      selectedRows,
    };
  }
}
