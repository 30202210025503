import React from 'react';
import { ILabelState } from '../typings';
import ControlBase from '../../base';

import 'braft-editor/dist/output.css';

class LabelControl extends ControlBase<ILabelState> {
  renderContent() {
    const { text, value, isHtml, fontSize, fontWeight } = this.state,
      style: React.CSSProperties = { fontSize, fontWeight, whiteSpace: 'nowrap' };
    if (isHtml)
      return (
        <div
          className="braft-output-content"
          style={style}
          id={this.props.id}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      );
    return (
      <div id={this.props.id} style={style}>
        {text}
      </div>
    );
  }
}

export default LabelControl;
