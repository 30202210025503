import React from 'react';
import Icon from '@mjcloud/icon';
import Service from '@mjcloud/service';
import { Rule } from 'antd/lib/form';
import globalData from '@mjcloud/global-data';
import { ObjectHelper } from '@mjcloud/utils';
import { Form, Input, Button } from 'antd';
import UserLayout from './layouts/UserLayout';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import { handleException, IErrorInfo, ErrorException } from './util';

import styles from './login.less';

const corpIdRules: Rule[] = [
  {
    required: true,
    message: '请输入corpId',
  },
  {},
];
const passwordRules: Rule[] = [
  {
    required: true,
    message: '请输入密码',
  },
  {
    min: 6,
    max: 20,
    message: '密码至少6位字符且不能超过20位字符',
  },
];

class BigScreenLoginPage extends React.Component<
  IRouteComponentProps,
  { submitting: boolean; corpId: string; error?: IErrorInfo }
> {
  constructor(props: IRouteComponentProps) {
    super(props);
    const { corpId } = ObjectHelper.search2params(window.location.search);
    this.state = { corpId, submitting: false };
  }

  private headleFinish = async (values: any) => {
    const { submitting } = this.state,
      { appId } = globalData.rbCore.config,
      { history } = this.props;
    if (submitting) return;
    try {
      this.setState({ submitting: true });
      await Service.bigScreenSignin({ ...values, appId });
      this.setState({ submitting: false });
      if (globalData.preLoginAddress) {
        window.location.href = globalData.preLoginAddress;
      } else {
        history.replace(`/${appId}/${globalData.DataCockpit}`);
      }
    } catch (ex) {
      this.setState({ submitting: false, ...handleException(ex) });
    }
  };

  render() {
    const { appId } = this.props.match.params;
    const { title, desc, copyright } = globalData.rbCore.config.appSettingList[appId];
    const { error } = this.state;
    const prefixIconStyle: React.CSSProperties = { color: 'rgba(0,0,0,.25)' };
    if (error) return <ErrorException error={error} />;
    return (
      <UserLayout className={styles.main} title={title} desc={desc} copyright={copyright}>
        <Form onFinish={this.headleFinish}>
          <Form.Item name="corpId" rules={corpIdRules}>
            <Input
              size="large"
              placeholder="请输入corpId"
              disabled={!!this.state.corpId}
              defaultValue={this.state.corpId}
              prefix={<Icon type="solution" style={prefixIconStyle} />}
            />
          </Form.Item>

          <Form.Item name="password" rules={passwordRules}>
            <Input.Password
              size="large"
              placeholder="请输入密码"
              prefix={<Icon type="lock" style={prefixIconStyle} />}
              // onPressEnter={this.headlePressEnter}
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={this.state.submitting}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </UserLayout>
    );
  }
}

export default BigScreenLoginPage;
