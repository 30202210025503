import OperGuide from './OperGuide';
import JsApiHelper from '@mjcloud/jsapi';
import { Form, Input, Button } from 'antd';
import { UpDownLayout } from '../../../layout';
import { generateWeworkAddressUrl } from './util';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled, { StyledComponent } from 'styled-components';
import React, { useState } from 'react';

const Clipboard: StyledComponent<'div', any, {}, any> & {
  Content: StyledComponent<'div', any, {}, any>;
} = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 16px;
  padding: 8px;
` as any;

Clipboard.Content = styled.div`
  flex: 1;
  margin-bottom: 4px;
`;

const EnhancedFCForm: React.FC = () => {
  const [url, setUrl] = useState<string>();
  const items = ['secret', 'token', 'encodingAESKey'];
  const addonAfter = (
    <a href="https://work.weixin.qq.com/wework_admin/frame#apps/contactsApi" target="_blank">
      前往获取
    </a>
  );
  const handleFinish = async (values: any) => {
    const url = await generateWeworkAddressUrl(values);
    setUrl(url);
  };

  return (
    <Form onFinish={handleFinish}>
      {items.map(item => (
        <Form.Item name={item} rules={[{ required: true, message: `请输入${item}` }]}>
          <Input placeholder={`请输入${item}`} addonAfter={addonAfter} />
        </Form.Item>
      ))}
      <Button type="primary" htmlType="submit" disabled={!!url}>
        生成URL
      </Button>
      {url && (
        <Clipboard>
          <Clipboard.Content>{url}</Clipboard.Content>
          <CopyToClipboard
            text={url}
            onCopy={() => {
              JsApiHelper.showToast({ content: '复制成功', type: 'success' });
            }}
          >
            <a
              href="https://work.weixin.qq.com/wework_admin/frame#apps/contactsApi"
              target="_blank"
            >
              复制并前往配置
            </a>
          </CopyToClipboard>
        </Clipboard>
      )}
    </Form>
  );
};

const SetAddressBookAPI: React.FC = () => {
  return (
    <UpDownLayout>
      <UpDownLayout.Content bordered={false} bodyStyle={{ padding: 0 }}>
        <OperGuide src="/operguide/address_book_API.mp4">企业微信通讯录操作指引</OperGuide>
        <EnhancedFCForm />
      </UpDownLayout.Content>
    </UpDownLayout>
  );
};

export default SetAddressBookAPI;
