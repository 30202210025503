import Reduce from './reduce';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import { EventListening } from '@mjcloud/utils';
import { InstanceBase } from '@mjcloud/instance';
import { ITableBaseReviseConfig } from './../typings';
import { IDictionary, ControlConfig, Size } from '@mjcloud/types';
import Store, { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import {
  IOperationContentState,
  OperationContentActionType,
  IOperationContentInitialStateParams,
  IOperationControlProps,
} from './typings';

class ChildrenExtendStore {
  constructor(
    private instance: OperationContent,
    private children: InstanceBase,
    private rowId: number,
    private isReviseEdit: (actionId: string, row: any) => boolean,
  ) { }

  private getExecutingState(sender: any, row: any) {
    const revise: ITableBaseReviseConfig = sender.parent.store.state.revise;
    if (revise && !!revise.enable) {
      const { [revise.statusField]: executingState } = row;
      return executingState;
    }
  }

  private getIsUserEdit(sender: any, row: any) {
    const revise: ITableBaseReviseConfig = sender.parent.store.state.revise;
    if (revise && !!revise.enable) {
      const { statusField, currentUserCanEditField } = revise;
      let {
        _reviseId: isRevise,
        _currentUserCanRevise: currentUserCanRevise,
        [currentUserCanEditField]: currentUserCanEdit,
        [statusField]: executingState,
      } = row;
      isRevise = !!isRevise;
      executingState = Number(executingState);
      if (!isNaN(executingState) && executingState > 0) {
        return isRevise ? currentUserCanRevise == 1 : currentUserCanEdit == 1;
      }
    }
  }

  handleUpdateDisplayAfter(e: ActionAfterEventArg) {
    const { instance, rowId } = this,
      { display, id } = e.newState.state as any;
    instance.stores[rowId].dispatch('updateDisplay', { id: id + rowId, display });
  }

  handleClickAfterBefore(e: ActionBeforeEventArg<any>) {
    const { instance, rowId } = this;
    const row = instance.parent.getRowForRowId(rowId);
    e.params = {
      ...e.params,
      row,
      rowId,
      _reviseId: row && row._reviseId,
      // _isUserEdit: row && this.getIsUserEdit(this.children, row),
      _executingState: row && this.getExecutingState(this.children, row),
    };
  }

  handleInitialStateAfter(e: ActionAfterEventArg) {
    const { instance, children, rowId } = this,
      { display: _display, isAuthority, displayControl, id } = e.newState.state as any,
      display = !isAuthority ? false : _display;

    if (display) {
      // TODO: 修复因QueueCallback去掉异步所引起的Bug
      const row = instance.parent.getRowForRowId(rowId);
      if (row) {
        let _fn = displayControl
          ? PageHelper.createPageScriptFunction(children.page, displayControl, true)
          : () => true;
        const fn = (e: any) => {
          // TODO: 缺失可修订人的判断条件
          if (this.isReviseEdit(id, e.data.row)) {
            const isUserEdit = this.getIsUserEdit(e.sender, e.data.row);
            return !!isUserEdit;
          } else {
            return _fn(e);
          }
        };
        const _display = fn(EventListening.getHandlerArg(children, { rowId, row }));
        if (!_display) {
          children.display = !!_display;
          instance.stores[rowId].dispatch('updateDisplay', {
            id: id + rowId,
            display: !!_display,
          });
        }
      }
    } else {
      instance.stores[rowId].dispatch('updateDisplay', {
        id: id + rowId,
        display,
      });
    }
    instance.parent.store.dispatch('updateOperationWidth', {});
  }
}

export default class OperationContent {
  private _stores: IDictionary<Store<IOperationContentState, OperationContentActionType>> = {};

  constructor(public id: string, public parent: any, private config: ControlConfig[]) {
    
  }

  get stores() {
    return this._stores;
  }

  updateSize(rowId: number, size: Size) {
    const store = this.stores[rowId];
    if (store) store.dispatch('updateSize', { size });
  }

  createStore(rowId: number) {
    return this._createStore(rowId);
  }

  _createStore(rowId: number) {
    if (this._stores[rowId]) return this._stores[rowId];
    const ChildrenControls: IOperationControlProps[] = [],
      controls: IDictionary<Store> = {};
    for (const item of this.config) {
      const instance = this.parent.__createControlByOperation(rowId, item);
      if (instance) {
        controls[instance.id] = instance.store;
        controls[instance.id].bindExtendStore(
          new ChildrenExtendStore(
            this,
            instance,
            rowId,
            this.parent.__isReviseEditId.bind(this.parent),
          ),
        );
        ChildrenControls.push({
          title: item['title'] || '',
          display: true,
          id: instance.id,
          parentId: this.parent.id,
          workbenchId: this.parent.workbenchPart
            ? this.parent.workbenchPart.workbench.id
            : undefined,
          pageKey: this.parent.page.id,
        });
      }
    }
    this._stores[rowId] = new Store<IOperationContentState, OperationContentActionType>({
      id: `${this.id}-${rowId}`,
      reduce: Reduce,
    });
    this._stores[rowId].setParent(this.parent.store);
    this._stores[rowId].dispatch<IOperationContentInitialStateParams>('initialState', {
      controls,
      initConfig: {},
      ChildrenControls,
      pageMode: this.parent.page.pageMode,
    });
    return this._stores[rowId];
  }
}
