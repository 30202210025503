import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IGridState, IGridSection, IGridItem, IGridInitialStateParams } from './typings';

export class GridReduce extends ReduceBase {
  initialState(store: Store<IGridState>, params: IGridInitialStateParams) {
    const { initConfig, pageMode, createControl } = params,
      { displayMode, direction, sections: _sections } = initConfig;
    const sections: IGridSection[] = _sections.items.map(section => {
      const { items, ...outerSection } = section;
      return {
        ...outerSection,
        items: items
          .map(item => {
            const instance = createControl(item);
            if (instance) {
              return {
                ...item,
                id: instance.id,
                parentId: instance.parent.id,
                pageKey: instance.page.id,
              } as IGridItem;
            }
            return undefined;
          })
          .filter(item => !!item) as IGridItem[],
      };
    });
    const state: IGridState = {
      sections,
      direction,
      tabIndex: -1,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }
}

export default new GridReduce();
