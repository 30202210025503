import React from 'react';
import { Service } from '@mjcloud/core';
import styled from 'styled-components';
import { IDictionary } from '@mjcloud/types';
import globalData from '@mjcloud/global-data';
import { ExceptionHelper } from '@mjcloud/exception';
import { Modal, Button, Tooltip, message } from 'antd';
import { LinkOutlined, QuestionCircleFilled } from '@ant-design/icons';

const FooterItem = styled.div<{ position: 'left' | 'center' | 'right' }>(props => ({
  textAlign: props.position,
  width: '50%',
  display: 'inline-block',
}));

const Pre = styled.pre`
  overflow: auto;
  border-width: 1px;
  border-color: #ccc;
  border-style: solid;
  padding: 8px;
  margin-bottom: 0;
  max-height: 200px;
`;

const Info = styled.dd`
  float: left;
  width: 40%;
  height: 24px;
`;

const Title = styled.dt`
  float: left;
  width: 10%;
  height: 24px;
  font-weight: 700;
`;

const Clear = styled.dt`
  clear: both;
  font-weight: 700;
`;

interface IErrorInfo extends IDictionary {}
interface IErrorModalState {
  visible: boolean;
  errors: IErrorInfo[];
  index: number;
}
export default class ErrorModal extends React.Component<{}, IErrorModalState> {
  constructor(props: {}) {
    super(props);
    this.state = { visible: false, errors: [], index: 0 };
    ExceptionHelper.error = async (exception, msg) => {
      let ex: IErrorInfo = {} as any;
      try {
        const edata = exception.data.data.e.data.response.data;
        if (edata.e) {
          ex = edata.e;
        } else {
          ex = edata;
          if (!ex.name) ex.name = 'unknown';
        }
        ex.errorCode = ex.code || ex.id;
        ex.position = '后台错误';
      } catch (e) {
        ex = exception;
        if (ex.isRequestService) {
          ex.errorCode = ex.code || ex.id;
          ex.position = '后台错误';
        } else {
          ex.position = '前台错误';
          ex.stackTrace = exception.stack;
          if (globalData.__TestApiErrorCallback) globalData.__TestApiErrorCallback(ex);
          try {
            ex.errorCode = await Service.errorLog(exception);
          } catch (error) {
            console.error(error);
          }
        }
      } finally {
        // 资源异常不进行错误提示
        if (exception.name === 'resourceError') return;
        // 不呈现报错
        if (exception.isNotPresent) return;

        if (globalData.debugger) {
          const { errors } = this.state;
          errors.push(ex);
          this.setState({
            errors: errors,
            visible: true,
          });
        }

        console.error(ex);
        if (msg) {
          let code = ex.errorCode;
          if (code) message.error(msg.replace(/{{code}}/g, code));
        } else message.error('发生内部错误，请联系系统管理员');
      }
    };
  }

  close = () => {
    this.setState({
      visible: false,
      errors: [],
      index: 0,
    });
  };

  prevIndex = () => {
    const { index } = this.state;
    this.setState({ index: index - 1 });
  };

  nextIndex = () => {
    const { index } = this.state;
    this.setState({ index: index + 1 });
  };

  renderFooter(index: number, length: number, error: IErrorInfo) {
    return (
      <div>
        <FooterItem position="left">
          <Button
            type="link"
            target="blank"
            href={'https://www.yuque.com/ydy/ae0zeg/s?q=' + (error && error.name)}
          >
            参考文档 <LinkOutlined {...({} as any)} />
          </Button>
        </FooterItem>
        <FooterItem position="right">
          <Button type="dashed" disabled={index == 0} onClick={this.prevIndex}>
            上一个
          </Button>
          <Button type="dashed" disabled={index == length - 1} onClick={this.nextIndex}>
            下一个
          </Button>

          <Button type="primary" disabled={true} title="尚未实现">
            上报BUG
          </Button>
          <Button onClick={this.close}>关闭</Button>
        </FooterItem>
      </div>
    );
  }

  render() {
    const { errors, index } = this.state;
    let error: IErrorInfo = {},
      isExistError = false;
    if (errors.length > 0) {
      error = errors[index];
      isExistError = true;
    }
    const {
      data,
      id = '',
      name = '',
      position = '',
      responsible = '',
      message = '',
      stackTrace,
    } = error;
    return (
      <Modal
        title={`错误信息（${index + 1} / ${errors.length}）`}
        centered={true}
        maskClosable={false}
        visible={this.state.visible}
        width="90%"
        onCancel={this.close}
        footer={this.renderFooter(index, errors.length, error)}
      >
        {isExistError && (
          <dl style={{ marginBottom: 0 }}>
            <Title>类型：</Title>
            <Info>{name}</Info>
            <Title>
              <Tooltip placement="top" title="通过ID搜索日志，可以快速定位错误">
                <>
                  <QuestionCircleFilled {...({} as any)} /> ID：
                </>
              </Tooltip>
            </Title>
            <Info>{id}</Info>
            <Title>定位：</Title>
            <Info>{position}</Info>
            <Title>责任：</Title>
            <Info>{responsible}</Info>
            {message && <Clear>消息：</Clear>}
            {message && (
              <dd>
                <Pre>{typeof message === 'string' && message}</Pre>
              </dd>
            )}
            <Clear />
            {data && <Clear>数据：</Clear>}
            {data && (
              <dd>
                <Pre>{typeof data === 'string' && data}</Pre>
              </dd>
            )}
            {stackTrace && <Clear>堆栈：</Clear>}
            {stackTrace && (
              <dd>
                <Pre>{typeof stackTrace === 'string' && stackTrace}</Pre>
              </dd>
            )}
          </dl>
        )}
      </Modal>
    );
  }
}
