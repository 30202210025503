import styled from 'styled-components';
import { Card as AntCard } from 'antd';
import { CardProps } from 'antd/lib/card';
import { SPACING, BASEFONTSIZE } from './constant';

export { default as LeftMiddleLayout } from './leftMidLayout';
export { default as LeftRightLayout } from './leftRightLayout';
export { default as MainLayout } from './mainLayout';
export { default as UpDownLayout } from './upDownLayout';

interface ICardProps extends CardProps {
  display?: 'flex';
  direction?: 'row' | 'column';
}

interface IContainerProps {
  direction?: 'row' | 'column';
}

interface IFlexProps {
  direction?: 'row' | 'column';
  wrap?: 'nowrap' | 'wrap';
  justify?: 'start' | 'end' | 'center' | 'between' | 'around';
  align?: 'start' | 'end' | 'center' | 'stretch';
  alignContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'stretch';
}

function parameter2value(value: string) {
  if (value === 'start' || value === 'end') {
    return `flex-${value}`;
  } else if (value === 'between' || value === 'around') {
    return `space-${value}`;
  }
  return value;
}

export const Flex = styled.div<IFlexProps>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  flex-wrap: ${({ wrap = 'nowrap' }) => wrap};
  justify-content: ${({ justify = 'start' }) => parameter2value(justify)};
  align-items: ${({ align = 'start' }) => parameter2value(align)};
  align-content: ${({ alignContent = 'stretch' }) => parameter2value(alignContent)};
`;

export const FlexItem = styled.div<IFlexProps>`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Container = styled(Flex)<IContainerProps>`
  height: 100%;
  overflow: hidden;
  padding: ${SPACING}px;
`;

export const Card = styled(AntCard)<ICardProps>`
  width: 100%;
  height: 100%;

  > .ant-card-body {
    width: 100%;
    height: 100%;
    padding: ${SPACING}px;
    display: ${({ display = 'block' }) => display};
    flex-direction: ${({ direction = 'row' }) => direction};
  }
`;

export function px2rem(px?: number | string) {
  if (typeof px === 'string') return px;
  return px ? `${px / BASEFONTSIZE}rem` : undefined;
}
