import ComplexSearch from '.';
import { InstanceBase } from '@mjcloud/instance';
import { ContainerExtendStoreBase } from '@mjcloud/extend-store';
import { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import { OperationTypeEnum, DataTypeEnum, Filter, FilterTypeEnum } from '@mjcloud/utils';
import {
  IComplexSearchState,
  IComplexSearchFindData,
  ComplexSearchActionType,
  IComplexSearchFindParams,
  IComplexSearchAdvancedData,
  IComplexSearchAdvancedState,
  IComplexSearchAdvancedConfig,
  IComplexSearchInitialStateParams,
  IComplexSearchUpdateAdvancedItemsParams,
} from './typings';
import {
  IValueState,
  ValueControlActionType,
  IValueControlUpdateValueParams,
} from '@mjcloud/instance/dist/valueInstanceBase';

function splitBySeparators(str: string, separators: string[]) {
  if (!str) return [];
  const reg = new RegExp(`[${separators.join()}]`);
  return `${str}`.split(reg).filter(token => token);
}

class ComplexSearchAdvancedItemExtendStore {
  constructor(
    private instance: ComplexSearch,
    private controlInstance: InstanceBase,
    private item: IComplexSearchAdvancedConfig,
  ) {}

  handleUpdateValueAfter(
    e: ActionAfterEventArg<
      IValueState,
      ValueControlActionType,
      IValueControlUpdateValueParams<any>
    >,
  ) {
    const {
      id,
      title,
      control = { nodeName: '' },
      op = OperationTypeEnum.eq,
      dbType = DataTypeEnum.String,
    } = this.item;
    const { value, text } = e.newState.state;
    const item: IComplexSearchAdvancedData = { id, title, value, text };
    let filter: Filter | null = new Filter(FilterTypeEnum.and);
    if (item.value) {
      switch (control.nodeName) {
        case 'textbox':
          filter.addCondition({
            left: id,
            right: item.value,
            op: OperationTypeEnum.like,
            title: item.title,
          });
          break;
        case 'radioList':
        case 'select':
          filter = new Filter(FilterTypeEnum.or);
          const { tokenSeparators } = e.newState.state as any,
            valueArr: string[] = splitBySeparators(item.value, tokenSeparators);
          for (const right of valueArr) {
            filter.addCondition({
              left: id,
              right,
              op: OperationTypeEnum.eq,
              title: item.title,
            });
          }
          break;
        case 'dateTimeRange':
        case 'numberRange':
          let { startFieldName, endFieldName } = e.newState.state as any;
          if (startFieldName === endFieldName) {
            startFieldName = 'start';
            endFieldName = 'end';
          }

          if (typeof item.value === 'object' && item.value._isObject) {
            const start = item.value[startFieldName],
              end = item.value[endFieldName];
            if (start) {
              filter.addCondition({
                left: id,
                right: item.value[startFieldName],
                op: OperationTypeEnum.greaterEq,
                title: item.title,
              });
            }
            if (end) {
              filter.addCondition({
                left: id,
                right: item.value[endFieldName],
                op: OperationTypeEnum.lessEq,
                title: item.title,
              });
            }
          }
          break;
        default:
          break;
      }
    } else {
      filter = null;
    }

    this.instance.store.dispatch<IComplexSearchUpdateAdvancedItemsParams>('updateAdvancedItems', {
      advancedItems: { [id]: item },
      advancedFilterItems: { [id]: filter },
    });
  }
}

class ComplexSearchExtendStore extends ContainerExtendStoreBase<ComplexSearch> {
  handleInitialStateBefore(e: ActionBeforeEventArg<IComplexSearchInitialStateParams>) {
    const { initConfig } = e.params,
      advanced: IComplexSearchAdvancedState[] = [];
    if (initConfig && initConfig.advanced && initConfig.advanced.items) {
      initConfig.advanced.items.forEach(item => {
        const {
          id,
          title,
          control,
          index = 1,
          op = OperationTypeEnum.eq,
          dbType = DataTypeEnum.String,
        } = item;
        if (!control.id) control.id = id;
        control['title'] = title;
        const controlInstance = this.instance.createControl(item.control);

        if (controlInstance) {
          controlInstance.store.bindExtendStore(
            new ComplexSearchAdvancedItemExtendStore(this.instance, controlInstance, item),
          );
          advanced.push({
            id,
            title,
            index,
            op,
            dbType,
            controls: controlInstance.store,
            controlProps: {
              id: controlInstance.id,
              parentId: this.instance.id,
              pageKey: this.instance.page.id,
            },
          });
        }
      });
    }
    e.params.advanced = advanced;
  }

  handleUpdateAdvancedItemsAfter(
    e: ActionAfterEventArg<IComplexSearchState, ComplexSearchActionType>,
  ) {
    const { deleting, searchValue } = e.newState.state;
    if (deleting) e.newState.dispatch('find', { value: searchValue });
  }

  handleDeleteAdvancedItemAfter(
    e: ActionAfterEventArg<
      IComplexSearchState,
      ComplexSearchActionType,
      { item: IComplexSearchAdvancedData }
    >,
  ) {
    this.instance.__deleteAdvancedItem(e.params.item.id);
  }

  handleFindAfter(
    e: ActionAfterEventArg<IComplexSearchState, ComplexSearchActionType, IComplexSearchFindParams>,
  ) {
    const { _filter: filter, advancedFilterItems } = e.newState.state;
    if (e.params.isReset) this.instance.__reset();
    if (this.instance.eventManager.getHandlerCount('find') > 0) {
      const data: IComplexSearchFindData = { filter, standardFilter: e.params.standardFilter };
      if (!e.params.isReset) {
        data.advancedFilterItems = advancedFilterItems;
      }
      this.instance.eventManager.trigger<IComplexSearchFindData>('find', data);
    }
  }
}

export default ComplexSearchExtendStore;
