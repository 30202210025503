import Store, { IReduce } from '@mjcloud/redux';
import { IOperationContentState, IOperationContentInitialStateParams } from './typings';
import { Size } from '@mjcloud/types';

class OperationContentReduce implements IReduce<IOperationContentState> {
  initialState(store: Store<IOperationContentState>, params: IOperationContentInitialStateParams) {
    const { initConfig, controls, ChildrenControls } = params;
    const state: IOperationContentState = {
      controls,
      tabIndex: -1,
      display: true,
      ChildrenControls,
      configIsFetching: false,
      config: initConfig,
    };
    return state;
  }

  updateSize(store: Store<IOperationContentState>, params: { size: Size }) {
    if (params) {
      const { width, height } = params.size,
        { size } = store.state;
      if (size) {
        const { width: oldwidth, height: oldheight } = size;
        if (oldwidth === width && oldheight === height) return store.state;
      }

      return { ...store.state, size: params.size };
    }
    return store.state;
  }

  updateDisplay(store: Store<IOperationContentState>, params: { id: string; display: boolean }) {
    const state: IOperationContentState = store.state,
      { id, display } = params;
    let isUpdate = false;
    state.ChildrenControls = state.ChildrenControls.map(controlProps => {
      if (id === controlProps.id && controlProps.display !== display) {
        controlProps.display = display;
        isUpdate = true;
      }
      return controlProps;
    });
    return isUpdate ? { ...state } : state;
  }
}

export default new OperationContentReduce();
