import Store from '@mjcloud/redux';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { ISwitchState, ISwitchInitialStateParams } from './typings';

export class SwitchReduce extends ValueReduceBase<number | string, ISwitchState> {
  initialState(store: Store<ISwitchState>, params: ISwitchInitialStateParams) {
    const { initConfig, pageMode } = params,
      { title, disabled, modifyMode, disabledMode, displayMode } = initConfig,
      { trueText, falseText, trueValue, falseValue } = initConfig,
      { value = 0, text = falseText } = store.state;
    let _disabled = PageModeHelper.disabledMode2boolean(pageMode, disabledMode);
    // if (disabled != null) _disabled = disabled;

    const state: ISwitchState = {
      text,
      title,
      value,
      trueText,
      falseText,
      trueValue,
      falseValue,
      tabIndex: 0,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: _disabled,
    };
    return state;
  }

  updateValue(store, params) {
    const state = super.updateValue(store, params);
    const { value, trueValue, trueText, falseText } = state;
    state.text = trueValue == value ? trueText : falseText;
    return { ...state };
  }
}

export default new SwitchReduce();
