import React from 'react';
import { Tooltip } from 'antd';
import DyIcon from '@mjcloud/icon';
import styled from 'styled-components';

interface IReviseProps {
  record: any;
  isLock: boolean;
  onClick: () => void;
}

interface IReviseIconProps {
  isApprove?: boolean;
  onClick: () => void;
}

const Icon = styled(DyIcon)`
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding-top: 3px;
`;

const LockIcon = styled(function(props) {
  return <Icon {...props} type="lock-fill" />;
})<IReviseIconProps>`
  color: #1890ff !important;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
`;

const UnlockIcon = styled(function(props) {
  return <Icon {...props} type="unlock-fill" />;
})<IReviseIconProps>`
  color: #faad14 !important;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
`;

const Revise: React.FC<IReviseProps> = ({ record, isLock, onClick }) => {
  const { _currentUserCanRevise, _currentUserCanLock, _promoterName, _userName, approveState } = record;
  const canUnLock = approveState === 9 || approveState == null;
  const canLock = _currentUserCanRevise || _currentUserCanLock;
  if (isLock) return <LockIcon disabled={!canUnLock} onClick={canUnLock ? onClick : undefined} />;

  let title = _userName == null ? null : `修订人: ${_userName}<br />解锁人: ${_promoterName}`;
  if (!title) return <UnlockIcon disabled={!canLock} onClick={canLock ? onClick : undefined} />;
  return (
    <Tooltip title={<div dangerouslySetInnerHTML={{ __html: title }} />} placement="topLeft">
      <UnlockIcon disabled={!canLock} onClick={canLock ? onClick : undefined} />
    </Tooltip>
  );
};

export default Revise;
