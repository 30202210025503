import Store from '@mjcloud/redux';
import PageModeHelper from '@mjcloud/page-mode-helper';
import SelectReduceBase from '../common/select/reduce';
import { IRadioState, IRadioInitialStateParams } from './typings';

export class RadioReduce extends SelectReduceBase {
  initialState(store: Store<IRadioState>, params: IRadioInitialStateParams) {
    const { initConfig, pageMode } = params,
      { value = '' } = store.state,
      { textFieldName, displayMode, disabledMode, title, modifyMode } = initConfig,
      { isAutoSelection, placeholder = `请选择${title || ''}` } = initConfig;
    let state: IRadioState = {
      title,
      value,
      placeholder,
      tabIndex: 0,
      rowIdCount: 1,
      dataSource: [],
      isValid: false,
      originalData: [],
      selectedRows: [],
      config: initConfig,
      multiSelect: false,
      originalDataSource: [],
      tokenSeparators: [',', '、'],
      textTokenSeparator: '、',
      vaueleTokenSeparator: ',',
      // TODO: defaultOpen配置值待实现
      defaultOpen: false,
      configIsFetching: false,
      textFieldName: textFieldName,
      isAutoSelection: !!isAutoSelection,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    state.tokenSeparators = state.tokenSeparators
      .concat([state.textTokenSeparator, state.vaueleTokenSeparator])
      .filter((tokenSeparator, index, self) => self.indexOf(tokenSeparator) === index);
    return state;
  }
}

export default new RadioReduce();
