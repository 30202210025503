
/**
 * 操作列最小宽度；操作列在收缩时宽度
 */
export const OPERATIONWIDTH = 80;
/**
 * 未设置列宽度下列最小宽度
 */
export const AUTOCELLWIDTH = 200;
/**
 * 序号列宽度
 */
export const SERIALNUMBERWIDTH = 50;
