import Reduce from './reduce';
import SelectInstanceBase from '../common/select';
import Store, { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import { ControlConfig, DataModeEnum } from '@mjcloud/types';
import SelectExtendStoreBase from '../common/select/extendStore';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import {
  IRadioButtonsConfig,
  IRadioButtonsState,
  RadioButtonsEventType,
  RadioButtonsActionType,
} from './typings';

class RadioButtonsExtendStore extends SelectExtendStoreBase<string, RadioButtons> {
  handleLoadedAfter(e: ActionAfterEventArg<IRadioButtonsState, RadioButtonsActionType, any>) {
    super.handleLoadedAfter(e);
    const { dataSource, isAutoSelection, multiSelect } = e.newState.state;
    if (isAutoSelection && !multiSelect && dataSource[0]) {
      const { _value, _text } = dataSource[0];
      e.params = { ...e.params, value: _value, text: _text };
      this.handleUpdateValueAfter(e);
    }
  }

  handleUpdateActiveIndexBefore(e: ActionBeforeEventArg<any>) {
    const { _text, _value } = this.instance.store.state.dataSource[e.params.activeIndex];
    e.params = { ...e.params, value: _value, text: _text };
    this.handleUpdateValueBefore(e);
  }

  handleUpdateActiveIndexAfter(e) {
    this.handleUpdateValueAfter(e);
  }
}

class RadioButtons extends SelectInstanceBase<
  string,
  IRadioButtonsState,
  RadioButtonsEventType,
  RadioButtonsActionType
> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  __createStore() {
    return new Store<IRadioButtonsState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new RadioButtonsExtendStore(this),
    });
  }

  initialState(initConfig: IRadioButtonsConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}

export default RadioButtons;
