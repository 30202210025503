import Store from '@mjcloud/redux';
import dayjs, { Dayjs } from 'dayjs';
import { IDictionary } from '@mjcloud/types';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { PanelMode } from 'rc-picker/lib/interface';
import { DateFormatType, IDateTimeRangeState, IDateTimeRangeInitialStateParams } from './typings';

function dateFormat2momentFormat(format?: DateFormatType) {
  let _format: string,
    mode: PanelMode = 'date',
    showTime = false;
  switch (format) {
    case 'HH:mm':
    case 'HH:mm:ss':
      _format = format;
      mode = 'time';
      break;
    case 'yyyy-MM-dd HH:mm':
      _format = 'YYYY-MM-DD HH:mm';
      showTime = true;
      break;
    case 'yyyy-MM-dd HH:mm:ss':
      _format = 'YYYY-MM-DD HH:mm:ss';
      showTime = true;
      break;
    case 'yyyy-MM':
      _format = 'YYYY-MM';
      mode = 'month';
      break;
    case 'yyyy':
      _format = 'YYYY';
      mode = 'year';
      break;
    case 'yyyy-MM-dd':
    default:
      _format = 'YYYY-MM-DD';
      break;
  }
  return { format: _format, mode, showTime };
}

export class DateTimeRangeReduce extends ValueReduceBase<IDictionary<Dayjs>> {
  initialState(store: Store<IDateTimeRangeState>, params: IDateTimeRangeInitialStateParams) {
    const { initConfig, pageMode } = params,
      { businessType, disabledMode, displayMode, max, min } = initConfig,
      { startFieldName, endFieldName, startPlaceholder, endPlaceholder } = initConfig,
      { title, needFast = false, modifyMode } = initConfig;
    const { format, mode, showTime } = dateFormat2momentFormat(initConfig.format);
    let state: IDateTimeRangeState = {
      mode,
      title,
      format,
      showTime,
      needFast,
      tabIndex: -1,
      businessType,
      endFieldName,
      startFieldName,
      endPlaceholder,
      startPlaceholder,
      config: initConfig,
      configIsFetching: false,
      value: { _isObject: true as any },
      max: max ? dayjs(max) : undefined,
      min: min ? dayjs(min) : undefined,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }

  updateValue(store: Store<IDateTimeRangeState>, params) {
    const state = super.updateValue(store, params) as IDateTimeRangeState;

    if (!state.value) {
      state.checkedFast = '-1';
      state.value = { _isObject: true } as any;
    } else {
      const { value, format, startFieldName, endFieldName } = state;

      if (value[startFieldName]) {
        value[startFieldName] = dayjs(dayjs(value[startFieldName]).format(format));
      }
      if (value[endFieldName]) {
        value[endFieldName] = dayjs(dayjs(value[endFieldName]).format(format));
        if ((state.mode === 'date', !state.showTime)) {
          value[endFieldName] = value[endFieldName]
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59);
        }
      }

      state.value = value;
      state.text = `${dayjs(value[startFieldName]).format(format)} ~ ${dayjs(
        value[endFieldName],
      ).format(format)}`;
      // params.text = state.text;
    }
    return state;
  }

  updateFast(store: Store<IDateTimeRangeState>, params) {
    return { ...store.state, checkedFast: params.checkedFast };
  }
}

export default new DateTimeRangeReduce();
