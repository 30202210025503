import React from 'react';
import { Result } from 'antd';
import { IDictionary } from '@mjcloud/types';
import { ExceptionHelper } from '@mjcloud/exception';

import styles from './login.less';

export interface IErrorInfo extends IDictionary {
  name: string;
  title: string;
  message: string;
}

export function hideScreen() {
  const screen = document.getElementById('screen');
  if (screen) {
    screen.style.display = 'none';
    // document.removeChild(screen);
  }
}

export function handleException(ex: IErrorInfo) {
  hideScreen();
  switch (ex.name) {
    case 'SyncingException':
      // 应用初始化异常
      ex.title = '应用初始化中';

    default:
      ex.title = '登录异常: ' + ex.name;
      break;
  }
  return { error: ex };
}

export const ErrorException: React.FC<{ error: IErrorInfo }> = ({ error }) => (
  <Result status="403" className={styles.error} title={error.title} subTitle={error.message} />
);

export const Screen: React.FC<{}> = () => <div id="screen"></div>;
