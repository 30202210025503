import React from 'react';
import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import { MAINPATH } from './constant';
import globalData from '@mjcloud/global-data';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import { handleException, IErrorInfo, ErrorException } from './util';

interface IDDLoginPageState {
  error?: IErrorInfo;
}

class DDLoginPage extends React.Component<IRouteComponentProps, IDDLoginPageState> {
  constructor(props: IRouteComponentProps) {
    super(props);
    this.state = {};
    this.ddSignin();

    // globalData.debugger = true
    // window['_requestAuthCode'] = async () => {
    //   const { code } = await JsApiHelper.requestAuthCode({ corpId: globalData.corpId });
    //   console.log('_requestAuthCode', code);
    // };
  }

  async ddSignin() {
    const { match, history } = this.props,
      appId = match.params.appId,
      corpId = globalData.corpId,
      { search, origin } = window.location,
      preLoginAddress: any = await JsApiHelper.getStorage('preLoginAddress');
    if (preLoginAddress) {
      JsApiHelper.removeStorage('preLoginAddress');
      // console.log('ddSignin', preLoginAddress);
    }
    try {
      // await Service.logout();
      const { code } = await JsApiHelper.requestAuthCode({ corpId });
      await Service.ddSignin({ appId, corpId, authCode: code });
      // Service.ddConfig({ appId, corpId, url: encodeURIComponent(origin + search) });
      history.replace(preLoginAddress ? preLoginAddress : `/${appId}${MAINPATH}`);
    } catch (ex) {
      const state = handleException(ex);
      if (state) this.setState(state);
    }
  }

  render() {
    const { error } = this.state;
    return error ? <ErrorException error={error} /> : null;
  }
}

export default DDLoginPage;
