import React from 'react';
import Icon from '@mjcloud/icon';
import classNames from 'classnames';

import styles from './index.less';

interface IListItemProps {
  onClick?(): void;
  readonly: boolean;
  theme: string;
  iconfont: string;
  placeholder: string;
  extra?: any;
}

const ListItem: React.FC<IListItemProps> = ({
  extra,
  theme,
  iconfont,
  readonly,
  placeholder,
  onClick,
}) => {
  if (theme === 'default') theme = 'def';
  return (
    <div onClick={onClick} className={classNames(styles[theme], styles.container)}>
      {extra ? (
        <div className={styles.value}>{extra}</div>
      ) : (
        !readonly && <div className={styles.placeholder}>{placeholder}</div>
      )}
      {!readonly && <Icon type={iconfont} className={styles.arrow} />}
    </div>
  );
};

export default ListItem;
