import { ILabelConfig } from './typings';

const config: ILabelConfig = {
  title: '',
  autoFocus: false,
  isHtml: undefined,
  value: undefined,
  defaultValue: undefined,
  fontSize: undefined,
  fontColor: undefined,
  fontWeight: undefined,
  format2Date: undefined,
  format2Number: undefined,
  modifyMode: undefined,
  controllerId: undefined,
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
