import React from 'react';
import Link from 'umi/link';
import styles from './index.less';
import FooterView from '../Footer';
import { IAppCopyright } from '@mjcloud/types';

interface IUserLayoutProps {
  title: string;
  desc: string;
  copyright?: IAppCopyright;
  className?: string;
}

const UserLayout: React.SFC<IUserLayoutProps> = ({
  title,
  desc,
  copyright,
  className,
  children,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.lang} />
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.header}>
            <img alt="logo" className={styles.logo} src="/images/logo_32x32.png" />
            <span className={styles.title}>{title}</span>
          </div>
          <div className={styles.desc}>{desc}</div>
        </div>
        <div className={className}>{children}</div>
      </div>
      {copyright && <FooterView {...copyright} />}
    </div>
  );
};

export default UserLayout;
