import Iframe from '..';
import React from 'react';
import ControlBase from '../../base';
import globalData from '@mjcloud/global-data';
import { IIframeState } from '../typings';

class IframeControl extends ControlBase<IIframeState, Iframe> {
  renderContent() {
    const { Load } = globalData;
    const { Template, pageKey } = this.state;
    if (Template && pageKey) return <Template id={pageKey} parentId={pageKey} pageKey={pageKey} />;
    return <Load />;
  }
}

export default IframeControl;
