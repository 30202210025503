import { ICondition } from '@mjcloud/utils/dist/filter';
import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { Filter, FilterTypeEnum, OperationTypeEnum } from '@mjcloud/utils';
import {
  IComplexSearchState,
  IComplexSearchInitialStateParams,
  IComplexSearchUpdateValueParams,
  IComplexSearchFindParams,
  IComplexSearchItem,
  IComplexSearchUpdateAdvancedItemsParams,
} from './typings';

export class ComplexSearchReduce extends ReduceBase<IComplexSearchState> {
  initialState(store: Store<IComplexSearchState>, params: IComplexSearchInitialStateParams) {
    const { initConfig, pageMode, advanced } = params,
      { expand, standard, searchText } = initConfig;
    let items: IComplexSearchItem[] = [],
      tips: Array<string> = [];
    if (standard) {
      for (const item of standard.items) {
        items.push(item);
        tips.push(item.title);
      }
    }
    const {
      tip = `请输入${tips.length > 0 ? tips.join('/') : '关键字'}`,
      displayMode,
    } = initConfig;
    const state: IComplexSearchState = {
      items,
      expand,
      advanced,
      searchText,
      tabIndex: -1,
      placeholder: tip,
      advancedItems: {},
      advancedHeight: 0,
      advancedWidth: window.innerWidth - 480,
      config: initConfig,
      _nowValue: undefined,
      searchValue: undefined,
      advancedFilterItems: {},
      configIsFetching: false,
      _filter: Filter.EqualFilter,
      // advancedHeight: advanced.length > 0 ? advanced.length * 52 : 0,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  updateAdvancedHeight(store: Store<IComplexSearchState>, params) {
    return { ...store.state, advancedHeight: params.advancedHeight };
  }
  updateAdvancedWidth(store: Store<IComplexSearchState>, params) {
    return { ...store.state, advancedWidth: params.advancedWidth };
  }

  updateValue(store: Store<IComplexSearchState>, params: IComplexSearchUpdateValueParams) {
    return { ...store.state, searchValue: params.value };
  }

  find(store: Store<IComplexSearchState>, params: IComplexSearchFindParams) {
    let state =
      params.expand == null ? store.state : this.updateExpand(store, { expand: params.expand });
    // if (state._nowValue === params.value) return state;
    state._nowValue = params.value;

    let filter: Filter = new Filter(FilterTypeEnum.and),
      standardFilter: Filter;
    if (!!params.value) {
      standardFilter = new Filter(FilterTypeEnum.or);
      for (var item of state.items) {
        const condition: ICondition = {
          left: item.left,
          right: params.value,
          op: OperationTypeEnum.like,
          title: item.title,
        };
        standardFilter.addCondition(condition);
      }
    } else {
      standardFilter = Filter.EqualFilter;
    }
    params.standardFilter = standardFilter;
    filter.addCondition(standardFilter);
    if (!params.isReset) {
      for (const key in state.advancedFilterItems) {
        const condition = state.advancedFilterItems[key];
        if (condition) {
          filter.addCondition(condition);
        }
      }

      state.selectedItems = Object.getOwnPropertyNames(state.advancedItems)
        .map(key => state.advancedItems[key])
        .filter(item => !!item)
        .filter(item => !!item.value);
    } else {
      state.selectedItems = [];
    }
    state._filter = filter;
    state.deleting = false;
    return state;
  }

  updateAdvancedItems(
    store: Store<IComplexSearchState>,
    params: IComplexSearchUpdateAdvancedItemsParams,
  ) {
    let state: IComplexSearchState = { ...store.state };
    state.advancedItems = { ...state.advancedItems, ...params.advancedItems };
    state.advancedFilterItems = { ...state.advancedFilterItems, ...params.advancedFilterItems };
    return state;
  }

  updateExpand(store: Store<IComplexSearchState>, params: { expand: boolean }) {
    return { ...store.state, expand: params.expand };
  }

  deleteAdvancedItem(store: Store<IComplexSearchState>, params: {}) {
    store.state.deleting = true;
    return store.state;
  }
}

export default new ComplexSearchReduce();
