import Chart from '.';
import { DataExtendStoreBase } from '@mjcloud/extend-store';

export default class ChartExtendStore extends DataExtendStoreBase<Chart> {
  protected isNeedNotificationPage = true;

  handleInitialStateBefore(e) {
    e.params.chartId = this.instance.id;
  }
}
