import RbCore from '@mjcloud/core-helper';
import { ListView } from 'antd-mobile';
import F2 from '@antv/f2/lib/index-all';
import globalData from '@mjcloud/global-data';
import { ControlFactory } from '../mcontrols';
import { controlDefaults, controlTypes } from '@mjcloud/mcontrols';

function run() {
  globalData.AntV = F2;
  globalData.rbCore = new RbCore(true);
  globalData.__controlDefaults = controlDefaults;
  globalData.controlTypes = controlTypes;
  globalData.ControlFactory = ControlFactory;
  globalData.__ListViewDataSource = ListView.DataSource;
  var deviceWidth = document.documentElement.clientWidth;
  if (deviceWidth > 750) deviceWidth = 750;
  document.documentElement.style.fontSize = deviceWidth + 'px';
}

export default run;
