import React from 'react';
import Icon from '@mjcloud/icon';
import classNames from 'classnames';
import { findDOMNode } from 'react-dom';
import { GridView } from '@mjcloud/mcontrols';
import { EventManager } from '@mjcloud/utils';
import PageHelper from '@mjcloud/page-helper';
import ControlBase from '../../core/base';
import ControllerHelper from '@mjcloud/controller';
import { ExceptionHelper } from '@mjcloud/exception';
import { Empty, Spin, Avatar, Tag, Skeleton, Row, Col } from 'antd';
import { List, Checkbox, ListView, PullToRefresh, Progress } from 'antd-mobile';
import { IGridViewState, IGridViewColumn } from '@mjcloud/mcontrols/dist/controls/grid-view/typings';

import styles from './index.less';

function __handler2Fn(sender, handler) {
  handler.trim();
  return PageHelper.createPageScriptFunction(sender, handler, true);
}

export default class GridViewControl extends ControlBase<IGridViewState, GridView> {
  private handleRefresh = () => {
    this.instance.refresh({ type: 'down' });
  };
  private handleEndReached = () => {
    const { type, current } = this.state;
    if (type !== 'done') this.instance.refresh({ type: 'up', pageIndex: current + 1 });
  };
  private handleTouch = (event: React.TouchEvent<HTMLDivElement>) => {
    console.log('handleTouch', event.changedTouches);
  };
  private handleClick = (row: any) => {
    this.instance.store.dispatch('rowClickAfter', { row });
  };
  private handleMoreClick = () => {
    this.instance.store.dispatch('moreClickAfter', {});
  };
  private handleCheckboxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, row: any) => {
    event.stopPropagation();
    row._selected = !row._selected;
    this.instance.store.dispatch('updateDataSource', { row });
    this.instance.eventManager.trigger('selectedChange', { row });
  };
  private handleHyperlinkClick = async (row: any, button: any) => {
    // TODO: 超链接的点击事件，这里不应该出现哈……
    const { text, controllerId, onBeforeClick, onAfterClick } = button;
    const exclusiveLockId = this.instance.page.applyExclusiveLock(`正在执行${text}操作...`);
    if (exclusiveLockId === 0) {
      return;
    }
    const eventManager = new EventManager(this.instance);
    if (onBeforeClick)
      eventManager.add('onBeforeClick', __handler2Fn(this.instance.page, onBeforeClick));
    if (onAfterClick)
      eventManager.add('onAfterClick', __handler2Fn(this.instance.page, onAfterClick));
    let params = { row },
      before = async data => {
        if (eventManager.getHandlerCount('onBeforeClick') > 0) {
          await eventManager.trigger('onBeforeClick', data);
        }
        return data;
      },
      after = async data => {
        if (eventManager.getHandlerCount('onAfterClick') > 0) {
          await eventManager.trigger('onAfterClick', data);
        }
        return data;
      };
    try {
      if (controllerId) {
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId,
          data: params,
          before,
          after,
        });
      } else {
        await before(params);
        await after(params);
      }

      this.instance.page.releaseExclusiveLock(exclusiveLockId);
    } catch (error) {
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      ExceptionHelper.dispose(error);
    }
  };

  renderEmpty(style?: React.CSSProperties) {
    return (
      <div className={styles.empty} style={style}>
        <Empty description={this.state.emptyText} />
      </div>
    );
  }

  renderNormalBrief(row: any) {
    switch (row._cols) {
      case 1:
        return row._briefList.map((brief, i) => (
          <div key={i} className={brief.className} style={brief.style2react}>
            {brief.text}
          </div>
        ));
      case 2:
        return (
          <div className={styles.normalBrief}>
            {row._briefList.map((brief, i) => {
              return (
                <div key={i} className={styles.normalBriefItem}>
                  <div className={classNames(styles.normalLeft, 'dy-font-elipsis')}>
                    {brief.left.text}
                  </div>
                  <div className={classNames(styles.normalRight, 'dy-font-elipsis')}>
                    {brief.right.text}
                  </div>
                </div>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  }
  renderNormal(row: any) {
    const { rowSelection } = this.state,
      showTags = row._tags.length >= 1,
      showFooter = showTags || row._buttons.length >= 1 || row._footer.length >= 1,
      showBrief = row._briefList.length >= 1 || showFooter,
      handleClick = () => this.handleClick(row),
      handleCheckboxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        this.handleCheckboxClick(event, row),
      avatar = row._avatar.url ? (
        <Avatar src={row._avatar.url} />
      ) : row._avatar.icon ? (
        <Avatar>
          <Icon type={row._avatar.icon} />
        </Avatar>
      ) : row._avatar.text ? (
        <Avatar>{row._avatar.text}</Avatar>
      ) : (
        undefined
      ),
      // TODO: 需要重新调整选择功能
      selection = (
        <div className={styles.checkbox} onClick={handleCheckboxClick}>
          <Checkbox checked={row._selected} />
        </div>
      ),
      // TODO: 需要考虑头像和选择并存时的处理机制
      thumb = rowSelection ? selection : avatar,
      extra = row._extra ? (
        <div
          className={styles.extra}
          style={row._extra.style2react}
          onClick={event => {
            event.stopPropagation();
            this.handleHyperlinkClick(row, row._extra.control);
          }}
        >
          {row._extra.text}
        </div>
      ) : null;
    return (
      <List.Item key={row.id} extra={extra} thumb={thumb} onClick={handleClick}>
        <div className={styles.normal}>
          <div className={styles.normalTitleContainer}>
            {row._title ? (
              <div
                className={classNames(styles.normalTitle, 'dy-font-elipsis')}
                style={row._title.style2react}
              >
                {row._title.text}
              </div>
            ) : null}
            {row._subtitle ? (
              <div
                className={classNames(styles.normalSubtitle, 'dy-font-elipsis')}
                style={row._subtitle.style2react}
              >
                {row._subtitle.text}
              </div>
            ) : null}
          </div>
          {row._status ? (
            <div
              className={classNames(styles.normalStatus, 'dy-font-elipsis')}
              style={row._status.style2react}
            >
              {row._status.text}
            </div>
          ) : null}
        </div>
        {showBrief ? (
          <List.Item.Brief>
            {this.renderNormalBrief(row)}
            {showFooter && (
              <div
                className={classNames(
                  styles.normalFooter,
                  row._buttons.length >= 1 && styles.cardFooter,
                )}
              >
                {showTags && (
                  <div className={styles.normalTags}>
                    {row._tags.map((tag, i) => (
                      <Tag key={i} color={tag.type}>
                        {tag.text}
                      </Tag>
                    ))}
                  </div>
                )}
                {row._footer.length >= 1 && (
                  <div
                    className={classNames(
                      styles.normalFooterContainer,
                      showTags ? styles.right : styles.left,
                    )}
                  >
                    {row._footer.map((footer, i) => (
                      <div key={i}>{`${footer.title}: ${footer.text}`}</div>
                    ))}
                  </div>
                )}
                <div className={styles.normalButtons}>
                  {row._buttons.map((button, i) => {
                    if (button.controlType == 'hyperlink') {
                      const handleClick = () => this.handleHyperlinkClick(row, button.control);
                      return (
                        <div key={i} className={styles.normalHyperlink} onClick={handleClick}>
                          {button.text}
                        </div>
                      );
                    }
                    return <span key={i}>{button.text}</span>;
                  })}
                </div>
              </div>
            )}
          </List.Item.Brief>
        ) : null}
      </List.Item>
    );
  }

  renderStatistics(row: any) {
    const showTags = row._tags.length >= 1,
      showFooter = row._tags.length >= 1 || row._footer.length >= 1,
      handleClick = () => this.handleClick(row);
    return (
      <List.Item key={row.id} onClick={handleClick}>
        <div className={styles.statisticsTitleContainer}>
          {row._title ? (
            <div
              className={classNames(styles.statisticsTitle, 'dy-font-elipsis')}
              style={row._title.style2react}
            >
              {row._title.text}
            </div>
          ) : null}
          {row._subtitle ? (
            <span
              className={classNames(styles.statisticsSubtitle, 'dy-font-elipsis')}
              style={row._subtitle.style2react}
            >
              {row._subtitle.text}
            </span>
          ) : null}
          <i className={styles.arrow}></i>
        </div>

        <List.Item.Brief>
          {(row._briefList as any[][]).map((items, key) => {
            const span = 24 / row._cols;
            return (
              <Row key={key} gutter={24} style={{ width: '100%' }}>
                {items.map(({ style2react, title, text }, index) => (
                  <Col span={span} key={index} style={{ padding: 0 }}>
                    <div className={styles.statisticsBrief} style={style2react}>
                      <div className={styles.statisticsBriefText}>{text}</div>
                      <div className={styles.statisticsBriefTitle}>{title}</div>
                    </div>
                  </Col>
                ))}
              </Row>
            );
          })}
          {showFooter && (
            <div
              className={classNames(
                styles.statisticsFooter,
                // row._buttons.length >= 1 && styles.cardFooter,
              )}
            >
              {showTags && (
                <div className={styles.statisticsTags}>
                  {row._tags.map((tag, i) => (
                    <Tag key={i} color={tag.type}>
                      {tag.text}
                    </Tag>
                  ))}
                </div>
              )}
              {row._footer.length >= 1 && (
                <div
                  className={classNames(
                    styles.statisticsFooterContainer,
                    showTags ? styles.right : styles.left,
                  )}
                >
                  {row._footer.map((footer, i) => (
                    <div key={i}>{`${footer.title}: ${footer.text}`}</div>
                  ))}
                </div>
              )}
            </div>
          )}
          {row._progress ? (
            <div className={styles.statisticsProgress}>
              <Progress percent={row._progress.percent} position="normal"></Progress>
              <div
                className={classNames(
                  styles.statisticsProgressText,
                  row._progress.percent === 0 && styles.statisticsProgressZero,
                )}
              >
                {row._progress.percent === 0
                  ? row._progress.zero
                  : row._progress.percent === 100
                  ? row._progress.full
                  : row._progress.percent + '%'}
              </div>
            </div>
          ) : null}
        </List.Item.Brief>
      </List.Item>
    );
  }

  renderStyle1(row: any) {
    const handleClick = () => this.handleClick(row);
    return (
      <List.Item key={row.id} onClick={handleClick}>
        <div className={styles.style1}>
          <div
            className={classNames(styles.style1Title, 'dy-font-elipsis')}
            style={row._title.style2react}
          >
            {row._title.text}
          </div>
          <div
            className={classNames(styles.style1Extra, 'dy-font-elipsis')}
            style={row._extra.style2react}
          >
            {row._extra.text}
          </div>
        </div>
        <List.Item.Brief>
          <div className={styles.style1Brief}>
            {row._briefList.map((brief, i) => {
              return (
                <div key={i} className={styles.style1BriefItem}>
                  <div
                    className={classNames(styles.style1Left, 'dy-font-elipsis')}
                    style={brief.left.style2react}
                  >
                    {brief.left.text}
                  </div>
                  <div
                    className={classNames(styles.style1Right, 'dy-font-elipsis')}
                    style={brief.right.style2react}
                  >
                    {brief.right.text}
                  </div>
                </div>
              );
            })}
          </div>
        </List.Item.Brief>
      </List.Item>
    );
  }

  renderRow = (rowData: any) => {
    const { styleName } = this.state;
    switch (styleName) {
      case 'normal':
        return this.renderNormal(rowData);
      case 'statistics':
        return this.renderStatistics(rowData);
      case 'style1':
        return this.renderStyle1(rowData);
      default:
        return <List.Item key={rowData.id}>xml配置缺失</List.Item>;
    }
  };

  renderFooter = () => {
    const { isFetching, type, total, pageSize } = this.state;
    let children: React.ReactNode = '';
    if (isFetching && type === 'up') {
      children = [
        <Icon key="load" type="icon-load" />,
        <span key="text" className={styles.moreName}>
          加载中……
        </span>,
      ];
    } else if (type !== 'done' && total > pageSize) {
      return (
        <a className={styles.more} onClick={this.handleEndReached}>
          点击加载更多
        </a>
      );
    } else {
      children = '我是有底线的';
    }
    return <div className={styles.more}>{children}</div>;
  };

  private ref = (instance: any | null) => {
    const lv = findDOMNode(instance),
      parentNode: any = lv && lv.parentNode;
    if (parentNode && parentNode.clientHeight) {
      this.instance.store.dispatch('updateScrollHeight', { height: parentNode.clientHeight });
    }
  };

  renderList() {
    const { _dataSource, pageSize, dataSource = [], isFetching, height, type } = this.state,
      style = { minHeight: '100%', height },
      pullToRefreshProps: any = {
        direction: 'down',
        refreshing: type === 'down' && isFetching,
        distanceToRefresh: window.devicePixelRatio * 25,
        onRefresh: this.handleRefresh,
      };
    if (dataSource.length > 0) {
      if (this.state.isLongList) {
        return (
          <ListView
            ref={this.ref}
            style={style}
            pageSize={pageSize}
            useBodyScroll={false}
            dataSource={_dataSource}
            renderRow={this.renderRow}
            onEndReachedThreshold={10}
            scrollRenderAheadDistance={500}
            renderFooter={this.renderFooter}
            onEndReached={this.handleEndReached}
            pullToRefresh={<PullToRefresh {...pullToRefreshProps} />}
            // renderBodyComponent={() => <CustomizeBody />}
          />
        );
      } else {
        return (
          <List style={style} ref={this.ref}>
            {dataSource.map(row => this.renderRow(row))}
          </List>
        );
      }
    }

    return this.renderEmpty(style);
  }

  renderTableColumn(column: IGridViewColumn) {
    const { dataSource } = this.state;
    const { id, field, width2rem: width, title, align } = column;
    return (
      <div key={id} style={{ width, flexBasis: width }} className={classNames(styles.tableRows)}>
        <div className={styles.tableColumn} style={{ textAlign: align }}>
          {title}
        </div>
        {dataSource.map(row => {
          const onClick = () => this.handleClick(row);
          return (
            <div
              key={row.id}
              onClick={onClick}
              style={{ textAlign: align }}
              className={classNames({
                [styles.tableRow]: true,
                [styles.tableRowActive]: row._highlight,
              })}
            >
              {row._row[field]}
            </div>
          );
        })}
      </div>
    );
  }

  renderTable() {
    const { columns, leftColumns, height, dataSource, columnWidthTotal2rem } = this.state,
      { table, total, isFetching, isLongList, pageSize } = this.state;
    let children: React.ReactNode;
    if (dataSource.length > 0) {
      children = (
        <React.Fragment>
          {leftColumns.length > 0 && (
            <div className={classNames(styles.tableBody, styles.tableFixedLeft, styles.fixed)}>
              {leftColumns.map(column => this.renderTableColumn(column))}
            </div>
          )}
          <div
            className={styles.tableBody}
            style={{ width: columnWidthTotal2rem }}
            // onTouchStart={this.handleTouch}
            // onTouchMove={this.handleTouch}
          >
            {columns.map(column => {
              const { fixed, width2rem: width } = column;
              return (
                <React.Fragment key={column.id}>
                  {fixed ? (
                    <div style={{ width, flexBasis: width }} />
                  ) : (
                    this.renderTableColumn(column)
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </React.Fragment>
      );
    } else {
      children = this.renderEmpty({ minHeight: '100%', height });
    }
    return (
      <Spin ref={this.ref} spinning={isFetching}>
        <div style={{ minHeight: height }}>
          <div className={styles.tableContainer}>
            <div className={styles.table}>{children}</div>
            {table && table.isMore && total > pageSize ? (
              <div className={styles.tableMore} onClick={this.handleMoreClick}>
                查看更多
              </div>
            ) : isLongList ? (
              <div className={styles.tableMore}>{this.renderFooter()}</div>
            ) : null}
          </div>
        </div>
      </Spin>
    );
  }

  renderContent() {
    const { styleName, isFetching, type } = this.state,
      containerClassName = classNames({
        [styles.container]: true,
        [styles.statistics]: styleName === 'statistics',
      });
    let length = 4,
      children: React.ReactNode;
    switch (styleName) {
      case 'table':
        length = 2;
        children = this.renderTable();
        break;
      default:
        children = this.renderList();
        break;
    }
    if (isFetching && type === 'first') {
      children = (
        <div className={styles.loading}>
          {Array.from({ length }, (_, i) => (
            <Skeleton key={i} active avatar />
          ))}
        </div>
      );
    }
    return <div className={containerClassName}>{children}</div>;
  }
}
