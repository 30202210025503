import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IWaterfallState, IWaterfallInitialStateParams } from './typings';

class WaterfallReduce extends ReduceBase<IWaterfallState> {
  initialState(store: Store<IWaterfallState>, params: IWaterfallInitialStateParams) {
    let { initConfig, items, pageMode } = params,
      { displayMode } = initConfig;
    const s: IWaterfallState = {
      items,
      tabIndex: -1,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return s;
  }
}

export default new WaterfallReduce();
