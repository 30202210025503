import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { DataModeEnum } from '@mjcloud/types';
import SelectInstanceBase from '../common/select';
import SelectExtendStoreBase from '../common/select/extendStore';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { ICascaderConfig, ICascaderState, CascaderEventType, CascaderActionType } from './typings';
import { ISelectBaseDataSource } from '../common/select/typings';

class CascaderExtendStore extends SelectExtendStoreBase<string, Cascader> {
  protected isFirstLoad = true;
}

function flattenTree(options: ISelectBaseDataSource[], ancestor: ISelectBaseDataSource[] = []) {
  let flattenOptions: ISelectBaseDataSource[][] = [];
  options.forEach(option => {
    const path = ancestor.concat(option);
    if (!option._children || !option._children.length) {
      flattenOptions.push(path);
    }
    if (option._children) {
      flattenOptions = flattenOptions.concat(flattenTree(option._children, path));
    }
  });
  return flattenOptions;
}

class Cascader extends SelectInstanceBase<
  string,
  ICascaderState,
  CascaderEventType,
  CascaderActionType
> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  __createStore() {
    return new Store<ICascaderState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new CascaderExtendStore(this),
    });
  }

  initialState(initConfig: ICascaderConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  choose2Name(name: string) {
    const { dataSource, textTokenSeparator, vaueleTokenSeparator } = this.store.state;
    const list = flattenTree(dataSource)
      .map(row => ({
        text: row.map(item => item._text).join(textTokenSeparator),
        value: row.map(item => item._value).join(vaueleTokenSeparator),
      }))
      .filter(row => row.text.search(name) >= 0);
    if (list.length > 0) {
      const { text, value } = list[0];
      this.store.dispatch('updateValue', { text, value, actionSourceSign: this.store });
    }
  }
}

export default Cascader;
