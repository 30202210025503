import React from 'react';
import { Modal } from 'antd';
import JsApiHelper from '@mjcloud/jsapi';
import Service, { IFileInfo } from '@mjcloud/service';
import { FormulaHelper, NumberHelper } from '@mjcloud/utils';

interface IDownloadModalProps {
  title: string;
  okText: string;
  file: IFileInfo;
  destroy(): void;
}

const sizeFormula = FormulaHelper.create('data.size / data.base');

function size2string(size: number) {
  if (typeof size !== 'number') size = NumberHelper.parse(size);
  if (isNaN(size)) return '0 kb';
  if (size > 1024) {
    return sizeFormula.calc({ size, base: 1024 }) + 'KB';
  } else if (size > 1024 * 1024) {
    return sizeFormula.calc({ size, base: 1024 * 1024 }) + 'MB';
  } else if (size > 1024 * 1024 * 1024) {
    return sizeFormula.calc({ size, base: 1024 * 1024 * 1024 }) + 'GB';
  }
  return size + 'B';
}
const DownloadModal: React.FC<IDownloadModalProps> = ({ destroy, file, title, okText }) => {
  const onOk = async () => {
    destroy();
    JsApiHelper.downloadFile({ url: Service.getUrl(file.url), name: file.name });
  };
  return (
    <div>
      <Modal visible={true} title={title} okText={okText} onOk={onOk} onCancel={destroy}>
        <p>文件名 : {file.name}</p>
        <p>文件大小: {size2string(file.size)}</p>
      </Modal>
    </div>
  );
};

export default DownloadModal;
