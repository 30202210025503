import React from 'react';
import globalData from '@mjcloud/global-data';
import ControlBase from '../../core/base';
import { ObjectHelper } from '@mjcloud/utils';
import RouterHelper from '@mjcloud/router-helper';
import ListItem from '../../component/ListItem';
import { IListEditState } from '@mjcloud/mcontrols/dist/controls/list-edit/typings';

export default class ListEditControl extends ControlBase<IListEditState> {
  private handleClick = () => {
    const { title } = this.state,
      { controlId, parentId, pageKey } = this.props,
      search = ObjectHelper.params2search({
        pageKey,
        parentId,
        controlId,
        _title: title,
        type: 'ListEdit',
      });
    RouterHelper.push({ pathname: `/${globalData.appId}/_extra`, search });
  };

  renderContent() {
    const { isFetching, showSummary, aggPropertieValue } = this.state,
      props = { theme: 'default', iconfont: 'icon-right', readonly: false, placeholder: '' };

    if (isFetching) return <ListItem {...props} extra="加载中……" />;
    return (
      <ListItem
        {...props}
        extra={showSummary ? aggPropertieValue : ''}
        onClick={this.handleClick}
      />
    );
  }
}
