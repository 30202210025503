import React from 'react';
import { Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import ControlBase from '../../base';
import { IDictionary } from '@mjcloud/types';
import { DatePicker } from '../../../components';
import { IDateTimeRangeState } from '../typings';
import locale from 'antd/es/date-picker/locale/zh_CN';
import styles from './index.less';

interface IFastItem {
  key: string;
  text: string;
}

const { CheckableTag } = Tag;
const fastList: IFastItem[] = [
  { key: '0', text: '全部' },
  { key: '1', text: '最近半年' },
  { key: '2', text: '最近3个月' },
  { key: '3', text: '本月' },
];

class DateTimeRangeControl extends ControlBase<IDateTimeRangeState> {
  private handCommChange(value: any[] | null): IDictionary<Dayjs> {
    const [start, end] = value || [];
    const { startFieldName, endFieldName, value: oldval } = this.state;
    // if (oldval[0] === value[0] && oldval[1] === value[1]) return;

    let _value: any = { _isObject: true };
    if (startFieldName === endFieldName) {
      _value = { start, end, _isObject: true };
    } else {
      _value = { [startFieldName]: start, [endFieldName]: end, _isObject: true };
    }
    return _value;
  }
  private handChange = (value: any[] | null) => {
    this.instance.store.dispatch('updateValue', {
      value: this.handCommChange(value),
      actionSourceSign: this.instance.store,
    });
    this.instance.store.dispatch('updateFast', {
      checkedFast: undefined,
      actionSourceSign: this.instance.store,
    });
  };

  private handFastChange = (record: IFastItem, checked: boolean) => {
    if (checked) {
      this.instance.store.dispatch('updateFast', {
        checkedFast: record.key,
        actionSourceSign: this.instance.store,
      });
      let value: IDictionary<Dayjs> | undefined,
        start: Dayjs,
        now = dayjs()
          .set('date', 1)
          .add(1, 'month');
      switch (record.key) {
        case '0':
          value = this.handCommChange([undefined, undefined]);
          break;
        case '1': // 半年
          start = dayjs().subtract(6, 'month');
          value = this.handCommChange([start, now]);
          break;
        case '2': // 3个月
          start = dayjs().subtract(3, 'month');
          value = this.handCommChange([start, now]);
          break;
        case '3': // 本月
          start = dayjs().set('date', 1);
          value = this.handCommChange([start, now]);
          break;
        default:
          break;
      }
      if (value)
        this.instance.store.dispatch('updateValue', {
          value,
          actionSourceSign: this.instance.store,
        });
    }
  };

  renderContent() {
    const {
      mode,
      format,
      showTime,
      needFast,
      readonly,
      value = {},
      checkedFast,
      startPlaceholder,
      endPlaceholder,
    } = this.state;
    let { startFieldName, endFieldName } = this.state;
    if (readonly) {
      const startDate = value[startFieldName] ? dayjs(value[startFieldName]).format(format) : null;
      const endDate = value[endFieldName] ? dayjs(value[endFieldName]).format(format) : null;
      if (startDate && endDate) return `${startDate} ~ ${endDate}`;
      if (!startDate && !endDate) return '';
      return `${startDate || 'NULL'} ~ ${endDate || 'NULL'}`;
    }

    if (startFieldName === endFieldName) {
      startFieldName = 'start';
      endFieldName = 'end';
    }
    const children = (
      <DatePicker.RangePicker
        locale={locale}
        mode={[mode, mode]}
        allowEmpty={[true, true]}
        format={format}
        showTime={showTime}
        value={[value[startFieldName], value[endFieldName]]}
        placeholder={[startPlaceholder, endPlaceholder]}
        onChange={this.handChange}
      />
    );
    if (needFast) {
      return (
        <div className={styles.dataRangeNeedfast}>
          <div>
            {fastList.map(fast => {
              const handFastChange = (checked: boolean) => this.handFastChange(fast, checked);
              return (
                <CheckableTag
                  key={fast.key}
                  checked={checkedFast === fast.key}
                  onChange={handFastChange}
                >
                  {fast.text}
                </CheckableTag>
              );
            })}
          </div>
          <div className={styles.dataRangeNeedfastFlex}>{children}</div>
        </div>
      );
    }
    return children;
  }
}
export default DateTimeRangeControl;
