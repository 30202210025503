import Store from '@mjcloud/redux';
import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IChartState, IChartInitialStateParams } from './typings';

export class ChartReduce extends DataReduceBase<IChartState> {
  initialState(store: Store<IChartState>, params: IChartInitialStateParams) {
    let type = '';
    const { chartId, initConfig, pageMode } = params,
      { width, height, title, series, pageSize, orderby = [], displayMode } = initConfig,
      { nameFieldName, xAxisFieldName, yAxisFieldName } = initConfig;
    for (const key in series) {
      const serie = series[key];
      if (serie && typeof serie === 'object') {
        type = key;
        break;
      }
    }
    const state: IChartState = {
      title,
      width,
      height,
      series,
      orderby,
      pageSize,
      id: chartId,
      tabIndex: -1,
      themeName: '',
      dataSource: [],
      nameFieldName,
      xAxisFieldName,
      yAxisFieldName,
      chartType: type,
      config: initConfig,
      styleName: 'normal',
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  startLoad(store: Store<IChartState>, params) {
    const state = super.startLoad(store, params) as IChartState,
      { orderBy = [] } = params;
    if (orderBy.length === 0) params.orderBy = state.orderby;
    if (params.pageSize == null) params.pageSize = state.pageSize;
    state.orderby = params.orderBy || [];
    return state;
  }

  loaded(store: Store<IChartState>, params) {
    const state = super.loaded(store, params) as IChartState,
      { xAxisFieldName, yAxisFieldName } = state;
    state.dataSource = state.dataSource
      .filter(({ [xAxisFieldName]: x }) => x != null)
      .filter(({ [yAxisFieldName]: y }) => y != null);

    // state.dataSource = state.dataSource.map(row => {
    //   if (row[yAxisFieldName] == null) row[yAxisFieldName] = 0;
    //   return row;
    // });
    return state;
  }

  updateCanvasSize(store, params) {
    const state = store.state,
      { width, height } = params;
    state.width = width;
    state.height = height;
    return state;
  }

  updateTooltip(store, params) {
    const { tooltipItems = [], tooltipStyle = {}, tooltipStyleText = '' } = params;
    return { ...store.state, tooltipItems, tooltipStyle, tooltipStyleText };
  }
}

export default new ChartReduce();
