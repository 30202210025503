import { INumberConfig } from './typings';

const config: INumberConfig = {
  title: '',
  autoFocus: false,
  placeholder: undefined,
  format: '#,##0.00',
  max: 999999999999999,
  min: -999999999999999,
  businessType: undefined,
  modifyMode: undefined,
  textFieldName: 'text',
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
