import React from 'react';
import { Spin } from 'antd';
import Accordion from './Collapse';
import globalData from '@mjcloud/global-data';
import ControlBase from '../../core/base';
import { IAccordionState } from '@mjcloud/mcontrols/dist/controls/accordion/typings';

import styles from './index.less';

export default class AccordionControl extends ControlBase<IAccordionState> {
  renderContent() {
    const { dataSource, isFetching } = this.state;
    const { ControlFactory } = globalData;
    return (
      <Spin spinning={isFetching}>
        <Accordion accordion openAnimation={{}} className={styles.container}>
          {dataSource.map(({ _title, _content, _collapse }, key) => (
            <Accordion.Panel
              key={key}
              header={_title}
              collapseNode={_collapse.map(props => {
                return (
                  <React.Fragment key={props.controlId}>
                    <ControlFactory {...props} />
                  </React.Fragment>
                );
              })}
            >
              {_content.map(props => {
                return (
                  <React.Fragment key={props.controlId}>
                    <ControlFactory {...props} />
                  </React.Fragment>
                );
              })}
            </Accordion.Panel>
          ))}
        </Accordion>
      </Spin>
    );
  }
}
