import React from 'react';
import Icon from '@mjcloud/icon';
import { Form } from 'antd';
import Animate from 'rc-animate';
import classNames from 'classnames';
import intersperse from 'intersperse';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { PREFIX_CLS } from '../constant';
import { FIELD_META_PROP, FIELD_DATA_PROP } from './constant';

import styles from './index.less';
import { FormItemProps } from 'antd/lib/form';

export type ValidateStatusType = 'success' | 'warning' | 'error' | 'validating';

export interface IFormItemProps extends FormItemProps {
  labelWidth?: number | false;
  labelNumber?: number;
  labelTextAlign?: 'left' | 'right' | 'center';
  cols?: number;
}

export interface FormItemContext {
  vertical: boolean;
}

function labelNumber2Width(labelWidth?: number | false) {
  switch (labelWidth) {
    case 5:
      return 96;

    default:
      return 96;
  }
}

const AntFormItem = styled(Form.Item)<IFormItemProps>`
  display: flex;
  flex-wrap: nowrap;

  .ant-form-item-label {
    flex: 0 0 ${({ labelNumber }) => labelNumber2Width(labelNumber)}px;

    > label {
      display: inline-block;
      white-space: normal;
      padding-top: 6px;
      line-height: 1.5;
      margin-right: 6px;
      padding-right: 6px;
      height: auto;
    }

    > label::after {
      position: absolute;
      top: 4.5px;
      right: 0px;
      margin: 0px;
    }
  }

  .ant-form-item-control {
    width: 0px;
    flex: 1;
  }
`;

const FormItem: React.FC<IFormItemProps> = ({ children, ...props }) => {
  return <AntFormItem {...props}>{children}</AntFormItem>;
};
export default FormItem;
