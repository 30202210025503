import React from 'react';
import globalData from '@mjcloud/global-data';
import EditableComplexCellBaseControl from '../../gridedit-cell-base/pc';
import { IEditableComplexCellProps, IEditableComplexCellState } from '../typings';

export default class EditableComplexCellControl extends EditableComplexCellBaseControl<
  IEditableComplexCellProps,
  IEditableComplexCellState
> {
  handleClick = (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {};

  renderContent() {
    const { ControlFactory } = globalData;
    const { id, rowId, parentId, pageKey } = this.props;
    return <ControlFactory id={`${id}${rowId}`} parentId={parentId} pageKey={pageKey} />;
  }

  renderText() {
    const { ControlFactory } = globalData;
    const { id, rowId, parentId, pageKey } = this.props;
    return <ControlFactory id={`${id}${rowId}`} parentId={parentId} pageKey={pageKey} />;
  }
}
