import React from 'react';
import JsApiHelper from '@mjcloud/jsapi';
import { message, Modal } from 'antd';
import { ExceptionHelper } from '@mjcloud/exception';
import { ModalFuncProps } from 'antd/lib/modal';

ExceptionHelper.warn = exception => {
  const content = exception.message.replace(/\n/g, '<br />'),
    modalFuncProps: ModalFuncProps = {
      title: exception.title,
      content: <div dangerouslySetInnerHTML={{ __html: content }} />,
    };
  switch (exception.level) {
    case 'Info':
      Modal.info(modalFuncProps);
      break;
    case 'Warn':
      Modal.warn(modalFuncProps);
      break;
    case 'Error':
      Modal.error(modalFuncProps);
      break;
    case 'Success':
      Modal.success(modalFuncProps);
      break;
    case 'Msg':
    default:
      JsApiHelper.showToast({ content: exception.message, type: 'exception' });
      break;
  }
};
