import React from 'react';
import { Tree } from 'antd';
import styled from 'styled-components';
import { TreeProps } from 'antd/lib/tree';
import { ITreeDataSourceItem } from '@mjcloud/data-source-helper';

interface TreeListProps extends Omit<TreeProps, 'treeData' | 'children'> {
  type?: 'tile';
  treeData: ITreeDataSourceItem[];
  icon2TreeNode?: (row: ITreeDataSourceItem) => React.ReactNode;
}

const DyTree = styled(Tree)`
  .ant-tree-list-holder-inner {
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  .ant-tree-treenode-motion {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

function loopData(
  data: ITreeDataSourceItem[],
  icon2TreeNode?: (row: ITreeDataSourceItem) => React.ReactNode,
) {
  return data.map((item, index) => {
    const { _key, _text, _disabled, _children } = item;
    if (_children) {
      return (
        <Tree.TreeNode
          key={_key}
          title={_text}
          selectable={false}
          disabled={_disabled}
          style={{ width: '100%', flex: '0 0 100%' }}
        >
          {loopData(_children, icon2TreeNode)}
        </Tree.TreeNode>
      );
    }
    return (
      <Tree.TreeNode
        key={_key}
        icon={icon2TreeNode ? () => icon2TreeNode(item) : undefined}
        title={_text}
        selectable={false}
        disabled={_disabled}
        style={{ width: '25%', flex: '0 0 25%' }}
      />
    );
  });
}

const TreeList: React.FC<TreeListProps> = ({
  type,
  treeData,
  children,
  icon2TreeNode,
  ...treeProps
}) => <DyTree {...treeProps}>{loopData(treeData, icon2TreeNode)}</DyTree>;

export default TreeList;
