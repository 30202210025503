import Reduce from './reduce';
import BraftEditor from 'braft-editor';
import { ValueInstanceBase } from '@mjcloud/instance';
import Store, { ActionAfterEventArg } from '@mjcloud/redux';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { IValueControlUpdateValueParams } from '@mjcloud/instance/dist/valueInstanceBase';
import {
  IRichTextboxState,
  IRichTextboxConfig,
  RichTextboxEventType,
  IRichTextboxConfigStartLoadParams,
  RichTextboxActionType,
  IRichTextboxUpdateEditorStateParams,
} from './typings';

class RichTextboxExtendStore extends ValueExtendStoreBase<string, RichTextbox> {
  handleUpdateValueAfter(
    e: ActionAfterEventArg<
      IRichTextboxState,
      RichTextboxActionType,
      IValueControlUpdateValueParams<any>
    >,
  ) {
    if (e.params.actionSourceSign === this.instance.store) return;
    // TODO: 暂时禁用可通过 dataModel 修改 editorState
    if (e.params.actionSourceSign) {
      this.instance.store.dispatch<IRichTextboxUpdateEditorStateParams>('updateEditorState', {
        editorState: BraftEditor.createEditorState(e.params.value),
      });
    }
  }
}

class RichTextbox extends ValueInstanceBase<
  string,
  IRichTextboxState,
  RichTextboxEventType,
  RichTextboxActionType
> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store<IRichTextboxState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new RichTextboxExtendStore(this),
    });
  }

  initialState(initConfig: IRichTextboxConfig) {
    this.store.dispatch<IRichTextboxConfigStartLoadParams>('configStartLoad', {
      initConfig,
    });
  }
}

export default RichTextbox;
