import Reduce from './reduce';
import Store from '@mjcloud/redux';
import ToolbarExtendStoreBase from './extendStore';
import { ControlConfig, IControlSetConfig } from '@mjcloud/types';
import { ContainerInstanceBase, ValueInstanceBase } from '@mjcloud/instance';
import {
  IToolbarState,
  IToolbarConfig,
  IToolbarConfigStartLoadParams,
  ToolbarThemeType,
} from './typings';

let separateId = 1,
  buttonsId = 1;

class ToolBar extends ContainerInstanceBase<IToolbarState> {
  __createStore() {
    return new Store<IToolbarState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new ToolbarExtendStoreBase(this),
    });
  }

  initialState(initConfig: IToolbarConfig) {
    this.store.dispatch<IToolbarConfigStartLoadParams>('configStartLoad', {
      initConfig,
    });
  }

  createControl(config: IControlSetConfig, theme?: ToolbarThemeType) {
    if (config.nodeName === 'button' && theme === 'big') {
      config.size = 'large';
    }
    if (config.nodeName === 'separate') {
      config.id = 'separate' + (separateId + 1);
    }
    if (config.nodeName === 'buttons' && !config.id) {
      config.id = 'buttons' + (buttonsId + 1);
    }
    if (config.nodeName === 'search') {
      config.styleName = 'header';
    }
    return this.__createControl(config.id, config as ControlConfig);
  }

  setItemWidth(controlId: string, width: string | number) {
    const control = this.findControl<any>(controlId);
    if (control && control.setWidth) control.setWidth(width);
  }

  setItemValue(controlId: string, value: string) {
    const control = this.findControl(controlId);
    if (control instanceof ValueInstanceBase) control.setValue(value, value);
  }

  setItemHide(controlId: string) {
    const control = this.findControl(controlId);
    if (control) control.display = false;
  }

  setItemShow(controlId: string) {
    const control = this.findControl(controlId);
    if (control) control.display = true;
  }
}

export default ToolBar;
