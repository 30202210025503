import { IAutoCompleteConfig } from './typings';

const config: IAutoCompleteConfig = {
  title: '',
  search: [{ nodeName: 'item', left: 'text' }],
  placeholder: undefined,
  autoFocus: false,
  modifyMode: undefined,
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
