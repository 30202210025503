import React from 'react';
import { InputItem } from 'antd-mobile';
import ControlBase from '../../core/base';
import { ITextboxState } from '@mjcloud/mcontrols/dist/controls/textbox/typings';

export default class TextboxControl extends ControlBase<ITextboxState> {
  private handChange = (value: string) => {
    this.instance.store.dispatch('updateValue', {
      value,
      text: value,
      actionSourceSign: this.instance.store,
    });
  };

  renderContent() {
    if (this.state.readonly) return <>{this.state.text || ''}</>;
    const { value: _val, text, button, disabled } = this.state,
      { readonly, placeholder } = this.state,
      { ruleKey, isAutoCode, isUUID } = this.state;
    if (readonly) return <div className="dy-font-elipsis">{text}</div>;
    const value = button ? text : _val;
    if (ruleKey && isAutoCode) {
      if (isUUID || !value) return <>自动生成</>;
      return <>{this.state.text || ''}</>;
    }
    let _value = value == null ? undefined : value.toString();
    return (
      <InputItem
        value={_value}
        disabled={disabled}
        onChange={this.handChange}
        placeholder={placeholder}
        clear
      />
    );
  }
}
