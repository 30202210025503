"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _common = require("../../util/common");

var _shape = _interopRequireDefault(require("./shape"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var SHAPE_MAP = {};
var INDEX = '_INDEX';

function getComparer(compare) {
  return function (left, right) {
    var result = compare(left, right);
    return result === 0 ? left[INDEX] - right[INDEX] : result;
  };
}

var _default = {
  getGroupClass: function getGroupClass() {},
  getChildren: function getChildren() {
    return this.get('children');
  },
  addShape: function addShape(type, cfg) {
    if (cfg === void 0) {
      cfg = {};
    }

    var shapeType = SHAPE_MAP[type];

    if (!shapeType) {
      shapeType = (0, _common.upperFirst)(type);
      SHAPE_MAP[type] = shapeType;
    }

    var shape = new _shape["default"][shapeType](cfg);
    this.add(shape);
    return shape;
  },
  addGroup: function addGroup(cfg) {
    var groupClass = this.getGroupClass();
    var rst = new groupClass(cfg);
    this.add(rst);
    return rst;
  },
  contain: function contain(item) {
    var children = this.get('children');
    return children.indexOf(item) > -1;
  },
  sort: function sort() {
    var children = this.get('children');

    for (var i = 0, len = children.length; i < len; i++) {
      var child = children[i];
      child[INDEX] = i;
    }

    children.sort(getComparer(function (obj1, obj2) {
      return obj1.get('zIndex') - obj2.get('zIndex');
    }));
    return this;
  },
  drawInner: function drawInner(context) {
    var children = this.get('children');

    for (var i = 0, len = children.length; i < len; i++) {
      var child = children[i];
      child.draw(context);
    }

    return this;
  },
  clear: function clear() {
    var children = this.get('children');

    while (children.length !== 0) {
      children[children.length - 1].remove(true);
    }

    return this;
  },
  add: function add(items) {
    var self = this;
    var children = self.get('children');

    if (!(0, _common.isArray)(items)) {
      items = [items];
    }

    for (var i = 0, len = items.length; i < len; i++) {
      var item = items[i];
      var parent = item.get('parent');

      if (parent) {
        var descendants = parent.get('children');

        _common.Array.remove(descendants, item);
      }

      self._setEvn(item);

      children.push(item);
    }

    return self;
  },
  _setEvn: function _setEvn(item) {
    var self = this;
    var _self$_attrs = self._attrs,
        context = _self$_attrs.context,
        canvas = _self$_attrs.canvas,
        aria = _self$_attrs.aria;
    var _item$_attrs = item._attrs,
        isGroup = _item$_attrs.isGroup,
        type = _item$_attrs.type;
    item._attrs.parent = self;
    item._attrs.context = context;
    item._attrs.canvas = canvas; // 是否需要无障碍处理

    if (aria && item._attrs.aria !== false) {
      item._attrs.aria = aria;
    }

    if (type === 'text' && canvas && canvas.get('fontFamily')) {
      item._attrs.attrs.fontFamily = item._attrs.attrs.fontFamily || canvas.get('fontFamily');
    }

    var clip = item._attrs.attrs.clip;

    if (clip) {
      clip._attrs.parent = self;
      clip._attrs.context = context;
      clip._attrs.canvas = canvas;
    }

    if (isGroup) {
      var children = item._attrs.children;

      for (var i = 0, len = children.length; i < len; i++) {
        item._setEvn(children[i]);
      }
    }
  },
  _getAriaLabel: function _getAriaLabel() {
    var _this$_attrs = this._attrs,
        aria = _this$_attrs.aria,
        ariaLabel = _this$_attrs.ariaLabel,
        children = _this$_attrs.children; // 主动关闭

    if (!aria) return;
    var childAriaLabels = [];

    if (children && children.length) {
      for (var i = 0, len = children.length; i < len; i++) {
        var _childAriaLabel = children[i].getAriaLabel();

        if (_childAriaLabel) {
          childAriaLabels.push(_childAriaLabel);
        }
      }
    }

    var childAriaLabel = childAriaLabels.join(' '); // 2个都有时拼接成完整句子

    if (ariaLabel && childAriaLabel) {
      return ariaLabel + " " + childAriaLabel + " ";
    } // 只有1个，或者都没有


    return ariaLabel || childAriaLabel;
  }
};
exports["default"] = _default;