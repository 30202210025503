import Waterfall from '.';
import { ContainerExtendStoreBase } from '@mjcloud/extend-store';
import { IWaterfallItem } from './typings';

export default class WaterfallExtendStore extends ContainerExtendStoreBase<Waterfall> {
  handleInitialStateBefore(e) {
    const { initConfig } = e.params,
      items: IWaterfallItem[] = [];
    let isShowBottomBlank = true;
    if (initConfig && initConfig.items) {
      initConfig.items.items.forEach((item, index) => {
        if (item.isShowBottomBlank == false) {
          isShowBottomBlank = false;
        } else {
          isShowBottomBlank = index + 1 === initConfig.items.items.length ? false : true;
        }

        const instance = this.instance.createControl(item.id);
        if (instance) {
          items.push({
            id: instance.id,
            parentId: instance.parent.id,
            pageKey: instance.page.id,
            isShowBottomBlank,
          });
        }
      });
    }
    e.params.items = items;
  }
}
