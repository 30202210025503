import React from 'react';
import { AutoComplete } from 'antd';
import ControlBase from '../../base';
import AutoCompleteInstance from '..';
import { SelectValue } from 'antd/lib/select';
import { IAutoCompleteState } from '../typings';
import { DelayCallback } from '@mjcloud/utils';

class AutoCompleteControl extends ControlBase<IAutoCompleteState, AutoCompleteInstance> {
  private handsearcSDelayCallback = (searchText: string) => {
    this.instance.store.dispatch('updateOptions', { searchText });
  };

  private _searchDelayCallback = new DelayCallback(this.handsearcSDelayCallback);

  private handleChange = (value: SelectValue) => {
    this.instance.store.dispatch('updateValue', {
      value,
      text: value,
      actionSourceSign: this.instance.store,
    });
  };

  private handleSearch = (selectValue: string) => {
    this._searchDelayCallback.execute(selectValue);
  };

  renderContent() {
    if (this.state.readonly) return <>{this.state.text || ''}</>;
    const { disabled, options, placeholder, value, autoFocus } = this.state;
    if (this.state.configErrorMessage)
      return <AutoComplete disabled value={this.state.errorMessage} />;
    return (
      <AutoComplete
        value={value}
        id={this.props.id}
        disabled={disabled}
        dataSource={options}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onChange={this.handleChange}
        onSearch={this.handleSearch}
        allowClear
      />
    );
  }
}

export default AutoCompleteControl;
