import React from 'react';
import { Divider } from 'antd';
import ControlBase from '../../base';
import { ISeparateState } from '../typings';

class SeparateControl extends ControlBase<ISeparateState> {
  renderContent() {
    const { type } = this.state;
    return <Divider type={type} style={{ top: 0 }} />;
  }
}

export default SeparateControl;
