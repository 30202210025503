import React from 'react';
import styled from 'styled-components';
import { NumberHelper } from '@mjcloud/utils';
import { TableProps } from 'antd/lib/table';
import { Tooltip, Table as AntdTable } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { IColumnProps } from '../../../../components/VirtualizedTable/interface';


export type AlignType = 'left' | 'center' | 'right';

const SummaryRow = AntdTable.Summary.Row;
const SummaryCell = styled(AntdTable.Summary.Cell)<{ align?: AlignType }>(props => ({
  textAlign: props.align,
}));
const StoreTable = styled(AntdTable)`
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 4px 8px;
  }

  .ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
    padding: 0px;
  }
`;

export interface ITableProps<T> extends TableProps<T> {
  summaryHeight?: number;
  showSummary?: boolean;
  summaryDataSource?: T[];
}

function loopColumns(
  columns: IColumnProps<any>[],
  summaryData: any = {},
  isOne = false,
): React.ReactNode[] {
  return columns.map((column, index) => {
    const { key, align, colSpan, children } = column;
    if (isOne && index === 0) {
      return <SummaryCell index={index}>总计</SummaryCell>;
    }
    if (children) {
      return loopColumns(children, summaryData);
    }
    if (colSpan === 0) {
      return undefined;
    }
    let val = ' ',
      placement: TooltipPlacement = 'topLeft';
    if (align === 'right') {
      placement = 'topRight';
    }
    if (typeof key === 'string') {
      if (summaryData[key] != null) {
        const { control } = column,
          { format: _format = '#,##0.00' } = control,
          { format = _format } = control.summary;

        val = summaryData[key];
        val = NumberHelper.format(val, format);
      }
    }
    return (
      <SummaryCell index={index} colSpan={colSpan} align={align}>
        <Tooltip title={val} placement={placement}>
          <>{val}</>
        </Tooltip>
      </SummaryCell>
    );
  });
}

// function Table<T extends object = any>(props: ITableProps<T>) {
//   const { summaryDataSource = [], summaryHeight, showSummary, ...restProps } = props;
//   const isShowSummary = showSummary && summaryDataSource.length > 0;
//   const renderSummary = () => {
//     const { columns = [], summaryDataSource = [] } = props;
//     return <SummaryRow>{loopColumns(columns, summaryDataSource[0], true)}</SummaryRow>;
//   };
//   return (
//     <StoreTable<T>
//       size="small"
//       {...restProps}
//       summary={isShowSummary ? renderSummary : undefined}
//     />
//   );
// }

export default class Table<RecordType extends object = any> extends React.Component<
  ITableProps<RecordType>
> {
  constructor(props: ITableProps<RecordType>) {
    super(props);
    this.state = {};
  }

  renderSummary = (pageData: RecordType[]) => {
    const { columns = [], summaryDataSource = [] } = this.props;
    return <SummaryRow>{loopColumns(columns, summaryDataSource[0], true)}</SummaryRow>;
  };

  render() {
    const { summaryDataSource = [], summaryHeight, showSummary, ...restProps } = this.props;
    const isShowSummary = showSummary && summaryDataSource.length > 0;
    return (
      <StoreTable<any>
        size="small"
        {...restProps}
        summary={isShowSummary ? this.renderSummary : undefined}
      />
    );
  }
}
