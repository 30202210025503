import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { Dayjs } from 'dayjs';
import { IDictionary } from '@mjcloud/types';
import { ValueInstanceBase } from '@mjcloud/instance';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { IDateTimeRangeConfig, IDateTimeRangeState } from './typings';

class DateTimeRangeExtendStore extends ValueExtendStoreBase<IDictionary<Dayjs>, DateTimeRange> {}

class DateTimeRange extends ValueInstanceBase<IDictionary<Dayjs>> {
  get valueType(): ValidationRuleType {
    return 'object';
  }

  __createStore() {
    return new Store<IDateTimeRangeState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new DateTimeRangeExtendStore(this),
    });
  }

  initialState(initConfig: IDateTimeRangeConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}

export default DateTimeRange;
