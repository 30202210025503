import { IRichTextboxConfig } from './typings';

const config: IRichTextboxConfig = {
  title: '',
  placeholder: '',
  autoFocus: false,
  modifyMode: undefined,
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
